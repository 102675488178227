<template>
  <div class="absolute inset-0 flex items-center justify-center opacity-100 group-hover:opacity-0 transition-all duration-300 bg-white/5">
    <div class="bg-white/90 px-4 py-2 rounded-full shadow-sm flex items-center gap-2">
      <span class="material-symbols-outlined text-primary">visibility</span> <span class="text-sm font-medium text-slate-700">{{ t('common.clickToPreview') }}</span>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>