<template>
  <div class="h-[80vh] w-full bg-gray-50 relative" :class="!loading ? 'component-layout' : ''">
    <div v-if="loading" class="loading-overlay h-[80vh]">
      <loading/>
    </div>

    <div v-else class="max-w-7xl mx-auto py-8 px-4">
      <div class="bg-white rounded-lg shadow-sm border p-6">
        <!-- Package Info -->
        <div class="mb-10">
          <div class="flex items-center justify-between">
            <div>
              <!-- Package Title Row -->
              <div class="flex items-center gap-2 mb-2">
                <h2 class="text-xl font-medium text-gray-900">{{ currentPackage.name }}</h2>
                <span class="px-2 py-0.5 bg-green-100 text-green-800 text-xs font-medium rounded" v-if="currentPackage.status === t('settings.components.payment.packageInfo.status.active')">
                  {{ t('settings.components.payment.packageInfo.status.active') }}
                </span>
              </div>
              <!-- Package Details Row -->
              <div class="text-sm text-gray-500">
                {{ t('settings.components.payment.packageInfo.period') }} - {{ t('settings.components.payment.packageInfo.renewal') }}: {{ currentPackage.renewalDate }}
              </div>
            </div>
<!--            <button @click="showPackageModal = true" class="bg-primary text-white px-4 py-2 rounded text-sm hover:bg-primary/90 transition-colors">-->
<!--              {{ t('settings.components.payment.packageInfo.changePackage') }}-->
<!--            </button>-->
          </div>
        </div>

        <!-- Usage Header -->
        <div class="mb-6 pb-2 border-b">
          <h1 class="text-lg font-medium text-gray-900">
            {{ t('settings.components.payment.moduleUsage.title') }} </h1>
        </div>

        <!-- Modules Usage List -->
        <div class="space-y-6">
          <div v-for="module in List" :key="module.id" class="group hover:bg-gray-50 p-3 -mx-3 rounded-lg transition-colors duration-200">
            <div class="flex justify-between items-center mb-2">
              <div>
                <h3 class="text-sm font-medium text-gray-700 group-hover:text-gray-900 transition-colors">
                  {{ module.name }} </h3>
                <p class="text-xs text-gray-500 mt-0.5">
                  {{ formatUsage(module.usedAmount, module.totalAmount) }} {{ t('settings.components.payment.moduleUsage.unit') }} </p>
              </div>
              <span :class="getPercentageColor(module.usedAmount, module.totalAmount)" class="text-xs font-medium transition-colors">
                {{ formatPercentage(module.usedAmount, module.totalAmount) }}
              </span>
            </div>

            <!-- Progress Bar -->
            <div class="h-2 bg-gray-100 rounded-full overflow-hidden">
              <div class="h-full transition-all duration-500 ease-in-out transform origin-left" :class="getProgressBarColor(module.usedAmount, module.totalAmount)" :style="`width: ${calculatePercentage(module.usedAmount, module.totalAmount)}%`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { packages } from '@/networking/urlManager'
import Loading from '@/components/loading/VLoading.vue'
import axios from '@/plugins/axiosInstance'
import moment from 'moment'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const emit = defineEmits(['saving'])
const assetStore = useAssetStore()

const List = ref([])
const loading = ref(false)
const showPackageModal = ref(false)
const currentPackage = ref({
  name: '',
  period: t('settings.components.payment.packageInfo.period'),
  status: t('settings.components.payment.packageInfo.status.active'),
  renewalDate: '',
})

const formatUsage = (used, total) => {
  return `${used || 0}/${total || 0}`
}

const formatPercentage = (used, total) => {
  if (!used || !total) return '0%'
  const percentage = calculatePercentage(used, total)
  return `${percentage}%`
}

const calculatePercentage = (used, total) => {
  if (!used || !total) return 0
  return Math.round((used / total) * 100)
}

const getProgressBarColor = (used, total) => {
  const percentage = calculatePercentage(used, total)
  if (percentage >= 90) return 'bg-[#FF453A]'
  if (percentage >= 70) return 'bg-[#FF9F0A]'
  return 'bg-primary'
}

const getPercentageColor = (used, total) => {
  const percentage = calculatePercentage(used, total)
  if (percentage >= 90) return 'text-[#FF453A]'
  if (percentage >= 70) return 'text-[#FF9F0A]'
  return 'text-primary'
}

const getDetail = async () => {
  loading.value = true
  try {
    const result = await axios.get(packages.futureDetail, {
      params: {
        assetId: assetStore.assetId,
      },
    })

    currentPackage.value = {
      name: result.data.data.packageName || t('settings.components.payment.packageInfo.packageNotFound'),
      period: t('settings.components.payment.packageInfo.period'),
      status: t('settings.components.payment.packageInfo.status.active'),
      renewalDate: moment(result.data.data.endDate).format('YYYY-MM-DD') || t('settings.components.payment.unknown'),
    }

    List.value = result.data.data.features?.filter(r => r.lobsPerUse > 0)?.map(item => ({
      id: item.featureId,
      name: item.name,
      usedAmount: Math.max(0, Number(item.monthlyLobsLimit) - Number(item.remainingLobs)),
      totalAmount: Number(item.monthlyLobsLimit) || 0,
      unit: t('settings.components.payment.moduleUsage.unit'),
    })) || []
  } catch (error) {
    console.error('Error fetching package details:', error)
    List.value = []
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await getDetail()
})
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}


</style>