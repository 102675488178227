<template>
  <div class="h-full">
    <TransitionGroup v-if="props.memories && props.memories.length > 0" name="list" tag="ul" class="h-full space-y-2 px-4 overflow-auto thin-scrollbar">
      <li v-for="memory in props.memories" :key="memory.id" class="group border border-gray-100 rounded-lg transition-all duration-200 hover:border-gray-200 hover:bg-gray-50">
        <div class="p-3.5 flex justify-between items-start gap-4">
          <div class="space-y-1.5 flex-grow min-w-0">
            <h3 class="font-medium text-[15px] text-gray-700 line-clamp-2 group-hover:text-gray-900">
              {{ memory.description }} </h3>
            <div class="flex items-center gap-3 text-xs text-gray-500">
              <div class="flex items-center gap-1.5">
                <span class="text-gray-400">{{ t('settings.components.configuration.components.memoryHistory.components.memoryList.category') }}:</span>
                <span class="text-gray-600">{{ getContentCategory(memory.contentTypeId) }}</span>
              </div>
              <div class="w-1 h-1 bg-gray-200 rounded-full"></div>
              <div class="flex items-center gap-1.5">
                <span class="text-gray-400">{{ t('settings.components.configuration.components.memoryHistory.components.memoryList.commentType') }}:</span>
                <span class="text-gray-600">{{ getType(memory.commentTypeId) }}</span>
              </div>
            </div>
          </div>
          <button @click="deleteMemory(memory.id)" class="p-1 -mt-1 -mr-1 rounded-md text-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-200 hover:bg-gray-100 hover:text-red-500 focus:outline-none" :disabled="memory.deleting">
            <span v-if="memory.deleting" class="material-symbols-outlined !text-[20px] animate-spin">loop</span> <span v-else class="material-symbols-outlined !text-[20px] text-primary">delete</span>
          </button>
        </div>
      </li>
    </TransitionGroup>

    <div v-else class="h-full flex items-center justify-center">
      <p class="text-[15px] font-medium text-gray-400">
        {{ t('settings.components.configuration.components.memoryHistory.components.memoryList.noMemoryFound') }} </p>
    </div>
  </div>
</template>

<script setup>
import { TransitionGroup } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  memories: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['deleteMemory'])
const { t } = useI18n()

const deleteMemory = (id) => {
  const memory = props.memories.find(memory => memory.id === id)
  if (memory) {
    emit('deleteMemory', id)
  }
}

const getContentCategory = (id) => {
  const categories = {
    1: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.title'),
    2: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.category'),
    3: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.content'),
    4: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.keyword'),
    5: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.image'),
    6: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.seoDescription'),
    7: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.modelTraining'),
  }
  return categories[id] || ''
}

const getType = (id) => {
  const types = {
    1: t('settings.components.configuration.components.memoryHistory.components.memoryList.types.general'),
    2: t('settings.components.configuration.components.memoryHistory.components.memoryList.types.special'),
  }
  return types[id] || ''
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(15px);
}
</style>