<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    <IntegrationCard v-for="integration in sortedIntegrations" :key="integration.id" :integration="integration" @toggle="toggleIntegration" @manage="openManageModal"/>
  </div>

  <IntegrationManageModal v-model="showManageModal" :integration="selectedIntegration"/>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import IntegrationCard from './components/integrationCard.vue'
import IntegrationManageModal from './components/integrationManageModal/index.vue'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'

const assetStore = useAssetStore()
const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const emit = defineEmits(['saving'])

// Integration cards state
const integrations = ref([
  {
    id: 1,
    name: 'Instagram',
    icon: require('@/assets/icons/instagram.svg'),
    description: computed(() => t('settings.components.integrations.instagram.description')),
    loading: false,
    disabled: true,
    disabledTooltip: computed(() => t('settings.components.integrations.instagram.disabledTooltip')),
    connected: false,
  },
  {
    id: 2,
    name: 'Facebook',
    icon: require('@/assets/icons/facebook.svg'),
    description: computed(() => t('settings.components.integrations.facebook.description')),
    loading: false,
    disabled: true,
    disabledTooltip: computed(() => t('settings.components.integrations.facebook.disabledTooltip')),
    connected: false,
  },
  {
    id: 3,
    name: 'X',
    icon: require('@/assets/icons/twitterx.svg'),
    description: computed(() => t('settings.components.integrations.x.description')),
    loading: false,
    disabled: true,
    disabledTooltip: computed(() => t('settings.components.integrations.x.disabledTooltip')),
    connected: false,
  },
  {
    id: 6,
    name: 'Google',
    icon: require('@/assets/icons/google.svg'),
    description: computed(() => t('settings.components.integrations.google.description')),
    loading: false,
    disabled: false,
    disabledTooltip: computed(() => t('settings.components.integrations.google.disabledTooltip')),
    connected: false,
  },
])

// Modal state
const showManageModal = ref(false)
const selectedIntegration = ref(null)

// Computed
const sortedIntegrations = computed(() =>
    integrations.value.sort((a, b) => a.id - b.id),
)

// Integration management
let hasCheckedIntegration = ref(false)

const toggleIntegration = async (id) => {
  const integration = integrations.value.find(i => i.id === id)
  if (!integration) return

  integration.loading = true

  try {
    if (!integration.connected) await connectIntegration(id)
    else await disconnectIntegration(id)

  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.integrations.responseHandler.errors.toggleIntegration'))
  } finally {
    setTimeout(() => {
      integration.loading = false
    }, 3000)
  }
}

const openManageModal = async (id) => {
  const integration = integrations.value.find(i => i.id === id)
  if (!integration) return

  selectedIntegration.value = integration
  showManageModal.value = true
}

const connectIntegration = async (id) => {
  const redirectURL = `${window.location.origin}/settings?integration=${id}&tab=integrations`
  const response = await axios.post(socialMedia.socialAuth, { redirectURL, platformId: id })

  if (response.data.data) window.location.href = response.data.data
  else responseHandlerService.handleError(t('settings.components.integrations.responseHandler.errors.authenticationProcess'))

}

const disconnectIntegration = async (id) => {
  try {
    await axios.put(socialMedia.disconnect, { assetId: assetStore.assetId, platformId: id })

    const integration = integrations.value.find(i => i.id === id)
    if (integration) integration.connected = false

    responseHandlerService.handleSuccess(t('settings.components.integrations.responseHandler.success.disintegrationSuccessful', { integrationName: integration.name }))
  } catch (error) {
    const integration = integrations.value.find(i => i.id === id)
    responseHandlerService.handleError(error, t('settings.components.integrations.responseHandler.errors.integrationError', { integrationName: integration?.name }))
  }
}

const checkAuth = async () => {
  try {
    const response = await axios.get(socialMedia.checkSocialAuth, {
      params: {
        assetId: assetStore.assetId,
      },
    })

    Object.entries(response.data.data).forEach(([id, connected]) => {
      const integration = integrations.value.find(i => i.id === Number(id))
      if (integration) {
        integration.connected = connected
      }
    })
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.integrations.responseHandler.errors.authenticationProcess'))
  } finally {
    setTimeout(() => {
      integrations.value.forEach(integration => {
        integration.loading = false
      })
    }, 3000)
  }
}

const handleOAuthResponse = async (id, data) => {
  const integration = integrations.value.find(i => i.id === id)
  if (!integration) return

  integration.loading = true

  try {
    await axios.post(socialMedia.socialOauth, {
      data,
      redirectURL: `${window.location.origin}/settings?integration=${id}&tab=integrations`,
      assetId: assetStore.assetId,
      platformId: id,
    })

    integration.connected = true
    responseHandlerService.handleSuccess(
        t('settings.components.integrations.responseHandler.success.integrationSuccessful', {
          integrationName: integration.name,
        }),
    )

    if (id === 6) {
      selectedIntegration.value = integration
      showManageModal.value = true
    }

  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.integrations.responseHandler.errors.integrationError', { integrationName: integration.name }))
  } finally {
    setTimeout(() => {
      integration.loading = false
    }, 3000)
    await router.replace({
      query: {
        ...route.query,
        integration: undefined,
        code: undefined,
      },
    })
  }
}

const checkPendingIntegration = () => {
  if (hasCheckedIntegration.value) return

  let { integration: integrationCode, tab } = route.query
  const integration = Number(integrationCode)

  let params = {}
  if (integration === 1) { // Instagram
    params = instagramAuth()
  } else {
    const { code } = route.query
    params = { code }
  }

  if (integration && tab) {
    handleOAuthResponse(integration, params)
    hasCheckedIntegration.value = true
  }
}

const instagramAuth = () => {
  const hash = route.hash.slice(1)
  return hash.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    acc[key] = decodeURIComponent(value)
    return acc
  }, {})
}

// Watchers
watch(() => locale.value, () => {
  integrations.value.forEach(integration => {
    integration.description = computed(() =>
        t(`settings.components.integrations.${integration.name.toLowerCase()}.description`),
    )
  })
})


// Lifecycle hooks
onMounted(() => {
  integrations.value.forEach(integration => {
    integration.loading = true
  })

  checkAuth()
  checkPendingIntegration()
})
</script>