<template>
  <div class="flex flex-col h-full">
    <!-- Fixed Header -->
    <h3 class="text-sm font-medium text-zinc-700 mb-6 shrink-0">
      {{ t('settings.components.integrations.components.platforms.google.selectProperty') }} </h3>

    <!-- Loading State -->
    <div v-if="loading" class="flex-1 flex justify-center items-center">
      <span class="material-symbols-outlined animate-spin text-blue-500">
        progress_activity
      </span>
    </div>

    <!-- Scrollable Content -->
    <div v-else class="flex-1 min-h-0">
      <div class="h-full overflow-y-auto thin-scrollbar">
        <div class="space-y-3">
          <div v-for="property in sortedProperties" :key="property.id" class="group flex items-center justify-between p-3.5 rounded-xl border transition-all duration-200 hover:shadow-sm cursor-pointer" :class="[
              selectedPropertyId === property.id
                ? 'border-blue-200 bg-blue-50/50 shadow-sm'
                : 'border-zinc-100 hover:border-zinc-200 hover:bg-zinc-50'
            ]" @click="handlePropertySelect(property.id)">
            <div class="flex-1 min-w-0">
              <h4 class="font-medium text-zinc-900 truncate">
                {{ property.displayName }} </h4>
              <div class="mt-0.5 flex items-center text-xs text-zinc-500">
                <span class="truncate">{{ property.industryCategory }}</span> <span class="mx-2 text-zinc-300">•</span> <span class="truncate">{{ property.timeZone }}</span>
              </div>
            </div>

            <div class="ml-4 flex items-center">
              <span class="material-symbols-outlined transition-colors" :class="[
                  selectedPropertyId === property.id
                    ? 'text-blue-500'
                    : 'text-zinc-300 group-hover:text-zinc-400'
                ]">
                {{ selectedPropertyId === property.id ? 'check_circle' : 'radio_button_unchecked' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

const props = defineProps({
  integrationId: {
    type: [Number, String],
    required: false,
  },
})

const { t } = useI18n()
const assetStore = useAssetStore()

// State
const loading = ref(false)
const properties = ref([])
const selectedPropertyId = ref('')
const initialPropertyId = ref('')

// Flag to track initial data load
const isInitialDataLoad = ref(true)

// Computed
const hasChanges = computed(() => {
  return selectedPropertyId.value !== initialPropertyId.value
})

const sortedProperties = computed(() => {
  if (!properties.value.length) return []

  return [...properties.value].sort((a, b) => {
    // Only sort selected item to top on initial load
    if (isInitialDataLoad.value) {
      if (a.id === selectedPropertyId.value) return -1
      if (b.id === selectedPropertyId.value) return 1
    }

    // Always sort alphabetically
    return a.displayName.localeCompare(b.displayName)
  })
})

// Methods
const fetchProperties = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.getAnalyticProperties, {
      params: {
        assetId: assetStore.assetId,
        platformId: props.integrationId,
      },
    })

    properties.value = response.data.data.properties || []
    selectedPropertyId.value = response.data.data.propertyId || ''
    initialPropertyId.value = response.data.data.propertyId || ''

    // Set initial load flag to false after data is loaded
    setTimeout(() => {
      isInitialDataLoad.value = false
    }, 0)
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.integrations.components.platforms.google.fetchError'))
  } finally {
    loading.value = false
  }
}

const handlePropertySelect = (propertyId) => {
  selectedPropertyId.value = propertyId
}

// Expose methods and computed properties to parent
defineExpose({
  hasChanges,
  getData: () => ({
    propertyId: selectedPropertyId.value,
  }),
})

// Initialize
fetchProperties()
</script>