<template>
  <!--  <Alert v-if="dashboardStore.keywords.showWarning" class="mb-4" layout="px-6" status="warning" :message="t('assets.dashboard.keywordAnalysis.warnings.lowVolumeWarning', { count: dashboardStore.keywords.deletedKeywords.length })" :hasAction="true" :action="handleWarningAction" :actionText="t('assets.dashboard.keywordAnalysis.warnings.viewDetails')" :onDismiss="dismissWarning"/>-->

  <div class="bg-white p-4 rounded-md shadow-md relative">
    <CustomTable :data="keywords" :columns="columns" v-bind="tableProps" @sort="handleSort" @filter="handleFilter" min-width="900px">
      <template #table-actions>
        <div class="flex items-center justify-between w-full mr-2">
          <div class="flex items-center space-x-2">
            <h1 class="text-base font-semibold">{{ t('assets.dashboard.keywordAnalysis.title') }}</h1>
            <!--#TODO export kontrol edilecek birde işte rakip sütunu geri eklenecek-->

            <!--            <Tooltip :title="t('assets.dashboard.keywordAnalysis.tooltips.exportTooltip')" v-if="keywords.length > 0">-->
            <!--              <template #content>-->
            <!--                <div class="flex items-center">-->
            <!--                  <span @click="exportToExcel" class="material-symbols-outlined text-secondary cursor-pointer !text-[22px] hover:text-primary">-->
            <!--                    export_notes-->
            <!--                  </span>-->
            <!--                </div>-->
            <!--              </template>-->
            <!--            </Tooltip>-->
          </div>
        </div>
      </template>

      <template #extra-actions>
        <div class="flex-none ml-auto">
          <DateRangePicker v-model="dateRange" :currentYearOnly="true" class="w-full"/>
        </div>
      </template>

      <!-- Default cell templates -->
      <template #number="{ index }">{{ index + 1 }}</template>
      <template #assetName="{ item }">
        <div class="w-full flex items-center">
          <v-tooltip :title="item.assetName" :display-condition="shouldShowTooltip(item.assetName)">
            <template #content>
              <div class="max-w-[200px] truncate">{{ item.assetName }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>

      <template #keywordName="{ item }">
        <div class="w-full flex items-center">
          <v-tooltip :title="item.keywordName" :display-condition="shouldShowTooltip(item.keywordName)">
            <template #content>
              <div class="max-w-[200px] truncate">{{ item.keywordName }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>

      <template #yandex="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 1).formattedDate" :displayCondition="true">
            <template #content>
              <span :class="[getMaxPositionData(item, 1).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 1).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #google="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 2).formattedDate" :displayCondition="true">
            <template #content>
              <span :class="[getMaxPositionData(item, 2).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 2).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #bing="{ item }">
        <div class="flex items-center justify-center">
          <Tooltip :title="getMaxPositionData(item, 3).formattedDate" :displayCondition="true">
            <template #content>
              <span :class="[getMaxPositionData(item, 3).class, 'cursor-pointer']">
                {{ getMaxPositionData(item, 3).position }}
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <template #volume="{ item }">
        <div class="flex items-center justify-center w-full">
          <span :class="[getVolumeDisplay(item).class, 'whitespace-nowrap overflow-hidden text-ellipsis']" :title="getVolumeDisplay(item).fullText">
            {{ getVolumeDisplay(item).displayText }}
          </span>
        </div>
      </template>

      <template #inContent="{ item }">
        <div class="flex items-center justify-center">
          <span>{{ item.entityCount }}</span>
        </div>
      </template>

      <template #source="{ item }">
        <div class="flex items-center justify-center">
          <span :class="{ 'cursor-pointer text-blue-600': item.type === 3, 'cursor-default': item.type !== 1 }">
            {{ getSourceTypeLabel(item.type) }}
          </span>
        </div>
      </template>
    </CustomTable>

    <!-- Modals -->
    <MonthlyKeywordVolumeModal v-model="showMonthlyVolumeModal" :item="selectedKeyword"/>
    <MonthlyKeywordPositionsModal v-model="showMonthlyPositionModal" :item="selectedKeyword"/>
    <DeletedKeywordsModal v-model="showDeletedKeywordsModal" :keywords="dashboardStore.keywords.deletedKeywords"/>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { saveAs } from 'file-saver'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useDashboardStore } from '@/stores/dashboardOfAssets'
import CustomTable from '@/components/table/VTable.vue'
import DateRangePicker from '@/components/base/inputs/VDateRangePicker.vue'
import MonthlyKeywordVolumeModal from './components/keywordModals/monthlyKeywordVolumeModal.vue'
import MonthlyKeywordPositionsModal from './components/keywordModals/monthlyKeywordPositionsModal.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import Alert from '@/components/feedback/Alert.vue'
import DeletedKeywordsModal from './components/keywordModals/DeletedKeywordsModal.vue'
import VTooltip from '@/components/common/VTooltip.vue'

dayjs.extend(utc)

const props = defineProps({
  assetIdList: {
    type: Array,
    default: () => [],
  },
  isFetchable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['keywordData'])

const { t } = useI18n()
const dashboardStore = useDashboardStore()

// State
const keywords = ref([])
const dateRange = ref({ start: null, end: null })
const selectedKeyword = ref(null)
const showMonthlyVolumeModal = ref(false)
const showMonthlyPositionModal = ref(false)
const showDeletedKeywordsModal = ref(false)
const sortKey = ref('')
const sortOrder = ref('DESC')
const selectedSourceFilter = ref('')

// Filter items
const filterItems = computed(() => [
  { id: '', name: t('assets.dashboard.keywordAnalysis.sourceType.all'), default: true },
  { id: '2', name: t('assets.dashboard.keywordAnalysis.sourceType.suggestion') },
])

// Columns configuration
const columns = computed(() => [
  { key: 'number', label: t('assets.dashboard.keywordAnalysis.columns.number'), width: 'w-1/12' },
  { key: 'assetName', label: t('assets.dashboard.keywordAnalysis.columns.assetName'), width: 'w-2/12' },
  { key: 'keywordName', label: t('assets.dashboard.keywordAnalysis.columns.keywords'), width: 'w-2/12' },
  { key: 'volume', label: t('assets.dashboard.keywordAnalysis.columns.searchVolume'), width: 'w-2/12', align: 'center', sortable: true },
  { key: 'source', label: t('assets.dashboard.keywordAnalysis.columns.source'), width: 'w-1/12', align: 'center' },
  { key: 'yandex', label: t('assets.dashboard.keywordAnalysis.columns.yandexRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'google', label: t('assets.dashboard.keywordAnalysis.columns.googleRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'bing', label: t('assets.dashboard.keywordAnalysis.columns.bingRank'), width: 'w-1/12', align: 'center', sortable: true },
  { key: 'inContent', label: t('assets.dashboard.keywordAnalysis.columns.incontent'), width: 'w-1/12', align: 'center', sortable: true },
])

const tableProps = computed(() => ({
  isLoading: dashboardStore.isComponentLoading('keywords'),
  itemsPerPage: keywords.value.length,
  totalCount: dashboardStore.keywords.pagination.totalCount,
  pageCount: dashboardStore.keywords.pagination.pageCount,
  currentPage: dashboardStore.keywords.pagination.currentPage,
  sortKey: sortKey.value,
  sortOrder: sortOrder.value,
  showFilter: true,
  filterOptions: [{
    id: 'source',
    icon: 'filter_alt',
    iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
    showText: false,
    items: filterItems.value,
  }],
  minHeight: dashboardStore.keywords.showWarning ? '250px' : '300px',
  maxHeight: dashboardStore.keywords.showWarning ? '250px' : '300px',
  showPagination: false,
}))

// Methods
const fetchData = async () => {
  if (!props.isFetchable) return
  dashboardStore.setLoading('keywords', true)

  try {
    const params = {
      assetList: props.assetIdList,
      type: '2',
      pagination: 0,
      page: dashboardStore.keywords.pagination.currentPage - 1,
      sortKey: sortKey.value,
      sortOrder: sortOrder.value,
      ...(selectedSourceFilter.value && { source: selectedSourceFilter.value.source }),
      ...(dateRange.value.start && dateRange.value.end && {
        startDate: dayjs(dateRange.value.start).utc().format(),
        endDate: dayjs(dateRange.value.end).utc().format(),
      }),
    }

    const response = await axios.post(dashboard.getKeywordAnalysis, params)
    keywords.value = response.data.data
    emit('keywordData', keywords.value)

    dashboardStore.setPagination({
      pageCount: 1,
      totalCount: keywords.value.length,
      currentPage: 1,
    })

    checkWarningCondition(response.data.data.deletedKeywords || [])
  } catch (error) {
    console.error('Error fetching keywords:', error)
  } finally {
    dashboardStore.setLoading('keywords', false)
  }
}

const checkWarningCondition = (data) => {
  const hasWarning = data.length > 0
  if (hasWarning) {
    dashboardStore.keywords.showWarning = true
    dashboardStore.setDeletedKeywords(data)
  } else {
    dashboardStore.keywords.showWarning = false
    dashboardStore.setDeletedKeywords([])
  }
}

const getMaxPositionData = (item, engineId) => {
  if (!item?.positions || !Array.isArray(item.positions)) {
    return {
      position: dateRange.value.start ? 'N/A' : '100+',
      class: 'text-gray-400',
      formattedDate: t('assets.dashboard.keywordAnalysis.positions.noMeasurement'),
    }
  }

  const enginePositions = item.positions.filter(pos => pos.engine === engineId)
  if (enginePositions.length === 0) {
    return {
      position: dateRange.value.start ? 'N/A' : '100+',
      class: 'text-gray-400',
      formattedDate: t('assets.dashboard.keywordAnalysis.positions.noMeasurement'),
    }
  }

  const maxPosition = enginePositions.reduce((max, current) =>
      parseInt(current.position) < parseInt(max.position) ? current : max, enginePositions[0])

  const formattedDate = maxPosition.addDate ? dayjs(maxPosition.addDate).format('YYYY-MM-DD') : ''
  let position = maxPosition.position === '0' ? '100+' : maxPosition.position || 'N/A'
  const positionDifference = parseInt(maxPosition.positionDifference) || 0
  const numericPosition = parseInt(position)

  if (position === 'N/A' || numericPosition > 100) {
    position = '100+'
  } else if (dateRange.value.start) {
    const sign = positionDifference > 0 ? '+' : ''
    position += ` (${sign}${positionDifference})`
  }

  let positionClass = 'text-gray-700'
  if (numericPosition === 1) {
    positionClass = 'text-primary font-bold'
  } else if (position === '100+') {
    positionClass = 'text-gray-400'
  } else if (dateRange.value.start) {
    positionClass = positionDifference > 0
        ? 'text-green-500 font-bold'
        : (positionDifference < 0 ? 'text-red-500 font-bold' : 'text-gray-700')
  }

  return {
    position,
    class: positionClass,
    formattedDate: formattedDate ? t('assets.dashboard.keywordAnalysis.positions.lastMeasurement', { date: formattedDate }) : '',
  }
}

const getVolumeDisplay = (item) => {
  const volume = item.volume || 0
  const volumeChangePercentage = parseFloat(item.volumeChangePercentage) || 0

  let displayText = volume.toString()
  let fullText = displayText
  let volumeClass = 'text-sm'

  if (dateRange.value.start && volumeChangePercentage !== 0) {
    const sign = volumeChangePercentage > 0 ? '+' : ''
    const changeText = `(${sign}${volumeChangePercentage}%)`
    fullText = `${displayText} ${changeText}`
    displayText = fullText
    volumeClass += volumeChangePercentage > 0 ? ' text-green-500' : ' text-red-500'
  }

  if (fullText.length > 12) {
    volumeClass += ' text-xs'
  }

  return { displayText, fullText, class: volumeClass }
}

const getSourceTypeLabel = (type) => {
  const sourceTypes = {
    1: 'manuel',
    2: 'suggestion',
    3: 'similar',
  }
  return t(`assets.dashboard.keywordAnalysis.sourceType.${sourceTypes[type] || 'unknown'}`)
}

const shouldShowTooltip = (text) => {
  if (!text) return false
  const tempDiv = document.createElement('div')
  tempDiv.style.visibility = 'hidden'
  tempDiv.style.position = 'absolute'
  tempDiv.style.whiteSpace = 'nowrap'
  tempDiv.innerText = text
  document.body.appendChild(tempDiv)
  const width = tempDiv.offsetWidth
  document.body.removeChild(tempDiv)
  return width > 200
}

// Event Handlers
const handleSort = ({ key, order }) => {
  sortKey.value = key
  sortOrder.value = order
  fetchData()
}

const handleFilter = (selectedFilter) => {
  selectedSourceFilter.value = selectedFilter
  fetchData()
}

const handleWarningAction = () => {
  if (dashboardStore.keywords.deletedKeywords.length) {
    showDeletedKeywordsModal.value = true
  }
}

const dismissWarning = () => {
  dashboardStore.keywords.showWarning = false
}

const exportToExcel = () => {
  const params = {
    assetList: props.assetIdList,
    type: '2',
    pagination: 0,
    download: true,
    page: dashboardStore.keywords.pagination.currentPage - 1,
    sortKey: sortKey.value,
    sortOrder: sortOrder.value,
    source: selectedSourceFilter.value,
    ...(dateRange.value.start && dateRange.value.end && {
      startDate: dayjs(dateRange.value.start).utc().format(),
      endDate: dayjs(dateRange.value.end).utc().format(),
    }),
  }

  axios.post(dashboard.getKeywordAnalysis, params, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileName = `keyword_analysis_${dayjs().format('YYYY-MM-DD')}.xlsx`
        saveAs(blob, fileName)
      })
      .catch((error) => console.error('Export failed:', error))
}

// Watchers
watch(dateRange, (newValue, oldValue) => {
  if (newValue.start !== oldValue?.start || newValue.end !== oldValue?.end) {
    if (newValue.start && newValue.end) {
      dashboardStore.setPagination({ currentPage: 1 })
      fetchData()
    } else if (newValue.start === null && newValue.end === null) {
      fetchData()
    }
  }
})

watch(() => props.isFetchable, (newValue) => {
  if (newValue) {
    fetchData()
  }
})
</script>