<template>
  <div class="space-y-6">
    <!-- Error Message -->
    <div v-if="error" class="text-sm text-red-500 p-4 bg-red-50 rounded-lg">
      {{ error }}
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <!-- Credit Card Payment -->
      <div class="relative border-2 rounded-xl p-6 cursor-pointer transition-all duration-300" :class="getMethodClasses('iyzico')" @click="selectMethod('iyzico')">
        <div class="flex items-start space-x-4">
          <img src="../../../../../../../../../assets/logo/iyzico-logo.png" alt="iyzico" class="h-8 w-auto"/>
          <div>
            <h3 class="text-lg font-bold text-gray-900 mb-2">
              {{ t('assets.components.create.components.payment.methods.iyzico.title') }} </h3>
            <p class="text-sm text-gray-500">
              {{ t('assets.components.create.components.payment.methods.iyzico.description') }} </p>
            <div class="mt-4 flex items-center gap-2">
              <img v-for="card in cards" :key="card.name" :src="card.image" :alt="card.name" :class="card.class"/>
            </div>
          </div>
        </div>
      </div>

      <!-- Bank Transfer -->
      <div class="relative border-2 border-gray-200 rounded-xl p-6 opacity-70">
        <div class="absolute top-3 right-3 bg-primary text-white px-3 py-1 rounded-full text-sm">
          {{ t('assets.components.create.components.payment.comingSoon') }}
        </div>
        <div class="flex items-start space-x-4">
          <span class="material-symbols-outlined text-3xl text-gray-600">account_balance</span>
          <div>
            <h3 class="text-lg font-bold text-gray-900 mb-2">
              {{ t('assets.components.create.components.payment.methods.bankTransfer.title') }} </h3>
            <p class="text-sm text-gray-500">
              {{ t('assets.components.create.components.payment.methods.bankTransfer.description') }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import visaImage from '@/assets/images/cards/visa.png'
import masterCard from '@/assets/images/cards/mastercard.png'

const props = defineProps({
  selectedMethod: String,
  disabled: Boolean,
})

const emit = defineEmits(['select', 'stepValidation'])

const { t } = useI18n()
const error = ref('')

const cards = [
  { name: 'visa', image: visaImage, class: 'h-3' },
  { name: 'mastercard', image: masterCard, class: 'h-6' },
]

const isValid = computed(() => {
  const valid = !!props.selectedMethod
  // Emit validation on computed change
  emitValidation(valid)
  return valid
})

const getMethodClasses = (method) => ({
  'border-primary bg-primary/5 shadow-lg': method === props.selectedMethod,
  'border-gray-200 hover:border-gray-300': method !== props.selectedMethod,
  'cursor-not-allowed opacity-50': props.disabled,
})

const emitValidation = (isValid) => {
  const validationResult = {
    isValid,
    errors: isValid ? {} : { method: t('assets.components.create.components.payment.methods.errors.required') },
  }

  error.value = isValid ? '' : validationResult.errors.method
  emit('stepValidation', validationResult)

  return validationResult
}

const selectMethod = (method) => {
  if (props.disabled) return
  emit('select', method)
}

// Watch for changes in selected method
watch(
    () => props.selectedMethod,
    () => emitValidation(isValid.value),
    { immediate: true },
)

onMounted(() => {
  validate()
})

// External validation method
const validate = async () => {
  return emitValidation(isValid.value)
}

// Expose methods and computed properties
defineExpose({
  validate,
  isValid,
})
</script>