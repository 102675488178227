<template>
  <VueFinalModal :click-to-close="!isLoading" :esc-to-close="!isLoading" @opened="handleModalOpened" @closed="handleModalClosed" class="flex justify-center items-center" content-class="flex flex-col bg-white rounded-lg shadow-xl w-full md:w-[90vw] md:max-w-5xl h-screen md:h-[80vh]">
    <div class="h-full flex flex-col">
      <header class="bg-white border-b px-4 py-2 rounded-t-lg flex items-center justify-between">
        <h2 class="text-lg font-bold text-gray-800">{{ t('settings.components.configuration.components.documentModal.title') }}</h2>
        <button type="button" @click="closeModal" class="text-gray-600 hover:text-gray-800 flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <div class="flex-grow flex flex-col md:flex-row overflow-hidden">
        <nav class="w-full md:w-1/4 bg-gray-100 p-4 space-y-1">
          <CustomButton v-for="tab in tabs" :key="tab.value" @click="setActiveTab(tab.value)" class="w-full text-left p-2 rounded-lg transition-colors" :button-text="tab.label" :icon="tab.icon" :color="activeTab === tab.value ? 'primary' : 'neutral'" :variation="activeTab === tab.value ? 'solid' : 'outline'" :full-width="true" icon-position="right" :is-icon-spaced="true" :show-icon="true"/>
        </nav>

        <main class="w-full md:w-3/4 p-4 md:p-6 overflow-auto flex flex-col relative">
          <div v-if="showDeleteConfirm" class="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div class="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
              <h3 class="text-lg font-semibold mb-2">{{ t('settings.components.configuration.components.documentModal.deleteConfirmTitle') }}</h3>
              <p class="mb-4">{{ t('settings.components.configuration.components.documentModal.deleteConfirmMessage') }}</p>
              <div class="flex justify-end space-x-2">
                <CustomButton @click="cancelDelete" :button-text="t('settings.components.configuration.components.documentModal.cancelDelete')" size="sm" color="secondary"/>
                <CustomButton @click="confirmDelete" :button-text="t('settings.components.configuration.components.documentModal.confirmDelete')" size="sm"/>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'uploadFile'" class="flex-grow">
            <FileUpload v-model="files"/>
          </div>

          <div v-else-if="activeTab === 'addUrl'" class="flex-grow">
            <UrlUpload v-model="urls"/>
          </div>

          <div v-else-if="activeTab === 'documents'" class="flex-grow">
            <CustomTable :data="documents" :columns="tableColumns" :is-loading="isLoadingDocuments">
              <template #fileName="{ item }">
                <Tooltip :title="item.fileName" :display-condition="true" class="z-50">
                  <template #content>
                    <span>{{ truncateText(item.fileName, 30) }}</span>
                  </template>
                </Tooltip>
              </template>

              <template #addDate="{item}">
                <div class="flex items-center w-full justify-center">
                  <span>
                  {{ item.addDate }}
                  </span>
                </div>
              </template>

              <template #description="{ item }">
                <Tooltip :title="item.description || t('settings.components.configuration.components.documentModal.noDescription')" :display-condition="true" class="z-50" :full-width="true" container-class="flex justify-center z-50">
                  <template #content>
                    <span class="material-symbols-outlined cursor-pointer text-primary !text-[20px]">info</span>
                  </template>
                </Tooltip>
              </template>

              <template #actions="{ item }">


                <div class="flex items-center space-x-2 justify-center">
                  <Tooltip :title="t('settings.components.configuration.components.documentModal.deleteTooltip')" :display-condition="true" class="z-50" container-class="flex justify-center z-50">
                    <template #content>
                      <button @click="deleteDocument(item.id)" class="text-red-500 hover:text-red-700 flex items-center">
                        <span class="material-symbols-outlined !text-[20px]">delete</span>
                      </button>
                    </template>
                  </Tooltip>
                  <Tooltip :title="t('settings.components.configuration.components.documentModal.viewDocument')" :display-condition="true" class="z-50" container-class="flex justify-center z-50">
                    <template #content>
                      <a :href="item.data" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700 flex items-center"> <span class="material-symbols-outlined !text-[20px]">open_in_new</span></a>
                    </template>
                  </Tooltip>
                </div>
              </template>

            </CustomTable>
          </div>

          <div v-if="activeTab !== 'documents'" class="mt-4 flex flex-col md:flex-row md:justify-between md:items-center space-y-2 md:space-y-0">
            <div class="text-sm text-gray-600">
              {{ uploadSummary }}
            </div>
            <CustomButton :button-text="t('settings.components.configuration.components.documentModal.uploadButton')" @click="handleUpload" :is-loading="isLoading" :is-disabled="isUploadDisabled" size="sm" variant="primary" class="w-full md:w-auto"/>
          </div>
        </main>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import FileUpload from './components/fileUpload.vue'
import UrlUpload from './components/urlUpload.vue'
import CustomTable from '@/components/table/VTable.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import dayjs from 'dayjs'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const emit = defineEmits(['update:show'])

const isLoading = ref(false)
const isLoadingDocuments = ref(false)
const activeTab = ref('documents')
const files = ref([])
const urls = ref([])
const documents = ref([])
const showDeleteConfirm = ref(false)
const documentToDelete = ref(null)

const tabs = [
  { value: 'documents', label: t('settings.components.configuration.components.documentModal.documentsTab'), icon: 'description' },
  { value: 'uploadFile', label: t('settings.components.configuration.components.documentModal.uploadFileTab'), icon: 'upload_file' },
  { value: 'addUrl', label: t('settings.components.configuration.components.documentModal.addUrlTab'), icon: 'link' },
]

const tableColumns = [
  { key: 'fileName', label: t('settings.components.configuration.components.documentModal.documentName') },
  { key: 'typeId', label: t('settings.components.configuration.components.documentModal.type') },
  { key: 'addDate', label: t('settings.components.configuration.components.documentModal.uploadDate'), align: 'center' },
  { key: 'description', label: t('settings.components.configuration.components.documentModal.description'), align: 'center' },
  { key: 'actions', label: t('settings.components.configuration.components.documentModal.actions'), align: 'center' },
]

const uploadSummary = computed(() => {
  if (activeTab.value === 'uploadFile') {
    return t('settings.components.configuration.components.documentModal.filesSummary', { count: files.value.length })
  } else if (activeTab.value === 'addUrl') {
    return t('settings.components.configuration.components.documentModal.urlsSummary', { count: urls.value.length })
  }
  return ''
})

const isUploadDisabled = computed(() => {
  if (activeTab.value === 'uploadFile') {
    return files.value.length === 0
  } else if (activeTab.value === 'addUrl') {
    return urls.value.length === 0
  }
  return true
})

const setActiveTab = (tabValue) => {
  if (showDeleteConfirm.value) {
    cancelDelete()
  }
  activeTab.value = tabValue
}

const handleModalOpened = () => {
  fetchDocuments()
}

const handleModalClosed = () => {
  resetState()
}

const resetState = () => {
  files.value = []
  urls.value = []
  activeTab.value = 'documents'
  cancelDelete()
}

const handleUpload = () => {
  if (isUploadDisabled.value) return

  cancelDelete()
  isLoading.value = true

  const formData = new FormData()
  formData.append('assetId', assetStore.assetId)
  formData.append('typeId', activeTab.value === 'uploadFile' ? 1 : 2)

  if (activeTab.value === 'uploadFile') {
    files.value.forEach((fileItem, index) => {
      formData.append(`file`, fileItem.file)
      formData.append(`description[${index}]`, fileItem.description || '')
    })
  } else {
    urls.value.forEach((urlItem, index) => {
      formData.append(`url`, urlItem.url)
      formData.append(`description`, urlItem.description || '')
    })
  }

  axios.post(settings.uploadDocumentFiles, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
      .then(response => {
        responseHandlerService.handleSuccess(t('settings.components.configuration.components.documentModal.uploadSuccess'))
        fetchDocuments()
        resetState()
        setActiveTab('documents')
      })
      .catch(error => {
        responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.uploadError'))
      })
      .finally(() => {
        isLoading.value = false
      })
}

const fetchDocuments = async () => {
  isLoadingDocuments.value = true
  try {
    const response = await axios.get(settings.getDocumentFiles, {
      params: { assetId: assetStore.assetId },
    })
    documents.value = response.data.data.map(doc => ({
      ...doc,
      typeId: doc.typeId === 1 ? t('settings.components.configuration.components.documentModal.file') : t('settings.components.configuration.components.documentModal.url'),
      addDate: dayjs(doc.addDate).format('YYYY-MM-DD HH:mm'),
      description: doc.description || t('settings.components.configuration.components.documentModal.noDescription'),
    }))
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.fetchError'))
  } finally {
    isLoadingDocuments.value = false
  }
}

const deleteDocument = (id) => {
  documentToDelete.value = id
  showDeleteConfirm.value = true
}

const confirmDelete = async () => {
  if (!documentToDelete.value) return

  try {
    await axios.put(settings.deleteDocumentFiles, { id: documentToDelete.value, assetId: assetStore.assetId })
    documents.value = documents.value.filter(doc => doc.id !== documentToDelete.value)
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.documentModal.deleteSuccess'))
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.deleteError'))
  } finally {
    cancelDelete()
  }
}

const cancelDelete = () => {
  documentToDelete.value = null
  showDeleteConfirm.value = false
}

const closeModal = () => {
  cancelDelete()
  emit('update:show', false)
}

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text
  return text.substr(0, maxLength) + '...'
}

onMounted(() => {
  fetchDocuments()
})

watch(activeTab, () => {
  if (showDeleteConfirm.value) {
    cancelDelete()
  }
})
</script>