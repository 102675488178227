import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from '@/plugins/axiosInstance'
import { packages } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import { encryptedStorage } from '@/utils/encryption'

export const useFeatureStore = defineStore('features', () => {
  const assetStore = useAssetStore()
  const { t } = useI18n()

  // State
  const loading = ref(false)
  const features = ref([])
  const featuresByAsset = ref({})
  const packageName = ref('')

  // Getters
  const activeFeatureCount = computed(() => features.value.length)

  const featuresByUsage = computed(() => {
    return features.value.sort((a, b) => {
      const aUsage = calculatePercentage(a.monthlyLobsLimit - a.remainingLobs, a.monthlyLobsLimit)
      const bUsage = calculatePercentage(b.monthlyLobsLimit - b.remainingLobs, b.monthlyLobsLimit)
      return bUsage - aUsage
    })
  })

  const hasActiveFeatures = computed(() => features.value.length > 0)

  // Actions
  async function fetchFeatures(silent = false) {
    const currentAssetId = assetStore.assetId

    if (!silent) {
      loading.value = true
    }

    try {
      const result = await axios.get(packages.futureDetail, {
        params: {
          assetId: currentAssetId,
        },
      })

      const data = result.data.data
      const activeFeatures = data.features.filter(feature =>
          feature.status &&
          feature.monthlyLobsLimit > 0 &&
          feature.lobsPerUse > 0,
      )

      features.value = activeFeatures
      packageName.value = data.packageName || t('navigation.layout.components.featureMenu.no_package')

      featuresByAsset.value[currentAssetId] = {
        features: activeFeatures,
        packageName: packageName.value,
      }

      return result
    } catch (error) {
      console.error('Error fetching features:', error)
      features.value = []
      packageName.value = t('navigation.layout.components.featureMenu.no_package')
      featuresByAsset.value[currentAssetId] = {
        features: [],
        packageName: packageName.value,
      }
      throw error
    } finally {
      if (!silent) {
        loading.value = false
      }
    }
  }

  // Feature kullanımı sonrası güncelleme
  async function refreshFeatures() {
    try {
      return await fetchFeatures(true)
    } catch (error) {
      console.error('Error refreshing features:', error)
      throw error
    }
  }

  function resetFeaturesByAsset(assetId = null) {
    if (assetId) {
      delete featuresByAsset.value[assetId]
    } else {
      const currentAssetId = assetStore.assetId
      delete featuresByAsset.value[currentAssetId]
    }
    features.value = []
    packageName.value = t('navigation.layout.components.featureMenu.no_package')
  }

  function calculatePercentage(used, total) {
    if (!used || !total) return 0
    return Math.min(100, Math.round((used / total) * 100))
  }

  function getUsageStatus(feature) {
    const percentage = calculatePercentage(
        feature.monthlyLobsLimit - feature.remainingLobs,
        feature.monthlyLobsLimit,
    )

    if (percentage >= 90) return 'critical'
    if (percentage >= 70) return 'warning'
    return 'normal'
  }

  function getColorsByStatus(status, type = 'bg') {
    const colors = {
      critical: {
        bg: 'bg-red-100',
        progress: 'bg-red-500',
        text: 'text-red-600',
      },
      warning: {
        bg: 'bg-yellow-100',
        progress: 'bg-yellow-500',
        text: 'text-yellow-600',
      },
      normal: {
        bg: 'bg-blue-100',
        progress: 'bg-blue-500',
        text: 'text-blue-600',
      },
    }

    return colors[status][type]
  }

  // Feature data'sını al
  function getFeatureById(featureId) {
    return features.value.find(f => f.featureId === featureId)
  }

  // Feature limiti kontrol et
  function hasAvailableLobs(featureId) {
    const feature = getFeatureById(featureId)
    return feature ? feature.remainingLobs >= feature.lobsPerUse : false
  }

  // Feature başına kullanılabilir kalan kullanım sayısı
  function getRemainingUses(featureId) {
    const feature = getFeatureById(featureId)
    return feature ? Math.floor(feature.remainingLobs / feature.lobsPerUse) : 0
  }

  return {
    // State
    loading,
    features,
    packageName,

    // Getters
    activeFeatureCount,
    featuresByUsage,
    hasActiveFeatures,

    // Actions
    fetchFeatures,
    refreshFeatures,
    resetFeaturesByAsset,
    calculatePercentage,
    getUsageStatus,
    getColorsByStatus,
    getFeatureById,
    hasAvailableLobs,
    getRemainingUses,
  }
}, {
  persist: {
    key: 'feature-store',
    storage: encryptedStorage,
    paths: ['featuresByAsset'],
  },
})