import { defineStore } from 'pinia'
import { encryptedStorage } from '@/utils/encryption'

const defaultDashboardState = {
  language: {
    currentId: 9,
    loading: false,
  },
  dateRange: {
    start: null,
    end: null,
  },
  metrics: {
    data: null,
    timestamp: null,
    totalContent: 0,
    totalVolume: 0,
    generatekeywordCount: 0,
    suggestionKeywwordCount: 0,
    deletedKeywordCount: 0,
  },
  chart: {
    data: null,
    selectedAssets: [],
    reportData: null,
    hasError: false,
    reportReady: false,
  },
  keywords: {
    filters: {
      source: '',
      sortKey: '',
      sortOrder: 'DESC',
      searchQuery: '',
    },
    pagination: {
      currentPage: 1,
      itemsPerPage: 30,
      totalCount: 0,
    },
    selectedKeywords: [],
    deletedKeywords: [],
    showWarning: false,
  },
  loadingStates: {
    metrics: false,
    chart: false,
    keywords: false,
  },
  isFetchable: false,
  reportRequirements: {
    metrics: null,    // null: başlangıç, false: hata, true: başarılı
    assets: null,
    keywords: null,
  },
}

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    ...defaultDashboardState,
    processedChartData: null,
  }),

  actions: {
    // Dil ile ilgili actions
    setLanguage(langId) {
      this.language.currentId = langId
    },

    setLanguageLoading(loading) {
      this.language.loading = loading
    },

    // Metrics ile ilgili actions
    setMetricsData(data) {
      this.metrics = {
        ...this.metrics,
        ...data,
        timestamp: Date.now(),
      }
    },

    // Chart ile ilgili actions
    setChartData(data) {
      this.chart.data = data
    },

    setSelectedAssets(assets) {
      this.chart.selectedAssets = assets
    },

    setReportData(data) {
      this.chart.reportData = data
      this.chart.reportReady = true
      this.setLoading('chart', false)
    },

    resetReportStatus() {
      this.chart.reportData = null
      this.chart.reportReady = false
    },

    setReportError(hasError) {
      this.chart.hasError = hasError
      this.chart.reportReady = !hasError
    },

    // Keywords ile ilgili actions
    setKeywordsFilter(filters) {
      this.keywords.filters = { ...this.keywords.filters, ...filters }
    },

    setPagination(pagination) {
      this.keywords.pagination = { ...this.keywords.pagination, ...pagination }
    },

    setDeletedKeywords(keywords) {
      this.keywords.deletedKeywords = keywords
      this.keywords.showWarning = keywords.length > 0
    },

    // Loading states
    setLoading(component, value) {
      this.loadingStates[component] = value
    },

    // Date range
    setDateRange(range) {
      this.dateRange = range
    },

    setFetchable(value) {
      this.isFetchable = value
    },

    // Reset actions
    resetChartState() {
      this.chart = { ...defaultDashboardState.chart }
    },

    resetKeywordsState() {
      this.keywords = { ...defaultDashboardState.keywords }
    },

    resetDashboard() {
      Object.assign(this.$state, defaultDashboardState)
    },

    setRequirementStatus(requirement, status) {
      if (requirement in this.reportRequirements) {
        this.reportRequirements[requirement] = status
      } else {
        console.warn(`Invalid requirement: ${requirement}`)
      }
    },

    resetRequirements() {
      this.reportRequirements = {
        metrics: null,
        assets: null,
        keywords: null,
      }
    },

    processChartData(keywordData, metricsData) {
      // Temel kontrol
      if (!keywordData?.length || !Object.keys(metricsData).length) {
        this.processedChartData = null
        return
      }

      // Volume bazlı sıralama
      const topByVolume = [...keywordData]
          .filter(item => typeof item.volume === 'number')
          .sort((a, b) => (b.volume || 0) - (a.volume || 0))
          .slice(0, 20)

      // Pozisyon hesaplama
      const itemsWithPositions = [...keywordData]
          .map(item => {
            const positions = item.positions || {}
            const validPositions = Object.values(positions)
                .filter(pos => typeof pos === 'number' && pos > 0)

            return {
              ...item,
              avgPosition: validPositions.length ?
                  validPositions.reduce((sum, pos) => sum + pos, 0) / validPositions.length :
                  Infinity,
            }
          })
          .filter(item => item.avgPosition !== Infinity)
          .sort((a, b) => a.avgPosition - b.avgPosition)
          .slice(0, 20)

      // Processed data'yı güncelle
      const processedData = {
        metricsData,
        keywordData: {
          topByVolume,
          topByPosition: itemsWithPositions,
        },
      }

      this.processedChartData = processedData
      return processedData
    },

    getAveragePosition(positions) {
      if (!positions || !Array.isArray(positions) || !positions.length) return Infinity

      const validPositions = positions
          .map(p => Number(p.position))
          .filter(p => !isNaN(p) && p > 0)

      return validPositions.length ?
          validPositions.reduce((sum, pos) => sum + pos, 0) / validPositions.length :
          Infinity
    },
  },

  getters: {
    currentLanguage: (state) => state.language.currentId,
    isLanguageLoading: (state) => state.language.loading,
    isComponentLoading: (state) => (component) => state.loadingStates[component],

    // Metrics getters
    metricsData: (state) => state.metrics,

    // Chart getters
    chartData: (state) => state.chart.data,
    selectedAssets: (state) => state.chart.selectedAssets,
    isReportReady: (state) => state.chart.reportReady,

    // Keywords getters
    currentFilters: (state) => state.keywords.filters,
    currentPagination: (state) => state.keywords.pagination,
    hasKeywordWarning: (state) => state.keywords.showWarning,
    deletedKeywordsList: (state) => state.keywords.deletedKeywords,

    canGenerateReport: (state) => {
      const requirements = state.reportRequirements
      return requirements.metrics === true &&
          requirements.assets === true &&
          requirements.keywords === true
    },

    hasRequirementError: (state) => {
      const requirements = state.reportRequirements
      return Object.values(requirements).some(status => status === false)
    },
  },

  persist: {
    key: 'dashboard-store',
    storage: encryptedStorage,
    paths: ['language', 'dateRange', 'keywords.filters', 'keywords.pagination'],
  },
})