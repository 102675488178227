<template>
  <div class="relative" ref="menuRoot">
    <!-- Trigger Slot -->
    <div @click="toggleMenu" class="flex items-center cursor-pointer" :class="triggerClass">
      <slot></slot>
    </div>

    <!-- Menu Panel -->
    <transition name="fade">
      <div v-if="isOpen" :class="[widthClass, 'absolute z-[999999999] top-full right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg focus:outline-none', sizeClasses]" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
  closeOnClickOutside: { type: Boolean, default: true },
  closeOnEscape: { type: Boolean, default: true },
  triggerClass: { type: String, default: '' },
  widthClass: { type: String, default: 'w-64' },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(props.modelValue)
const menuRoot = ref(null)

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

watch(() => props.modelValue, (newVal) => {
  isOpen.value = newVal
})

watch(isOpen, (newVal) => {
  emit('update:modelValue', newVal)
})

const onClickOutside = (e) => {
  if (
      props.closeOnClickOutside &&
      isOpen.value &&
      menuRoot.value &&
      !menuRoot.value.contains(e.target)
  ) {
    isOpen.value = false
  }
}

const onEscape = (e) => {
  if (props.closeOnEscape && e.key === 'Escape' && isOpen.value) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('mousedown', onClickOutside)
  document.addEventListener('keydown', onEscape)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', onClickOutside)
  document.removeEventListener('keydown', onEscape)
})

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-sm py-1'
    case 'md':
      return 'text-base py-2'
    case 'lg':
      return 'text-lg py-3'
    default:
      return 'text-sm py-1'
  }
})
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
