const ignoredErrors = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Duplicate keys found during update',
  'WebSocket connection',
  'net::ERR_CONNECTION_REFUSED',
]

export const setupErrorHandler = (app) => {
  // Production'da console.error'ları gizle
  if (process.env.NODE_ENV === 'production') {
    const originalConsoleError = console.error
    console.error = (...args) => {
      if (args[0] && typeof args[0] === 'string') {
        const shouldIgnore = ignoredErrors.some(err => args[0].includes(err))
        if (shouldIgnore) return

        // Vue specific warnings
        if (args[0].includes('[Vue warn]')) return
      }
      originalConsoleError.apply(console, args)
    }
  }

  // Global error handler
  app.config.errorHandler = (err, instance, info) => {
    // İzlenmesi gereken hataları kontrol et
    const shouldIgnore = ignoredErrors.some(error =>
        err.message?.includes(error) || info?.includes(error),
    )

    if (!shouldIgnore) {
      console.error('Vue Error:', err)

      // Sentry entegrasyonu varsa hataları gönder
      if (window.Sentry) {
        window.Sentry.captureException(err, {
          extra: {
            componentName: instance?.type?.name,
            info,
            error: err,
          },
        })
      }
    }
  }

  // Warning handler
  app.config.warnHandler = (msg, instance, trace) => {
    const shouldIgnore = ignoredErrors.some(error => msg.includes(error))

    if (!shouldIgnore && process.env.NODE_ENV !== 'production') {
      console.warn('Vue Warning:', msg)
      console.log('Component:', instance)
      console.log('Trace:', trace)
    }
  }
}