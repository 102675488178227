import { defineStore } from 'pinia'
import { encryptedStorage } from '@/utils/encryption'

const defaultTheme = {
  primaryColor: '#c2410c',
  secondaryColor: '#0c4a6e',
  accentColor: '#0284c7',
}

const defaultSnackbarPosition = {
  bottom: true,
  right: true,
  top: false,
  left: false,
}

export const useUIStore = defineStore('ui', {
  state: () => ({
    language: '',
    theme: {
      primaryColor: defaultTheme.primaryColor,
      secondaryColor: defaultTheme.secondaryColor,
      accentColor: defaultTheme.accentColor,
    },
    snackbarPosition: {
      bottom: defaultSnackbarPosition.bottom,
      right: defaultSnackbarPosition.right,
      top: defaultSnackbarPosition.top,
      left: defaultSnackbarPosition.left,
    },
    toasts: [],
    maxToasts: 5,
  }),

  actions: {
    setLanguage(language) {
      this.$patch((state) => {
        state.language = language
      })
    },

    setTheme(theme) {
      this.$patch((state) => {
        state.theme = {
          primaryColor: theme?.primaryColor ?? state.theme.primaryColor,
          secondaryColor: theme?.secondaryColor ?? state.theme.secondaryColor,
          accentColor: theme?.accentColor ?? state.theme.accentColor,
        }
      })
    },

    resetTheme() {
      this.$patch((state) => {
        state.theme = {
          primaryColor: defaultTheme.primaryColor,
          secondaryColor: defaultTheme.secondaryColor,
          accentColor: defaultTheme.accentColor,
        }
      })
    },

    setSnackbarPosition(position) {
      this.$patch((state) => {
        // Yeni obje oluşturarak güncelleme yap
        const newPosition = {
          bottom: state.snackbarPosition.bottom,
          right: state.snackbarPosition.right,
          top: state.snackbarPosition.top,
          left: state.snackbarPosition.left,
        }

        // Vertical position
        if (position.bottom) {
          newPosition.bottom = true
          newPosition.top = false
        } else if (position.top) {
          newPosition.bottom = false
          newPosition.top = true
        }

        // Horizontal position
        if (position.right) {
          newPosition.right = true
          newPosition.left = false
        } else if (position.left) {
          newPosition.right = false
          newPosition.left = true
        }

        state.snackbarPosition = newPosition
      })
    },

    addToast(toast) {
      this.$patch((state) => {
        const newToasts = [...state.toasts]
        if (newToasts.length >= state.maxToasts) {
          newToasts.shift()
        }
        newToasts.push({
          id: `toast-${Date.now()}`,
          timestamp: Date.now(),
          message: toast.message,
          type: toast.type,
          duration: toast.duration,
        })
        state.toasts = newToasts
      })
    },

    removeToast(id) {
      this.$patch((state) => {
        state.toasts = state.toasts.filter(toast => toast.id !== id)
      })
    },

    clearAllToasts() {
      this.$patch((state) => {
        state.toasts = []
      })
    },

    setMaxToasts(max) {
      this.$patch((state) => {
        state.maxToasts = max
        if (state.toasts.length > max) {
          state.toasts = state.toasts.slice(-max)
        }
      })
    },

    resetUIState() {
      this.$patch((state) => {
        state.language = ''
        state.theme = {
          primaryColor: defaultTheme.primaryColor,
          secondaryColor: defaultTheme.secondaryColor,
          accentColor: defaultTheme.accentColor,
        }
        state.snackbarPosition = {
          bottom: defaultSnackbarPosition.bottom,
          right: defaultSnackbarPosition.right,
          top: defaultSnackbarPosition.top,
          left: defaultSnackbarPosition.left,
        }
        state.toasts = []
        state.maxToasts = 5
      })
    },
  },

  getters: {
    getCurrentTheme: (state) => state.theme,

    getSnackbarPosition: (state) => {
      const { top, bottom, left, right } = state.snackbarPosition
      return {
        vertical: top ? 'top' : 'bottom',
        horizontal: left ? 'left' : 'right',
      }
    },

    getActiveToasts: (state) =>
        [...state.toasts].sort((a, b) => b.timestamp - a.timestamp),

    getToastCount: (state) => state.toasts.length,

    isToastLimitReached: (state) =>
        state.toasts.length >= state.maxToasts,

    getCurrentLanguage: (state) => state.language || 'en',
  },

  persist: {
    key: 'ui-store',
    storage: encryptedStorage,
    paths: ['language', 'theme', 'snackbarPosition'],
    afterRestore: (context) => {
      // Theme kontrolü
      const hasValidTheme = ['primaryColor', 'secondaryColor', 'accentColor']
          .every(color => context.store.theme?.[color]?.startsWith('#'))

      if (!hasValidTheme) {
        context.store.$patch((state) => {
          state.theme = {
            primaryColor: defaultTheme.primaryColor,
            secondaryColor: defaultTheme.secondaryColor,
            accentColor: defaultTheme.accentColor,
          }
        })
      }

      // Snackbar position kontrolü
      const hasValidPosition = ['top', 'bottom', 'left', 'right']
          .every(pos => typeof context.store.snackbarPosition?.[pos] === 'boolean')

      if (!hasValidPosition) {
        context.store.$patch((state) => {
          state.snackbarPosition = {
            bottom: defaultSnackbarPosition.bottom,
            right: defaultSnackbarPosition.right,
            top: defaultSnackbarPosition.top,
            left: defaultSnackbarPosition.left,
          }
        })
      }

      // Language kontrolü
      if (!context.store.language) {
        context.store.$patch((state) => {
          state.language = 'en'
        })
      }
    },
  },
})