<template>
  <div class="p-4 bg-slate-50 rounded-lg">
    <h3 class="font-medium text-sm mb-2">
      {{ t('entity.components.contentReview.seoImprovements') }} </h3>
    <ul class="list-disc list-inside text-sm text-slate-600">
      <li v-for="(shortcoming, index) in shortcomings" :key="index">
        {{ shortcoming }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  shortcomings: Array,
})
</script>