<template>
  <div class="w-full">
    <customTable :data="sourcesData" :columns="tableColumns" :is-loading="false" :show-search="false" :show-sort="false" :show-filter="false">
      <template #table-actions>
        <div class="flex items-center space-x-2">
          <customButton :button-text="t('assets.components.create.components.source.components.sourceTable.buttons.newSource')" icon="add" icon-position="right" size="sm" @click="addSource" :show-icon="true"/>
          <customButton :button-text="t('assets.components.create.components.source.components.sourceTable.buttons.authorization')" :icon="isAuth ? 'lock' : 'lock_open'" icon-position="right" size="sm" variation="outline" :color="isAuth ? 'primary' : 'secondary'" :additional-button-class="!isAuth ? 'animate-pulse' : ''" @click="openModal" :show-icon="true"/>
        </div>
      </template>

      <template #category="{ item, index }">
        <sourceCategory :model-value="item.sourceCategoryId" @update:modelValue="updateSourceCategory(index, $event)"/>
      </template>

      <template #getUrl="{ item, index }">
        <customInput :model-value="item.getUrl" @update:modelValue="updateSourceUrl(index, 'getUrl', $event)" type="url" icon="link" :show-icon="true" size="sm" :placeholder="t('assets.components.create.components.source.components.sourceTable.fields.getContentUrl')"/>
      </template>

      <template #addUrl="{ item, index }">
        <customInput :model-value="item.addUrl" @update:modelValue="updateSourceUrl(index, 'addUrl', $event)" type="url" icon="link" :show-icon="true" size="sm" :placeholder="t('assets.components.create.components.source.components.sourceTable.fields.addContentUrl')"/>
      </template>

      <template #editUrl="{ item, index }">
        <customInput :model-value="item.editUrl" @update:modelValue="updateSourceUrl(index, 'editUrl', $event)" type="url" icon="link" :show-icon="true" size="sm" :placeholder="t('assets.components.create.components.source.components.sourceTable.fields.editContentUrl')"/>
      </template>

      <template #actions="{ item, index }">
        <div class="flex items-center justify-center space-x-2">
          <button v-if="index !== 0" @click="removeSource(index)" class="text-red-500 hover:text-red-600 transition-colors">
            <span class="material-symbols-outlined text-sm">delete</span>
          </button>
        </div>
      </template>
    </customTable>

    <customModal v-model="showModal">
      <div class="flex flex-col space-y-5">
        <div class="text-center">
          <h2 class="text-lg font-bold text-secondary mb-2">
            {{ t('assets.components.create.components.source.components.sourceTable.modal.title') }} </h2>
          <p class="text-gray-500 text-sm">
            {{ t('assets.components.create.components.source.components.sourceTable.modal.description') }} </p>
        </div>

        <div class="flex flex-col space-y-4 w-full">
          <customInput v-model="authUrl" :label="t('assets.components.create.components.source.components.sourceTable.modal.authUrl')" :placeholder="t('assets.components.create.components.source.components.sourceTable.modal.authUrl')" icon="link" :show-icon="true"/>
          <customInput v-model="username" :label="t('assets.components.create.components.source.components.sourceTable.modal.username')" :placeholder="t('assets.components.create.components.source.components.sourceTable.modal.username')" icon="person" :show-icon="true"/>
          <customInput v-model="password" :label="t('assets.components.create.components.source.components.sourceTable.modal.password')" :placeholder="t('assets.components.create.components.source.components.sourceTable.modal.password')" type="password" icon="lock" :show-icon="true"/>
          <customButton :button-text="loading ? t('assets.components.create.components.source.components.sourceTable.buttons.loading') : t('assets.components.create.components.source.components.sourceTable.buttons.save')" :is-disabled="loading" :is-loading="loading" :full-width="true" @click="sendRequest"/>
        </div>
      </div>
    </customModal>

    <customDialog v-model="showConfirmDialog" :title="t('assets.components.create.components.source.components.sourceTable.dialog.deleteTitle')" :message="t('assets.components.create.components.source.components.sourceTable.dialog.deleteMessage')" :show-cancel-button="true" :cancel-button-text="t('assets.components.create.components.source.components.sourceTable.dialog.cancel')" :confirm-button-text="t('assets.components.create.components.source.components.sourceTable.dialog.confirm')" @confirm="confirmDelete"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useUIStore } from '@/stores/ui'
import { useI18n } from 'vue-i18n'
import sourceCategory from './sourceCategory.vue'
import customModal from '@/components/modals/VModal.vue'
import customDialog from '@/components/feedback/VDialog.vue'
import customInput from '@/components/base/inputs/VInput.vue'
import customButton from '@/components/base/buttons/VButton.vue'
import customTable from '@/components/table/VTable.vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const username = ref('')
const password = ref('')
const authUrl = ref('')
const isAuth = ref(false)
const loading = ref(false)
const showModal = ref(false)
const showConfirmDialog = ref(false)
const selectedIndex = ref(null)

const assetStore = useAssetStore()
const uiStore = useUIStore()
const { t } = useI18n()

const sources = computed(() => assetStore.assetData.sources)

const sourcesData = computed(() => {
  if (!sources.value?.length) {
    return [{
      sourceCategoryId: '',
      getUrl: '',
      addUrl: '',
      editUrl: '',
    }]
  }
  return sources.value
})

const tableColumns = computed(() => [
  {
    key: 'category',
    label: t('assets.components.create.components.source.components.sourceTable.fields.category'),
    width: 'w-2/12',
  },
  {
    key: 'getUrl',
    label: t('assets.components.create.components.source.components.sourceTable.fields.getContentUrl'),
    width: 'w-2/12',
  },
  {
    key: 'addUrl',
    label: t('assets.components.create.components.source.components.sourceTable.fields.addContentUrl'),
    width: 'w-2/12',
  },
  {
    key: 'editUrl',
    label: t('assets.components.create.components.source.components.sourceTable.fields.editContentUrl'),
    width: 'w-2/12',
  },
  {
    key: 'actions',
    label: '#',
    width: 'w-1/12',
    align: 'center',
  },
])

const addSource = () => {
  const newSource = {
    sourceCategoryId: '',
    getUrl: '',
    addUrl: '',
    editUrl: '',
  }
  assetStore.setSources([...sources.value, newSource])
}

const updateSourceCategory = (index, value) => {
  const updatedSources = [...sources.value]
  updatedSources[index] = { ...updatedSources[index], sourceCategoryId: value }
  assetStore.setSources(updatedSources)
}

const updateSourceUrl = (index, field, value) => {
  const updatedSources = [...sources.value]
  updatedSources[index] = { ...updatedSources[index], [field]: value }
  assetStore.setSources(updatedSources)
}

const removeSource = (index) => {
  if (sources.value.length <= 1) {
    responseHandlerService.handleError('En az bir kaynak olmalıdır')
    return
  }
  selectedIndex.value = index
  showConfirmDialog.value = true
}

const confirmDelete = () => {
  const newSources = sources.value.filter((_, index) => index !== selectedIndex.value)
  assetStore.setSources(newSources)
  selectedIndex.value = null
  showConfirmDialog.value = false
}

const openModal = () => {
  showModal.value = true
}

const sendRequest = async () => {
  if (!authUrl.value || !username.value || !password.value) {
    responseHandlerService.handleError(
        t('assets.components.create.components.source.components.sourceTable.errors.fillAllFields'),
    )
    return
  }

  loading.value = true
  try {
    await axios.post(assets.customerTokenController, {
      authUrl: authUrl.value,
      username: username.value,
      password: password.value,
    })

    isAuth.value = true
    await assetStore.setAuthList({
      authUrl: authUrl.value,
      username: username.value,
      password: password.value,
      isAuth: true,
    })
    showModal.value = false
    uiStore.setSnackbarPosition({ top: true, left: true })
    responseHandlerService.handleSuccess(
        t('assets.components.create.components.source.components.sourceTable.success.authorizationSuccess'),
    )
  } catch (error) {
    uiStore.setSnackbarPosition({ top: true, left: true })
    responseHandlerService.handleError(error.response.data.message)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  if (!sources.value?.length) {
    assetStore.setSources([{
      sourceCategoryId: '',
      getUrl: '',
      addUrl: '',
      editUrl: '',
    }])
  }

  username.value = assetStore.assetData.authList.username
  password.value = assetStore.assetData.authList.password
  authUrl.value = assetStore.assetData.authList.authUrl
  isAuth.value = assetStore.assetData.authList.isAuth
})
</script>

<style scoped>
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-none {
  animation: none;
}
</style>
