/**
 * Menu yönetimi için merkezi store
 * @module stores/menuStore
 *
 * @example
 * // Store'u import et ve kullan
 * import { useMenuStore } from '@/stores/menuStore'
 *
 * const menuStore = useMenuStore()
 *
 * // Tekli menu kullanımı
 * menuStore.toggleMenu('profile')
 *
 * // Çoklu menu kullanımı
 * menuStore.setAllowMultiple(true)
 * menuStore.toggleMenu('profile')
 * menuStore.toggleMenu('language')
 *
 * // Menu grupları
 * const headerMenus = menuStore.createMenuGroup(['profile', 'language', 'notifications'])
 * headerMenus.toggleGroup('profile')
 */

import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    /**
     * Açık olan menulerin Set koleksiyonu
     * @type {Set<string>}
     */
    openMenus: new Set(),

    /**
     * Birden fazla menünün aynı anda açık kalmasına izin verilip verilmediği
     * @type {boolean}
     * @default false
     */
    allowMultiple: false,
  }),

  actions: {
    /**
     * Belirtilen menu'yü aç/kapat
     * @param {string} menuId - Menu identifier
     *
     * @example
     * menuStore.toggleMenu('profile')
     */
    toggleMenu(menuId) {
      if (this.openMenus.has(menuId)) {
        this.openMenus.delete(menuId)
      } else {
        if (!this.allowMultiple) {
          this.openMenus.clear()
        }
        this.openMenus.add(menuId)
      }
    },

    /**
     * Belirtilen menu'yü kapat
     * @param {string} menuId - Menu identifier
     *
     * @example
     * menuStore.closeMenu('profile')
     */
    closeMenu(menuId) {
      this.openMenus.delete(menuId)
    },

    /**
     * Belirtilen menu hariç diğer tüm menuleri kapat
     * @param {string} menuId - Açık kalacak menu identifier
     *
     * @example
     * menuStore.closeAllExcept('profile')
     */
    closeAllExcept(menuId) {
      if (!this.allowMultiple) {
        this.openMenus.clear()
        if (menuId) {
          this.openMenus.add(menuId)
        }
      }
    },

    /**
     * Tüm açık menuleri kapat
     *
     * @example
     * menuStore.closeAll()
     */
    closeAll() {
      this.openMenus.clear()
    },

    /**
     * Belirtilen menu'nün açık olup olmadığını kontrol et
     * @param {string} menuId - Menu identifier
     * @returns {boolean} Menu'nün açık olup olmadığı
     *
     * @example
     * if (menuStore.isOpen('profile')) {
     *   // Menu açık
     * }
     */
    isOpen(menuId) {
      return this.openMenus.has(menuId)
    },

    /**
     * Birden fazla menünün aynı anda açık kalmasına izin ver/verme
     * @param {boolean} value - İzin durumu
     *
     * @example
     * // Çoklu menu'ye izin ver
     * menuStore.setAllowMultiple(true)
     *
     * // Tekli menu'ye dön
     * menuStore.setAllowMultiple(false)
     */
    setAllowMultiple(value) {
      this.allowMultiple = value
      if (!value && this.openMenus.size > 1) {
        const lastMenu = Array.from(this.openMenus).pop()
        this.openMenus.clear()
        if (lastMenu) {
          this.openMenus.add(lastMenu)
        }
      }
    },

    /**
     * Belirli menuler için grup oluştur
     * @param {string[]} menuIds - Gruptaki menu identifier'ları
     * @returns {{
     *   toggleGroup: (clickedId: string) => void,
     *   closeGroup: () => void
     * }} Grup metodları
     *
     * @example
     * // Grup oluştur
     * const headerMenus = menuStore.createMenuGroup(['profile', 'language'])
     *
     * // Gruptaki bir menu'yü aç (diğerleri kapanır)
     * headerMenus.toggleGroup('profile')
     *
     * // Gruptaki tüm menuleri kapat
     * headerMenus.closeGroup()
     */
    createMenuGroup(menuIds) {
      return {
        toggleGroup: (clickedId) => {
          if (this.openMenus.has(clickedId)) {
            this.openMenus.delete(clickedId)
          } else {
            menuIds.forEach(id => {
              if (id !== clickedId) {
                this.openMenus.delete(id)
              }
            })
            this.openMenus.add(clickedId)
          }
        },
        closeGroup: () => {
          menuIds.forEach(id => this.openMenus.delete(id))
        },
      }
    },
  },
})