<template>
  <div class="mb-6 min-h-[400px]">
    <div class="rounded-lg border overflow-hidden">
      <!-- Status Header -->
      <div class="px-6 py-4" :class="statusConfig[status].headerClass">
        <div class="flex items-center space-x-4">
          <div :class="iconWrapperClasses">
            <span class="material-symbols-outlined text-2xl">
              {{ statusConfig[status].icon }}
            </span>
          </div>
          <div>
            <h3 class="font-semibold text-lg">{{ t(`assets.components.create.components.payment.status.${status}.title`) }}</h3>
            <p class="mt-1 text-sm" :class="statusConfig[status].messageClass">
              {{ t(`assets.components.create.components.payment.status.${status}.message`) }}
            </p>
          </div>
        </div>
      </div>

      <!-- Transaction Details -->
      <div v-if="showDetails" class="bg-white px-6 py-4 border-t">
        <div class="space-y-4">
          <!-- Payment Info Grid -->
          <div class="grid grid-cols-2 gap-4 text-sm">
            <div v-if="transactionInfo.method" class="col-span-2 sm:col-span-1">
              <span class="text-gray-500 block">{{ t('assets.components.create.components.payment.status.details.method') }}</span>
              <span class="font-medium">{{ getMethodName(transactionInfo.method) }}</span>
            </div>

            <div v-if="transactionInfo.id" class="col-span-2 sm:col-span-1">
              <span class="text-gray-500 block">{{ t('assets.components.create.components.payment.status.details.transactionId') }}</span>
              <span class="font-medium">{{ transactionInfo.id }}</span>
            </div>

            <div v-if="transactionInfo.date" class="col-span-2 sm:col-span-1">
              <span class="text-gray-500 block">{{ t('assets.components.create.components.payment.status.details.date') }}</span>
              <span class="font-medium">{{ formatDate(transactionInfo.date) }}</span>
            </div>

            <div v-if="amount" class="col-span-2 sm:col-span-1">
              <span class="text-gray-500 block">{{ t('assets.components.create.components.payment.status.details.amount') }}</span>
              <span class="font-medium">${{ amount }}</span>
            </div>
          </div>

          <!-- Progress Timeline for Pending Status -->
          <div v-if="status === 'pending'" class="pt-4">
            <div class="flex items-center justify-between relative">
              <div class="absolute left-0 top-1/2 -translate-y-1/2 w-full h-1 bg-gray-100">
                <div class="h-full bg-primary/70 animate-progress" :style="{ width: progressWidth }"></div>
              </div>

              <template v-for="(step, index) in progressSteps" :key="index">
                <div class="relative z-10 flex flex-col items-center">
                  <div class="w-8 h-8 rounded-full flex items-center justify-center" :class="getStepClasses(step.status)">
                    <span class="material-symbols-outlined text-sm">
                      {{ getStepIcon(step.status) }}
                    </span>
                  </div>
                  <span class="mt-2 text-xs text-gray-600">{{ t(`assets.components.create.components.payment.status.steps.${step.key}`) }}</span>
                </div>
              </template>
            </div>
          </div>

          <!-- Error Message & Try Again -->
          <div v-if="status === 'failed' && errorMessage" class="pt-4">
            <div class="bg-red-50 text-red-700 p-4 rounded-md">
              <div class="flex flex-col">
                <div class="flex items-start">
                  <span class="material-symbols-outlined mr-3">error</span>
                  <div>
                    <p class="font-medium">{{ t('assets.components.create.components.payment.status.error.title') }}</p>
                    <p class="text-sm mt-1">{{ errorMessage }}</p>
                  </div>
                </div>
                <button class="mt-4 text-sm text-primary hover:text-primary-dark flex items-center space-x-1 self-end" @click="handleTryAgain">
                  <span class="material-symbols-outlined text-base">refresh</span>
                  <span>{{ t('assets.components.create.components.payment.status.actions.tryAgain') }}</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Success Actions -->
          <div v-if="status === 'success'" class="pt-4 flex justify-end">
            <button class="text-sm text-primary hover:text-primary-dark flex items-center space-x-1" @click="$emit('download-receipt')">
              <span class="material-symbols-outlined text-base">download</span>
              <span>{{ t('assets.components.create.components.payment.status.actions.downloadReceipt') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useStepsStore } from '@/stores/steps'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'

const props = defineProps({
  status: {
    type: String,
    required: true,
    validator: value => ['pending', 'success', 'failed'].includes(value),
  },
  amount: {
    type: [Number, String],
    default: null,
  },
  transactionInfo: {
    type: Object,
    default: () => ({}),
  },
  errorMessage: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['download-receipt', 'try-again', 'status-change'])
const stepsStore = useStepsStore()
const { t } = useI18n()

const statusConfig = {
  pending: {
    icon: 'pending',
    headerClass: 'bg-yellow-50 border-b border-yellow-100',
    iconClass: 'bg-yellow-100 text-yellow-600',
    messageClass: 'text-yellow-600',
  },
  success: {
    icon: 'check_circle',
    headerClass: 'bg-green-50 border-b border-green-100',
    iconClass: 'bg-green-100 text-green-600',
    messageClass: 'text-green-600',
  },
  failed: {
    icon: 'error',
    headerClass: 'bg-red-50 border-b border-red-100',
    iconClass: 'bg-red-100 text-red-600',
    messageClass: 'text-red-600',
  },
}

const progressSteps = [
  { key: 'initiated', status: 'completed' },
  { key: 'processing', status: 'current' },
  { key: 'verifying', status: 'pending' },
  { key: 'completed', status: 'pending' },
]

const showDetails = computed(() => {
  return props.status === 'success' ||
      props.status === 'pending' ||
      (props.status === 'failed' && props.errorMessage)
})

const progressWidth = computed(() => {
  const completedSteps = progressSteps.filter(step => step.status === 'completed').length
  const totalSteps = progressSteps.length - 1
  return `${(completedSteps / totalSteps) * 100}%`
})

const iconWrapperClasses = computed(() =>
    `rounded-full w-10 h-10 flex items-center justify-center ${statusConfig[props.status].iconClass}`
)

const getStepClasses = (status) => ({
  'bg-primary text-white': status === 'completed',
  'bg-primary/70 text-white ring-4 ring-primary/20': status === 'current',
  'bg-gray-100 text-gray-400': status === 'pending',
})

const getStepIcon = (status) => ({
  completed: 'check',
  current: 'pending',
  pending: 'circle',
}[status])

const getMethodName = (method) => ({
  iyzico: 'Credit Card (iyzico)',
  'bank-transfer': 'Bank Transfer',
}[method] || method)

const formatDate = (date) => format(new Date(date), 'MMM dd, yyyy HH:mm')

const handleTryAgain = () => emit('try-again')

watch(() => props.status, (newStatus) => {
  const navigationUpdate = {
    prevDisabled: newStatus === 'pending' || newStatus === 'success',
    nextDisabled: newStatus === 'pending' || newStatus === 'failed',
  }

  stepsStore.updateNavigation(navigationUpdate)
  emit('status-change', newStatus)
}, { immediate: true })

onMounted(() => {
  const navigationUpdate = {
    prevDisabled: props.status === 'pending' || props.status === 'success',
    nextDisabled: props.status === 'pending' || props.status === 'failed',
  }
  stepsStore.updateNavigation(navigationUpdate)
})
</script>

<style scoped>
.animate-progress {
  transition: width 0.5s ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>