<template>
  <div class="component-layout relative">
    <!-- Yükleniyor Örtüsü -->
    <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center z-10">
      <loading :loading-text="t('dashboard.components.pageInsightInfo.loading')" :show-loading-text="true"/>
    </div>

    <!-- Her zaman veri bölümünü render et, başlangıçta sıfır değerler ile -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div v-for="deviceName in ['desktop', 'mobile']" :key="deviceName" class="bg-white shadow-lg rounded-lg p-4">
        <div class="rounded-lg p-6">
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg font-semibold">
              {{ deviceName === 'desktop' ? t('dashboard.components.pageInsightInfo.desktopSEO') : t('dashboard.components.pageInsightInfo.mobileSEO') }} </h2>
          </div>
          <div class="flex items-center justify-center mb-6">
            <!-- Radial Bar Grafiği -->
            <apexchart :ref="`chart-${deviceName}`" type="radialBar" height="300" width="300" :options="getRadialChartOptions(deviceData[deviceName])" :series="[deviceData[deviceName].score]"></apexchart>
          </div>
          <div class="space-y-3">
            <div v-for="(categoryName, index) in ['performance', 'accessibility', 'best-practices', 'seo']" :key="categoryName" :class="{'bg-white': index % 2 === 0, 'bg-gray-50': index % 2 !== 0}" class="flex items-center justify-between p-2 hover:bg-gray-200 rounded cursor-pointer" @click="selectCategory(deviceData[deviceName], deviceData[deviceName].categories[categoryName], deviceName)">
              <div class="flex items-center">
                <div class="w-3 h-3 rounded-full mr-2" :style="{backgroundColor: getCategoryColor(categoryName)}"></div>
                <span class="text-sm">{{ t(`dashboard.components.pageInsightInfo.${categoryName}`) }}</span>
                <Tooltip v-if="deviceData[deviceName]?.categories[categoryName]?.id" :title="t('dashboard.components.pageInsightInfo.detail')" container-class="flex items-center justify-center">
                  <template #content>
                      <span class="material-symbols-outlined ml-2 cursor-pointer text-gray-500 !text-[20px]" @click.stop="openModal(deviceData[deviceName].categories[categoryName], deviceName)">
                        error
                      </span>
                  </template>
                </Tooltip>
              </div>
              <span :class="deviceData[deviceName].categories[categoryName].score < 0.9 ? 'text-red-500' : 'text-green-500'" class="font-semibold text-sm">
                {{ scoreToPercentage(deviceData[deviceName].categories[categoryName].score) }}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <infoModal v-if="showModal" v-model="showModal" :showModalId="showModalId" :deviceName="deviceName" @closeModal="closeModal" :auditDetail="selectedDevice === 'desktop' ? deviceData.desktop : deviceData.mobile"/>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import infoModal from './components/infoModal/index.vue'
import Loading from '@/components/loading/VLoading.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import Tooltip from '@/components/common/VTooltip.vue'
import { useAssetStore } from '@/stores/asset'
import { useUIStore } from '@/stores/ui'

const { t } = useI18n()
const assetStore = useAssetStore()
const uiStore = useUIStore()

const loading = ref(false)

const showModal = ref(false)
const showModalId = ref('')
const deviceName = ref('')
const selectedDevice = ref('')
const selectedCategories = reactive({})

const deviceData = reactive({
  desktop: initializeDeviceData(),
  mobile: initializeDeviceData(),
})

// Dil değişikliğini izle
watch(() => uiStore.language, (newLanguage, oldLanguage) => {
  if (newLanguage !== oldLanguage) {
    getRates()
  }
})

// Cihaz verilerini başlatma fonksiyonu
function initializeDeviceData() {
  return {
    score: 0,
    categories: {
      performance: { score: 0 },
      accessibility: { score: 0 },
      'best-practices': { score: 0 },
      seo: { score: 0 },
    },
  }
}

// Kategori renkleri tanımları
const categoryColors = {
  performance: '#3b82f6',
  accessibility: '#10b981',
  'best-practices': '#f59e0b',
  seo: '#ef4444',
}

// Grafik renkleri tanımları
const chartColors = {
  primary: '#FC002B',
  accent: '#FC9F00',
}

// Skoru yüzdeye çevirme fonksiyonu
const scoreToPercentage = (score) => (score * 100).toFixed(0)

// Kategori rengini getiren fonksiyon
const getCategoryColor = (categoryName) => categoryColors[categoryName] || '#6b7280'

// Kategori başlığını getiren fonksiyon
const getCategoryTitle = (categoryName) => t(`dashboard.components.pageInsightInfo.${categoryName}`)

// Kategori seçme fonksiyonu
const selectCategory = (device, category, deviceName) => {
  selectedCategories[deviceName] = category
  device.score = scoreToPercentage(category.score)
}

// Ortalama skoru hesaplama fonksiyonu
const getAverageScore = (device) => {
  const scores = Object.values(device.categories).map(category => category.score)
  const average = scores.reduce((a, b) => a + b, 0) / scores.length
  return scoreToPercentage(average)
}

// Modal açma fonksiyonu
const openModal = (category, deviceType) => {
  showModal.value = true
  showModalId.value = category.id
  deviceName.value = deviceType
  selectedDevice.value = deviceType
}

// Modal kapatma fonksiyonu
const closeModal = () => {
  showModal.value = false
}

// Radial chart seçeneklerini hesaplayan fonksiyon
const getRadialChartOptions = (device) => ({
  chart: {
    height: 300,
    type: 'radialBar',
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '70%',
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          offsetY: -10,
          color: '#888',
          fontSize: '13px',
        },
        value: {
          color: '#111',
          fontSize: '30px',
          show: true,
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: [chartColors.accent],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'round',
  },
  labels: ['Score'],
  colors: [chartColors.primary],
})

// SEO skorlarını getiren fonksiyon
const getRates = () => {
  loading.value = true

  axios.post(dashboard.getSeoScores, {
    assetId: assetStore.assetId,
    locale: uiStore.language,
  }).then((res) => {
    deviceData.desktop.score = res.data.data.desktop.score
    deviceData.mobile.score = res.data.data.mobile.score

    deviceData.desktop = {
      ...res.data.data.desktop,
      score: getAverageScore(res.data.data.desktop),
    }

    deviceData.mobile = {
      ...res.data.data.mobile,
      score: getAverageScore(res.data.data.mobile),
    }

    loading.value = false
  }).catch((err) => {
    // responseHandlerService.handleError(err, t('dashboard.errors.getRates'))
    loading.value = false
  })
}

// Bileşen yüklendiğinde SEO skorlarını al
onMounted(() => {
  getRates()
})

</script>