/**
 * Deep clones an object or array
 * @param {*} obj - The object/array to clone
 * @returns {*} A deep clone of the input
 * @example
 * const original = { a: 1, b: { c: 2 } }
 * const clone = deepClone(original)
 */
export const deepClone = (obj) => {
  // Handle null, undefined and primitive types
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // Handle Date objects
  if (obj instanceof Date) {
    return new Date(obj.getTime())
  }

  // Handle Array objects
  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item))
  }

  // Handle Objects
  const cloned = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      cloned[key] = deepClone(obj[key])
    }
  }

  return cloned
}

/**
 * Checks if two objects are deeply equal
 * @param {*} obj1 - First object to compare
 * @param {*} obj2 - Second object to compare
 * @returns {boolean} True if objects are deeply equal
 * @example
 * const obj1 = { a: 1, b: { c: 2 } }
 * const obj2 = { a: 1, b: { c: 2 } }
 * isEqual(obj1, obj2) // true
 */
export const isEqual = (obj1, obj2) => {
  // Handle null/undefined
  if (obj1 === obj2) {
    return true
  }

  // Handle type mismatch
  if (typeof obj1 !== typeof obj2) {
    return false
  }

  // Handle non-object types
  if (typeof obj1 !== 'object') {
    return obj1 === obj2
  }

  // Handle null (after previous checks)
  if (obj1 === null || obj2 === null) {
    return obj1 === obj2
  }

  // Handle Date objects
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime()
  }

  // Handle Arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false
    }
    return obj1.every((item, index) => isEqual(item, obj2[index]))
  }

  // Handle regular objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  return keys1.every(key =>
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      isEqual(obj1[key], obj2[key]),
  )
}

/**
 * Gets the difference between two objects
 * @param {object} obj1 - First object
 * @param {object} obj2 - Second object to compare against
 * @returns {object} Object containing only the different values
 * @example
 * const obj1 = { a: 1, b: 2, c: 3 }
 * const obj2 = { a: 1, b: 3, d: 4 }
 * getDifference(obj1, obj2) // { b: 2, c: 3, d: 4 }
 */
export const getDifference = (obj1, obj2) => {
  const difference = {}

  // Check properties in obj1
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (!isEqual(obj1[key], obj2[key])) {
        difference[key] = obj1[key]
      }
    }
  }

  // Check for properties in obj2 that aren't in obj1
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
        difference[key] = obj2[key]
      }
    }
  }

  return difference
}