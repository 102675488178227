<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="handleModalClose" class="flex justify-center items-center" content-class="flex flex-col bg-white w-screen h-screen" contentTransition="vfm-fade">
    <div class="h-full flex flex-col">
      <!-- Header -->
      <div class="w-full flex items-center justify-between bg-[#FC002B] bg-opacity-90 text-white px-4 py-3">
        <h2 class="text-sm font-medium flex items-center text-white">
          <span class="material-symbols-outlined !text-[22px] mr-2">description</span> {{ t('assets.dashboard.aiReport.reportPreview.title') }} </h2>

        <div class="flex items-center gap-4">
          <div class="hidden sm:flex items-center gap-1.5 text-white/70">
            <kbd class="px-2 py-0.5 text-xs font-semibold bg-white/20 rounded">ESC</kbd> <span class="text-xs">{{ t('assets.dashboard.aiReport.reportPreview.escHint') }}</span>
          </div>

          <button @click="$emit('update:modelValue', false)" class="flex items-center gap-2 px-3 py-1.5 rounded-lg hover:bg-white/20 active:bg-white/30 transition-all">
            <span class="hidden sm:inline text-sm">{{ t('assets.dashboard.aiReport.reportPreview.close') }}</span> <span class="material-symbols-outlined !text-[22px]">close</span>
          </button>
        </div>
      </div>

      <!-- Content -->
      <div v-if="loading" class="flex-1 flex items-center justify-center">
        <span class="material-symbols-outlined animate-spin">progress_activity</span> <span class="ml-2">{{ t('assets.dashboard.aiReport.reportPreview.loading') }}</span>
      </div>

      <template v-else>
        <div class="flex-1 overflow-y-auto p-6">
          <div class="max-w-4xl mx-auto">
            <article class="prose prose-sm lg:prose-base max-w-none">
              <div v-html="reportContent"></div>
            </article>
          </div>
        </div>
      </template>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  reportContent: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const loading = ref(false)

const handleModalClose = () => {
  emit('update:modelValue', false)
}
</script>