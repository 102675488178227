<template>
  <div class="calendar-grid overflow-x-auto">
    <div class="min-w-[768px]">
      <!-- Gün başlıkları -->
      <div class="days-header grid grid-cols-[50px_repeat(7,1fr)] gap-1 text-sm mb-2">
        <div class="hour-column"></div>
        <div v-for="day in weekDays" :key="`${day.date}-${uiStore.language}`" class="day-column text-center">
          <div class="day-name font-medium">{{ day.dayName }}</div>
          <div :class="['day-number text-xl', day.isToday ? 'text-primary' : '']">
            {{ day.dayOfMonth }}
          </div>
        </div>
      </div>

      <div class="relative">
        <!-- Zaman slotları -->
        <div ref="timeSlots" class="time-slots-container max-h-[60vh] overflow-y-auto thin-scrollbar scroll-smooth" @scroll="handleScroll">
          <div class="time-slots grid grid-cols-[50px_repeat(7,1fr)] gap-x-1">
            <template v-for="hour in visibleHours" :key="hour">
              <!-- Saat etiketi -->
              <div class="hour-label text-right pr-2 text-xs text-gray-500 h-10 leading-10">
                {{ formatHour(hour) }}
              </div>
              <!-- Her gün için zaman slotu -->
              <template v-for="day in weekDays" :key="`${hour}-${day.date}-${uiStore.locale}`">
                <div class="time-slot border-t border-gray-200 relative h-10">
                  <div class="events-container absolute inset-0 overflow-hidden px-1">
                    <!-- Event'ler -->
                    <template v-if="getEventsForDayAndHour(day.date, hour).length">
                      <div v-for="(event, index) in getEventsForDayAndHour(day.date, hour).slice(0, 1)" :key="`${event.id}-${uiStore.locale}`" @click="$emit('open-content-modal', event.id)" :class="[
                              'event-item rounded text-xs truncate cursor-pointer p-1 flex items-center justify-between',
                              getEventClass(event),
                              getStateClass(event.stateId)
                           ]">
                        <div class="flex items-center gap-1">
                          <span class="material-symbols-outlined !text-[14px]">
                            {{ getPlatformIcon(event.platform) }}
                          </span> <span class="event-title text-[10px] truncate">
                            {{ event.title }} ({{ event.localTime }})
                          </span>
                        </div>
                        <span class="event-state text-[8px] ml-1">
                          {{ getStateName(event.stateId) }}
                        </span>
                      </div>
                      <!-- Daha fazla event varsa -->
                      <div v-if="getEventsForDayAndHour(day.date, hour).length > 1" class="more-events text-[10px] text-gray-400 cursor-pointer mt-1" @click="$emit('open-more-events-modal', getEventsForDayAndHour(day.date, hour))">
                        +{{ getEventsForDayAndHour(day.date, hour).length - 1 }} more
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useUIStore } from '@/stores/ui'

const uiStore = useUIStore()

const props = defineProps({
  weekDays: {
    type: Array,
    required: true,
  },
  visibleHours: {
    type: Array,
    required: true,
  },
  getEventsForDayAndHour: {
    type: Function,
    required: true,
  },
  formatHour: {
    type: Function,
    required: true,
  },
  getEventClass: {
    type: Function,
    required: true,
  },
  getStateClass: {
    type: Function,
    required: true,
  },
  getPlatformIcon: {
    type: Function,
    required: true,
  },
  getStateName: {
    type: Function,
    required: true,
  },
})

const emit = defineEmits(['open-content-modal', 'open-more-events-modal'])

const timeSlots = ref(null)
const hourHeight = 40

const eventHours = computed(() => {
  const hours = new Set()
  props.weekDays.forEach(day => {
    props.visibleHours.forEach(hour => {
      if (props.getEventsForDayAndHour(day.date, hour).length > 0) {
        hours.add(hour)
      }
    })
  })
  return Array.from(hours).sort((a, b) => a - b)
})

const scrollToFirstEvent = () => {
  if (!timeSlots.value || eventHours.value.length === 0) return

  const firstEventHour = eventHours.value[0]
  const scrollPosition = firstEventHour * hourHeight

  timeSlots.value.scrollTo({
    top: Math.max(0, scrollPosition - hourHeight),
    behavior: 'smooth',
  })
}

const handleScroll = () => {
  // Scroll işlemleri için gerekirse ek işlemler buraya eklenebilir
}

onMounted(() => {
  nextTick(() => {
    scrollToFirstEvent()
  })
})

// weekDays veya locale değişiminde yeniden render
watch([() => props.weekDays, () => uiStore.language], () => {
  nextTick(() => {
    scrollToFirstEvent()
  })
}, { deep: true })
</script>

<style scoped>
.hour-label {
  @apply h-10 flex items-center justify-end;
}

.time-slot {
  @apply h-10;
}

.event-item {
  @apply flex items-center w-full;
  @apply text-xs p-1 rounded-sm shadow-sm border-y border-r border-y-gray-100 border-r-gray-100;
  @apply transition-all duration-200;
  @apply hover:scale-[1.02] hover:shadow-md;
}

.more-events {
  @apply absolute bottom-0 left-0 right-0 text-center bg-gray-100 text-gray-600;
  @apply opacity-75 hover:opacity-100 px-1 py-0.5 transition-opacity duration-200;
}
</style>