<template>
  <div class="flex flex-col">
    <h3 class="text-sm font-medium text-slate-600 mb-3 flex items-center gap-2">
      <span class="material-symbols-outlined text-primary">comment</span> {{ t('entity.components.contentReview.analysis') }} </h3>

    <template v-if="parsedMessage?.type === 'detailed'">
      <ScoreAnalysisCarousel v-if="carouselItems.length" :items="carouselItems">
        <template #item="{ item }">
          <ScoreCard v-if="item.type === 'section'" :item="item"/>
          <ImprovementsCard v-else :improvements="item.data"/>
        </template>
      </ScoreAnalysisCarousel>
    </template>

    <div v-else class="bg-slate-50 rounded-lg p-4">
      <p class="text-sm text-slate-600">
        {{ parsedMessage?.message || t('entity.components.contentReview.noAnalysisAvailable') }} </p>
    </div>
  </div>
</template>

<script setup>
import ScoreAnalysisCarousel from '@/components/common/VCarousel.vue'
import ImprovementsCard from './ImprovementsCard.vue'
import ScoreCard from './ScoreCard.vue'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  parsedMessage: Object,
  carouselItems: Array,
})
</script>