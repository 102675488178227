<template>
  <div class="bg-slate-50 rounded-lg p-4">
    <h4 class="font-medium text-sm mb-3">
      {{ t('entity.components.contentReview.improvements') }} </h4>
    <ul class="space-y-1 list-disc list-inside text-sm text-slate-600 max-h-32 overflow-y-auto thin-scrollbar">
      <li v-for="(improvement, index) in improvements" :key="index">
        {{ improvement }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  improvements: Array,
})
</script>