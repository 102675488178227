<template>
  <div class="dashboard space-y-4">
    <div v-if="!isAuthorized || !hasPropertyId" class="flex flex-col items-center justify-center min-h-[400px] p-8 bg-white rounded-lg shadow-sm">
      <div class="text-center space-y-4 mb-6">
        <h3 class="text-xl font-semibold text-gray-900">{{ t(authMessage.title) }}</h3>
        <p class="text-gray-600 max-w-md">{{ t(authMessage.description) }}</p>
      </div>

      <custom-button :button-text="t('dashboard.analytics.button.goToSettings')" color="primary" size="sm" :show-icon="true" icon="settings" @click="navigateToIntegrations"/>
    </div>

    <template v-else>
      <div class="p-4 component-layout">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <MetricCard :title="t('dashboard.analytics.metrics.totalVisitors')" :value="processedData.summary?.totalActiveUsers ?? 0" :change="0" :loading="loading"/>
          <MetricCard :title="t('dashboard.analytics.metrics.totalSessions')" :value="processedData.summary?.totalSessions ?? 0" :change="0" :loading="loading"/>
          <MetricCard :title="t('dashboard.analytics.metrics.bounceRate')" :value="formatBounceRate(processedData.summary?.averageBounceRate)" :change="0" :loading="loading"/>
        </div>
      </div>

      <VisitorChart :title="t('dashboard.analytics.charts.visitors')" :data="visitorChartData" :loading="loading"/>

      <div class="component-layout">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <CountryChart :title="t('dashboard.analytics.charts.countries')" :data="countryChartData" :loading="loading"/>
          <DeviceChart :title="t('dashboard.analytics.charts.devices')" :data="deviceChartData" :loading="loading"/>
        </div>
      </div>

      <div class="component-layout">
        <SourceTable :title="t('dashboard.analytics.charts.sources')" :data="sourceTableData" :loading="loading"/>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import VisitorChart from './components/visitorChart.vue'
import MetricCard from './components/metricCard.vue'
import CountryChart from './components/countryChart.vue'
import DeviceChart from './components/deviceChart.vue'
import SourceTable from './components/sourceTable.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

const router = useRouter()
const assetStore = useAssetStore()
const { t } = useI18n()

const processedData = ref({
  summary: {},
  byDate: {},
  byCountry: {},
  byDevice: {},
  bySource: {},
})
const loading = ref(false)
const isAuthorized = ref(true)
const hasPropertyId = ref(true)

const authMessage = computed(() => {
  if (!isAuthorized.value) {
    return {
      title: 'dashboard.analytics.auth.googleRequired.title',
      description: 'dashboard.analytics.auth.googleRequired.description',
    }
  } else if (!hasPropertyId.value) {
    return {
      title: 'dashboard.analytics.auth.propertyRequired.title',
      description: 'dashboard.analytics.auth.propertyRequired.description',
    }
  }
  return {
    title: '',
    description: '',
  }
})

const navigateToIntegrations = () => {
  router.push('/settings?tab=integrations')
}

const formatBounceRate = (rate) => {
  return rate ? (rate * 100).toFixed(2) + '%' : '0%'
}

const visitorChartData = computed(() => {
  if (!processedData.value.byDate) return []
  return Object.entries(processedData.value.byDate).map(([date, data]) => ({
    date,
    activeUsers: data.activeUsers,
    sessions: data.sessions,
  }))
})

const countryChartData = computed(() => {
  if (!processedData.value.byCountry) return []
  return Object.entries(processedData.value.byCountry)
      .sort((a, b) => b[1].activeUsers - a[1].activeUsers)
      .slice(0, 10)
      .map(([country, data]) => ({
        country,
        activeUsers: data.activeUsers,
      }))
})

const deviceChartData = computed(() => {
  if (!processedData.value.byDevice) return []
  return Object.entries(processedData.value.byDevice).map(([device, data]) => ({
    device,
    activeUsers: data.activeUsers,
  }))
})

const sourceTableData = computed(() => {
  if (!processedData.value.bySource) return []
  return Object.entries(processedData.value.bySource)
      .sort((a, b) => b[1].activeUsers - a[1].activeUsers)
      .slice(0, 10)
      .map(([source, data]) => ({
        source,
        activeUsers: data.activeUsers,
        sessions: data.sessions,
      }))
})

const fetchAnalyticsData = async () => {
  try {
    loading.value = true
    const response = await axios.get(dashboard.getAnalyticsData, {
      params: { assetId: assetStore.assetId },
    })

    if (response.data.data) {
      if (response.data.data.authorized === false) {
        isAuthorized.value = false
        return
      }

      if (!response.data.data.hasPropertyId) {
        hasPropertyId.value = false
        return
      }

      processedData.value = response.data.data.processedGeneralData
      isAuthorized.value = true
      hasPropertyId.value = true
    }
  } catch (error) {
    if (error.response?.data?.data?.authorized === false) {
      isAuthorized.value = false
    } else if (error.response?.data?.data?.hasPropertyId === false) {
      hasPropertyId.value = false
    } else {
      responseHandlerService.handleError(error)
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await fetchAnalyticsData()
})
</script>