<template>
  <VueFinalModal v-model="isModalOpen" class="flex justify-center items-center" contentClass="flex flex-col bg-white rounded-lg shadow-xl w-[500px]">
    <div class="flex flex-col w-full px-1" style="height: 600px;">
      <!-- Header -->
      <div class="w-full flex items-center justify-between bg-white px-4 py-3 border-b border-gray-200 rounded-t-lg">
        <h2 class="text-base font-semibold text-gray-900">{{ assetStore.assetName || t('navigation.layout.components.projectSelectionModal.title') }}</h2>
        <button @click="closeModal" class="p-1 hover:bg-gray-100 rounded-md transition-colors flex items-center">
          <span class="material-symbols-outlined text-gray-400 text-lg">close</span>
        </button>
      </div>

      <!-- Search -->
      <div class="px-4 py-3 border-b border-gray-100">
        <custom-input v-if="projects.length > 0 || hasSearched" v-model="searchQuery" :icon="searchQuery || hasSearched ? 'close' : 'search'" :show-icon="true" :icon-clickable="true" @icon-clicked="clearSearch" @keyup.enter="handleSearch" :placeholder="t('navigation.layout.components.projectSelectionModal.searchPlaceholder')" size="sm"/>
      </div>

      <!-- Content Area -->
      <div class="flex-1 divide-y divide-gray-100 overflow-y-auto thin-scrollbar">
        <!-- Loading State -->
        <div v-if="loading" class="h-full flex justify-center items-center py-10">
          <loading :show-loading-text="true" :loading="loading" :loading-text="t('navigation.layout.components.projectSelectionModal.loadingText')" loading-text-class="font-medium text-sm"/>
        </div>

        <!-- No Projects State -->
        <div v-else-if="projects.length === 0" class="h-full flex justify-center items-center py-10">
          <span class="text-sm text-gray-500">
            {{ t('navigation.layout.components.projectSelectionModal.noProjects') }}
          </span>
        </div>

        <!-- Projects List -->
        <div v-else class="h-full">
          <div v-for="project in projects" :key="project.id" @click="selectProject(project)" class="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer transition-colors group">
            <div class="flex items-center space-x-3 flex-1 min-w-0">
              <div class="w-8 h-8 rounded-lg bg-gray-100 flex items-center justify-center group-hover:bg-white transition-colors">
                <span class="material-symbols-outlined text-gray-500">dataset</span>
              </div>
              <div class="flex-1 min-w-0">
                <div class="font-medium text-sm text-gray-900 truncate">{{ project.name }}</div>
                <div class="font-medium text-xs text-gray-400 truncate">{{ project.website }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div v-if="pageCount > 1 && !hasSearched" class="border-t border-gray-100 px-4 py-2.5 bg-white rounded-b-lg flex items-center justify-end">
        <pagination :current-page="currentPage" :page-count="pageCount" @page-changed="fetchPage" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import CustomInput from '@/components/base/inputs/VInput.vue'
import Loading from '@/components/loading/VLoading.vue'
import Pagination from '@/components/base/pagination/index.vue'
import axios from '@/plugins/axiosInstance'
import { useAssetStore } from '@/stores/asset'
import { assets } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const assetStore = useAssetStore()
const emit = defineEmits(['update:modelValue', 'project-selected'])
const { t } = useI18n()

// Refs
const isModalOpen = ref(props.modelValue)
const searchQuery = ref('')
const loading = ref(false)
const projects = ref([])
const currentPage = ref(1)
const totalCount = ref(1)
const pageCount = ref(1)
const hasSearched = ref(false)

// Watch modal prop changes
watch(() => props.modelValue, (newValue) => {
  isModalOpen.value = newValue
  if (newValue) fetchProjects()
})

// Watch modal state changes
watch(isModalOpen, (newValue) => {
  emit('update:modelValue', newValue)
  if (!newValue) {
    resetModalState()
  }
})

const resetModalState = () => {
  searchQuery.value = ''
  currentPage.value = 1
  hasSearched.value = false
  projects.value = []
}

// Fetch projects from API
const fetchProjects = async () => {
  loading.value = true
  try {
    const response = await axios.get(assets.getAll, {
      params: {
        search: searchQuery.value,
        isActive: 2,
        page: currentPage.value - 1,
        sort: 'id DESC',
      },
    })

    projects.value = response.data.data.detail
    totalCount.value = response.data.data.totalCount
    pageCount.value = response.data.data.pageCount

  } catch (error) {
    responseHandlerService.handleError(error, t('navigation.layout.components.projectSelectionModal.errorLoading'))
  } finally {
    loading.value = false
  }
}

// Search handlers
const handleSearch = () => {
  if (searchQuery.value.trim()) {
    hasSearched.value = true
    currentPage.value = 1
    fetchProjects()
  }
}

const clearSearch = () => {
  searchQuery.value = ''
  if (hasSearched.value) {
    hasSearched.value = false
    currentPage.value = 1
    fetchProjects()
  }
}

// Pagination handler
const fetchPage = (page) => {
  currentPage.value = page
  fetchProjects()
}

// Project selection handler
const selectProject = async (project) => {
  if (loading.value) return

  try {
    loading.value = true

    // Sadece yeni değerleri set et
    await assetStore.setAssetId(project.id)
    await assetStore.setAssetName(project.name)

    // Sayfayı yenile
    window.location.reload()

  } catch (error) {
    console.error('Project switching error:', error)
    responseHandlerService.handleError(
        error,
        t('navigation.layout.components.projectSelectionModal.errorSwitching'),
    )
  } finally {
    loading.value = false
  }
}

// Modal close handler
const closeModal = () => {
  if (!loading.value) {
    isModalOpen.value = false
  }
}
</script>