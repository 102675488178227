import { ref } from 'vue'
import ExcelJS from 'exceljs'
import { validateAndNormalizePriority } from '../utils/priorityUtils'
import { KeywordUtils } from '@/utils/keywordUtils'
import { responseHandlerService } from '@/plugins/responseHandlerService'

export function useKeywordImport(props, emit, t) {
  const importResults = ref(null)

  // Process methods...
  const processKeyword = (keyword, priority, updatedKeywords) => {
    const priorityResult = validateAndNormalizePriority(priority)
    const normalizedKeyword = KeywordUtils.normalize(keyword)

    if (!normalizedKeyword) {
      return { type: 'invalid', keyword: null }
    }

    // Aktif veya güncellenmiş keywordlerde benzer keyword ara (state 1, 2, 4)
    const existingKeyword = KeywordUtils.findSimilarInArray(
        keyword,
        updatedKeywords.filter(k => k.state !== 3),
    )

    if (existingKeyword) {
      const isCurrentEmpty = existingKeyword.priority === null || existingKeyword.priority === undefined || existingKeyword.priority === ''
      const isNewEmpty = priorityResult.normalized === null
      const isCurrentZero = existingKeyword.priority === 0

      const hasRealChange = (() => {
        if ((isCurrentEmpty || isCurrentZero) && isNewEmpty) return false
        if (isCurrentEmpty !== isNewEmpty) return true
        return existingKeyword.priority !== priorityResult.normalized
      })()

      if (hasRealChange) {
        existingKeyword.priority = priorityResult.normalized
        if (existingKeyword.state === 1) {
          existingKeyword.state = 4
        }

        return {
          type: 'updated',
          keyword: existingKeyword,
          priorityChange: {
            ...priorityResult,
            changeType: isCurrentEmpty ? 'empty_to_value' :
                isNewEmpty ? 'value_to_empty' :
                    'value_change',
          },
        }
      }
      return { type: 'exists', keyword: existingKeyword }
    }

    const deletedKeyword = KeywordUtils.findSimilarInArray(
        keyword,
        updatedKeywords.filter(k => k.state === 3),
    )

    if (deletedKeyword) {
      deletedKeyword.state = 4
      deletedKeyword.priority = priorityResult.normalized
      return {
        type: 'reactivated',
        keyword: deletedKeyword,
        priorityChange: {
          ...priorityResult,
          changeType: priorityResult.normalized === null ? 'value_to_empty' :
              deletedKeyword.priority === null ? 'empty_to_value' :
                  'value_change',
        },
      }
    }

    const newKeyword = {
      name: keyword.trim(),
      state: 2,
      priority: priorityResult.normalized,
    }
    updatedKeywords.push(newKeyword)

    return {
      type: 'new',
      keyword: newKeyword,
      priorityChange: {
        ...priorityResult,
        changeType: priorityResult.normalized === null ? 'empty_value' : 'new_value',
      },
    }
  }

  const processImportedKeywords = (importedKeywords, invalidRows) => {
    const updatedKeywords = [...props.keywords]
    const results = {
      new: 0,
      reactivated: 0,
      updated: 0,
      unchanged: 0,
      invalid: invalidRows.length,
      priorityNormalized: 0,
      skipped: 0,
      details: {
        newKeywords: [],
        updatedKeywords: [],
        unchangedKeywords: [],
        skippedKeywords: [],
        reactivatedKeywords: [],
        priorityNormalizedKeywords: [],
        invalidKeywords: invalidRows,
      },
    }

    // Phase 1: Process updates to existing keywords
    const remainingKeywords = []
    importedKeywords.forEach(importedKeyword => {
      const existingKeyword = KeywordUtils.findSimilarInArray(
          importedKeyword.name,
          updatedKeywords,
      )

      if (existingKeyword) {
        const result = processKeyword(
            importedKeyword.name,
            importedKeyword.priority,
            updatedKeywords,
        )
        handleProcessResult(result, results, importedKeyword.rowNumber)
      } else {
        remainingKeywords.push(importedKeyword)
      }
    })

    // Phase 2: Process new keywords within available slots
    const availableSlots = props.maxKeywords - results.new - results.reactivated
    remainingKeywords.forEach((newKeyword, index) => {
      if (index >= availableSlots) {
        results.skipped++
        results.details.skippedKeywords.push({
          name: newKeyword.name,
          priority: newKeyword.priority,
          rowNumber: newKeyword.rowNumber,
          reason: 'keyword_limit_exceeded',
        })
        return
      }

      const result = processKeyword(newKeyword.name, newKeyword.priority, updatedKeywords)
      handleProcessResult(result, results, newKeyword.rowNumber)
    })

    return { results, updatedKeywords }
  }

  const handleProcessResult = (result, results, rowNumber) => {
    switch (result.type) {
      case 'new':
        results.new++
        results.details.newKeywords.push({ ...result.keyword, rowNumber })
        break
      case 'updated':
        results.updated++
        results.details.updatedKeywords.push({ ...result.keyword, rowNumber })
        break
      case 'reactivated':
        results.reactivated++
        results.details.reactivatedKeywords.push({ ...result.keyword, rowNumber })
        break
    }

    if (result.priorityChange?.wasNormalized) {
      results.priorityNormalized++
      results.details.priorityNormalizedKeywords.push({
        name: result.keyword.name,
        originalPriority: result.priorityChange.original,
        normalizedPriority: result.priorityChange.normalized,
        changeType: result.priorityChange.changeType,
        reason: result.priorityChange.reason,
        rowNumber,
      })
    }
  }

  const showImportResults = (results) => {
    let hasSuccessMessage = false

    if (results.priorityNormalized > 0) {
      responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.priorityNormalized', { count: results.priorityNormalized }))
    }

    if (results.invalid > 0) {
      responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.invalidRows', { count: results.invalid }))
    }

    if (results.skipped > 0) {
      responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.limitExceeded', { count: results.skipped, max: props.maxKeywords }))
    }

    ['new', 'reactivated', 'updated'].forEach(type => {
      if (results[type] > 0) {
        responseHandlerService.successHandler(t(`settings.components.configuration.components.keywordsTable.keywords${type.charAt(0).toUpperCase() + type.slice(1)}`, { count: results[type] }))
        hasSuccessMessage = true
      }
    })

    if (!hasSuccessMessage && results.invalid === 0 && results.skipped === 0) {
      responseHandlerService.warningHandler(t('settings.components.configuration.components.keywordsTable.noChanges'))
    }
  }

  const handleFileUpload = async (file) => {
    if (!file) return

    try {
      const workbook = new ExcelJS.Workbook()
      const arrayBuffer = await file.arrayBuffer()
      await workbook.xlsx.load(arrayBuffer)
      const worksheet = workbook.worksheets[0]

      const importedKeywords = []
      const invalidRows = []

      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return

        const keyword = row.getCell(1).text?.trim()
        const priority = row.getCell(2).value

        if (!keyword) {
          invalidRows.push({ row: rowNumber, reason: 'empty_keyword' })
          return { success: false }
        }

        importedKeywords.push({ name: keyword, priority, rowNumber })
      })

      const { results, updatedKeywords } = processImportedKeywords(importedKeywords, invalidRows)
      importResults.value = results

      if (results.new > 0 || results.reactivated > 0 || results.updated > 0) {
        emit('update:keywords', updatedKeywords)
      }

      showImportResults(results)

      return { success: true, results }
    } catch (error) {
      console.error('Import failed:', error)
      return { success: false, error }
    }
  }

  return {
    importResults,
    handleFileUpload,
  }
}