<template>
  <div class="layout-spacing">
    <div class="component-layout">
      <customTable :data="userList" :columns="columns" :is-loading="load" :items-per-page="10" :total-count="totalCount" :page-count="Math.ceil(totalCount / 10)" :current-page="currentPage" :show-search="true" :search-placeholder="t('users.searchPlaceholder')" @search="handleSearch" @page-change="handlePageChange">
        <template #table-actions>
          <customButton :button-text="t('users.addUser')" color="primary" variation="solid" size="sm" :show-icon="true" icon="add" icon-position="left" @click="openModal('add')"/>
        </template>

        <template #actions="{ item }">
          <div class="flex gap-2">
            <customButton color="secondary" variation="outline" size="sm" :show-icon="true" icon="edit" :is-text-showable="false" @click="editUser(item)"/>
            <customButton color="primary" variation="outline" size="sm" :show-icon="true" icon="delete" :is-text-showable="false" @click="openDeleteDialog(item)"/>
          </div>
        </template>
      </customTable>
    </div>
  </div>
  <userFormModal v-model="showModal" :isEditing="isEditing" :userData="selectedUser" @update="fetchUserData"/>
  <CustomDialog v-model="showDeleteDialog" :title="t('users.deleteTitle')" :message="t('users.deleteConfirmation')" :confirm-button-text="t('general.yes')" :cancel-button-text="t('general.no')" :show-cancel-button="true" @confirm="handleDeleteConfirm" @decline="showDeleteDialog = false" :loading="buttonLoading"/>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from '@/plugins/axiosInstance'
import { users } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import customTable from '@/components/table/VTable.vue'
import customButton from '@/components/base/buttons/VButton.vue'
import userFormModal from './components/userFormModal.vue'
import CustomDialog from '@/components/feedback/VDialog.vue'

const { t } = useI18n()

// Data
const userList = ref([])
const load = ref(false)
const showModal = ref(false)
const showDeleteDialog = ref(false)
const isEditing = ref(false)
const selectedUser = ref({})
const currentPage = ref(1)
const totalCount = ref(0)
const searchQuery = ref('')
const buttonLoading = ref(false)

// Table columns configuration
const columns = [
  {
    key: 'name',
    label: t('users.nameColumn'),
    width: 'w-5/12',
  },
  {
    key: 'email',
    label: t('users.emailColumn'),
    width: 'w-6/12',
  },
  {
    key: 'actions',
    label: '',
    width: 'w-1/12',
    align: 'center',
  },
]

// Methods
const fetchUserData = async (page = currentPage.value) => {
  load.value = true
  try {
    const response = await axios.get(users.getAll, {
      params: {
        search: searchQuery.value,
        page: page - 1,
        sort: 'id DESC',
      },
    })
    userList.value = response.data.data.detail
    totalCount.value = response.data.data.totalCount
  } catch (error) {
    console.error(error)
    responseHandlerService.handleError(error)
  } finally {
    load.value = false
  }
}

const handleSearch = (query) => {
  searchQuery.value = query
  currentPage.value = 1
  fetchUserData()
}

const handlePageChange = (page) => {
  currentPage.value = page
  fetchUserData(page)
}

const editUser = (user) => {
  openModal('edit', user)
}

const openDeleteDialog = (user) => {
  selectedUser.value = user
  showDeleteDialog.value = true
}

const handleDeleteConfirm = async () => {
  buttonLoading.value = true
  try {
    await axios.delete(users.delete, {
      data: { userId: selectedUser.value.id },
    })
    responseHandlerService.handleSuccess(t('users.deleteSuccess'))
    await fetchUserData(currentPage.value)
  } catch (error) {
    console.error(error)
    responseHandlerService.handleError(error, t('users.deleteError'))
  } finally {
    showDeleteDialog.value = false
    buttonLoading.value = false
  }
}

const openModal = (mode, user = null) => {
  isEditing.value = mode === 'edit'
  selectedUser.value = user || {}
  showModal.value = true
}

onMounted(() => {
  fetchUserData(1)
})
</script>