<template>
  <sideModal ref="sideModal" modalClass="w-full lg:w-10/12 xl:w-6/12" :isHeader="true" :headerTitle="$t('entity.components.contentReview.component.contentHistory.modal.title')" @opened="getAll">
    <template #external>
      <customDialog v-model="showConfirmDialog" :title="$t('entity.components.contentReview.component.contentHistory.modal.confirmDialog.title')" :message="$t('entity.components.contentReview.component.contentHistory.modal.confirmDialog.message')" :showCancelButton="true" :cancelButtonText="$t('entity.components.contentReview.component.contentHistory.modal.confirmDialog.cancel')" :confirmButtonText="$t('entity.components.contentReview.component.contentHistory.modal.confirmDialog.confirm')" @update:show="showConfirmDialog = $event"/>
    </template>

    <template #content>
      <div class="p-4 w-full h-full">
        <div class="overflow-auto h-[550px] xl:h-[650px] 2xl:h-[750px] mt-4 thin-scrollbar">
          <div v-if="isLoading" class="w-full h-full">
            <loading :loading-text="$t('entity.components.contentReview.component.contentHistory.modal.loading')" :showLoadingText="true" loadingTextClass="text-sm font-semibold"/>
          </div>
          <div v-else class="flex flex-col h-full space-y-4">
            <content-history-list :histories="histories" @withdrawHistory="(id) => emit('withdrawHistory', id)"/>
          </div>
        </div>
      </div>
    </template>
  </sideModal>
</template>

<script setup>
import { ref } from 'vue'
import { useAssetStore } from '@/stores/asset'
import loading from '@/components/loading/VLoading.vue'
import SideModal from '@/components/modals/VSideModal.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import customDialog from '@/components/feedback/VDialog.vue'
import ContentHistoryList from './components/contentHistoryList.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  entityId: {
    type: [String, Number],
    default: '',
  },
  topId: {
    type: [String, Number],
    default: '',
  },
})

const emit = defineEmits(['withdrawHistory'])
const assetStore = useAssetStore()

const sideModal = ref(null)
const isLoading = ref(false)
const showConfirmDialog = ref(false)
const histories = ref([])

const toggleSidebar = () => {
  setTimeout(() => {
    if (sideModal.value) {
      sideModal.value.show()
    }
  }, 100)
}

const getAll = () => {
  isLoading.value = true
  setTimeout(() => {
    axios.get(entity.contentHistory, {
      params: {
        entityId: props.entityId,
        topId: props.topId,
        assetId: assetStore.assetId,
      },
    }).then((result) => {
      histories.value = result.data.data
    }).catch((err) => {
      responseHandlerService.handleError(err, $t('entity.components.contentReview.component.contentHistory.modal.errorMessage'))
    }).finally(() => {
      isLoading.value = false
    })
  }, 1000)
}

defineExpose({
  toggleSidebar,
})
</script>