import { computed } from 'vue'
import { useUIStore } from '@/stores/ui'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

// Dil dosyalarını import et
import 'dayjs/locale/tr'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/zh'
import 'dayjs/locale/ru'

// Yükle tüm gerekli plugin'leri
[utc, timezone, weekOfYear, localizedFormat, isoWeek, customParseFormat, isSameOrBefore, isBetween].forEach(dayjs.extend)

export function useDate() {
  const uiStore = useUIStore()
  const userTimezone = dayjs.tz.guess()

  const getWeekDays = computed(() => (date) => {
    dayjs.locale(uiStore.language)
    const startOfWeek = dayjs(date).tz(userTimezone).startOf('isoWeek')

    return Array.from({ length: 7 }, (_, i) => {
      const dayObject = startOfWeek.add(i, 'day')
      return {
        date: dayObject.format('YYYY-MM-DD'),
        dayOfMonth: dayObject.date(),
        dayName: dayObject.format('ddd'),
        month: dayObject.month() + 1,
        year: dayObject.year(),
        isToday: dayObject.format('YYYY-MM-DD') === dayjs().tz(userTimezone).format('YYYY-MM-DD'),
        fullDayName: dayObject.format('dddd'),
      }
    })
  })

  const getMonthYearString = computed(() => (weekDays) => {
    dayjs.locale(uiStore.language)
    const [firstDay, lastDay] = [weekDays[0], weekDays[6]]
    const firstDate = dayjs(`${firstDay.year}-${firstDay.month}-${firstDay.dayOfMonth}`).tz(userTimezone)
    const lastDate = dayjs(`${lastDay.year}-${lastDay.month}-${lastDay.dayOfMonth}`).tz(userTimezone)

    return firstDay.month === lastDay.month
        ? firstDate.format('MMMM YYYY')
        : `${firstDate.format('MMM')} - ${lastDate.format('MMM')} ${lastDate.format('YYYY')}`
  })

  const formatHour = computed(() => (hour) => {
    dayjs.locale(uiStore.language)
    return dayjs().tz(userTimezone).hour(hour).minute(0).format('HH:mm')
  })

  const format = computed(() => (date, formatStr) => {
    dayjs.locale(uiStore.language)
    return dayjs(date).tz(userTimezone).format(formatStr)
  })

  const formatDate = computed(() => (date, formatStr = 'L') => {
    dayjs.locale(uiStore.language)
    return dayjs(date).tz(userTimezone).format(formatStr)
  })

  const formatDateTime = computed(() => (date, formatStr = 'L LT') => {
    dayjs.locale(uiStore.language)
    return dayjs(date).tz(userTimezone).format(formatStr)
  })

  // Normal metodlar
  const now = () => dayjs().tz(userTimezone)
  const parse = (date) => dayjs(date).tz(userTimezone)
  const add = (date, amount, unit) => dayjs(date).tz(userTimezone).add(amount, unit)
  const subtract = (date, amount, unit) => dayjs(date).tz(userTimezone).subtract(amount, unit)
  const startOfWeek = (date) => dayjs(date).tz(userTimezone).startOf('isoWeek')
  const endOfWeek = (date) => dayjs(date).tz(userTimezone).endOf('isoWeek')
  const startOfDay = (date) => dayjs(date).tz(userTimezone).startOf('day')
  const endOfDay = (date) => dayjs(date).tz(userTimezone).endOf('day')
  const startOfMonth = (date) => dayjs(date).tz(userTimezone).startOf('month')
  const endOfMonth = (date) => dayjs(date).tz(userTimezone).endOf('month')
  const startOfYear = (date) => dayjs(date).tz(userTimezone).startOf('year')
  const endOfYear = (date) => dayjs(date).tz(userTimezone).endOf('year')
  const isSameDay = (date1, date2) => dayjs(date1).tz(userTimezone).isSame(dayjs(date2), 'day')
  const isSameYear = (date1, date2) => dayjs(date1).tz(userTimezone).isSame(dayjs(date2), 'year')
  const isSameOrBefore = (date1, date2) => dayjs(date1).tz(userTimezone).isSameOrBefore(dayjs(date2))
  const isBetween = (date, startDate, endDate, unit = 'day', inclusivity = '[]') =>
      dayjs(date).tz(userTimezone).isBetween(dayjs(startDate), dayjs(endDate), unit, inclusivity)
  const isValid = (date) => dayjs(date).isValid()
  const toLocal = (date) => dayjs(date).tz(userTimezone)
  const toUTC = (date) => dayjs(date).utc()

  const getWeeksInMonth = (date) => {
    const start = startOfMonth(date)
    const end = endOfMonth(date)
    const weeks = []
    let current = startOfWeek(start)

    while (current.isBefore(end)) {
      weeks.push(current)
      current = current.add(1, 'week')
    }

    return weeks
  }

  const getMonthsInYear = (date) => {
    const start = startOfYear(date)
    const months = []

    for (let i = 0; i < 12; i++) {
      months.push(start.add(i, 'month'))
    }

    return months
  }

  return {
    getWeekDays,
    getMonthYearString,
    formatHour,
    format,
    formatDate,
    formatDateTime,
    now,
    parse,
    add,
    subtract,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    isSameDay,
    isSameYear,
    isSameOrBefore,
    isBetween,
    isValid,
    toLocal,
    toUTC,
    getWeeksInMonth,
    getMonthsInYear,
  }
}