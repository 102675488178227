<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="$emit('update:modelValue', false)" class="flex justify-center items-center" contentClass="flex flex-col bg-white max-w-xl w-full shadow-xl lg:h-auto lg:rounded-xl mx-4 overflow-hidden" contentTransition="vfm-fade">
    <div class="flex flex-col">
      <!-- Header -->
      <div class="w-full flex items-center justify-between px-4 py-3 border-b border-gray-100">
        <h2 class="text-base font-medium text-gray-900 flex items-center gap-2">
          <span class="material-symbols-outlined text-primary/80 !text-[20px]">preview</span> {{ t('entity.components.shareMenu.components.previewModal.preview') }} </h2>
        <button @click="$emit('update:modelValue', false)" class="p-1.5 rounded-full hover:bg-gray-100 transition-colors">
          <span class="material-symbols-outlined !text-[20px] text-gray-400">close</span>
        </button>
      </div>

      <!-- Social Media Preview -->
      <div class="p-4">
        <!-- Social Card -->
        <div class="border border-gray-200 rounded-lg overflow-hidden shadow-sm hover:shadow transition-shadow">
          <!-- Image with Copy Button -->
          <div v-if="image" class="relative group">
            <img :src="image" :alt="title" class="w-full h-48 object-cover"/>
            <button @click="copyText(image)" class="absolute top-2 right-2 p-1.5 bg-white/90 backdrop-blur-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity hover:bg-white flex items-center gap-1.5">
              <span class="material-symbols-outlined !text-[16px]">content_copy</span> <span class="text-xs">{{ t('entity.components.shareMenu.components.previewModal.copyImageUrl') }}</span>
            </button>
          </div>

          <!-- Content -->
          <div class="p-4 space-y-2">
            <h3 class="font-semibold text-gray-900 line-clamp-2">{{ title }}</h3>
            <p class="text-sm text-gray-600 line-clamp-3">{{ content }}</p>

            <!-- Hashtags -->
            <div v-if="hashtags?.length" class="flex flex-wrap gap-1.5 pt-2 border-t border-gray-100">
              <span v-for="tag in hashtags" :key="tag" class="text-xs text-primary font-medium hover:text-primary/80 cursor-pointer">
                {{ tag }}
              </span>
            </div>

            <!-- URL -->
            <a v-if="url" :href="url" target="_blank" rel="noopener noreferrer" class="block pt-2 border-t border-gray-100 flex items-center gap-2 text-sm text-gray-500 hover:text-gray-700 transition-colors">
              <span class="material-symbols-outlined !text-[16px]">link</span> <span class="truncate">{{ url }}</span> </a>
          </div>
        </div>

        <!-- Quick Copy Buttons -->
        <div class="mt-4 flex flex-wrap gap-2">
          <button @click="copyText(title + '\n\n' + content)" class="flex items-center gap-1.5 px-3 py-1.5 text-xs text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
            <span class="material-symbols-outlined !text-[16px]">content_copy</span> {{ t('entity.components.shareMenu.components.previewModal.copyContent') }}
          </button>

          <button v-if="image" @click="copyText(image)" class="flex items-center gap-1.5 px-3 py-1.5 text-xs text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
            <span class="material-symbols-outlined !text-[16px]">image</span> {{ t('entity.components.shareMenu.components.previewModal.copyImageUrl') }}
          </button>

          <button v-if="hashtags?.length" @click="copyText(hashtags.map(tag => `${tag}`).join(' '))" class="flex items-center gap-1.5 px-3 py-1.5 text-xs text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
            <span class="material-symbols-outlined !text-[16px]">tag</span> {{ t('entity.components.shareMenu.components.previewModal.copyTags') }}
          </button>

          <button v-if="url" @click="copyText(url)" class="flex items-center gap-1.5 px-3 py-1.5 text-xs text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
            <span class="material-symbols-outlined !text-[16px]">link</span> {{ t('entity.components.shareMenu.components.previewModal.copyUrl') }}
          </button>
        </div>
      </div>

      <!-- Footer -->
      <div class="px-4 py-3 border-t border-gray-100">
        <custom-button @click="copyAllContent" :button-text=" t('entity.components.shareMenu.components.previewModal.copyAll')" :show-icon="true" size="sm" icon="file_copy" :full-width="true"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  title: String,
  content: String,
  url: String,
  image: String,
  hashtags: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const copyAllContent = () => {
  const allContent = [
    props.title,
    props.content,
    props.image,
    props.url,
    props.hashtags?.length ? props.hashtags.map(tag => `${tag}`).join(' ') : '',
  ].filter(Boolean).join('\n\n').trim()

  copyText(allContent)
}

const copyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
    responseHandlerService.handleSuccess(t('entity.components.shareMenu.components.previewModal.copySuccess'))
  } catch (err) {
    responseHandlerService.handleError(t('entity.components.shareMenu.components.previewModal.copyError'))
  }
}
</script>