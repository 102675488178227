<template>
  <VueFinalModal v-model="showModal" class="flex justify-center items-center" content-class="relative bg-white rounded-xl w-[500px] max-w-full mx-4 p-6 shadow-lg border" :click-to-close="false" :esc-to-close="true">
    <div class="space-y-5">
      <!-- Modal Header -->
      <div class="flex justify-between items-center">
        <h3 class="text-xl font-medium text-gray-900">
          {{ t('settings.components.configuration.components.keywordsTable.addNewKeywords') }} </h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-500 p-1 hover:bg-gray-100 rounded-full transition-colors">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="space-y-4">
        <!-- Column Headers -->
        <div class="flex items-center gap-3">
          <div class="flex-grow pl-3">
            <span class="text-sm font-medium text-gray-700">
              {{ t('settings.components.configuration.components.keywordsTable.keyword') }}
            </span>
          </div>
          <div class="w-36 pl-3">
            <span class="text-sm font-medium text-gray-700">
              {{ t('settings.components.configuration.components.keywordsTable.priority') }}
            </span>
          </div>
          <div class="w-8"></div>
        </div>

        <TransitionGroup name="keyword-list">
          <div v-for="(keyword, index) in keywords" :key="index" class="flex items-center gap-3 animate-in fade-in">
            <div class="flex-grow">
              <CustomInput v-model="keyword.name" :placeholder="t('settings.components.configuration.components.keywordsTable.enterKeyword')" size="md" class="w-full focus:ring-2 focus:ring-primary/20" :show-error="true" :error="getKeywordError(keyword.name)"/>
            </div>
            <div class="w-36">
              <CustomSelect v-model="keyword.priority" :options="priorityOptions" :placeholder="t('settings.components.configuration.components.keywordsTable.selectPriority')" size="md" class="w-full" :clearable="true"/>
            </div>
            <div class="w-8 flex justify-center">
              <button v-if="index !== 0" @click="removeKeyword(index)" class="text-red-500 hover:text-red-600 hover:bg-red-50 p-1.5 rounded-full transition-colors">
                <span class="material-symbols-outlined text-sm">delete</span>
              </button>
            </div>
          </div>
        </TransitionGroup>
      </div>

      <!-- Add More Button -->
      <div v-if="keywords.length < 5" @click="addKeyword" class="flex items-center text-sm text-primary hover:text-primary/80 cursor-pointer transition-colors">
        <span class="material-symbols-outlined mr-1 text-sm">add</span> <span>{{ t('settings.components.configuration.components.keywordsTable.addAnotherKeyword') }}</span>
      </div>

      <!-- Modal Footer -->
      <div class="flex justify-end gap-3 pt-4 border-t">
        <CustomButton type="button" :button-text="t('common.cancel')" @click="closeModal" color="secondary" variant="outline" size="sm"/>
        <CustomButton type="submit" :button-text="t('common.save')" @click="handleSave" :disabled="!isValid" color="primary" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomSelect from '@/components/base/inputs/VSelect.vue'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  maxKeywords: {
    type: Number,
    default: 350,
  },
  currentCount: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['update:modelValue', 'save'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const keywords = ref([{ name: '', priority: null }])

const priorityOptions = computed(() =>
    Array.from({ length: 10 }, (_, i) => ({
      value: i + 1,
      label: (i + 1).toString(),
    })),
)

const getKeywordError = (value) => {
  if (value.trim().length > 0 && value.trim().length < 2) {
    return t('settings.components.configuration.validation.keyword.minLength')
  }
  return ''
}

const isValid = computed(() => {
  return keywords.value.some(k => {
    const name = k.name.trim()
    return name !== '' && name.length >= 2
  })
})

const addKeyword = () => {
  if (keywords.value.length < 5) {
    keywords.value.push({ name: '', priority: null })
  }
}

const removeKeyword = (index) => {
  keywords.value.splice(index, 1)
}

const handleSave = () => {
  const validKeywords = keywords.value
      .filter(k => {
        const name = k.name.trim()
        return name !== '' && name.length >= 2
      })
      .map(k => ({
        name: k.name.trim(),
        priority: k.priority,
        state: 2,
      }))

  if (validKeywords.length === 0) return

  emit('save', validKeywords)
  closeModal()
}

const closeModal = () => {
  showModal.value = false
  keywords.value = [{ name: '', priority: null }]
}

// Reset form when modal opens
watch(() => showModal.value, (newValue) => {
  if (newValue) {
    keywords.value = [{ name: '', priority: null }]
  }
})
</script>

<style scoped>
.keyword-list-move,
.keyword-list-enter-active,
.keyword-list-leave-active {
  transition: all 0.3s ease;
}

.keyword-list-enter-from,
.keyword-list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.keyword-list-leave-active {
  position: absolute;
}
</style>