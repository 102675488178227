import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'

// Dil dosyalarını import et
import 'dayjs/locale/tr'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/zh'
import 'dayjs/locale/ru'

// Yükle tüm gerekli plugin'leri
[utc, timezone, weekOfYear, localizedFormat, isoWeek, customParseFormat, isSameOrBefore, isBetween].forEach(dayjs.extend)

// Format şablonları
const localeFormats = {
  tr: {
    full: {
      datetime: 'DD MMMM YYYY HH:mm',
      date: 'DD MMMM YYYY',
      time: 'HH:mm:ss',
    },
    long: {
      datetime: 'DD MMM YYYY HH:mm',
      date: 'DD MMM YYYY',
      time: 'HH:mm:ss',
    },
    medium: {
      datetime: 'DD.MM.YYYY HH:mm',
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
    },
    short: {
      datetime: 'DD.MM.YY HH:mm',
      date: 'DD.MM.YY',
      time: 'HH:mm',
    },
  },
  de: {
    full: {
      datetime: 'DD. MMMM YYYY HH:mm',
      date: 'DD. MMMM YYYY',
      time: 'HH:mm:ss',
    },
    long: {
      datetime: 'DD. MMM YYYY HH:mm',
      date: 'DD. MMM YYYY',
      time: 'HH:mm:ss',
    },
    medium: {
      datetime: 'DD.MM.YYYY HH:mm',
      date: 'DD.MM.YYYY',
      time: 'HH:mm',
    },
    short: {
      datetime: 'DD.MM.YY HH:mm',
      date: 'DD.MM.YY',
      time: 'HH:mm',
    },
  },
  fr: {
    full: {
      datetime: 'DD MMMM YYYY HH:mm',
      date: 'DD MMMM YYYY',
      time: 'HH:mm:ss',
    },
    long: {
      datetime: 'DD MMM YYYY HH:mm',
      date: 'DD MMM YYYY',
      time: 'HH:mm:ss',
    },
    medium: {
      datetime: 'DD/MM/YYYY HH:mm',
      date: 'DD/MM/YYYY',
      time: 'HH:mm',
    },
    short: {
      datetime: 'DD/MM/YY HH:mm',
      date: 'DD/MM/YY',
      time: 'HH:mm',
    },
  },
  default: {
    full: {
      datetime: 'MMMM D, YYYY h:mm A',
      date: 'MMMM D, YYYY',
      time: 'h:mm:ss A',
    },
    long: {
      datetime: 'MMM D, YYYY h:mm A',
      date: 'MMM D, YYYY',
      time: 'h:mm:ss A',
    },
    medium: {
      datetime: 'MM/DD/YYYY h:mm A',
      date: 'MM/DD/YYYY',
      time: 'h:mm A',
    },
    short: {
      datetime: 'M/D/YY h:mm A',
      date: 'M/D/YY',
      time: 'h:mm A',
    },
  },
}

/**
 * Tarih işlemleri için yardımcı fonksiyonlar içeren servis
 */
export const dateHelper = {
  /** Kullanıcının tarayıcısından algılanan zaman dilimi */
  userTimezone: dayjs.tz.guess(),

  /** Kullanıcının dil/bölge ayarı */
  userLocale: navigator.language || navigator.userLanguage,

  /**
   * Kullanıcının diline göre format şablonlarını döndürür
   * @private
   * @returns {Object} Format şablonları
   */
  _getLocaleFormats() {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    return localeFormats[locale] || localeFormats.default
  },

  /**
   * Şu anki tarihi kullanıcının zaman diliminde döndürür
   * @returns {Object} Dayjs objesi
   */
  now() {
    return dayjs().tz(this.userTimezone)
  },

  /**
   * Verilen tarihi parse eder ve kullanıcının zaman diliminde döndürür
   * @param {string|Date|Object} date - Parse edilecek tarih
   * @returns {Object} Dayjs objesi
   */
  parse(date) {
    return dayjs(date).tz(this.userTimezone)
  },

  /**
   * Verilen tarihi belirtilen formatta formatlar
   * @param {string|Date|Object} date - Formatlanacak tarih
   * @param {string} format - Çıktı formatı (örn: 'YYYY-MM-DD')
   * @returns {string} Formatlanmış tarih string'i
   */
  format(date, format) {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    dayjs.locale(locale)
    return dayjs(date).tz(this.userTimezone).format(format)
  },

  /**
   * Tarihi kullanıcının yerel formatında döndürür
   * @param {string|Date|Object} date - Formatlanacak tarih
   * @param {string} style - Format stili ('full', 'long', 'medium', 'short')
   * @param {Object} options - Opsiyonel format seçenekleri
   * @returns {string} Formatlanmış tarih string'i
   */
  formatLocalized(date, style = 'medium', options = {}) {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    dayjs.locale(locale)

    const dayjsDate = dayjs(date).tz(this.userTimezone)
    const formats = this._getLocaleFormats()
    const { type = 'datetime' } = options

    const format = formats[style]?.[type] || formats.medium[type]
    return dayjsDate.format(format)
  },

  /**
   * Saati formatlar
   * @param {number} hour - 0-23 arası saat
   * @returns {string} Formatlanmış saat
   */
  formatHour(hour) {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    dayjs.locale(locale)
    return dayjs().hour(hour).minute(0).format('HH:mm')
  },

  /**
   * Verilen tarihe belirtilen miktarda zaman ekler
   * @param {string|Date|Object} date - Başlangıç tarihi
   * @param {number} amount - Eklenecek miktar
   * @param {string} unit - Zaman birimi ('day', 'week', 'month', etc.)
   * @returns {Object} Dayjs objesi
   */
  add(date, amount, unit) {
    return dayjs(date).tz(this.userTimezone).add(amount, unit)
  },

  /**
   * Verilen tarihten belirtilen miktarda zaman çıkarır
   * @param {string|Date|Object} date - Başlangıç tarihi
   * @param {number} amount - Çıkarılacak miktar
   * @param {string} unit - Zaman birimi ('day', 'week', 'month', etc.)
   * @returns {Object} Dayjs objesi
   */
  subtract(date, amount, unit) {
    return dayjs(date).tz(this.userTimezone).subtract(amount, unit)
  },

  /**
   * İki tarih arasındaki farkı hesaplar
   * @param {string|Date|Object} date1 - İlk tarih
   * @param {string|Date|Object} date2 - İkinci tarih
   * @param {string} unit - Zaman birimi ('day', 'week', 'month', etc.)
   * @returns {number} İki tarih arasındaki fark
   */
  diff(date1, date2, unit) {
    return dayjs(date1).tz(this.userTimezone).diff(dayjs(date2), unit)
  },

  /**
   * Verilen tarihin haftasının günlerini döndürür
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Array} Haftanın günlerinin detaylarını içeren dizi
   */
  getWeekDays(date) {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    dayjs.locale(locale)
    const startOfWeek = this.startOfWeek(date)
    return Array.from({ length: 7 }, (_, i) => {
      const dayObject = startOfWeek.add(i, 'day')
      return {
        date: dayObject.format('YYYY-MM-DD'),
        dayOfMonth: dayObject.date(),
        dayName: dayObject.format('ddd'),
        month: dayObject.month() + 1,
        year: dayObject.year(),
        isToday: dayObject.format('YYYY-MM-DD') === this.now().format('YYYY-MM-DD'),
      }
    })
  },

  /**
   * Hafta görünümü için ay-yıl string'ini oluşturur
   * @param {Array} weekDays - Haftanın günlerini içeren dizi
   * @returns {string} Ay-yıl string'i
   */
  getMonthYearString(weekDays) {
    const locale = this.userLocale.toLowerCase().split('-')[0]
    dayjs.locale(locale)
    const [firstDay, lastDay] = [weekDays[0], weekDays[6]]
    const firstDate = this.parse(`${firstDay.year}-${firstDay.month}-${firstDay.dayOfMonth}`)
    const lastDate = this.parse(`${lastDay.year}-${lastDay.month}-${lastDay.dayOfMonth}`)

    return firstDay.month === lastDay.month
        ? firstDate.format('MMMM YYYY')
        : `${firstDate.format('MMM')} - ${lastDate.format('MMM')} ${lastDate.format('YYYY')}`
  },

  /**
   * UTC tarihini yerel zaman dilimine çevirir
   * @param {string|Date|Object} utcDate - UTC formatında tarih
   * @returns {Object} Yerel zaman diliminde Dayjs objesi
   */
  toLocal(utcDate) {
    return dayjs(utcDate).tz(this.userTimezone)
  },

  /**
   * Yerel tarihi UTC'ye çevirir
   * @param {string|Date|Object} localDate - Yerel zaman diliminde tarih
   * @returns {Object} UTC formatında Dayjs objesi
   */
  toUTC(localDate) {
    return dayjs(localDate).utc()
  },

  /**
   * İki tarihin aynı gün olup olmadığını kontrol eder
   * @param {string|Date|Object} date1 - İlk tarih
   * @param {string|Date|Object} date2 - İkinci tarih
   * @returns {boolean} Aynı gün ise true
   */
  isSameDay(date1, date2) {
    return dayjs(date1).tz(this.userTimezone).isSame(dayjs(date2), 'day')
  },

  /**
   * Verilen tarihin başlangıcını döndürür (00:00:00)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  startOfDay(date) {
    return dayjs(date).tz(this.userTimezone).startOf('day')
  },

  /**
   * Verilen tarihin sonunu döndürür (23:59:59)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  endOfDay(date) {
    return dayjs(date).tz(this.userTimezone).endOf('day')
  },

  /**
   * Verilen tarihin ayının başlangıcını döndürür
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  startOfMonth(date) {
    return dayjs(date).tz(this.userTimezone).startOf('month')
  },

  /**
   * Verilen tarihin ayının sonunu döndürür
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  endOfMonth(date) {
    return dayjs(date).tz(this.userTimezone).endOf('month')
  },

  /**
   * Verilen tarihin haftasının başlangıcını döndürür (Pazartesi)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  startOfWeek(date) {
    return dayjs(date).tz(this.userTimezone).startOf('isoWeek')
  },

  /**
   * Verilen tarihin haftasının sonunu döndürür (Pazar)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  endOfWeek(date) {
    return dayjs(date).tz(this.userTimezone).endOf('isoWeek')
  },

  /**
   * İlk tarihin ikinci tarihle aynı veya daha önce olup olmadığını kontrol eder
   * @param {string|Date|Object} date1 - İlk tarih
   * @param {string|Date|Object} date2 - İkinci tarih
   * @returns {boolean} İlk tarih ikinci tarihle aynı veya daha önce ise true
   */
  isSameOrBefore(date1, date2) {
    return dayjs(date1).tz(this.userTimezone).isSameOrBefore(dayjs(date2))
  },

  /**
   * Verilen tarihin geçerli olup olmadığını kontrol eder
   * @param {string|Date|Object} date - Kontrol edilecek tarih
   * @returns {boolean} Geçerli ise true
   */
  isValid(date) {
    return dayjs(date).isValid()
  },

  /**
   * Verilen ay içindeki tüm haftaları döndürür
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Array} Haftaların başlangıç tarihlerini içeren dizi
   */
  getWeeksInMonth(date) {
    const start = this.startOfMonth(date)
    const end = this.endOfMonth(date)
    const weeks = []
    let current = this.startOfWeek(start)

    while (current.isBefore(end)) {
      weeks.push(current)
      current = current.add(1, 'week')
    }

    return weeks
  },

  /**
   * İki tarih arasında olup olmadığını kontrol eder
   * @param {string|Date|Object} date - Kontrol edilecek tarih
   * @param {string|Date|Object} startDate - Başlangıç tarihi
   * @param {string|Date|Object} endDate - Bitiş tarihi
   * @param {string} unit - Tarih birimi ('year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second')
   * @param {string} inclusivity - Kapsayıcılık ayarı '()' = exclusive, '[]' = inclusive, '[)' = yarı açık aralık
   * @returns {boolean} İki tarih arasındaysa true
   * @example
   * // Tarihin iki tarih arasında olup olmadığını kontrol et
   * dateHelper.isBetween('2023-01-15', '2023-01-01', '2023-01-31', 'day', '[]') // returns true
   * dateHelper.isBetween('2023-02-01', '2023-01-01', '2023-01-31', 'day', '[]') // returns false
   */
  isBetween(date, startDate, endDate, unit = 'day', inclusivity = '[]') {
    return dayjs(date).tz(this.userTimezone).isBetween(
        dayjs(startDate).tz(this.userTimezone),
        dayjs(endDate).tz(this.userTimezone),
        unit,
        inclusivity,
    )
  },

  /**
   * Verilen tarihin yılının başlangıcını döndürür (1 Ocak 00:00:00)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  startOfYear(date) {
    return dayjs(date).tz(this.userTimezone).startOf('year')
  },

  /**
   * Verilen tarihin yılının sonunu döndürür (31 Aralık 23:59:59)
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Object} Dayjs objesi
   */
  endOfYear(date) {
    return dayjs(date).tz(this.userTimezone).endOf('year')
  },

  /**
   * İki tarihin aynı yılda olup olmadığını kontrol eder
   * @param {string|Date|Object} date1 - İlk tarih
   * @param {string|Date|Object} date2 - İkinci tarih
   * @returns {boolean} Aynı yıl ise true
   */
  isSameYear(date1, date2) {
    return dayjs(date1).tz(this.userTimezone).isSame(dayjs(date2), 'year')
  },

  /**
   * Verilen yıl içindeki tüm ayları döndürür
   * @param {string|Date|Object} date - Referans tarih
   * @returns {Array} Ayların başlangıç tarihlerini içeren dizi
   */
  getMonthsInYear(date) {
    const start = this.startOfYear(date)
    const months = []

    for (let i = 0; i < 12; i++) {
      months.push(start.add(i, 'month'))
    }

    return months
  },
}