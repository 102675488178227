<template>
  <div class="fixed bg-white rounded-lg shadow-lg py-2 min-w-[220px] z-[1002] border border-gray-100/50 backdrop-blur-sm backdrop-saturate-150">
    <div class="px-2 space-y-0.5">
      <router-link v-for="subItem in submenuItems" :key="subItem.key" :to="{ path: currentMenuItem?.path || '', query: { tab: subItem.key }}" class="block px-3 py-2 rounded-md text-sm transition-all duration-200 hover:bg-gray-100" :class="{
          'text-primary bg-primary/10 font-medium': isActiveSubRoute(currentMenuItem?.path, subItem.key),
          'text-gray-700 hover:text-gray-900': !isActiveSubRoute(currentMenuItem?.path, subItem.key)
        }" @click="$emit('click')">
        {{ subItem.label }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'

const props = defineProps({
  submenuItems: {
    type: Array,
    required: true,
  },
  currentMenuItem: {
    type: Object,
    required: true,
  },
})

const route = useRoute()

const isActiveSubRoute = (path, tabKey) => {
  return route.path.startsWith(path) && route.query.tab === tabKey
}
</script>