<template>
  <div class="flex items-center justify-between mt-8 px-4">
    <customButton v-if="canGoPrev" @click="$emit('prev')" :is-disabled="loading" :button-text="prevButtonText || t('common.previous')" color="secondary" variation="outline" class="w-32"/>
    <div v-else class="w-32"></div>

    <customButton v-if="!isLastStep" @click="$emit('next')" :is-loading="loading" :is-disabled="!canGoNext" :button-text="nextButtonText || t('common.next')" class="w-32"/>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import customButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()

defineProps({
  canGoNext: {
    type: Boolean,
    default: true,
  },
  canGoPrev: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isLastStep: {
    type: Boolean,
    default: false,
  },
  nextButtonText: {
    type: String,
    default: '',
  },
  prevButtonText: {
    type: String,
    default: '',
  },
})
</script>