<template>
  <div>
    <div ref="triggerRef" @click="toggleDatePicker" class="px-3 py-1.5 border rounded-md hover:bg-gray-50 bg-white cursor-pointer flex items-center gap-2 text-sm w-full">
      <span class="material-symbols-outlined text-gray-500 !text-[20px]">calendar_today</span> <span class="text-gray-700">{{ displayRangeText }}</span>
      <span class="material-symbols-outlined text-gray-500 !text-[18px]">{{ isDatePickerVisible ? 'expand_less' : 'expand_more' }}</span>
    </div>

    <Teleport to="body">
      <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
        <div v-if="isDatePickerVisible" ref="floatingRef" class="z-[99999999999999]" :style="{
            position: strategy,
            top: `${y ?? 0}px`,
            left: `${x ?? 0}px`,
            width: 'max-content'
          }">
          <div class="bg-white rounded-lg shadow-lg border border-gray-200 p-4">
            <!-- Quick Range Selections -->
            <div class="grid grid-cols-2 gap-2 mb-4 border-b border-gray-200 pb-4">
              <button v-for="range in quickRanges" :key="range.key" @click="applyQuickRange(range)" class="px-3 py-1.5 text-sm rounded-md text-left transition-colors duration-150" :class="[
                      selectedQuickRange?.key === range.key
                        ? 'bg-primary text-white'
                        : 'text-gray-700 hover:bg-gray-100'
                    ]">
                {{ t(`globalComponents.dateRangePicker.quickRanges.${range.key}`) }}
              </button>
            </div>

            <div class="flex justify-between mb-2">
              <span @click="goToToday" class="text-sm text-primary cursor-pointer hover:underline">
                {{ t('globalComponents.dateRangePicker.goToToday') }}
              </span> <span @click="clearDateRange" class="text-sm text-primary cursor-pointer hover:underline">
                {{ t('globalComponents.dateRangePicker.clear') }}
              </span>
            </div>

            <!-- İki takvimi yan yana göster -->
            <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div v-for="(calendarDate, index) in [leftDate, rightDate]" :key="index" class="w-full sm:w-64">
                <div class="flex justify-between items-center mb-4">
                  <!-- Önceki aya gitmek için buton -->
                  <button @click="changeMonth(index, -1)" class="text-gray-600 hover:text-gray-800 focus:outline-none" :disabled="isFirstMonth(calendarDate)">
                    <span class="material-symbols-outlined" :class="{ 'opacity-50': isFirstMonth(calendarDate) }">chevron_left</span>
                  </button>

                  <!-- Ay ve yılı gösteren butonlar -->
                  <div class="flex space-x-2">
                    <button @click="toggleMonthSelector(index)" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                      {{ formatMonth(calendarDate) }}
                    </button>
                    <button @click="toggleYearSelector(index)" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                      {{ calendarDate.year() }}
                    </button>
                  </div>

                  <!-- Sonraki aya gitmek için buton -->
                  <button @click="changeMonth(index, 1)" class="text-gray-600 hover:text-gray-800 focus:outline-none" :disabled="isCurrentMonth(calendarDate)">
                    <span class="material-symbols-outlined" :class="{ 'opacity-50': isCurrentMonth(calendarDate) }">chevron_right</span>
                  </button>
                </div>

                <!-- Ay seçici bölümü -->
                <div v-if="showMonthSelector[index]" class="mb-4 grid grid-cols-3 gap-2">
                  <button v-for="(month, monthIndex) in localizedMonthNames" :key="monthIndex" @click.stop="selectMonth(index, monthIndex)" class="px-2 py-1 text-sm rounded-md hover:bg-primary hover:text-white transition-colors duration-150" :class="{ 'bg-primary text-white': monthIndex === calendarDate.month() }">
                    {{ month.slice(0, 3) }}
                  </button>
                </div>

                <!-- Yıl seçici bölümü -->
                <div v-if="showYearSelector[index]" class="mb-4 grid grid-cols-3 gap-2 max-h-40 overflow-y-auto">
                  <button v-for="year in yearRange" :key="year" @click.stop="selectYear(index, year)" class="px-2 py-1 text-sm rounded-md hover:bg-primary hover:text-white transition-colors duration-150" :class="{ 'bg-primary text-white': year === calendarDate.year() }">
                    {{ year }}
                  </button>
                </div>

                <!-- Takvim günlerini göster -->
                <div v-if="!showMonthSelector[index] && !showYearSelector[index]">
                  <div class="grid grid-cols-7 gap-1 mb-1">
                    <div v-for="day in daysOfWeek" :key="day" class="text-center text-xs font-medium text-gray-500">
                      {{ day }}
                    </div>
                  </div>

                  <div class="grid grid-cols-7 gap-1">
                    <div v-for="{ date, isCurrentMonth, isToday, isInRange, isRangeStart, isRangeEnd, isFuture } in getCalendarDays(calendarDate)" :key="date.toISOString()" @click="selectDate(date)" @mouseenter="handleMouseEnter(date)" class="h-8 flex items-center justify-center text-sm rounded-full cursor-pointer transition-colors duration-200" :class="{
                        'text-gray-300': !isCurrentMonth || isFuture,
                        'bg-primary text-white font-bold transform scale-110': isRangeStart || isRangeEnd && !isFuture,
                        'bg-primary bg-opacity-10 text-primary': isInRange && !isRangeStart && !isRangeEnd,
                        'hover:bg-gray-100': isCurrentMonth && !isInRange && !isRangeStart && !isRangeEnd && !isFuture,
                        'text-gray-800': isCurrentMonth && !isInRange && !isRangeStart && !isRangeEnd && !isFuture,
                        'ring-2 ring-primary': isToday && !isInRange && !isRangeStart && !isRangeEnd,
                        'cursor-not-allowed': isFuture
                      }">
                      {{ date.date() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/vue'
import { dateHelper } from '@/utils/dateHelper'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ start: null, end: null }),
  },
  minYear: {
    type: Number,
    default: () => dateHelper.now().year() - 101,
  },
  maxYear: {
    type: Number,
    default: () => dateHelper.now().year(),
  },
  currentYearOnly: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const { t, tm } = useI18n()

// Refs
const leftDate = ref(dateHelper.subtract(dateHelper.now(), 1, 'month').startOf('month'))
const rightDate = ref(dateHelper.now().startOf('month'))
const isDatePickerVisible = ref(false)
const showMonthSelector = ref([false, false])
const showYearSelector = ref([false, false])
const selectedStartDate = ref(null)
const selectedEndDate = ref(null)
const hoverDate = ref(null)
const selectedQuickRange = ref(null)
const triggerRef = ref(null)
const floatingRef = ref(null)

// Computed Properties
const daysOfWeek = computed(() => {
  const days = tm('general.dayNames')
  return Object.keys(days)
      .sort((a, b) => Number(a) - Number(b))
      .map(key => days[key].slice(0, 3))
})

const localizedMonthNames = computed(() => tm('general.monthNames'))

const displayRangeText = computed(() => {
  if (selectedQuickRange.value) {
    // Label'ı key'e göre dinamik olarak alıyoruz
    return t(`globalComponents.dateRangePicker.quickRanges.${selectedQuickRange.value.key}`)
  }

  const start = selectedStartDate.value || props.modelValue.start
  const end = selectedEndDate.value || props.modelValue.end

  if (start && end) {
    return `${dateHelper.formatLocalized(start, 'medium', { type: 'date' })} - ${dateHelper.formatLocalized(end, 'medium', { type: 'date' })}`
  }

  return t('globalComponents.dateRangePicker.today')
})

const quickRanges = computed(() => [
  {
    key: 'last7Days',
    value: () => ({
      start: dateHelper.subtract(dateHelper.now(), 7, 'day').toDate(),
      end: dateHelper.now().toDate(),
    }),
  },
  {
    key: 'last30Days',
    value: () => ({
      start: dateHelper.subtract(dateHelper.now(), 30, 'day').toDate(),
      end: dateHelper.now().toDate(),
    }),
  },
  {
    key: 'thisMonth',
    value: () => ({
      start: dateHelper.startOfMonth(dateHelper.now()).toDate(),
      end: dateHelper.now().toDate(),
    }),
  },
  {
    key: 'lastMonth',
    value: () => ({
      start: dateHelper.startOfMonth(dateHelper.subtract(dateHelper.now(), 1, 'month')).toDate(),
      end: dateHelper.endOfMonth(dateHelper.subtract(dateHelper.now(), 1, 'month')).toDate(),
    }),
  },
  {
    key: 'last3Months',
    value: () => ({
      start: dateHelper.subtract(dateHelper.now(), 3, 'month').toDate(),
      end: dateHelper.now().toDate(),
    }),
  },
  {
    key: 'thisYear',
    value: () => ({
      start: dateHelper.startOfYear(dateHelper.now()).toDate(),
      end: dateHelper.now().toDate(),
    }),
  },
])

const yearRange = computed(() => {
  if (props.currentYearOnly) {
    const currentYear = dateHelper.now().year()
    return [currentYear - 1, currentYear]
  }
  return Array.from(
      { length: props.maxYear - props.minYear + 1 },
      (_, i) => props.minYear + i,
  ).reverse()
})

// Floating UI Setup
const { x, y, strategy, update } = useFloating(triggerRef, floatingRef, {
  placement: 'bottom-start',
  middleware: [
    offset(10),
    flip(),
    shift({ padding: 5 }),
  ],
  whileElementsMounted: autoUpdate,
})

// Calendar Methods
const formatMonth = (date) => {
  return localizedMonthNames.value[dateHelper.parse(date).month()].slice(0, 3).toUpperCase()
}

const isFirstMonth = (date) => {
  return dateHelper.isSameDay(
      date,
      dateHelper.subtract(dateHelper.now(), 100, 'year').startOf('month'),
  )
}

const isCurrentMonth = (date) => {
  return dateHelper.isSameDay(date, dateHelper.now().startOf('month'))
}

const getCalendarDays = (month) => {
  const startOfMonth = dateHelper.startOfMonth(month)
  const endOfMonth = dateHelper.endOfMonth(month)
  const startDate = dateHelper.startOfWeek(startOfMonth)
  const endDate = dateHelper.endOfWeek(endOfMonth)

  const days = []
  let day = startDate
  const today = dateHelper.now()

  while (dateHelper.isSameOrBefore(day, endDate)) {
    const currentDate = dateHelper.parse(day)
    const isCurrentMonth = currentDate.month() === dateHelper.parse(month).month()
    const isToday = dateHelper.isSameDay(currentDate, today)
    const isInRange = isDateInRange(currentDate)
    const isRangeStart = selectedStartDate.value && dateHelper.isSameDay(currentDate, selectedStartDate.value)
    const isRangeEnd = (selectedEndDate.value && dateHelper.isSameDay(currentDate, selectedEndDate.value)) ||
        (selectedStartDate.value && !selectedEndDate.value && dateHelper.isSameDay(currentDate, hoverDate.value))
    const isFuture = dateHelper.parse(currentDate).isAfter(today)

    days.push({
      date: currentDate,
      isCurrentMonth,
      isToday,
      isInRange,
      isRangeStart,
      isRangeEnd,
      isFuture,
    })

    day = dateHelper.add(day, 1, 'day')
  }

  return days
}

const isDateInRange = (date) => {
  if (selectedStartDate.value && selectedEndDate.value) {
    return dateHelper.isBetween(date, selectedStartDate.value, selectedEndDate.value, 'day', '[]')
  }
  if (selectedStartDate.value && hoverDate.value) {
    return dateHelper.isBetween(date, selectedStartDate.value, hoverDate.value, 'day', '[]')
  }
  return false
}

// Selection Methods
const applyQuickRange = (range) => {
  const { start, end } = range.value()
  selectedStartDate.value = dateHelper.parse(start)
  selectedEndDate.value = dateHelper.parse(end)
  selectedQuickRange.value = range
  emit('update:modelValue', { start, end })
  isDatePickerVisible.value = false
}

const selectDate = (date) => {
  if (dateHelper.parse(date).isAfter(dateHelper.now())) return

  selectedQuickRange.value = null

  if (!selectedStartDate.value || (selectedStartDate.value && selectedEndDate.value)) {
    selectedStartDate.value = dateHelper.parse(date)
    selectedEndDate.value = null
    hoverDate.value = null
  } else {
    if (dateHelper.isSameDay(date, selectedStartDate.value)) return

    if (dateHelper.parse(date).isBefore(selectedStartDate.value)) {
      selectedEndDate.value = selectedStartDate.value
      selectedStartDate.value = dateHelper.parse(date)
    } else {
      selectedEndDate.value = dateHelper.parse(date)
    }

    emit('update:modelValue', {
      start: selectedStartDate.value.toDate(),
      end: selectedEndDate.value.toDate(),
    })
    isDatePickerVisible.value = false
  }
}

// Month/Year Selection Methods
const toggleMonthSelector = (index) => {
  showMonthSelector.value[index] = !showMonthSelector.value[index]
  showYearSelector.value[index] = false
  nextTick(() => update())
}

const toggleYearSelector = (index) => {
  showYearSelector.value[index] = !showYearSelector.value[index]
  showMonthSelector.value[index] = false
  nextTick(() => update())
}

const selectMonth = (calendarIndex, monthIndex) => {
  if (calendarIndex === 0) {
    const newDate = dateHelper.parse(leftDate.value).month(monthIndex)
    if (dateHelper.parse(newDate).isAfter(rightDate.value)) {
      leftDate.value = dateHelper.subtract(rightDate.value, 1, 'month')
    } else {
      leftDate.value = newDate
    }
  } else {
    const newDate = dateHelper.parse(rightDate.value).month(monthIndex)
    if (dateHelper.parse(newDate).isAfter(dateHelper.now())) {
      rightDate.value = dateHelper.now().startOf('month')
    } else if (dateHelper.parse(newDate).isBefore(leftDate.value)) {
      rightDate.value = dateHelper.add(leftDate.value, 1, 'month')
    } else {
      rightDate.value = newDate
    }
  }
  showMonthSelector.value[calendarIndex] = false
  nextTick(() => update())
}

const selectYear = (calendarIndex, year) => {
  if (calendarIndex === 0) {
    const newDate = dateHelper.parse(leftDate.value).year(year)
    if (dateHelper.parse(newDate).isAfter(rightDate.value)) {
      leftDate.value = dateHelper.subtract(rightDate.value, 1, 'month')
    } else {
      leftDate.value = newDate
    }
  } else {
    const newDate = dateHelper.parse(rightDate.value).year(year)
    if (dateHelper.parse(newDate).isAfter(dateHelper.now())) {
      rightDate.value = dateHelper.now().startOf('month')
    } else if (dateHelper.parse(newDate).isBefore(leftDate.value)) {
      rightDate.value = dateHelper.add(leftDate.value, 1, 'month')
    } else {
      rightDate.value = newDate
    }
  }
  showYearSelector.value[calendarIndex] = false
  nextTick(() => update())
}

// Navigation Methods
const changeMonth = (calendarIndex, delta) => {
  if (calendarIndex === 0) {
    const newDate = dateHelper.add(leftDate.value, delta, 'month')
    if (dateHelper.subtract(dateHelper.now(), 100, 'year').isAfter(newDate)) return

    leftDate.value = newDate
    if (dateHelper.parse(leftDate.value).isAfter(rightDate.value) ||
        dateHelper.isSameDay(leftDate.value, rightDate.value)) {
      rightDate.value = dateHelper.add(leftDate.value, 1, 'month')
    }
  } else {
    const newDate = dateHelper.add(rightDate.value, delta, 'month')
    if (dateHelper.parse(newDate).isAfter(dateHelper.now())) return

    rightDate.value = newDate
    if (dateHelper.parse(rightDate.value).isBefore(leftDate.value) ||
        dateHelper.isSameDay(rightDate.value, leftDate.value)) {
      leftDate.value = dateHelper.subtract(rightDate.value, 1, 'month')
    }
  }
}

const handleMouseEnter = (date) => {
  if (selectedStartDate.value && !selectedEndDate.value) {
    hoverDate.value = date
  }
}

const toggleDatePicker = () => {
  isDatePickerVisible.value = !isDatePickerVisible.value
  if (isDatePickerVisible.value) {
    nextTick(() => update())
  }
}

const clearDateRange = () => {
  selectedStartDate.value = null
  selectedEndDate.value = null
  hoverDate.value = null
  selectedQuickRange.value = null
  isDatePickerVisible.value = false
  emit('update:modelValue', { start: null, end: null })
}

const goToToday = () => {
  const today = dateHelper.now()
  rightDate.value = dateHelper.startOfMonth(today)
  leftDate.value = dateHelper.subtract(rightDate.value, 1, 'month')
}

const handleClickOutside = (event) => {
  const pickerElement = floatingRef.value
  const triggerElement = triggerRef.value

  if (isDatePickerVisible.value && pickerElement && triggerElement) {
    if (!pickerElement.contains(event.target) && !triggerElement.contains(event.target)) {
      isDatePickerVisible.value = false
    }
  }
}

// Lifecycle Hooks & Watchers
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

watch(isDatePickerVisible, async (visible) => {
  if (visible) {
    await nextTick()
    update()
    if (props.modelValue.start && props.modelValue.end) {
      selectedStartDate.value = dateHelper.parse(props.modelValue.start)
      selectedEndDate.value = dateHelper.parse(props.modelValue.end)
      rightDate.value = dateHelper.startOfMonth(selectedEndDate.value)
      leftDate.value = dateHelper.subtract(rightDate.value, 1, 'month')
    }
  }
})

watch(() => props.modelValue, (newValue) => {
  if (newValue.start && newValue.end) {
    selectedStartDate.value = dateHelper.parse(newValue.start)
    selectedEndDate.value = dateHelper.parse(newValue.end)
  }
})

defineExpose({
  triggerRef,
})
</script>