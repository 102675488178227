<template>
  <div>
    <slot name="external"></slot>
    <!-- Overlay ve Sidebar Container -->
    <div @mousedown="close" class="fixed inset-0 bg-black/50 transition-all duration-300 backdrop-blur-sm" :class="[
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible',
        'z-[1000]'
      ]">
      <!-- Sidebar -->
      <div @mousedown.stop :class="[
          'fixed top-0 right-0 h-screen w-full lg:w-[500px] shadow-lg',
          'transform transition-transform duration-300 ease-in-out translate-x-full',
          'flex flex-col bg-gradient-to-br from-white to-gray-50',
          isOpen && '!translate-x-0',
          modalClass
        ]">
        <!-- Header -->
        <div v-if="isHeader" class="h-14 flex items-center justify-between px-4 border-b border-gray-100 bg-white/80 backdrop-blur-sm">
          <h2 class="text-lg font-semibold text-gray-800">{{ headerTitle }}</h2>
          <div class="flex items-center gap-2">
            <button v-if="isRefresh" @click="$emit('refresh',true)" class="p-2 rounded-full hover:bg-gray-100 transition-colors">
              <i class="fa-solid fa-arrows-rotate text-gray-600 text-lg"></i>
            </button>
            <button @click="close()" class="p-2 rounded-full hover:bg-gray-100 transition-colors">
              <span class="material-symbols-outlined !text-[22px] text-gray-600">close</span>
            </button>
          </div>
        </div>

        <!-- Content -->
        <div :class="[
            'flex-1 overflow-y-auto',
            contentClass
          ]">
          <slot name="content"></slot>
        </div>

        <!-- Footer -->
        <div class="p-4 border-t border-gray-100 bg-white/80 backdrop-blur-sm">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideModal',
  props: {
    externalClass: String,
    modalClass: String,
    isHeader: Boolean,
    headerTitle: String,
    isClosable: Boolean,
    closeController: Boolean,
    isRefresh: Boolean,
    hiddenOverflowY: Boolean,
    contentClass: String,
  },
  data() {
    return {
      isOpen: false,
      load: false,
    }
  },
  methods: {
    async show() {
      this.isOpen = true
      this.$emit('opened', true)
    },
    async close() {
      if (this.isClosable && this.closeController) {
        this.$emit('closeErorr', true)
      } else {
        this.isOpen = false
        this.$emit('closed', false)
      }
    },
  },
}
</script>

<style scoped>
/* Smooth scrollbar for content */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>