<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" class="flex justify-center items-center" content-class="flex flex-col bg-white max-w-4xl w-full rounded-lg shadow-lg max-h-[90vh]">
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="p-4 border-b shrink-0">
        <div class="flex items-center justify-between">
          <div>
            <h2 class="text-lg font-semibold text-gray-900">
              {{ t('entity.components.newContent.components.keywordDetailModal.title') }} </h2>
            <p class="text-sm text-gray-600 mt-1">
              {{ t('entity.components.newContent.components.keywordDetailModal.description') }} </p>
          </div>
          <button @click="$emit('update:modelValue', false)" class="p-2 hover:bg-gray-100 rounded-lg transition-colors">
            <span class="material-symbols-outlined">close</span>
          </button>
        </div>
      </div>

      <!-- Tabs -->
      <div class="p-4 flex flex-col flex-1 min-h-0">
        <div class="flex flex-wrap gap-2 mb-4 shrink-0">
          <button v-for="tab in tabs" :key="tab.value" @click="activeTab = tab.value" :class="[
              'px-4 py-2 text-sm rounded-lg transition-colors focus-none',
              activeTab === tab.value
                ? 'bg-primary text-white'
                : 'bg-gray-100 hover:bg-gray-200 text-gray-700 '
            ]">
            {{ t(`entity.components.newContent.components.keywordDetailModal.tabs.${tab.value}`) }}
          </button>
        </div>

        <!-- Content with Scroll -->
        <div class="overflow-y-auto flex-1 min-h-0">
          <!-- Keywords Grid -->
          <div class="space-y-3">
            <div v-for="keyword in currentKeywords" :key="keyword.keyword" class="p-3 border rounded-lg hover:shadow-sm transition-shadow">
              <div class="flex justify-between items-start">
                <div>
                  <h3 class="font-medium text-gray-900">{{ keyword.keyword }}</h3>
                  <div class="flex gap-2 mt-1 flex-wrap">
                    <span class="text-xs px-2 py-0.5 bg-gray-100 rounded-full">
                      {{ keyword.searchIntent }}
                    </span> <span v-if="keyword.season" class="text-xs px-2 py-0.5 bg-blue-50 rounded-full">
                      {{ keyword.season }}
                    </span>
                  </div>
                </div>
                <div class="text-right">
                  <div v-if="keyword.difficulty !== undefined" :class="[
                      'text-sm font-medium',
                      getDifficultyColor(keyword.difficulty)
                    ]">
                    {{ formatNumber(keyword.difficulty) }}% {{ t('entity.components.newContent.components.keywordDetailModal.keyword.difficulty') }}
                  </div>
                  <div v-if="keyword.priority !== undefined" :class="[
                      'text-sm font-medium',
                      getPriorityColor(keyword.priority)
                    ]">
                    {{ formatPriority(keyword.priority) }} {{ t('entity.components.newContent.components.keywordDetailModal.keyword.priority') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="p-4 bg-gray-50 border-t shrink-0">
        <div class="grid grid-cols-3 gap-4">
          <div v-for="stat in currentStats" :key="stat.label" class="bg-white p-3 rounded-lg border">
            <div class="text-sm text-gray-600">{{ stat.label }}</div>
            <div class="text-lg font-semibold mt-1">{{ stat.value }}</div>
            <div :class="[
                'text-sm font-medium mt-1',
                stat.trend > 0 ? 'text-green-600' : 'text-red-600'
              ]">
              {{ stat.trend > 0 ? '+' : '' }}{{ formatNumber(stat.trend) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  keywordData: {
    type: Object,
    required: false,
  },
})

const activeTab = ref('main')

const tabs = [
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.main'), value: 'main', icon: 'key' },
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.specific'), value: 'specific', icon: 'manage_search' },
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.lsi'), value: 'lsi', icon: 'psychology' },
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.questions'), value: 'questions', icon: 'help' },
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.seasonal'), value: 'seasonal', icon: 'calendar_month' },
  { label: t('entity.components.newContent.components.keywordDetailModal.tabs.local'), value: 'local', icon: 'location_on' },
]

// Priority formatter
const formatPriority = (value) => {
  if (value === undefined || value === null) return '0'
  return Math.round(value)
}

// Number formatter for other values
const formatNumber = (value) => {
  if (value === undefined || value === null) return '0'
  return Number(value).toFixed(1).replace(/\.0$/, '')
}

const currentKeywords = computed(() => {
  switch (activeTab.value) {
    case 'main':
      return props.keywordData.mainKeywords
    case 'specific':
      return props.keywordData.longTailKeywords
    case 'lsi':
      return props.keywordData.lsiKeywords
    case 'questions':
      return props.keywordData.questionKeywords
    case 'seasonal':
      return props.keywordData.seasonalKeywords
    case 'local':
      return props.keywordData.localKeywords
    default:
      return []
  }
})

const currentStats = computed(() => {
  const keywords = currentKeywords.value
  if (!keywords?.length) return []

  return [
    {
      label: t('entity.components.newContent.components.keywordDetailModal.stats.averageDifficulty'),
      value: `${formatNumber(
          keywords.reduce((acc, curr) => acc + (curr.difficulty || 0), 0) /
          keywords.length,
      )}%`,
      trend: 5,
    },
    {
      label: t('entity.components.newContent.components.keywordDetailModal.stats.averagePriority'),
      value: formatPriority(
          keywords.reduce((acc, curr) => acc + (curr.priority || 0), 0) /
          keywords.length,
      ),
      trend: 12,
    },
    {
      label: t('entity.components.newContent.components.keywordDetailModal.stats.totalKeywords'),
      value: keywords.length,
      trend: 8,
    },
  ]
})

const getDifficultyColor = (value) => {
  if (value <= 50) return 'text-green-600'
  if (value <= 70) return 'text-yellow-600'
  return 'text-red-600'
}

const getPriorityColor = (value) => {
  if (value >= 9) return 'text-green-600'
  if (value >= 7) return 'text-yellow-600'
  return 'text-red-600'
}
</script>

