<template>
  <div class="space-y-6">
    <!-- Dosya yükleme alanı -->
    <div class="border-2 border-dashed p-6 rounded-lg text-center transition-all duration-300 ease-in-out" :class="{
        'border-blue-500 bg-blue-50': isDragOver,
        'border-gray-300 hover:border-gray-400': !isDragOver
      }" @dragenter.prevent="handleDragEnter" @dragleave.prevent="handleDragLeave" @dragover.prevent @drop.prevent="handleFileDrop">
      <input type="file" ref="fileInput" class="hidden" multiple @change="handleFileUpload" accept=".pdf,.doc,.docx,.txt,.rtf,.jpg,.jpeg,.png,.gif"/>
      <div class="flex flex-col items-center space-y-2">
        <span class="material-symbols-outlined text-4xl" :class="isDragOver ? 'text-blue-500' : 'text-gray-400'">
          cloud_upload
        </span>
        <p class="text-gray-600" :class="{ 'text-blue-600': isDragOver }">
          {{ isDragOver ? t('settings.components.configuration.components.documentModal.dropFilesHere') : t('settings.components.configuration.components.documentModal.dragDropMessage') }} </p>
        <p class="text-xs text-gray-500">
          {{ t('settings.components.configuration.components.documentModal.fileRestrictions', { maxSize: formatFileSize(MAX_FILE_SIZE) }) }} </p>
        <p class="text-xs text-primary font-medium">
          {{ t('settings.components.configuration.components.documentModal.fileNote') }} </p>
        <CustomButton @click="fileInput.click()" :button-text="t('settings.components.configuration.components.documentModal.browseFiles')" size="sm" variant="secondary"/>
      </div>
    </div>

    <!-- Yüklenen dosyalar listesi -->
    <div v-if="modelValue && modelValue.length > 0">
      <div class="flex items-center mb-3">
        <div class="w-8 h-px bg-gray-300 mr-3"></div>
        <h3 class="text-sm font-medium text-gray-500">{{ t('settings.components.configuration.components.documentModal.uploadedFiles') }}</h3>
        <div class="flex-grow h-px bg-gray-300 ml-3"></div>
      </div>
      <div class="overflow-y-auto max-h-[30vh] thin-scrollbar">
        <TransitionGroup name="list" tag="ul" class="space-y-3">
          <li v-for="(file, index) in modelValue" :key="file.id" class="bg-white p-3 rounded-md shadow-sm transition duration-300 ease-in-out hover:shadow-md border border-gray-200">
            <div class="flex justify-between items-center mb-2">
              <div class="flex items-center space-x-3 max-w-[calc(100%-40px)]">
                <span class="material-symbols-outlined text-gray-400" v-if="isImage(file)">image</span> <span class="material-symbols-outlined text-gray-400" v-else>description</span>
                <span class="text-secondary text-sm underline truncate" :title="file.name">{{ file.name }}</span>
              </div>
              <CustomButton @click="removeFile(index)" size="sm" variant="danger" icon="delete" :isTextShowable="false" :show-icon="true"/>
            </div>
            <CustomInput :model-value="file.description" @update:model-value="updateDescription(index, $event)" :placeholder="t('settings.components.configuration.components.documentModal.descriptionPlaceholder')" type="text" size="sm"/>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { modelValue } = toRefs(props)

const fileInput = ref(null)
const isDragOver = ref(false)

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

const ALLOWED_FILE_TYPES = [
  'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain', 'application/rtf', 'image/jpeg', 'image/png', 'image/gif',
]

const formatFileSize = (size) => {
  if (size < 1024) return size + ' B'
  else if (size < 1048576) return (size / 1024).toFixed(1) + ' KB'
  else return (size / 1048576).toFixed(1) + ' MB'
}

const isValidFile = (file) => {
  if (!file || typeof file !== 'object') {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.invalidFile'))
    return false
  }
  if (!file.size || file.size > MAX_FILE_SIZE) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.fileTooLarge', { fileName: file.name, maxSize: formatFileSize(MAX_FILE_SIZE) }))
    return false
  }
  if (!file.type || !ALLOWED_FILE_TYPES.includes(file.type)) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.invalidFileType', { fileName: file.name }))
    return false
  }
  return true
}

const isImage = (file) => {
  return file && file.type && typeof file.type === 'string' && file.type.startsWith('image/')
}

const handleDragEnter = (event) => {
  event.preventDefault()
  isDragOver.value = true
}

const handleDragLeave = (event) => {
  event.preventDefault()
  if (!event.currentTarget.contains(event.relatedTarget)) {
    isDragOver.value = false
  }
}

const handleFileUpload = (event) => {
  const newFiles = Array.from(event.target.files)
      .filter(isValidFile)
      .map(file => ({
        id: uuidv4(),
        file,
        name: file.name,
        description: '',
      }))
  if (newFiles.length === 0) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.noValidFiles'))
  } else {
    const updatedFiles = [...(modelValue.value || []), ...newFiles]
    emit('update:modelValue', updatedFiles)
  }
}

const handleFileDrop = (event) => {
  event.preventDefault()
  isDragOver.value = false
  const newFiles = Array.from(event.dataTransfer.files)
      .filter(isValidFile)
      .map(file => ({
        id: uuidv4(),
        file,
        name: file.name,
        description: '',
      }))
  if (newFiles.length === 0) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.noValidFiles'))
  } else {
    const updatedFiles = [...(modelValue.value || []), ...newFiles]
    emit('update:modelValue', updatedFiles)
  }
}

const removeFile = (index) => {
  if (!modelValue.value) return
  const updatedFiles = [...modelValue.value]
  updatedFiles.splice(index, 1)
  emit('update:modelValue', updatedFiles)
}

const updateDescription = (index, newDescription) => {
  if (!modelValue.value) return
  const updatedFiles = [...modelValue.value]
  updatedFiles[index] = { ...updatedFiles[index], description: newDescription }
  emit('update:modelValue', updatedFiles)
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

</style>