<template>
  <div class="relative" data-menu="features">
    <!-- Trigger Button -->
    <button class="inline-flex items-center px-2 md:px-3 py-1.5 bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none gap-2" :class="menuStore.isOpen('features') ? 'bg-gray-50' : ''" @click="handleMenuClick">
      <span class="material-symbols-outlined !text-[18px] text-gray-600">dashboard</span> <span class="hidden md:block text-xs font-medium text-gray-700">
        {{ t('navigation.layout.components.featureMenu.features') }}
      </span> <span v-if="featureStore.hasActiveFeatures" class="flex items-center justify-center px-1.5 py-0.5 text-[10px] font-medium text-white bg-blue-500 rounded-full min-w-[18px]">
        {{ featureStore.activeFeatureCount }}
      </span> <span v-if="featureStore.loading" class="material-symbols-outlined !text-[18px] text-gray-400 animate-spin">
        progress_activity
      </span> <span v-else class="hidden md:block material-symbols-outlined !text-[18px] text-gray-400 transition-transform duration-200" :class="{ 'rotate-180': menuStore.isOpen('features') }">
        expand_more
      </span>
    </button>

    <!-- Feature Menu -->
    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <div v-show="menuStore.isOpen('features')" v-click-outside="handleClickOutside" class="fixed md:absolute inset-x-0 md:inset-x-auto top-14 md:top-auto md:right-0 bg-white shadow-lg z-50 border border-gray-200
                  md:w-[380px] lg:w-[420px] xl:w-[480px] md:mt-1 md:rounded-lg
                  max-h-[480px] md:max-h-[640px] flex flex-col">

        <!-- Header -->
        <div class="flex-none p-3 border-b border-gray-100">
          <div class="flex items-center justify-between">
            <h3 class="text-sm font-medium text-gray-900">
              {{ t('navigation.layout.components.featureMenu.feature_status') }} </h3>
            <div class="flex items-center gap-2">
              <span v-if="featureStore.packageName" class="px-2 py-0.5 text-xs font-medium text-gray-600 bg-gray-100 rounded-full">
                {{ featureStore.packageName }}
              </span> <span class="px-2 py-0.5 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
                {{ featureStore.activeFeatureCount }} {{ t('navigation.layout.components.featureMenu.active') }}
              </span>
            </div>
          </div>
        </div>

        <!-- Loading State -->
        <div v-if="featureStore.loading" class="flex-1 flex flex-col items-center justify-center gap-2 min-h-[200px]">
          <span class="material-symbols-outlined text-primary animate-spin text-2xl">
            progress_activity
          </span> <span class="text-sm text-gray-600">
            {{ t('navigation.layout.components.featureMenu.loading') }}
          </span>
        </div>

        <!-- Empty State -->
        <div v-else-if="!featureStore.hasActiveFeatures" class="flex-1 p-8 text-center">
          <span class="material-symbols-outlined text-gray-400 text-4xl mb-2">assignment</span>
          <p class="text-sm text-gray-500">
            {{ t('navigation.layout.components.featureMenu.no_features') }} </p>
        </div>

        <!-- Features List -->
        <div v-else class="flex-1 overflow-y-auto thin-scrollbar">
          <div v-for="feature in featureStore.featuresByUsage" :key="feature.featureId" class="p-3 hover:bg-gray-50 border-b border-gray-100 last:border-0">
            <div class="flex flex-col gap-2">
              <!-- Feature Info -->
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2 max-w-[60%]">
                  <span class="material-symbols-outlined text-[18px] shrink-0" :class="featureIcons[feature.featureId]?.class">
                    {{ featureIcons[feature.featureId]?.icon }}
                  </span>
                  <div class="flex flex-col min-w-0">
                    <span class="text-sm font-medium text-gray-900 truncate">
                      {{ feature.name }}
                    </span> <span class="text-xs text-gray-500">
                      {{ feature.description }}
                    </span>
                  </div>
                </div>

                <!-- Usage Stats -->
                <div class="flex items-center gap-3 shrink-0">
                  <div class="w-20 h-1.5 rounded-full" :class="featureStore.getColorsByStatus(featureStore.getUsageStatus(feature), 'bg')">
                    <div class="h-full rounded-full transition-all duration-300" :class="featureStore.getColorsByStatus(featureStore.getUsageStatus(feature), 'progress')" :style="{ width: `${featureStore.calculatePercentage(feature.monthlyLobsLimit - feature.remainingLobs, feature.monthlyLobsLimit)}%` }"></div>
                  </div>
                  <span class="text-xs font-medium min-w-[32px] text-right" :class="featureStore.getColorsByStatus(featureStore.getUsageStatus(feature), 'text')">
                    {{ featureStore.calculatePercentage(feature.monthlyLobsLimit - feature.remainingLobs, feature.monthlyLobsLimit) }}%
                  </span>
                </div>
              </div>

              <!-- Usage Details -->
              <div class="flex items-center justify-between text-xs text-gray-500">
                <span>
                  {{ t('navigation.layout.components.featureMenu.remaining') }}:
                  <span class="font-medium text-gray-700">{{ feature.remainingLobs }}/{{ feature.monthlyLobsLimit }}</span>
                </span> <span>
                  {{ t('navigation.layout.components.featureMenu.perUse') }}:
                  <span class="font-medium text-gray-700">{{ feature.lobsPerUse }} LOBS</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div v-if="!featureStore.loading && featureStore.hasActiveFeatures" class="flex-none p-2.5 bg-gray-50 border-t border-gray-100">
          <div class="flex items-center justify-between text-xs">
            <div class="flex items-center gap-2 text-gray-500">
              <span class="material-symbols-outlined text-[18px]">info</span> <span>{{ t('navigation.layout.components.featureMenu.availableFeatures') }}</span>
            </div>
            <div>
              <span class="px-2 py-0.5 text-xs font-medium text-blue-800 bg-blue-100 rounded-full">
                {{ featureStore.activeFeatureCount }} {{ t('navigation.layout.components.featureMenu.active') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useMenuStore } from '@/stores/menuStore'
import { useFeatureStore } from '@/stores/featureStore'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const assetStore = useAssetStore()
const menuStore = useMenuStore()
const featureStore = useFeatureStore()

const featureIcons = {
  1: { icon: 'share', class: 'text-blue-500' },
  2: { icon: 'language', class: 'text-green-500' },
  3: { icon: 'trending_up', class: 'text-purple-500' },
  4: { icon: 'monitoring', class: 'text-orange-500' },
  5: { icon: 'travel_explore', class: 'text-teal-500' },
  6: { icon: 'key', class: 'text-cyan-500' },
  7: { icon: 'image', class: 'text-pink-500' },
  8: { icon: 'play_circle', class: 'text-red-500' },
  9: { icon: 'group', class: 'text-indigo-500' },
  10: { icon: 'calendar_month', class: 'text-amber-500' },
  11: { icon: 'edit_document', class: 'text-lime-500' },
  12: { icon: 'upload_file', class: 'text-emerald-500' },
  13: { icon: 'confirmation_number', class: 'text-rose-500' },
  14: { icon: 'auto_awesome', class: 'text-violet-500' },
  15: { icon: 'support_agent', class: 'text-sky-500' },
}

// Asset değiştiğinde feature'ları resetle ve yeniden çek
watch(() => assetStore.assetId, () => {
  if (assetStore.assetId) {
    featureStore.resetFeaturesByAsset()
    featureStore.fetchFeatures()
  }
})

// Component mount olduğunda feature'ları çek
onMounted(() => {
  if (assetStore.assetId) featureStore.fetchFeatures()
})

const handleMenuClick = () => {
  menuStore.toggleMenu('features')
}

const handleClickOutside = (event) => {
  const isClickInsideAnyMenu = event.target.closest('[data-menu]')
  if (!isClickInsideAnyMenu) {
    menuStore.closeAll()
  }
}
</script>