<template>
  <div class="bg-white rounded-xl shadow-sm overflow-hidden">
    <div class="p-6 border-b border-slate-100">
      <h2 class="text-lg font-semibold flex items-center gap-2">
        <span class="material-symbols-outlined text-primary">description</span> {{ t('entity.components.contentReview.content') }} </h2>
    </div>
    <div class="preview-container relative">
      <div class="p-6 max-h-[500px] overflow-y-auto preview-content group cursor-pointer hover:scale-95" @click="handlePreview">
        <div class="prose prose-sm max-w-none blur-[10px] group-hover:blur-0 transition-all duration-300" v-html="content"></div>
        <PreviewOverlay/>
      </div>
    </div>
  </div>
</template>

<script setup>
import PreviewOverlay from './PreviewOverlay.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const emit = defineEmits(['preview'])

const handlePreview = () => {
  emit('preview')
}

defineProps({
  content: String,
})
</script>

<style scoped>
.preview-container {
  position: relative;
}

.preview-content {
  position: relative;
}
</style>