<template>
  <div>
    <apexchart type="radialBar" height="350" :options="chartOptions" :series="[score]"></apexchart>
  </div>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { computed } from 'vue'

const props = defineProps({
  score: {
    type: Number,
    required: false,
    default: 0,
  },
})

const chartColors = {
  primary: '#FC002B', // Kırmızı: Düşük skorlar için
  accent: '#FC9F00', // Turuncu: Ana renk (Yüksek skorlar için)
}

const chartOptions = computed(() => ({
  chart: {
    type: 'radialBar',
    height: 350,
    offsetY: -20,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#e7e7e7',
        strokeWidth: '97%',
        margin: 5,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: '22px',
        },
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: [chartColors.accent], // Turuncuya geçiş
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
    colors: [chartColors.primary], // Kırmızı ile başlangıç
  },
  colors: [getColor(props.score)], // Ana renklerin belirlenmesi
}))

const getColor = (score) => {
  // Skor değerine göre renk ataması
  if (score >= 40) return chartColors.accent // Turuncu: Ana renk (Daha yüksek skorlar için)
  return chartColors.primary // Kırmızı: Düşük skorlar için
}
</script>

<style scoped>
/* Ekstra stiller ekleyebilirsiniz */
</style>