<template>
  <div class="component-layout">
    <div class="calendar-container p-4 bg-white rounded-md shadow-md">
      <CalendarHeader :currentMonthYear="currentMonthYear" :currentDate="currentDate" :markedDates="markedDates" :loading="loading" @change-week="changeWeek" @set-today="setToday" @update:modelValue="handleDatePickerChange"/>

      <div class="calendar-body relative">
        <CalendarGrid :weekDays="weekDays" :visibleHours="visibleHours" :getEventsForDayAndHour="getEventsForDayAndHour" :formatHour="formatHourWrapper" :getEventClass="getEventClass" :getStateClass="getStateClass" :getPlatformIcon="getPlatformIcon" :getStateName="getStateName" @open-content-modal="openContentModal" @open-more-events-modal="openMoreEventsModal"/>
        <LoadingOverlay v-if="loading"/>
      </div>

      <PreviewModal v-if="isPreviewModalOpen && selectedContent" v-model="isPreviewModalOpen" :content="selectedContent" :show-footer="true" @confirmed="handleConfirmed" @rejected="handleRejected" @refreshed="handleRefreshed" :row-id="selectedContent.id" :fetch-data="true"/>

      <MoreEventsModal v-model="isMoreEventsModalOpen" :events="moreEvents" @open-content-modal="openContentModal"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useDate } from './composables/useDate'
import { useUIStore } from '@/stores/ui'
import axios from '@/plugins/axiosInstance'
import { scheduleAssistant } from '@/networking/urlManager'
import { usePlatform, useState, useEventUtils } from './composables'
import { useAssetStore } from '@/stores/asset'
import CalendarHeader from './components/calendarHeader.vue'
import CalendarGrid from './components/calendarGrid.vue'
import LoadingOverlay from './components/loadingOverlay.vue'
import PreviewModal from '@/components/modals/PreviewContentModal.vue'
import MoreEventsModal from './components/moreEventsModal.vue'

const uiStore = useUIStore()
const assetStore = useAssetStore()

const {
  getWeekDays,
  getMonthYearString,
  formatHour,
  now,
  add,
  parse,
  format,
  toLocal,
  startOfWeek,
  endOfWeek,
} = useDate()

const { Platform, getPlatformIcon } = usePlatform()
const { State, getStateClass, getStateName } = useState()
const { getEventClass } = useEventUtils(Platform, State)

const currentDate = ref(now())
const loading = ref(false)
const events = ref([])
const moreEvents = ref([])
const isMoreEventsModalOpen = ref(false)
const isPreviewModalOpen = ref(false)
const selectedContent = ref(null)

const formatHourWrapper = (hour) => formatHour.value(hour)

const weekDays = computed(() => getWeekDays.value(currentDate.value))
const currentMonthYear = computed(() => getMonthYearString.value(weekDays.value))
const visibleHours = computed(() => Array.from({ length: 24 }, (_, i) => i))

const eventMap = computed(() => {
  const map = new Map()
  events.value.forEach(event => {
    const localDate = toLocal(event.date)
    const date = format.value(localDate, 'YYYY-MM-DD')
    const hour = localDate.hour()
    const key = `${date}-${hour}`

    if (!map.has(key)) map.set(key, [])
    map.get(key).push({
      ...event,
      localTime: format.value(localDate, 'HH:mm'),
      localDate,
    })
  })
  return map
})

const markedDates = computed(() => {
  return events.value.map(event => {
    const localDate = toLocal(event.date)
    return format.value(localDate, 'YYYY-MM-DD')
  })
})

const getEventsForDayAndHour = (date, hour) => {
  const key = `${date}-${hour}`
  return eventMap.value.get(key) || []
}

const fetchEvents = async () => {
  loading.value = true
  try {
    const weekStartDate = startOfWeek(currentDate.value)
    const weekEndDate = endOfWeek(currentDate.value)

    const startMonth = {
      year: format.value(weekStartDate, 'YYYY'),
      month: format.value(weekStartDate, 'M'),
    }

    const endMonth = {
      year: format.value(weekEndDate, 'YYYY'),
      month: format.value(weekEndDate, 'M'),
    }

    const monthsToFetch = new Set([
      `${startMonth.year}-${startMonth.month}`,
      `${endMonth.year}-${endMonth.month}`,
    ])

    const promises = Array.from(monthsToFetch).map(async (monthYear) => {
      const [year, month] = monthYear.split('-')
      const response = await axios.get(scheduleAssistant.getContents, {
        params: {
          assetId: assetStore.assetId,
          year,
          month,
        },
      })
      return response.data.data
    })

    const results = await Promise.all(promises)
    events.value = results.flat()

  } catch (error) {
    console.error('Error fetching events:', error)
  } finally {
    loading.value = false
  }
}

watch(() => uiStore.language, async () => {
  const current = currentDate.value
  await nextTick()
  currentDate.value = parse(new Date(current))
}, { immediate: true })

const changeWeek = ({ direction }) => {
  const oldDate = currentDate.value
  const newDate = add(currentDate.value, direction, 'week')

  const oldMonth = format.value(oldDate, 'YYYY-MM')
  const newMonth = format.value(newDate, 'YYYY-MM')

  currentDate.value = newDate

  if (oldMonth !== newMonth) fetchEvents()
}

const setToday = () => {
  const oldMonth = format.value(currentDate.value, 'YYYY-MM')
  currentDate.value = now()
  const newMonth = format.value(currentDate.value, 'YYYY-MM')

  if (oldMonth !== newMonth) fetchEvents()
}

const openContentModal = (rowId) => {
  isMoreEventsModalOpen.value = false
  const content = events.value.find(event => event.id === rowId)
  if (content) {
    selectedContent.value = {
      ...content,
      id: rowId,
    }
    isPreviewModalOpen.value = true
  }
}

const handleConfirmed = ({ id, result }) => {
  if (result) updateEventState(id, 3)
}

const handleRejected = ({ id, result }) => {
  if (result) updateEventState(id, 2)
}

const handleRefreshed = ({ content, result }) => {
  if (result) {
    const index = events.value.findIndex(event => event.id === content.id)
    if (index !== -1) events.value[index] = { ...events.value[index], ...content }
  }
}

const updateEventState = (id, newState) => {
  const event = events.value.find(event => event.id === id)
  if (event) event.stateId = newState
}

const handleDatePickerChange = (newDate) => {
  const oldMonth = format.value(currentDate.value, 'YYYY-MM')
  currentDate.value = parse(newDate)
  const newMonth = format.value(currentDate.value, 'YYYY-MM')

  if (oldMonth !== newMonth) fetchEvents()
}

const openMoreEventsModal = (events) => {
  moreEvents.value = events
  isMoreEventsModalOpen.value = true
}

const handleKeyDown = (event) => {
  if (event.code === 'ArrowLeft') changeWeek({ direction: -1 })
  else if (event.code === 'ArrowRight') changeWeek({ direction: 1 })
}

onMounted(() => {
  fetchEvents()
  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})
</script>