<template>
  <VueFinalModal v-model="showModal" @opened="fetchData" @closed="closeModal" class="flex justify-center items-center" content-class="flex flex-col p-6 bg-white rounded-lg shadow-xl max-w-6xl w-full h-[100vh] sm:h-[80vh]">
    <div class="flex flex-col space-y-4 w-full">
      <header class="flex justify-between items-start">
        <div>
          <h2 class="text-2xl font-bold text-gray-800">{{ t('dashboard.components.keywordAnalysis.modals.positionGraph.title') }}</h2>
          <p class="text-sm text-gray-600">{{ item.name }}</p>
        </div>
        <button @click="closeModal" class="p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <div class="grid grid-cols-3 gap-4">
        <div v-for="engine in engines" :key="engine.id">
          <label :for="'location-' + engine.id" class="block text-sm font-medium text-gray-700">{{ engine.name }}</label>
          <select :id="'location-' + engine.id" v-model="selectedLocations[engine.id]" @change="updateChart" class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border border-gray-200">
            <option v-for="location in getLocations(engine.id)" :key="location.locationId" :value="location.locationId">
              {{ location.locationName }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="isLoading" class="h-[600px] flex items-center justify-center">
        <Loading/>
      </div>
      <div v-else-if="hasData" class="h-[600px] w-full">
        <v-chart ref="chartRef" class="chart" :option="chartOption" autoresize @click="handleChartClick"/>
      </div>
      <div v-else class="flex flex-col justify-center items-center rounded-lg bg-gray-100 border border-gray-300 p-4 h-[500px]">
        <p class="text-2xl md:text-3xl font-semibold text-gray-600">{{ t('common.noData') }}</p>
      </div>
    </div>
  </VueFinalModal>
</template>


<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import Loading from '@/components/loading/VLoading.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, LegendComponent, DataZoomComponent, ToolboxComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import { useAssetStore } from '@/stores/asset'

dayjs.locale('tr')

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent, DataZoomComponent, ToolboxComponent])

const props = defineProps({
  modelValue: Boolean,
  item: { type: [Object], required: false },
})

const assetStore = useAssetStore()
const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const showModal = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const isLoading = ref(true)
const keywordData = ref([])
const selectedLocations = ref({})
const chartRef = ref(null)

const hasData = computed(() => keywordData.value.length > 0)

const engines = [
  { id: 1, name: 'Yandex', color: '#FF0000' },
  { id: 2, name: 'Google', color: '#4285F4' },
  { id: 3, name: 'Bing', color: '#008373' },
]

const fetchData = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(dashboard.getMonthlyKeywordPositions, { params: { assetId: assetStore.assetId, keywordId: props.item.id } })

    if (response.data && response.data.data) {
      keywordData.value = response.data.data
      initializeSelectedLocations()
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  } finally {
    isLoading.value = false
  }
}

const initializeSelectedLocations = () => {
  engines.forEach(engine => {
    const engineData = keywordData.value.find(data => data.engineId === engine.id)
    if (engineData && engineData.locations.length > 0) {
      selectedLocations.value[engine.id] = engineData.locations[0].locationId
    }
  })
}

const getLocations = engineId => {
  const engineData = keywordData.value.find(data => data.engineId === engineId)
  return engineData ? engineData.locations : []
}

const chartOption = computed(() => {
  const currentYear = dayjs().year()
  const years = [currentYear - 2, currentYear - 1, currentYear]
  const allMonths = years.flatMap(year =>
      Array.from({ length: 12 }, (_, month) => ({ year, month: month + 1 })),
  )

  const series = engines.map(engine => {
    const engineData = keywordData.value.find(data => data.engineId === engine.id)
    if (!engineData) return { name: engine.name, type: 'line', data: [] }

    const locationData = engineData.locations.find(loc => loc.locationId === selectedLocations.value[engine.id])
    if (!locationData) return { name: engine.name, type: 'line', data: [] }

    return {
      name: engine.name,
      type: 'line',
      symbol: 'circle',
      symbolSize: 8,
      lineStyle: {
        width: 3,
      },
      itemStyle: {
        color: engine.color,
      },
      data: allMonths.map(yearMonth => {
        const positionData = locationData.position.find(
            pos => pos.year === yearMonth.year && pos.month === yearMonth.month,
        )
        return positionData ? Math.max(0, Math.round(parseFloat(positionData.averagePosition) || 0)) : null
      }),
    }
  })

  const allPositions = series.flatMap(s => s.data.filter(p => p !== null))
  const maxPosition = Math.max(...allPositions, 1)
  const yAxisMax = Math.max(Math.ceil(maxPosition / 10) * 10, 100)

  return {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        const date = allMonths[params[0].dataIndex]
        const monthName = t(`general.monthNames[${date.month - 1}]`)
        let result = `${monthName} ${date.year}<br/>`
        params.forEach(param => {
          if (param.value !== null) {
            result += `${param.seriesName}: ${param.value}<br/>`
          }
        })
        return result
      },
    },
    legend: {
      data: engines.map(engine => engine.name),
      top: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: allMonths.map(({ year, month }) => `${t(`general.monthNames[${month - 1}]`)} ${year}`),
      axisLabel: {
        interval: 2,
        rotate: 45,
      },
    },
    yAxis: {
      type: 'value',
      name: t('dashboard.components.keywordAnalysis.modals.positionGraph.position'),
      min: 0,
      max: yAxisMax,
      interval: 10,
      minInterval: 1,
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        formatter: value => Math.round(value),
      },
    },
    series: series,
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: 0,
        start: 66.67,
        end: 100,
        zoomLock: true,
        moveOnMouseMove: true,
      },
    ],
    toolbox: {
      feature: {
        saveAsImage: {
          title: t('dashboard.components.keywordAnalysis.modals.positionGraph.saveImage'),
          icon: 'path://M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z',
        },
        myTool1: {
          show: true,
          title: t('dashboard.components.keywordAnalysis.modals.positionGraph.goToCurrentYear'),
          icon: 'path://M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z',
          onclick: function () {
            if (chartRef.value) {
              chartRef.value.setOption({
                dataZoom: [
                  {
                    start: 66.67,
                    end: 100,
                  },
                ],
              })
            }
          },
        },
      },
      itemSize: 16,
      itemGap: 16,
      right: 20,
      top: 8,
    },
  }
})

const scrollToCurrentYear = () => {
  if (chartRef.value) {
    chartRef.value.setOption({
      dataZoom: [
        {
          start: 66.67,
          end: 100,
        },
      ],
    })
  }
}

const handleChartClick = params => {
  if (params.componentType === 'graphic') {
    scrollToCurrentYear()
  }
}

const closeModal = () => {
  showModal.value = false
  keywordData.value = []
  selectedLocations.value = {}
}

onMounted(() => {
  if (showModal.value) fetchData()
})

watch(() => props.item, fetchData)
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>