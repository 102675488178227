<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'dashboard'" @change-tab="handleTabChange"></tab-navigation>
  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import entities from './tabs/entities/index.vue'
import dashboard from './tabs/dashboard/index.vue'

import TabNavigation from '@/components/base/navigation/TabNavigation.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'seo')

// Dil değiştiğinde güncellenecek tabs
const tabs = computed(() => [
  { name: 'dashboard', label: t('assets.tabs.dashboard'), component: markRaw(dashboard) },
  { name: 'entities', label: t('assets.tabs.assets'), component: markRaw(entities) },
])

const currentTabComponent = computed(() => {
  const tab = tabs.value.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

onMounted(() => {
  if (!tabs.value.find(tab => tab.name === currentTab.value)) {
    currentTab.value = 'dashboard'
  }
})

watch(() => route.query.tab, (newTab) => {
  currentTab.value = newTab || 'dashboard'
})
</script>