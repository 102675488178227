<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'calendar'" @change-tab="handleTabChange">
    <template #right-content>
      <!--      <custom-button @click="saveTabSettings" button-text="Kaydet" color="header" :isLoading="buttonLoading" :loading-text="'Kaydediliyor'" size="sm"/>-->
    </template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef" @saving="(val) => buttonLoading = val"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TabNavigation from '@/components/base/navigation/TabNavigation.vue'
import Calendar from '@/views/scheduleAssistant/components/calendar/calendar.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const buttonLoading = ref(false)
const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'calendar')

const tabs = computed(() => [
  { name: 'calendar', label: t('scheduleAssistant.tabs.calendar'), component: markRaw(Calendar) },
])

const currentTabComponent = computed(() => {
  const tab = tabs.value.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}
</script>