<template>
  <div class="component-layout">
    <div class="bg-white p-4 shadow-md rounded-md">
      <h3 class="text-lg font-semibold mb-4 text-secondary">{{ t('dashboard.analytics.visitors.title') }}</h3>
      <div class="relative chart-container">
        <div ref="chartContainer" class="w-full h-full transition-opacity duration-500 ease-in-out" :class="{ 'opacity-0': loading, 'opacity-100': !loading }"></div>
        <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-75 flex justify-center items-center">
          <span class="material-symbols-outlined text-6xl text-primary animate-spin">progress_activity</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, LegendComponent, DataZoomComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

const { t, locale } = useI18n()

echarts.use([LineChart, GridComponent, TooltipComponent, LegendComponent, DataZoomComponent, CanvasRenderer])

const props = defineProps({
  data: Array,
  loading: Boolean,
})

const chartContainer = ref(null)
let chart = null

const isMobile = ref(false)

const updateMobileStatus = () => {
  isMobile.value = window.innerWidth < 768
}

const handleResize = () => {
  updateMobileStatus()
  if (chart) {
    chart.resize()
    updateChart()
  }
}

const chartOptions = computed(() => {
  return {
    color: ['#FC002B', '#FC9F00'],
    tooltip: {
      trigger: 'axis',
      borderColor: '#FC002B',
    },
    legend: {
      data: [
        t('dashboard.analytics.visitors.legends.activeUsers'),
        t('dashboard.analytics.visitors.legends.sessions'),
      ],
      textStyle: { color: '#1e293b' },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: isMobile.value ? '15%' : '10%',
      top: '15%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: props.data?.map(item => item.date) || [],
      axisLabel: {
        color: '#1e293b',
        rotate: isMobile.value ? 45 : 0,
        interval: isMobile.value ? 'auto' : 0,
        formatter: (value) => {
          return value.replace('2024-', '')
        },
      },
      axisLine: { lineStyle: { color: '#1e293b' } },
    },
    yAxis: {
      type: 'value',
      axisLabel: { color: '#1e293b' },
      axisLine: { lineStyle: { color: '#1e293b' } },
      splitLine: { lineStyle: { color: '#f8fafc' } },
    },
    series: [
      {
        name: t('dashboard.analytics.visitors.legends.activeUsers'),
        type: 'line',
        data: props.data?.map(item => item.activeUsers) || [],
        lineStyle: { width: 3 },
        symbol: 'circle',
        symbolSize: 8,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
      },
      {
        name: t('dashboard.analytics.visitors.legends.sessions'),
        type: 'line',
        data: props.data?.map(item => item.sessions) || [],
        lineStyle: { width: 3 },
        symbol: 'circle',
        symbolSize: 8,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
      },
    ],
    dataZoom: isMobile.value ? [
      {
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        start: 50,
        end: 100,
        height: 20,
        bottom: 0,
        borderColor: '#ccc',
        fillerColor: 'rgba(252, 0, 43, 0.2)',
        handleStyle: {
          color: '#FC002B',
          borderColor: '#FC002B',
        },
        textStyle: {
          color: '#1e293b',
        },
        brushSelect: false,
      },
    ] : [],
  }
})

const initChart = () => {
  if (chartContainer.value) {
    chart = echarts.init(chartContainer.value)
    updateChart()
  }
}

const updateChart = () => {
  if (!chart || !props.data) return
  chart.setOption(chartOptions.value)
}

onMounted(() => {
  updateMobileStatus()
  window.addEventListener('resize', handleResize)
  if (!props.loading) {
    nextTick(() => {
      initChart()
    })
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
  if (chart) {
    chart.dispose()
  }
})

watch(() => props.data, updateChart, { deep: true })

watch(() => props.loading, (newVal) => {
  if (!newVal) {
    nextTick(() => {
      if (chart) {
        chart.resize()
        updateChart()
      } else {
        initChart()
      }
    })
  }
})

watch([isMobile, locale], () => {
  if (chart) {
    nextTick(() => {
      updateChart()
    })
  }
})
</script>

<style scoped>
.chart-container {
  height: 400px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }
}
</style>
