<template>
  <div class="bg-slate-50 rounded-lg p-4">
    <h4 class="font-medium text-sm mb-2 capitalize">
      {{ t(`entity.components.contentReview.${item.key === 'title' ? 'titleScore' : item.key}`) }} </h4>
    <div class="flex items-center gap-2 mb-2">
      <div class="h-2 w-20 bg-gray-200 rounded-full overflow-hidden">
        <div class="h-full bg-primary rounded-full" :style="{ width: `${item.data.score * 10}%` }"/>
      </div>
      <span class="text-sm text-slate-600">{{ item.data.score }}/10</span>
    </div>
    <p class="text-sm text-slate-600">{{ item.data.message }}</p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  item: Object,
})
</script>