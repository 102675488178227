<template>
  <div class="border-b pb-6">
    <div class="flex items-center justify-between">
      <div>
        <h2 class="text-xl font-bold text-secondary">{{ title }}</h2>
        <p class="mt-2 text-gray-600 text-sm">{{ subtitle }}</p>
      </div>

      <!-- Security Badges -->
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-2 text-sm text-gray-500">
          <span class="material-symbols-outlined text-green-500">lock</span> <span>{{ t('assets.components.create.components.payment.securePayment') }}</span>
        </div>
        <div class="flex items-center space-x-2 text-sm text-gray-500">
          <span class="material-symbols-outlined text-green-500">verified_user</span> <span>{{ t('assets.components.create.components.payment.secureTransaction') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  currentNestedStep: {
    type: Number,
    required: true,
  },
})

const { t } = useI18n()

const title = computed(() => {
  switch (props.currentNestedStep) {
    case 0:
      return t('assets.components.create.components.payment.steps.billing.title')
    case 1:
      return t('assets.components.create.components.payment.steps.method.title')
    case 2:
      return t('assets.components.create.components.payment.steps.confirmation.title')
    default:
      return t('assets.components.create.components.payment.title')
  }
})

const subtitle = computed(() => {
  switch (props.currentNestedStep) {
    case 0:
      return t('assets.components.create.components.payment.steps.billing.subtitle')
    case 1:
      return t('assets.components.create.components.payment.steps.method.subtitle')
    case 2:
      return t('assets.components.create.components.payment.steps.confirmation.subtitle')
    default:
      return t('assets.components.create.components.payment.subtitle')
  }
})
</script>