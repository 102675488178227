<template>
  <div class="bg-white p-4 rounded-lg shadow-sm w-full">
    <h2 class="text-2xl font-bold text-secondary mb-5">
      {{ t('assets.components.create.components.source.websiteConfiguration.title') }} </h2>
    <sourceTable v-model="sources"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import sourceTable from './components/sourceTable.vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const assetStore = useAssetStore()
const { t } = useI18n()
const isValid = ref(false)

const sources = computed(() => assetStore.assetData.sources)
const introductionList = computed(() => assetStore.assetData.introductionList)
const authList = computed(() => assetStore.assetData.authList)
const isDataReceived = computed(() => assetStore.assetData.isDataReceived)

const sendRequest = async () => {
  await assetStore.setAssetLoading(true)

  const websiteUrl = new URL(introductionList.value.website)

  const data = {
    name: introductionList.value.name,
    website: websiteUrl.href,
    languageId: introductionList.value.languageId,
    username: authList.value.username,
    password: authList.value.password,
    authUrl: authList.value.authUrl,
    sourceList: sources.value,
    searchEngineLocations: introductionList.value.searchEngineLocations,
  }

  try {
    const res = await axios.post(assets.info, data)
    const responseData = res.data.data

    await assetStore.setAssetInputLoading(true)
    isValid.value = true
    await assetStore.setAssetId(responseData.assetId)
    await assetStore.setAssetCustomerLanguageId(responseData.customerLanguageId)
    await assetStore.setInformationList(responseData)
    await assetStore.setIsDataReceived(true)
    await assetStore.setAssetLoading(false)
  } catch (err) {
    responseHandlerService.handleError(err, t('assets.create.source.websiteConfiguration.errors.generalError'))
    isValid.value = false
    await assetStore.setAssetLoading(false)
  }
}

const finalize = async () => {
  if (!authList.value.isAuth) {
    responseHandlerService.handleWarning(t('assets.create.source.websiteConfiguration.errors.authorizationRequired'))
    return false
  }

  if (
      !introductionList.value.name ||
      !introductionList.value.website ||
      !introductionList.value.languageId ||
      !authList.value.username ||
      !authList.value.password ||
      !authList.value.authUrl
  ) {
    responseHandlerService.handleError(t('assets.create.source.websiteConfiguration.errors.fillAllFields'))
    return false
  }

  if (isDataReceived.value) {
    return true
  }

  await sendRequest()
  return isValid.value
}

onMounted(async () => {
  await assetStore.setAssetLoading(false)
})

defineExpose({
  finalize,
})
</script>