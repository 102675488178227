<template>
  <div class="keyword-table h-full flex flex-col">
    <customTable :data="keywordsTableData" :columns="columns" :is-loading="isLoading" :show-search="false" max-height="40vh" :selectable="true" v-model:selectedIds="selectedKeywords" itemId="id" @selection-change="handleSelectionChange">
      <!-- Table Header Actions -->
      <template #table-actions>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
          <!-- Left Actions Group -->
          <div class="flex flex-col sm:items-center sm:flex-row gap-4">
            <!-- Delete Button -->
            <CustomButton v-if="selectedKeywords.length > 0" :button-text="t('settings.components.configuration.components.keywordsTable.bulkDelete')" @click="handleBulkDelete" size="sm" color="primary" variation="outline" icon="delete" icon-position="right" :show-icon="true"/>

            <!-- Add & Share Group -->
            <div class="flex items-center gap-2 w-full xs:w-auto">
              <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.addKeywords')" @click="showAddModal = true" size="sm" color="primary" icon="add" icon-position="right" :show-icon="true"/>

              <button v-if="keywordsTableData.length > 0" @click="exportKeywords" :disabled="isExporting" class="material-symbols-outlined text-secondary cursor-pointer !text-[20px] hover:text-primary disabled:opacity-50 disabled:cursor-not-allowed w-8 h-8 flex-shrink-0 flex items-center justify-center" :title="t('settings.components.configuration.components.keywordsTable.keywordsExport')">
                ios_share
              </button>
            </div>
          </div>

          <!-- Right Actions -->
          <div class="flex flex-col sm:flex-row justify-end sm:items-center gap-2">
            <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.downloadTemplate')" @click="downloadTemplate" size="sm" color="secondary" icon="download" icon-position="right" :show-icon="true"/>

            <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.uploadExcel')" @click="triggerFileInput" size="sm" color="primary" icon="upload" icon-position="right" :show-icon="true"/>

            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUploadEvent" accept=".xlsx,.xls"/>
          </div>
        </div>
      </template>

      <!-- Row Actions -->
      <template #actions="{ item }">
        <div class="flex items-center justify-center space-x-2">
          <button @click.stop="handleEdit(item)" class="text-primary hover:text-primary/70">
            <span class="material-symbols-outlined !text-[20px]">edit</span>
          </button>
          <button @click.stop="removeKeyword(item)" class="text-red-500 hover:text-red-700">
            <span class="material-symbols-outlined !text-[20px]">delete</span>
          </button>
        </div>
      </template>

      <!-- Priority Display -->
      <template #priority="{ item }">
        <p class="text-center">{{ item.priority || '-' }}</p>
      </template>

      <!-- No Data Message -->
      <template #no-data>
        <div class="text-center py-4 text-gray-500">
          {{ t('settings.components.configuration.components.keywordsTable.noKeywords') }}
        </div>
      </template>
    </customTable>

    <!-- Modals -->
    <add-keyword-modal v-model="showAddModal" :max-keywords="maxKeywords" :current-count="activeKeywordsCount" @save="handleAddKeywords"/>

    <edit-keyword-modal v-model="showEditModal" :keyword="selectedKeyword" @save="handleEditKeyword"/>

    <ImportResultModal v-model="showResultModal" :results="importResults"/>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useKeywords } from './composables/useKeywords'
import { useKeywordImport } from './composables/useKeywordImport'
import { useKeywordExport } from './composables/useKeywordExport'
import { responseHandlerService } from '@/plugins/responseHandlerService'

// Components
import customTable from '@/components/table/VTable.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import AddKeywordModal from './components/addKeywordModal.vue'
import EditKeywordModal from './components/editKeywordModal.vue'
import ImportResultModal from './components/ImportResultModal.vue'

const props = defineProps({
  keywords: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  maxKeywords: {
    type: Number,
    default: 350,
  },
})

const emit = defineEmits(['update:keywords'])

const { t } = useI18n()

// Refs
const fileInput = ref(null)
const showAddModal = ref(false)
const showResultModal = ref(false)

// Composables
const {
  selectedKeywords,
  selectedKeyword,
  showEditModal,
  keywordsTableData,
  activeKeywordsCount,
  handleSelectionChange,
  handleBulkDelete,
  handleAddKeywords,
  handleEdit,
  handleEditKeyword,
  removeKeyword,
} = useKeywords(props, emit, t)

const { importResults, handleFileUpload } = useKeywordImport(props, emit, t)
const { isExporting, exportKeywords, downloadTemplate } = useKeywordExport(keywordsTableData, t)

// Computed
const columns = computed(() => [
  {
    key: 'name',
    label: t('settings.components.configuration.components.keywordsTable.keyword'),
    width: '1/2',
  },
  {
    key: 'priority',
    label: t('settings.components.configuration.components.keywordsTable.priority'),
    width: '1/4',
    align: 'center',
  },
  {
    key: 'actions',
    label: t('settings.components.configuration.components.keywordsTable.actions'),
    width: '1/4',
    align: 'center',
  },
])

// Event Handlers
const triggerFileInput = () => {
  fileInput.value.click()
}

const handleFileUploadEvent = async (event) => {
  const file = event.target.files[0]
  if (!file) return

  const result = await handleFileUpload(file)
  if (result.success) showResultModal.value = true
  else responseHandlerService.errorHandler(t('settings.components.configuration.components.keywordsTable.fileUploadError'))

  event.target.value = ''
}

// Cleanup
const resetModals = () => {
  showAddModal.value = false
  showResultModal.value = false
  selectedKeyword.value = null
}

const cleanup = () => {
  selectedKeywords.value = []
  resetModals()
}

onBeforeUnmount(() => {
  cleanup()
})
</script>