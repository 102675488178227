import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { ref, computed } from 'vue'
import { encryptedStorage } from '@/utils/encryption'

export const useNavigationStore = defineStore('navigation', () => {
  const { t } = useI18n()

  // state
  const isSidebarOpen = ref(false)
  const showSubmenu = ref(false)
  const openSubmenuIndex = ref(null)

  // getters
  const menuItems = computed(() => [
    {
      label: t('navigation.layout.menu.dashboard.label'),
      icon: 'dashboard',
      path: '/dashboard',
      subItems: [
        { label: t('navigation.layout.menu.dashboard.items.seo'), key: 'seo' },
        { label: t('navigation.layout.menu.dashboard.items.analytics'), key: 'analytics' },
      ],
    },
    {
      label: t('navigation.layout.menu.contentModerator.label'),
      icon: 'group',
      path: '/content-moderator',
      subItems: [
        { label: t('navigation.layout.menu.contentModerator.items.contents'), key: 'contents' },
        { label: t('navigation.layout.menu.contentModerator.items.new'), key: 'new-content' },
        // { label: t('navigation.layout.menu.contentModerator.items.video'), key: 'new-video-content' },
      ],
    },
    {
      label: t('navigation.layout.menu.planningAssistant.label'),
      icon: 'calendar_month',
      path: '/content/scheduling/assistant',
    },
    {
      label: t('navigation.layout.menu.socialMedia.label'),
      icon: 'share',
      path: '/social-media',
      subItems: [
        { label: t('navigation.layout.menu.socialMedia.items.posts'), key: 'posts' },
        { label: t('navigation.layout.menu.socialMedia.items.new'), key: 'new-content' },
      ],
    },
    {
      label: t('navigation.layout.menu.settings.label'),
      icon: 'settings',
      path: '/settings',
      subItems: [
        { label: t('navigation.layout.menu.settings.items.general'), key: 'general' },
        { label: t('navigation.layout.menu.settings.items.schedule'), key: 'schedule' },
        { label: t('navigation.layout.menu.settings.items.entity'), key: 'settingsConfiguration' },
        { label: t('navigation.layout.menu.settings.items.website'), key: 'sourceConfiguration' },
        { label: t('navigation.layout.menu.settings.items.integrations'), key: 'integrations' },
        { label: t('navigation.layout.menu.settings.items.payment'), key: 'payment' },
      ],
    },
  ])

  // computed states
  const isSubmenuOpen = computed(() => showSubmenu.value && openSubmenuIndex.value !== null)

  // actions
  function toggleSidebar() {
    isSidebarOpen.value = !isSidebarOpen.value
    if (!isSidebarOpen.value) {
      closeSubmenu()
    }
  }

  function closeSidebar() {
    isSidebarOpen.value = false
    closeSubmenu()
  }

  function closeSubmenu() {
    showSubmenu.value = false
    openSubmenuIndex.value = null
  }

  function setSubmenuState(show, index) {
    showSubmenu.value = show
    openSubmenuIndex.value = index
  }

  function resetNavigationState() {
    isSidebarOpen.value = false
    showSubmenu.value = false
    openSubmenuIndex.value = null
  }

  return {
    // state
    isSidebarOpen,
    showSubmenu,
    openSubmenuIndex,
    // getters
    menuItems,
    isSubmenuOpen,
    // actions
    toggleSidebar,
    closeSidebar,
    closeSubmenu,
    setSubmenuState,
    resetNavigationState,
  }
}, {
  persist: {
    key: 'navigation-store',
    storage: encryptedStorage,
    paths: ['isSidebarOpen', 'showSubmenu', 'openSubmenuIndex'],
    afterRestore: (context) => {
      // Sayfa yenilendiğinde veya tarayıcı açıldığında submenu'yü kapat
      if (context.store.showSubmenu || context.store.openSubmenuIndex !== null) {
        context.store.closeSubmenu()
      }
    },
  },
})