import * as yup from 'yup'

export const createFileValidationSchema = (t, options = {}) => {
  const {
    maxTotalSize = 30 * 1024 * 1024, // 30MB in bytes
    maxFiles = 2,
    allowedTypes = ['image/jpeg', 'image/png'],
  } = options

  return yup.object().shape({
    images: yup
        .array()
        .nullable()
        .max(maxFiles, t('form.errors.maxImagesError', { count: maxFiles }))
        .test('isImage', t('form.errors.invalidImageFormat'), (values) => {
          if (!values || !values.length) return true
          return values.every(image => {
            if (!image?.file?.type) return false
            const fileType = image.file.type.toLowerCase()
            return allowedTypes.includes(fileType)
          })
        })
        .test('totalSize', t('form.errors.totalSizeError', { size: '30MB' }), (values) => {
          if (!values || !values.length) return true
          const totalSize = values.reduce((sum, image) => sum + (image?.file?.size || 0), 0)
          return totalSize <= maxTotalSize
        }),
  })
}

export const createNewContentSchema = (t) => {
  return yup.object().shape({
    languageId: yup
        .number()
        .required(t('form.errors.required')),

    assistiveDescription: yup
        .string()
        .nullable(),


    assistiveImageDescription: yup
        .string()
        .nullable(),

    keywords: yup
        .array()
        .min(3, t('form.errors.minKeywords', { count: 3 }))
        .max(52, t('form.errors.maxKeywords', { count: 52 }))
        .required(t('form.errors.required')),

    images: yup
        .array()
        .nullable(),
  }).concat(createFileValidationSchema(t, {
    maxFileSize: 10 * 1024 * 1024,
    maxFiles: 2,
    allowedTypes: ['image/jpeg', 'image/png'],
  }))
}