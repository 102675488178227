<template>
  <div class="component-layout">
    <div class="flex flex-col space-y-4">
      <!-- Main Content Grid -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 min-h-[65vh]">
        <!-- Left Column: About Us -->
        <div class="flex flex-col">
          <div class="bg-white rounded-lg shadow-md p-4 h-full">
            <h3 class="text-lg font-semibold mb-4 text-gray-700">
              {{ t('settings.components.informationList.aboutusLabel') }} </h3>
            <div class="flex-1 h-full">
              <customInput type="textarea" :label="t('settings.components.informationList.aboutusLabel')" :description="t('settings.components.informationList.aboutusDescription')" class="h-full" v-model="generalSettings.aboutus" :error="errors.aboutus" :loading="loading" :auto-grow="false" text-area-height="h-[calc(65vh-8rem)] resize-none"/>
            </div>
          </div>
        </div>

        <!-- Right Column: Social Media and Contact -->
        <div class="flex flex-col space-y-6">
          <!-- Social Media Card -->
          <div class="bg-white rounded-lg shadow-md p-4 flex-1">
            <h3 class="text-lg font-semibold mb-4 text-gray-700">
              {{ t('settings.components.informationList.socialMedia') }} </h3>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <customInput v-for="(social, key) in socialInputs" :key="key" icon="link" :show-icon="true" :label="t(`settings.components.informationList.${key}Label`)" :description="t(`settings.components.informationList.${key}Description`)" class="w-full" v-model="generalSettings[social.modelKey]" :error="errors[social.modelKey]" :loading="loading"/>
            </div>
          </div>

          <!-- Contact Card -->
          <div class="bg-white rounded-lg shadow-md p-4 flex-1">
            <h3 class="text-lg font-semibold mb-4 text-gray-700">
              {{ t('settings.components.informationList.contact') }} </h3>
            <div class="space-y-4">
              <customInput :label="t('settings.components.informationList.phoneLabel')" type="text" :description="t('settings.components.informationList.phoneDescription')" class="w-full" v-model="generalSettings.phone" :error="errors.phone" :loading="loading"/>
              <customInput :label="t('settings.components.informationList.whatsappLabel')" type="text" :description="t('settings.components.informationList.whatsappDescription')" class="w-full" v-model="generalSettings.wphone" :error="errors.wphone" :loading="loading"/>
            </div>
          </div>
        </div>
      </div>

      <!-- AI Assistant Button Section -->
      <!--      <div class="bg-white rounded-md p-4 w-full">-->
      <!--        <div class="flex items-center justify-between">-->
      <!--          <div class="flex-grow">-->
      <!--            <h3 class="text-sm font-semibold mb-1">-->
      <!--              {{ t('settings.components.informationList.autoOptimizeTitle') }} </h3>-->
      <!--            <p class="text-xs mb-2 text-gray-500">-->
      <!--              {{ t('settings.components.informationList.autoOptimizeDescription') }} </p>-->
      <!--          </div>-->
      <!--          <CustomButton :button-text="t('general.view')" @click="openAILinkModal" size="sm"/>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- Modal -->
      <customModal v-model="showModal" modal-title="AI Asistanı Seçenekleri">
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <CustomButton button-text="Asistana Git" @click="goToAssistant" size="sm" icon="open_in_new" :showIcon="true" :fullWidth="true"/>
            <CustomButton button-text="HTML Kodunu Kopyala" @click="copyHTMLCode" size="sm" icon="content_copy" :showIcon="true" :fullWidth="true"/>
          </div>
        </div>
      </customModal>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { createSettingsSchema } from '@/validations/schemas/settings.general.schema'
import customInput from '@/components/base/inputs/VInput.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import customModal from '@/components/modals/VModal.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { settings } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'
import { useAssetStore } from '@/stores/asset'

const assetStore = useAssetStore()
const { t } = useI18n()
const schema = createSettingsSchema(t)
const emit = defineEmits(['saving'])

const showModal = ref(false)
const htmlCode = ref('')
const loading = ref(false)
const aiLink = ref('')
const errors = reactive({})

const generalSettings = reactive({
  aboutus: '',
  facebookUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  linkedinUrl: '',
  phone: '',
  wphone: '',
  communicationId: '',
})

const socialInputs = {
  facebook: { modelKey: 'facebookUrl' },
  instagram: { modelKey: 'instagramUrl' },
  twitter: { modelKey: 'twitterUrl' },
  linkedin: { modelKey: 'linkedinUrl' },
}

const validateField = async (field, value) => {
  try {
    await schema.validateAt(field, { [field]: value })
    errors[field] = ''
  } catch (err) {
    errors[field] = err.message
  }
}

const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.generalSettings, {
      params: { assetId: assetStore.assetId },
    })
    if (response.data?.data) {
      Object.keys(generalSettings).forEach(key => {
        generalSettings[key] = response.data.data[key] || ''
      })
    }
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.errorFetchingSettings'))
  } finally {
    loading.value = false
  }
}

const save = async () => {
  emit('saving', true)
  try {
    await schema.validate(generalSettings, { abortEarly: false })
    await axios.put(settings.generalSettings, {
      ...generalSettings,
      assetId: assetStore.assetId,
    })
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
  } catch (error) {
    if (error.name === 'ValidationError') {
      error.inner.forEach(err => {
        errors[err.path] = err.message
      })
      return
    }
    responseHandlerService.handleError(error, t('settings.errorUpdatingSettings'))
  } finally {
    emit('saving', false)
  }
}

const openAILinkModal = () => {
  aiLink.value = `${settings.aiIframe}?access_code=${assetStore.assetId}`
  htmlCode.value = `<button onclick="window.open('${aiLink.value}', '_blank')">AI Asistanı</button>`
  showModal.value = true
}

const goToAssistant = () => {
  window.open(aiLink.value, '_blank')
}

const copyHTMLCode = () => {
  navigator.clipboard.writeText(htmlCode.value)
      .then(() => {
        responseHandlerService.handleSuccess('HTML kodu panoya kopyalandı!')
        showModal.value = false
      })
      .catch(() => {
        responseHandlerService.handleError('Kopyalama işlemi başarısız oldu. Lütfen kodu manuel olarak kopyalayın.')
      })
}

watch(generalSettings, (newVal, oldVal) => {
  const changedField = Object.keys(newVal).find(key => newVal[key] !== oldVal[key])
  if (changedField) {
    validateField(changedField, newVal[changedField])
  }
}, { deep: true })

onMounted(() => {
  fetch()
})

defineExpose({
  save,
})
</script>