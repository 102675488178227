import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { setupErrorHandler } from '@/plugins/errorHandler'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'

const app = createApp(App)

// Error handler'ı ekle
setupErrorHandler(app)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.logStoreChanges = false
})
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

// Diğer importlar
import router from './router'
import { clickOutside } from './directives/clickOutside'
import { MotionPlugin } from '@vueuse/motion'
import { createVfm } from 'vue-final-modal'
import VueViewer from 'v-viewer'
import socketPlugin from './plugins/socket'
import VueApexCharts from 'vue3-apexcharts'
import { setupI18n } from '@/i18n'
import * as Sentry from '@sentry/vue'
import ToastPlugin from './plugins/toast-plugin'
import { responseHandlerService } from '@/plugins/responseHandlerService'

// Stil importları
import './css/index.css'
import './assets/tailwind.css'
import 'material-symbols'
import 'material-symbols/outlined.css'
import 'vue-final-modal/style.css'
import 'viewerjs/dist/viewer.css'

const i18n = setupI18n(pinia)

const env = process.env.VUE_APP_MODE

const socketUrl = {
  development: 'http://localhost:3000/notification',
  production: 'https://api.lobsterlead.com/notification',
  test: 'https://api.lobster.softwareoptimize.com/notification',
}[env] || 'Invalid VUE_APP_MODE'

const vfm = createVfm()

const viewerOptions = {
  defaultOptions: {
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
  },
}

const toastOptions = {
  timeout: 4000,
  showProgress: true,
  isDarkMode: false,
  maxToasts: 2,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false
    }
    return toast
  },
}

if (env === 'production' || env === 'test') {
  Sentry.init({
    app,
    dsn: 'https://644968285a450ff9a19880365b3eb600@o4508159879675904.ingest.de.sentry.io/4508159939051600',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        isNameRequired: true,
        isEmailRequired: true,
        autoInject: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/yourserver\.io\/api/,
      'https://portal.lobsterlead.com',
      'https://portal.lobster.softwareoptimize.com',
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: env || 'development',
  })
}

app.config.globalProperties.$responseHandlerService = responseHandlerService


app
    .use(router)
    .use(vfm)
    .use(MotionPlugin)
    .use(i18n)
    .use(ToastPlugin, toastOptions)
    .use(VueApexCharts)
    .use(VueViewer, viewerOptions)
    .use(socketPlugin, { url: socketUrl })
    .directive('click-outside', clickOutside)

app.mount('#app')

export { pinia }