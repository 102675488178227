import io from 'socket.io-client'
import { useAuthStore } from '@/stores/auth'

export default {
  install: (app, { url, options = {} }) => {
    if (!app.config.globalProperties.$socket) {
      const authStore = useAuthStore()
      const socketOptions = {
        ...options,
        auth: {
          token: authStore.userData?.token,
        },
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        transports: ['websocket'],
      }

      app.config.globalProperties.$socket = io(url, socketOptions)
    }
  },
}