<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
    <template v-if="dashboardStore.isComponentLoading('metrics')">
      <MetricSkeleton v-for="i in 5" :key="i"/>
    </template>
    <template v-else>
      <MetricCard v-for="(value, key) in filteredMetrics" :key="key" :label="metricLabels[key]" :value="animatedValues[key]"/>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useDashboardStore } from '@/stores/dashboardOfAssets'
import axios from '@/plugins/axiosInstance'
import { genaralReporting } from '@/networking/urlManager'
import MetricCard from './components/MetricCard.vue'
import MetricSkeleton from './components/MetricSkeleton.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  keywordData: {
    type: Array,
    default: () => [],
  },
  assetData: {
    type: Array,
    default: () => [],
  },
})

const { t } = useI18n()
const dashboardStore = useDashboardStore()
const emit = defineEmits(['metricsData'])
const animatedValues = ref({})
const initialFetchDone = ref(false)

const metricLabels = computed(() => ({
  generatekeywordCount: t('assets.dashboard.metrics.generatedKeywords'),
  suggestionKeywwordCount: t('assets.dashboard.metrics.suggestedKeywords'),
  deletedKeywordCount: t('assets.dashboard.metrics.deletedKeywords'),
  totalContent: t('assets.dashboard.metrics.totalContent'),
  totalVolume: t('assets.dashboard.metrics.totalVolume'),
}))

const filteredMetrics = computed(() => {
  const metrics = dashboardStore.metricsData
  if (!metrics) return {}

  return {
    generatekeywordCount: metrics.generatekeywordCount,
    suggestionKeywwordCount: metrics.suggestionKeywwordCount,
    deletedKeywordCount: metrics.deletedKeywordCount,
    totalContent: metrics.totalContent,
    totalVolume: metrics.totalVolume,
  }
})

const calculateAdditionalMetrics = () => ({
  totalContent: props.assetData.reduce((sum, asset) => sum + (asset.contentCount || 0), 0),
  totalVolume: props.keywordData.reduce((sum, keyword) => sum + (keyword.volume || 0), 0),
})

const updateMetricsFromProps = () => {
  dashboardStore.setLoading('metrics', true)
  const additionalMetrics = calculateAdditionalMetrics()
  const currentMetrics = dashboardStore.metricsData || {}

  const metrics = {
    ...currentMetrics,
    ...additionalMetrics,
    timestamp: Date.now(),
  }

  dashboardStore.setMetricsData(metrics)
  emit('metricsData', metrics)

  Object.entries(filteredMetrics.value).forEach(([key, value]) => {
    if (typeof value === 'number') {
      animateValue(value, key)
    }
  })

  dashboardStore.setLoading('metrics', false)
}

const fetchInitialMetrics = async () => {
  if (initialFetchDone.value) return

  dashboardStore.setLoading('metrics', true)
  try {
    const response = await axios.get(genaralReporting.generatesKeywordCounts)
    const metrics = {
      ...response.data.data,
      ...calculateAdditionalMetrics(),
      timestamp: Date.now(),
    }

    dashboardStore.setMetricsData(metrics)
    emit('metricsData', metrics)

    Object.entries(filteredMetrics.value).forEach(([key, value]) => {
      if (typeof value === 'number') {
        animateValue(value, key)
      }
    })

    initialFetchDone.value = true
  } catch (error) {
    console.error('Error fetching metrics:', error)
  } finally {
    dashboardStore.setLoading('metrics', false)
  }
}

const animateValue = async (target, key) => {
  const duration = 1000
  const startTime = performance.now()
  const startValue = animatedValues.value[key] || 0

  const animate = (currentTime) => {
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1)
    const easeOutQuart = 1 - Math.pow(1 - progress, 4)

    animatedValues.value[key] = Math.floor(startValue + (target - startValue) * easeOutQuart)

    if (progress < 1) {
      requestAnimationFrame(animate)
    } else {
      animatedValues.value[key] = target
    }
  }

  requestAnimationFrame(animate)
}

watch(
    [() => props.keywordData, () => props.assetData],
    ([newKeywordData, newAssetData], [oldKeywordData, oldAssetData]) => {
      if (
          (newKeywordData?.length && newKeywordData !== oldKeywordData) ||
          (newAssetData?.length && newAssetData !== oldAssetData)
      ) {
        updateMetricsFromProps()
      }
    },
    { deep: true },
)

onMounted(fetchInitialMetrics)
</script>