<template>
  <div class="flex flex-col">
    <!-- Label ve Description -->
    <div v-if="label || description" class="mb-2">
      <div class="flex items-center space-x-2 mb-1">
        <div :class="['flex items-center', labelIconPosition === 'left' ? 'flex-row' : 'flex-row-reverse']">
          <icon-component v-if="labelIconPosition === 'left' && labelIcon" :icon="labelIcon" :class="[labelIconClass, 'mr-1', iconClickableClass]" @click="emitIconClick"/>
          <label v-if="label" :for="inputId" :class="[labelClass, additionalLabelClass]" class="text-sm"> {{ label }}<span v-if="required" class="text-primary">*</span> </label>
          <icon-component v-if="labelIconPosition === 'right' && labelIcon" :icon="labelIcon" :class="[labelIconClass, 'ml-1', iconClickableClass]" @click="emitIconClick"/>
        </div>
        <img v-if="showAiIcon" :src="aiStarIcon" class="cursor-pointer w-4 hover:opacity-70 transition-opacity" @click="emitIconClick" alt="star icon"/>
      </div>
      <p v-if="description" :class="[descriptionClass, additionalDescriptionClass]" class="text-xs mb-1">{{ description }}</p>
    </div>

    <!-- Select Component -->
    <div class="custom-select relative" ref="selectRef">
      <button @click="toggleDropdown" :class="[
          'flex items-center justify-between border bg-white rounded-md focus:outline-none w-full',
          sizeClasses,
          error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-gray-500 focus:ring-1 focus:ring-gray-500'
        ]">
        <span class="truncate">{{ selectedOption ? selectedOption.label : placeholder }}</span> <span :class="['material-symbols-outlined text-gray-400', iconSizeClass]">expand_more</span>
      </button>
      <div v-if="isOpen" class="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto thin-scrollbar">
        <ul>
          <li v-for="option in options" :key="option.value" @click="selectOption(option)" :class="[
              'hover:bg-gray-100 cursor-pointer truncate',
              sizeClasses
            ]">
            {{ option.label }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Error Message -->
    <div v-if="showError" class="h-3">
      <div v-if="error" class="flex items-start gap-1">
        <span class="material-symbols-outlined text-red-500 !text-[14px] mt-0.5">error</span>
        <p class="text-xs text-red-500">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import IconComponent from '@/components/common/VIcon.vue'
import { v4 as uuidv4 } from 'uuid'

const props = defineProps({
  modelValue: [String, Number],
  options: { type: Array, default: () => [] },
  placeholder: { type: String, default: '' },
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  error: { type: String, default: '' },
  showError: { type: Boolean, default: true },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  labelClass: { type: String, default: 'font-semibold text-secondary' },
  descriptionClass: { type: String, default: 'text-gray-600' },
  additionalDescriptionClass: { type: String, default: '' },
  additionalLabelClass: { type: String, default: '' },
  showAiIcon: { type: Boolean, default: false },
  labelIcon: { type: String, default: '' },
  labelIconClass: { type: String, default: 'text-primary !text-[20px]' },
  labelIconPosition: {
    type: String,
    default: 'left',
    validator: (value) => ['left', 'right'].includes(value),
  },
  iconClickable: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'iconClicked'])

const isOpen = ref(false)
const selectRef = ref(null)
const inputId = ref(uuidv4())
const aiStarIcon = require('@/assets/icons/aiStar.png')

const selectedOption = computed(() =>
    props.options.find(option => option.value === props.modelValue),
)

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-xs py-1.5 px-2'
    case 'md':
      return 'text-sm py-2 px-3'
    case 'lg':
      return 'text-base py-2.5 px-3'
    default:
      return 'text-sm py-2 px-3'
  }
})

const iconSizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return '!text-[16px]'
    case 'md':
      return '!text-[18px]'
    case 'lg':
      return '!text-[20px]'
    default:
      return '!text-[18px]'
  }
})

const iconClickableClass = computed(() => {
  return props.iconClickable ? 'cursor-pointer hover:text-blue-600 transition-colors' : ''
})

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const selectOption = (option) => {
  emit('update:modelValue', option.value)
  isOpen.value = false
}

const handleClickOutside = (event) => {
  if (selectRef.value && !selectRef.value.contains(event.target)) {
    isOpen.value = false
  }
}

const emitIconClick = () => {
  emit('iconClicked')
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>