<template>
  <div class="bg-white shadow-md rounded-md p-4">
    <h3 class="text-lg font-semibold mb-4 text-secondary">{{ t('dashboard.analytics.countries.title') }}</h3>
    <div class="relative chart-container">
      <div ref="chartContainer" :style="{ height: chartHeight }" class="w-full transition-opacity duration-500 ease-in-out" :class="{ 'opacity-0': loading, 'opacity-100': !loading }"></div>
      <div v-if="loading" class="absolute inset-0 flex justify-center items-center bg-opacity-75 bg-white">
        <span class="material-symbols-outlined text-6xl text-primary animate-spin">progress_activity</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, LegendComponent, DataZoomComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

const { t, locale } = useI18n()

echarts.use([BarChart, GridComponent, TooltipComponent, LegendComponent, DataZoomComponent, CanvasRenderer])

const props = defineProps({
  data: Array,
  loading: Boolean,
})

const chartContainer = ref(null)
let chart = null

const isMobile = ref(false)
const chartHeight = ref('400px')

const updateMobileStatus = () => {
  isMobile.value = window.innerWidth < 768
  chartHeight.value = isMobile.value ? '300px' : '400px'
}

const handleResize = () => {
  updateMobileStatus()
  if (chart) {
    chart.resize()
    updateChart()
  }
}

const colorPalette = [
  '#1e293b', '#374151', '#4B5563', '#6B7280', '#9CA3AF',
  '#FC002B', '#D10024', '#FF3355', '#FF6680', '#FF99AA',
]

const chartOptions = computed(() => {
  const options = {
    color: colorPalette,
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      borderColor: '#FC002B',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: isMobile.value ? '15%' : '10%',
      top: '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: props.data?.map(item => item.country) || [],
      axisLabel: {
        interval: 0,
        rotate: 45,
        color: '#1e293b',
        align: 'right',
        verticalAlign: 'middle',
        margin: 16,
      },
      axisLine: { lineStyle: { color: '#1e293b' } },
    },
    yAxis: {
      type: 'value',
      axisLabel: { color: '#1e293b' },
      axisLine: { lineStyle: { color: '#1e293b' } },
      splitLine: { lineStyle: { color: '#f8fafc' } },
    },
    series: [
      {
        name: t('dashboard.analytics.countries.activeUsers'),
        type: 'bar',
        data: props.data?.map(item => item.activeUsers) || [],
        itemStyle: {
          color: (params) => colorPalette[params.dataIndex % colorPalette.length],
        },
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        label: {
          show: true,
          position: 'top',
          color: '#1e293b',
        },
      },
    ],
  }

  if (isMobile.value) {
    options.dataZoom = [
      {
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        start: 0,
        end: 50,
        height: 20,
        bottom: 0,
        borderColor: '#ccc',
        fillerColor: 'rgba(252, 0, 43, 0.2)',
        handleStyle: {
          color: '#FC002B',
          borderColor: '#FC002B',
        },
        textStyle: {
          color: '#1e293b',
        },
        brushSelect: false,
        moveOnMouseMove: true,
        zoomOnMouseWheel: false,
        moveOnMouseWheel: true,
        preventDefaultMouseMove: false,
      },
    ]
  }

  return options
})

const initChart = () => {
  if (chartContainer.value) {
    chart = echarts.init(chartContainer.value)
    updateChart()
  }
}

const updateChart = () => {
  if (!chart || !props.data) return
  chart.setOption(chartOptions.value)
}

onMounted(() => {
  updateMobileStatus()
  window.addEventListener('resize', handleResize)
  if (!props.loading) {
    initChart()
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
  if (chart) {
    chart.dispose()
  }
})

watch(() => props.data, updateChart, { deep: true })

watch(() => props.loading, (newVal) => {
  if (!newVal) {
    nextTick(() => {
      if (chart) {
        chart.resize()
        updateChart()
      } else {
        initChart()
      }
    })
  }
})

watch([isMobile, locale], () => {
  if (chart) {
    nextTick(() => {
      updateChart()
    })
  }
})
</script>