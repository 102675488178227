<template>
  <div class="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
    <div class="flex items-center gap-4">
      <h1 class="text-xl md:text-2xl font-bold text-gray-800">
        {{ t('assets.report') }} </h1>
      <custom-button v-if="hasData" color="primary" :button-text="t('assets.allAssets')" @click="$emit('navigateToAllEntities')"/>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()
const emit = defineEmits(['navigateToAllEntities', 'language-selected'])

const props = defineProps({
  hasData: Boolean,
})
</script>