<template>
  <div class="relative" data-menu="profile" v-click-outside="handleClickOutside">
    <!-- Profile trigger -->
    <button @click="toggleMenu('profile')" class="flex items-center justify-center h-8 rounded-full hover:bg-gray-100 focus:outline-none transition-colors" :class="menuStore.isOpen('profile') ? 'text-primary bg-gray-100' : 'text-gray-600'">
      <span class="material-symbols-outlined !text-[22px]">account_circle</span>
    </button>

    <!-- Dropdown menu -->
    <div v-if="menuStore.isOpen('profile')" class="absolute right-0 mt-1 z-[100] min-w-[160px] p-1 bg-white rounded-md shadow-[0_2px_16px_-2px_rgba(0,0,0,0.1)] border border-black/[0.06]">
      <!-- User info -->
      <p class="px-2.5 py-1.5 text-[11px] text-black/40">
        {{ authStore.getFullName }} </p>

      <!-- Menu items -->
      <div class="mt-0.5">
        <button v-if="route.path.startsWith('/assets')" @click="users" class="menu-item">
          {{ t('navigation.layout.components.profile.users') }}
        </button>

        <button @click="logout" class="menu-item text-red-500">
          {{ t('navigation.layout.components.profile.signOut') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import { useAuthStore } from '@/stores/auth'
import { useMenuStore } from '@/stores/menuStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const menuStore = useMenuStore()

const toggleMenu = (menuId) => {
  menuStore.toggleMenu(menuId)
}

const handleClickOutside = (event) => {
  const isClickInsideAnyMenu = event.target.closest('[data-menu]')
  if (!isClickInsideAnyMenu) {
    menuStore.closeAll()
  }
}

const logout = () => {
  menuStore.closeAll()
  axios.post(auth.logOut)
      .then(() => {
        authStore.clearUserData()
        router.push('/')
      })
      .catch(() => {
        authStore.clearUserData()
        router.push('/')
      })
}

const users = () => {
  router.push('/users')
  menuStore.closeAll()
}
</script>

<style scoped>
.menu-item {
  @apply w-full text-left px-2.5 py-1.5 text-[13px] text-gray-700 hover:bg-black/[0.02] rounded-lg transition-colors font-medium;
}
</style>