<template>
  <VueFinalModal @closed="emit('update:modelValue', false)" class="flex justify-center items-center" content-class="relative bg-white rounded-lg w-[90%] md:w-[800px] lg:w-[1000px] mx-4 max-h-[80vh] min-h-[300px] flex flex-col" :click-to-close="true" :esc-to-close="true">
    <div class="flex flex-col h-full">
      <!-- Header -->
      <div class="shrink-0 p-4 border-b">
        <div class="flex justify-between items-center">
          <h3 class="text-lg font-medium text-gray-900">
            {{ t('settings.components.configuration.components.keywordsTable.importResults') }} </h3>
          <button @click="closeModal" class="text-gray-400 hover:text-gray-500">
            <span class="material-symbols-outlined">close</span>
          </button>
        </div>
      </div>

      <!-- Content -->
      <div class="flex-1 overflow-hidden flex flex-col">
        <!-- Summary Cards -->
        <div class="shrink-0 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3 p-4 border-b bg-gray-50">
          <div class="bg-white p-3 rounded-md shadow-sm border">
            <p class="text-xs text-gray-600 mb-1">{{ t('settings.components.configuration.components.keywordsTable.newKeywords') }}</p>
            <p class="text-lg font-semibold text-green-600">{{ results?.new || 0 }}</p>
          </div>
          <div class="bg-white p-3 rounded-md shadow-sm border">
            <p class="text-xs text-gray-600 mb-1">{{ t('settings.components.configuration.components.keywordsTable.updatedKeywords') }}</p>
            <p class="text-lg font-semibold text-blue-600">{{ results?.updated || 0 }}</p>
          </div>
          <div class="bg-white p-3 rounded-md shadow-sm border">
            <p class="text-xs text-gray-600 mb-1">{{ t('settings.components.configuration.components.keywordsTable.reactivatedKeywords') }}</p>
            <p class="text-lg font-semibold text-purple-600">{{ results?.reactivated || 0 }}</p>
          </div>
          <div class="bg-white p-3 rounded-md shadow-sm border">
            <p class="text-xs text-gray-600 mb-1">{{ t('settings.components.configuration.components.keywordsTable.normalizedPriorities') }}</p>
            <p class="text-lg font-semibold text-orange-600">{{ results?.priorityNormalized || 0 }}</p>
          </div>
          <div class="bg-white p-3 rounded-md shadow-sm border">
            <p class="text-xs text-gray-600 mb-1">{{ t('settings.components.configuration.components.keywordsTable.skippedKeywords') }}</p>
            <p class="text-lg font-semibold text-yellow-600">{{ results?.skipped || 0 }}</p>
          </div>
        </div>

        <!-- Tabs Navigation -->
        <div class="shrink-0 border-b px-2 bg-white">
          <nav class="flex -mb-px space-x-4 overflow-x-auto">
            <button v-for="tab in tabs" :key="tab.key" @click="activeTab = tab.key" :class="[
                'px-3 py-2 text-sm font-medium whitespace-nowrap border-b-2',
                activeTab === tab.key
                  ? 'border-primary text-primary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              ]">
              {{ tab.label }} ({{ getTabCount(tab.key) }})
            </button>
          </nav>
        </div>

        <!-- Tab Content - Scrollable Area -->
        <div class="flex-1 overflow-y-auto max-h-[calc(80vh-320px)] thin-scrollbar min-h-[calc(80vh-320px)]">
          <div class="p-4">
            <template v-if="getTabContent(activeTab).length">
              <div v-for="item in getTabContent(activeTab)" :key="item.name" class="p-3 mb-2 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors">
                <div class="flex justify-between items-center">
                  <span class="font-medium">{{ item.name }}</span> <span class="text-sm" :class="getItemStatusClass(item)">
                    {{ getItemStatusText(item) }}
                  </span>
                </div>
                <div v-if="item.reason" class="text-xs text-gray-500 mt-1">
                  {{ getReasonText(item) }}
                </div>
              </div>
            </template>
            <div v-else class="py-8 flex items-center justify-center text-gray-500 text-sm">
              {{ t('settings.components.configuration.components.keywordsTable.noItemsInTab') }}
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="shrink-0 p-4 border-t bg-gray-50">
        <div class="flex justify-end">
          <CustomButton :button-text="t('common.close')" @click="closeModal" color="secondary" size="sm"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  results: Object,
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const activeTab = ref('new')


const tabs = [
  { key: 'new', label: t('settings.components.configuration.components.keywordsTable.newKeywords') },
  { key: 'updated', label: t('settings.components.configuration.components.keywordsTable.updatedKeywords') },
  { key: 'reactivated', label: t('settings.components.configuration.components.keywordsTable.reactivatedKeywords') },
  { key: 'normalized', label: t('settings.components.configuration.components.keywordsTable.normalizedPriorities') },
  { key: 'skipped', label: t('settings.components.configuration.components.keywordsTable.skippedKeywords') },
]

const getTabCount = (tab) => {
  const mapping = {
    new: 'newKeywords',
    updated: 'updatedKeywords',
    reactivated: 'reactivatedKeywords',
    normalized: 'priorityNormalizedKeywords',
    skipped: 'skippedKeywords',
  }
  return props.results?.details?.[mapping[tab]]?.length || 0
}

const getTabContent = (tab) => {
  const mapping = {
    new: 'newKeywords',
    updated: 'updatedKeywords',
    reactivated: 'reactivatedKeywords',
    normalized: 'priorityNormalizedKeywords',
    skipped: 'skippedKeywords',
  }
  return props.results?.details?.[mapping[tab]] || []
}

const getItemStatusClass = (item) => {
  if (item.changeType === 'empty_to_value') return 'text-green-600'
  if (item.changeType === 'value_to_empty') return 'text-red-600'
  return 'text-blue-600'
}

const getItemStatusText = (item) => {
  if ('priority' in item) return `${t('settings.components.configuration.components.keywordsTable.priority')}: ${item.priority || '-'}`
  if (item.changeType === 'empty_to_value') return `${t('settings.components.configuration.components.keywordsTable.emptyValue')} → ${item.normalizedPriority}`
  if (item.changeType === 'value_to_empty') return `${item.originalPriority} → ${t('settings.components.configuration.components.keywordsTable.emptyValue')}`
  return `${item.originalPriority} → ${item.normalizedPriority}`
}

const getReasonText = (item) => {
  if (item.reason?.includes('keyword')) {
    return t(`settings.components.configuration.components.keywordsTable.skipReason.${item.reason}`)
  }
  return t(`settings.components.configuration.components.keywordsTable.normalizeReason.${item.reason}`)
}

const closeModal = () => {
  emit('update:modelValue', false)
  activeTab.value = 'new'
}
</script>