<template>
  <div class="w-full max-w-3xl mx-auto">
    <!-- Desktop View -->
    <div class="hidden lg:flex relative justify-between items-center px-4">
      <!-- Progress Path -->
      <div v-if="!steps[currentStep]?.nestedSteps" class="absolute top-1/2 -translate-y-1/2 left-0 w-full h-[2px]">
        <div class="w-full h-full bg-gray-100 absolute"></div>
        <div class="h-full absolute transition-all duration-500 ease-out" :class="currentStep === steps.length - 1 ? 'bg-primary' : 'bg-gradient-to-r from-primary to-primary/70'" :style="`width: ${getProgressWidth()}%`">
          <div v-if="currentStep !== steps.length - 1" class="absolute right-0 top-1/2 -translate-y-1/2 w-2 h-2 bg-primary rounded-full animate-pulse"/>
        </div>
      </div>

      <!-- Steps -->
      <template v-for="(step, index) in steps" :key="index">
        <div class="relative z-10" v-show="!steps[currentStep]?.nestedSteps || index > currentStep || steps[index].nestedSteps">
          <button @click="$emit('stepClick', index)" :disabled="!canNavigateToStep(index)" class="group relative flex flex-col items-center transition-all duration-300" :class="[
              !canNavigateToStep(index) ? 'cursor-not-allowed' : 'cursor-pointer',
              {'opacity-0': steps[currentStep]?.nestedSteps && index < currentStep}
            ]">
            <div class="w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300" :class="[
                completedSteps.includes(index) ? 'bg-primary shadow-md' :
                currentStep === index ? 'bg-primary ring-4 ring-primary/20' :
                'bg-white border-2 border-gray-200'
              ]">
              <span v-if="completedSteps.includes(index)" class="material-symbols-outlined text-white">check</span>
              <span v-else class="text-base font-medium" :class="currentStep === index ? 'text-white' : 'text-gray-400'">{{ index + 1 }}</span>
            </div>
            <span class="absolute mt-14 text-sm font-medium whitespace-nowrap" :class="currentStep >= index || completedSteps.includes(index) ? 'text-gray-900' : 'text-gray-400'">{{
                step.label
              }}</span>
          </button>
        </div>

        <!-- Connector -->
        <div v-if="index < steps.length - 1 && (!steps[currentStep]?.nestedSteps)" class="flex-1 mx-4">
          <div class="h-[2px]" :class="completedSteps.includes(index) ? 'bg-primary' : 'bg-gray-100'"></div>
        </div>

        <!-- Nested Steps -->
        <div v-if="step.nestedSteps && index === currentStep" class="flex items-center space-x-4 mt-4">
          <template v-for="(nestedStep, nIndex) in step.nestedSteps" :key="nestedStep.ref">
            <div class="flex flex-col items-center">
              <div class="w-6 h-6 rounded-full flex items-center justify-center border-2 transition-all duration-300" :class="getNestedStepClasses(nIndex)">
                <span v-if="currentNestedStep > nIndex" class="material-symbols-outlined text-xs text-white">check</span> <span v-else class="text-xs">{{ nIndex + 1 }}</span>
              </div>
              <span class="mt-2 text-xs font-medium whitespace-nowrap" :class="getNestedStepLabelClass(nIndex)">{{ nestedStep.label }}</span>
            </div>

            <div v-if="nIndex < step.nestedSteps.length - 1" class="w-16 h-[1px] transition-all duration-300" :class="getNestedConnectorClasses(nIndex)"></div>
          </template>
        </div>
      </template>
    </div>

    <!-- Mobile View -->
    <div class="block lg:hidden bg-gray-50 p-4 rounded-lg shadow-sm">
      <div class="flex flex-col space-y-4">
        <template v-for="(step, index) in steps" :key="index">
          <div class="flex items-center justify-between py-3 px-4 rounded-lg transition-all duration-300" :class="[
                 currentStep === index ? 'bg-primary/10 border border-primary' :
                 'bg-white border border-gray-200',
                 'shadow-sm'
               ]">
            <div class="flex items-center space-x-4">
              <div :class="[
                  'w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium',
                  currentStep === index ? 'bg-primary text-white' :
                  completedSteps.includes(index) ? 'bg-primary/10 text-primary' :
                  'bg-gray-100 text-gray-400'
                ]">
                {{ index + 1 }}
              </div>
              <div>
                <div :class="[
                    'text-base font-medium',
                    currentStep === index ? 'text-primary' :
                    completedSteps.includes(index) ? 'text-gray-900' : 'text-gray-400'
                  ]">
                  {{ step.label }}
                </div>
                <!-- Nested Steps -->
                <div v-if="step.nestedSteps && index === currentStep" class="mt-2 flex flex-col space-y-2 pl-2 border-l-2 border-gray-200">
                  <template v-for="(nestedStep, nIndex) in step.nestedSteps" :key="nestedStep.ref">
                    <div class="flex items-center">
                      <div :class="[
                        'w-4 h-4 rounded-full flex items-center justify-center text-xs font-medium border',
                        currentNestedStep > nIndex ? 'bg-primary text-white' :
                        currentNestedStep === nIndex ? 'border-primary text-primary' : 'border-gray-200 text-gray-400'
                      ]">{{ nIndex + 1 }}
                      </div>
                      <div class="ml-2 text-sm" :class="[
                          currentNestedStep > nIndex ? 'text-gray-900' :
                          currentNestedStep === nIndex ? 'text-primary' : 'text-gray-400'
                        ]">
                        {{ nestedStep.label }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <button v-if="canNavigateToStep(index)" @click="$emit('stepClick', index)" class="text-sm font-medium text-primary hover:underline">
              Go
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script setup>
const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
  currentNestedStep: {
    type: Number,
    default: 0,
  },
  completedSteps: {
    type: Array,
    default: () => [],
  },
})

defineEmits(['stepClick'])

const getProgressWidth = () => {
  const baseProgress = (props.currentStep / (props.steps.length - 1))
  if (props.steps[props.currentStep]?.nestedSteps) {
    const step = props.steps[props.currentStep]
    const nestedProgress = props.currentNestedStep / (step.nestedSteps.length - 1)
    return (baseProgress * 100) + (nestedProgress * (100 / props.steps.length))
  }
  return baseProgress * 100
}

const canNavigateToStep = (index) => {
  return props.completedSteps.includes(index) || index === props.currentStep
}

const getNestedStepClasses = (index) => ({
  'border-primary bg-primary text-white': props.currentNestedStep > index,
  'border-primary text-primary': props.currentNestedStep === index,
  'border-gray-200 text-gray-400': props.currentNestedStep < index,
})

const getNestedStepLabelClass = (index) => ({
  'text-gray-900': props.currentNestedStep > index,
  'text-primary': props.currentNestedStep === index,
  'text-gray-400': props.currentNestedStep < index,
})

const getNestedConnectorClasses = (index) => ({
  'bg-primary': props.currentNestedStep > index,
  'bg-primary/50': props.currentNestedStep === index,
  'bg-gray-200': props.currentNestedStep < index,
})
</script>

<style scoped>
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
