import * as yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

// Base schema creator function
export const createBillingBaseSchema = (t) => ({
  email: yup
      .string()
      .required(t('form.errors.required'))
      .email(t('form.errors.invalidEmail')),

  identityNumber: yup
      .string()
      .required(t('form.errors.required'))
      .test('identityNumber', t('form.errors.invalidIdentityNumber'), value => {
        if (!value) return false

        const isTurkishId = /^[1-9][0-9]{10}$/.test(value)
        const isPassport = /^[A-Z0-9]{6,20}$/.test(value)
        const isGenericId = /^[A-Z0-9]{5,20}$/.test(value)

        if (isPassport || isGenericId) return true

        if (isTurkishId) {
          let oddSum = 0, evenSum = 0
          const tenthDigit = parseInt(value.charAt(9))
          const eleventhDigit = parseInt(value.charAt(10))

          for (let i = 0; i < 9; i++) {
            i % 2 === 0 ? oddSum += parseInt(value.charAt(i)) : evenSum += parseInt(value.charAt(i))
          }

          const total = oddSum * 7 - evenSum
          return (total % 10 === tenthDigit) &&
              ((oddSum + evenSum + tenthDigit) % 10 === eleventhDigit)
        }

        return true
      }),

  name: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
          t('form.errors.invalidName'),
      ),

  surname: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
          t('form.errors.invalidSurname'),
      ),

  phone: yup
      .string()
      .required(t('form.errors.required'))
      .test('phone', t('form.errors.invalidPhone'), value => {
        if (!value) return false
        try {
          const phoneNumber = parsePhoneNumberFromString(value)
          return phoneNumber ? phoneNumber.isValid() : false
        } catch {
          return false
        }
      }),

  address: yup
      .string()
      .required(t('form.errors.required'))
      .min(10, t('form.errors.minLength', { length: 10 })),

  city: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
          t('form.errors.invalidCity'),
      ),

  country: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
          t('form.errors.invalidCountry'),
      ),
})

export const createBillingSchema = (t) => {
  const schema = createBillingBaseSchema(t)
  return yup.object().shape(schema)
}

// Ortak validasyonlar için
export const createCommonValidations = (t) => ({
  requiredString: yup
      .string()
      .required(t('form.errors.required')),

  name: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿğĞıİöÖüÜşŞçÇ\s'-]+$/,
          t('form.errors.invalidName'),
      ),
})

// Validation mesajları değiştiğinde şemayı yeniden oluşturmak için
export const refreshSchema = (t) => createBillingSchema(t)