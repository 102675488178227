<template>
  <div class="bg-white rounded-lg border border-gray-200 shadow-sm flex flex-col h-full">
    <div class="p-4 border-b border-gray-200">
      <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div class="flex items-center gap-4">
          <h3>{{ t('assets.dashboard.assetTable.title') }}</h3>
          <div class="flex items-center gap-2">
            <span>{{ t('assets.dashboard.assetTable.selectedCount', { count: selectedAssets.length }) }}</span>
            <custom-button v-if="showFilterButton" color="secondary" variation="solid" :button-text="t('assets.dashboard.assetTable.applyFilter')" size="sm" @click="applyFilter"/>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 flex flex-col">
      <custom-table :data="filteredData" :columns="columns" selectable show-select-all :selected-ids="selectedAssets" @selection-change="handleSelectionChange" :is-loading="loading" class="flex-1" max-height="30vh" min-width="600px">
        <template #assetName="{ item }">
          <div class="w-full flex items-center">
            <v-tooltip :title="item.assetName" :display-condition="shouldShowTooltip(item.assetName)">
              <template #content>
                <div class="max-w-[200px] truncate">{{ item.assetName }}</div>
              </template>
            </v-tooltip>
          </div>
        </template>

        <template #assetUrl="{ item }">
          <div class="w-full flex items-center">
            <v-tooltip :title="item.assetUrl" :display-condition="shouldShowTooltip(item.assetUrl)">
              <template #content>
                <div class="max-w-[200px] truncate">{{ item.assetUrl }}</div>
              </template>
            </v-tooltip>
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from '@/plugins/axiosInstance'
import { genaralReporting } from '@/networking/urlManager'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomTable from '@/components/table/VTable.vue'
import VTooltip from '@/components/common/VTooltip.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const selectedAssets = ref([])
const initialSelection = ref([])
const tableData = ref([])
const searchText = ref('')
const loading = ref(false)

const columns = computed(() => [
  { key: 'assetName', label: t('assets.dashboard.assetTable.columns.assetName'), width: 'w-3/12' },
  { key: 'assetUrl', label: t('assets.dashboard.assetTable.columns.assetUrl'), width: 'w-3/12' },
  { key: 'contentCount', label: t('assets.dashboard.assetTable.columns.contentCount'), align: 'center' },
  { key: 'keywordCount', label: t('assets.dashboard.assetTable.columns.keywordCount'), align: 'center' },
  { key: 'suggestCount', label: t('assets.dashboard.assetTable.columns.suggestCount'), align: 'center' },
])

const shouldShowTooltip = (text) => {
  if (!text) return false
  const tempDiv = document.createElement('div')
  tempDiv.style.visibility = 'hidden'
  tempDiv.style.position = 'absolute'
  tempDiv.style.whiteSpace = 'nowrap'
  tempDiv.innerText = text
  document.body.appendChild(tempDiv)
  const width = tempDiv.offsetWidth
  document.body.removeChild(tempDiv)
  return width > 200
}

const showFilterButton = computed(() => {
  return JSON.stringify(selectedAssets.value.sort()) !== JSON.stringify(initialSelection.value.sort())
})

const filteredData = computed(() => {
  return tableData.value.filter(item =>
      item.assetName.toLowerCase().includes(searchText.value.toLowerCase()),
  )
})

const handleSelectionChange = (newSelections) => {
  selectedAssets.value = newSelections
}

const applyFilter = () => {
  emit('selectionChange', selectedAssets.value)
  initialSelection.value = [...selectedAssets.value]
}

const fetchData = () => {
  loading.value = true
  axios.get(genaralReporting.assetsGetAll)
      .then(response => {
        const data = response.data.data.map(item => ({
          id: item.id,
          assetName: item.name,
          assetUrl: item.website,
          contentCount: item.contentCount,
          keywordCount: item.keywordCount,
          suggestCount: item.suggestCount || 0,
        }))
        tableData.value = data
        emit('assetData', tableData.value)
        selectedAssets.value = data.map(item => item.id)
        initialSelection.value = [...selectedAssets.value]
        emit('selectionChange', selectedAssets.value)
      })
      .catch(error => {
        console.error('Veri çekme hatası:', error)
      })
      .finally(() => {
        loading.value = false
      })
}

const emit = defineEmits(['selectionChange', 'assetData'])

onMounted(() => {
  fetchData()
})
</script>