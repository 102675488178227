<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="close" class="flex justify-center items-center" content-class="flex flex-col bg-white w-screen h-screen">
    <!-- Header -->
    <div class="w-full flex items-center justify-between bg-primary bg-opacity-90 text-white px-4 py-3">
      <h2 class="text-sm font-medium flex items-center">
        <span class="material-symbols-outlined !text-[22px] mr-2">code</span> {{ t('entity.components.copyModal.title') }}</h2>
      <div class="flex items-center gap-4">
        <div class="hidden sm:flex items-center gap-1.5 text-white/70">
          <kbd class="px-2 py-0.5 text-xs font-semibold bg-white/20 rounded">ESC</kbd> <span class="text-xs">{{ t('entity.components.copyModal.escToClose') }}</span>
        </div>
        <button @click="close" class="flex items-center gap-2 px-3 py-1.5 rounded-lg hover:bg-white/20 active:bg-white/30 transition-all">
          <span class="hidden sm:inline text-sm">{{ t('entity.components.copyModal.close') }}</span> <span class="material-symbols-outlined !text-[22px]">close</span>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="flex-1 overflow-y-auto">
      <div class="max-w-5xl mx-auto p-6">
        <!-- Style Options -->
        <div class="mb-6 flex items-center gap-3 bg-gray-50/50 p-2 rounded-lg">
          <span class="text-sm text-gray-600">{{ t('entity.components.copyModal.styleOptions.title') }}:</span>
          <div class="flex items-center rounded-md bg-white shadow-sm border overflow-hidden">
            <button @click="styleType = 'plain'" :class="[
                  'px-2.5 py-1 text-xs flex items-center gap-1 border-r transition-colors',
                  styleType === 'plain'
                    ? 'bg-primary text-white hover:bg-primary/90'
                    : 'text-gray-600 hover:bg-gray-50'
                ]">
              <span class="material-symbols-outlined !text-base">code</span> {{ t('entity.components.copyModal.styleOptions.plain') }}
            </button>
            <button @click="styleType = 'blog'" :class="[
                  'px-2.5 py-1 text-xs flex items-center gap-1 transition-colors',
                  styleType === 'blog'
                    ? 'bg-primary text-white hover:bg-primary/90'
                    : 'text-gray-600 hover:bg-gray-50'
                ]">
              <span class="material-symbols-outlined !text-base">article</span> {{ t('entity.components.copyModal.styleOptions.blog') }}
            </button>
          </div>
        </div>

        <!-- Meta Elements Grid -->
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
          <!-- Meta Title -->
          <div class="bg-white border rounded-lg shadow-sm group">
            <div class="p-3 bg-gray-50 border-b flex justify-between items-center">
              <div class="flex items-center gap-2">
                <span class="material-symbols-outlined text-primary">{{ metaSections[0].icon }}</span>
                <h3 class="font-medium text-gray-700">{{ t(`entity.components.copyModal.sections.${metaSections[0].id}.title`) }}</h3>
              </div>
              <CustomButton @click="copySection(metaSections[0].id)" color="secondary" size="sm" :show-icon="true" :button-text="copied === metaSections[0].id ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
            </div>
            <div class="p-3">
              <div class="font-mono text-sm bg-gray-50 p-3 rounded max-h-32 overflow-y-auto">
                {{ metaSections[0].content }}
              </div>
            </div>
          </div>

          <!-- Meta Description -->
          <div class="bg-white border rounded-lg shadow-sm group">
            <div class="p-3 bg-gray-50 border-b flex justify-between items-center">
              <div class="flex items-center gap-2">
                <span class="material-symbols-outlined text-primary">{{ metaSections[1].icon }}</span>
                <h3 class="font-medium text-gray-700">{{ t(`entity.components.copyModal.sections.${metaSections[1].id}.title`) }}</h3>
              </div>
              <CustomButton @click="copySection(metaSections[1].id)" color="secondary" size="sm" :show-icon="true" :button-text="copied === metaSections[1].id ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
            </div>
            <div class="p-3">
              <div class="font-mono text-sm bg-gray-50 p-3 rounded max-h-32 overflow-y-auto">
                {{ metaSections[1].content }}
              </div>
            </div>
          </div>

          <!-- Meta Keywords - Full Width -->
          <div class="col-span-1 sm:col-span-2 bg-white border rounded-lg shadow-sm group">
            <div class="p-3 bg-gray-50 border-b flex justify-between items-center">
              <div class="flex items-center gap-2">
                <span class="material-symbols-outlined text-primary">{{ metaSections[2].icon }}</span>
                <h3 class="font-medium text-gray-700">{{ t(`entity.components.copyModal.sections.${metaSections[2].id}.title`) }}</h3>
              </div>
              <CustomButton @click="copySection(metaSections[2].id)" color="secondary" size="sm" :show-icon="true" :button-text="copied === metaSections[2].id ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
            </div>
            <div class="p-3">
              <div class="font-mono text-sm bg-gray-50 p-3 rounded max-h-32 overflow-y-auto">
                {{ metaSections[2].content }}
              </div>
            </div>
          </div>
        </div>

        <!-- Images Section -->
        <div v-if="formattedImages.length > 0" class="mb-6">
          <div class="bg-white border rounded-lg shadow-sm">
            <div class="p-3 bg-gray-50 border-b flex justify-between items-center">
              <div class="flex items-center gap-2">
                <span class="material-symbols-outlined text-primary">image</span>
                <h3 class="font-medium text-gray-700">{{ t('entity.components.copyModal.sections.images.title') }}</h3>
              </div>
            </div>
            <div class="p-3">
              <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
                <div v-for="(image, index) in formattedImages" :key="index" class="relative group cursor-pointer" @click="openImageInNewTab(image.url)">
                  <img :src="image.url" :alt="props.content.title" class="w-full h-40 object-cover rounded-lg border"/>
                  <div class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg">
                    <span class="material-symbols-outlined text-white mr-2">open_in_new</span> <span class="text-white text-sm">{{ t('general.view') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Blog Style Section -->
        <div v-if="styleType === 'blog'" class="mb-6">
          <div class="bg-white border rounded-lg shadow-sm">
            <div class="p-3 bg-gray-50 border-b flex justify-between items-center">
              <div class="flex items-center gap-2">
                <span class="material-symbols-outlined text-primary">brush</span>
                <h3 class="font-medium text-gray-700">{{ t('entity.components.copyModal.sections.styles.title') }}</h3>
              </div>
              <CustomButton @click="copySection('styles')" color="secondary" size="sm" :show-icon="true" :button-text="copied === 'styles' ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
            </div>
            <div class="p-3">
              <div class="font-mono text-sm bg-gray-50 p-3 rounded max-h-64 overflow-y-auto">
                {{ blogStyles }}
              </div>
            </div>
          </div>
        </div>

        <!-- Content Section -->
        <div class="bg-gray-900 rounded-lg overflow-hidden">
          <div class="p-3 bg-gray-800 flex items-center justify-between">
            <div class="flex items-center gap-2">
              <span class="material-symbols-outlined text-blue-400">description</span> <span class="text-white font-medium">{{ t('entity.components.copyModal.content') }}</span>
            </div>
            <CustomButton @click="copyContent" color="secondary" size="sm" :show-icon="true" :button-text="copied === 'content' ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
          </div>
          <div class="p-4">
            <pre class="text-sm font-mono text-gray-300">{{ formattedContent }}</pre>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="w-full border-t bg-white">
      <div class="max-w-5xl mx-auto px-6 py-3">
        <div class="flex items-center justify-end">
          <CustomButton @click="copyContent" color="primary" size="sm" :show-icon="true" :button-text="copied === 'content' ? t('entity.components.copyModal.copied') : t('entity.components.copyModal.copy')" icon="content_copy"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  content: {
    type: Object,
    required: true,
    default: () => ({
      title: '',
      body: '',
      keywords: '',
      metaDescription: '',
      images: '',
    }),
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const copied = ref('')
const styleType = ref('plain')

// Image handling functions
const handleImageUrls = (images) => {
  let imageUrls = images

  if (images === null) {
    imageUrls = []
  } else if (typeof images === 'string') {
    try {
      imageUrls = JSON.parse(images)
    } catch (error) {
      console.error('Failed to parse images:', error)
      imageUrls = []
    }
  }

  return Array.isArray(imageUrls) ? imageUrls : []
}

const getImageSrc = (item) => {
  if (!item) return ''
  return typeof item === 'object' ? item.image || item.url || '' : item
}

// Modified image handling for downloads
const formattedImages = computed(() => {
  const images = handleImageUrls(props.content.images)
  return images.map(img => ({
    url: getImageSrc(img),
    storageUrl: typeof img === 'object' ? img.storage || img.url : img,
  })).filter(img => img.url)
})

// Formatted content with title and optional blog styling
const formattedContent = computed(() => {
  const contentWithTitle = `<h1>${props.content.title}</h1>\n${props.content.body}`

  return styleType.value === 'blog'
      ? `<div class="blog-content">\n  ${contentWithTitle}\n</div>`
      : contentWithTitle
})

const blogStyles = `<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .blog-content {
    max-width: 768px;
    margin: 0 auto;
    padding: 2rem;
    font-family: system-ui, -apple-system, sans-serif;
    line-height: 1.6;
    color: #374151;
  }
  .blog-content h1 {
    font-size: 2.5rem;
    font-weight: 800;
    margin: 2rem 0;
    color: #111827;
    line-height: 1.2;
  }
  .blog-content h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 1.5rem 0;
    color: #1F2937;
  }
  .blog-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1.25rem 0;
    color: #374151;
  }
  .blog-content p {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75;
  }
  .blog-content img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin: 2rem 0;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  .blog-content a {
    color: #2563eb;
    text-decoration: underline;
  }
  .blog-content a:hover {
    color: #1d4ed8;
  }
  .blog-content ul, .blog-content ol {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
  }
  .blog-content li {
    margin-bottom: 0.5rem;
  }
  .blog-content blockquote {
    border-left: 4px solid #e5e7eb;
    padding-left: 1rem;
    margin: 1.5rem 0;
    color: #6b7280;
    font-style: italic;
  }
  @media (max-width: 768px) {
    .blog-content {
      padding: 1rem;
    }
    .blog-content h1 {
      font-size: 2rem;
    }
    .blog-content h2 {
      font-size: 1.75rem;
    }
    .blog-content h3 {
      font-size: 1.5rem;
    }
  }
</style>`

// Meta Sections
const metaSections = computed(() => {
  return [
    {
      id: 'meta-title',
      title: t('entity.components.copyModal.sections.metaTitle.title'),
      icon: 'sell',
      content: `<meta name="title" content="${props.content.title}">`,
    },
    {
      id: 'meta-desc',
      title: t('entity.components.copyModal.sections.metaDesc.title'),
      icon: 'description',
      content: `<meta name="description" content="${props.content.metaDescription}">`,
    },
    {
      id: 'meta-keywords',
      title: t('entity.components.copyModal.sections.metaKeywords.title'),
      icon: 'key',
      content: `<meta name="keywords" content="${props.content.keywords}">`,
    },
  ]
})

const copyText = async (text, section) => {
  try {
    await navigator.clipboard.writeText(text)
    copied.value = section
    setTimeout(() => copied.value = '', 2000)
  } catch (err) {
    console.error('Failed to copy:', err)
  }
}

const copySection = (sectionId) => {
  if (sectionId === 'styles') {
    copyText(blogStyles, 'styles')
  } else {
    const section = metaSections.value.find(s => s.id === sectionId)
    if (section) {
      copyText(section.content, sectionId)
    }
  }
}

const copyContent = () => copyText(formattedContent.value, 'content')

// Image opening functions
const openImageInNewTab = (url) => {
  if (url && url.trim() !== '') {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
}

const close = () => emit('update:modelValue', false)
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.group:hover img {
  filter: brightness(0.7);
  transition: filter 0.3s ease;
}
</style>