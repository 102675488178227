<template>
  <form id="billing-form" autocomplete="on" @submit.prevent class="mb-8 border rounded-xl p-6">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div v-for="field in fields" :key="field.name">
        <custom-input v-model="form[field.name]" :label="t(`form.${field.name}`)" :type="field.type" :name="field.name" :autocomplete="field.autocomplete" :required="true" :error="errors[field.name]" :placeholder="field.placeholder || t(`form.${field.name}Placeholder`)" @update:modelValue="value => handleInput(field.name, value)" @blur="() => handleBlur(field.name)" @focus="() => handleFocus(field.name)"/>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import CustomInput from '@/components/base/inputs/VInput.vue'
import { createBillingSchema } from '@/validations/schemas/asset.create.billing.schema'

const props = defineProps({
  currentStep: {
    type: Number,
    default: 0,
  },
})

const assetStore = useAssetStore()
const { t } = useI18n()
const emit = defineEmits(['stepValidation'])

const form = ref({})
const errors = ref({})
const touched = ref(new Set())
const isValidating = ref(false)

const fields = [
  { name: 'email', type: 'email', autocomplete: 'email' },
  { name: 'identityNumber', type: 'text', autocomplete: 'off' },
  { name: 'name', type: 'text', autocomplete: 'given-name' },
  { name: 'surname', type: 'text', autocomplete: 'family-name' },
  { name: 'phone', type: 'tel', autocomplete: 'tel', placeholder: '+90' },
  { name: 'address', type: 'text', autocomplete: 'street-address' },
  { name: 'city', type: 'text', autocomplete: 'address-level2' },
  { name: 'country', type: 'text', autocomplete: 'country-name' },
]

const schema = computed(() => createBillingSchema(t))
const storedData = computed(() => assetStore.assetData.paymentDetails?.formContent)

const handleInput = async (field, value) => {
  form.value[field] = value

  await assetStore.setPaymentDetails({
    formContent: { ...form.value },
  })

  if (touched.value.has(field)) await validateField(field)
}

const handleBlur = async (field) => {
  touched.value.add(field)
  await validateField(field)
}

const handleFocus = (field) => {
  touched.value.add(field)
  if (errors.value[field]) {
    errors.value = { ...errors.value, [field]: '' }
  }
}

const validateField = async (field) => {
  try {
    await schema.value.validateAt(field, form.value)
    errors.value = { ...errors.value, [field]: '' }
    return true
  } catch (error) {
    errors.value = { ...errors.value, [field]: error.message }
    return false
  }
}

const validateForm = async (showAllErrors = false) => {
  if (isValidating.value) return false

  isValidating.value = true
  let isValid = false

  try {
    await schema.value.validate(form.value, { abortEarly: false })
    errors.value = {}
    isValid = true
  } catch (error) {
    const newErrors = {}
    error.inner?.forEach(err => {
      if (showAllErrors || touched.value.has(err.path)) {
        newErrors[err.path] = err.message
      }
    })
    errors.value = { ...newErrors }
    isValid = false
  } finally {
    isValidating.value = false
  }

  emit('stepValidation', { isValid, errors: { ...errors.value } })
  return isValid
}

const initForm = () => {
  const initialData = storedData.value ||
      fields.reduce((acc, field) => ({
        ...acc,
        [field.name]: field.name === 'phone' ? '+90' : '',
      }), {})

  form.value = { ...initialData }
  errors.value = {}
  touched.value.clear()
}

watch(() => storedData.value, (newData) => {
  if (newData) {
    form.value = { ...newData }
    validateForm(true)
  }
}, { deep: true })

watch(() => form.value, () => {
  if (Object.keys(touched.value).length > 0) {
    validateForm()
  }
}, { deep: true })

onMounted(() => {
  initForm()
  validateForm(false)
})

defineExpose({
  validate: () => validateForm(true),
  reset: initForm,
})
</script>