<template>
  <div>
    <h1 class="text-xl font-semibold text-slate-900">{{ title }}</h1>
    <p class="text-sm text-slate-500 mt-1">
      {{ category || t('entity.components.contentReview.noCategoryProvided') }} </p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps({
  title: String,
  category: String,
})
</script>