<template>
  <div class="calendar-header flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
    <div class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
      <div class="month-year-container">
        <h2 class="text-xl sm:text-2xl text-center sm:text-left inline-flex items-baseline">
          <span class="font-semibold mr-2">{{ currentMonthYear.split(' ')[0] }}</span> <span class="font-light">{{ currentMonthYear.split(' ').slice(1).join(' ') }}</span>
        </h2>
      </div>
      <DatePicker v-model="modelValue" :markedDates="markedDates" @update:modelValue="handleDateChange">
        <template #trigger="{ toggleDatePicker }">
          <button @click="toggleDatePicker" class="text-secondary text-sm px-3 py-1 rounded border shadow-sm flex items-center justify-center w-full sm:w-auto">
            <span class="material-symbols-outlined align-middle mr-1 !text-[16px]">
              calendar_today
            </span> <span>{{ t('scheduleAssistant.components.calendar.selectDate') }}</span>
          </button>
        </template>
      </DatePicker>
    </div>

    <div class="flex items-center space-x-2 w-full sm:w-auto justify-center sm:justify-end">
      <button @click="handleWeekChange(-1)" class="nav-button" :disabled="loading">
        <span class="material-symbols-outlined">chevron_left</span>
      </button>

      <button @click="$emit('set-today')" class="today-button px-3 py-1 bg-secondary text-white rounded-md text-xs" :disabled="loading">
        Today
      </button>

      <button @click="handleWeekChange(1)" class="nav-button" :disabled="loading">
        <span class="material-symbols-outlined">chevron_right</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { dateHelper } from '@/utils/dateHelper'
import DatePicker from '@/components/base/inputs/VDatePicker.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  currentMonthYear: String,
  currentDate: Object,
  markedDates: Array,
  loading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const emit = defineEmits(['change-week', 'set-today', 'update:modelValue'])

const modelValue = computed({
  get: () => props.currentDate,
  set: (value) => emit('update:modelValue', value),
})

const handleDateChange = (newDate) => {
  emit('update:modelValue', newDate)
}

const handleWeekChange = (direction) => {
  const currentMonth = dateHelper.format(props.currentDate, 'M')
  const newDate = dateHelper.add(props.currentDate, direction, 'week')
  const newMonth = dateHelper.format(newDate, 'M')

  emit('change-week', {
    direction,
    monthChanged: currentMonth !== newMonth,
  })
}
</script>

<style scoped>
.nav-button {
  @apply text-gray-400 hover:text-secondary p-1 flex items-center transition-colors duration-200;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.nav-button:disabled:hover {
  @apply text-gray-400;
}

.month-year-container {
  @apply flex flex-row items-baseline whitespace-nowrap;
}

.today-button {
  @apply transition-opacity duration-200;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}
</style>