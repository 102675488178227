import { ref, computed } from 'vue'
import { KeywordUtils } from '@/utils/keywordUtils'
import { responseHandlerService } from '@/plugins/responseHandlerService'

export function useKeywords(props, emit, t) {
  const selectedKeywords = ref([])
  const selectedKeyword = ref(null)
  const showEditModal = ref(false)

  const keywordsTableData = computed(() => {
    const keywordMap = new Map()

    props.keywords
        .filter(keyword => keyword.state !== 3)
        .forEach(keyword => {
          const normalizedName = KeywordUtils.normalize(keyword.name)
          const existing = keywordMap.get(normalizedName)

          if (!existing || keyword.state > existing.state ||
              (keyword.state === existing.state &&
                  (keyword.id > existing.id || keyword.keywordId > existing.keywordId))) {
            keywordMap.set(normalizedName, keyword)
          }
        })

    return Array.from(keywordMap.values())
        .map(keyword => ({
          id: keyword.id || keyword.keywordId,
          keywordId: keyword.keywordId,
          name: keyword.name,
          state: keyword.state,
          priority: keyword.priority,
          isNew: keyword.state === 2,
        }))
        .sort((a, b) => {
          if (a.priority !== b.priority) {
            return (b.priority || 0) - (a.priority || 0)
          }
          return a.name.localeCompare(b.name)
        })
  })

  const activeKeywordsCount = computed(() => keywordsTableData.value.length)

  const handleSelectionChange = (newSelection) => {
    selectedKeywords.value = newSelection
  }

  const handleBulkDelete = () => {
    if (!selectedKeywords.value.length) return

    const updatedKeywords = props.keywords.map(k => {
      if (selectedKeywords.value.includes(k.id || k.keywordId)) {
        if (k.state === 2) {
          return null
        }
        return { ...k, state: 3, priority: null }
      }
      return k
    }).filter(k => k !== null)

    emit('update:keywords', updatedKeywords)
    responseHandlerService.successHandler(
        t('settings.components.configuration.components.keywordsTable.bulkDeleteSuccess', {
          count: selectedKeywords.value.length,
        }),
    )
    selectedKeywords.value = []
  }

  const handleEdit = (keyword) => {
    selectedKeyword.value = { ...keyword }
    showEditModal.value = true
  }

  const handleEditKeyword = (editedKeyword) => {
    const similarKeyword = KeywordUtils.findSimilarInArray(
        editedKeyword.name,
        props.keywords.filter(k =>
            k.state !== 3 &&
            ((k.id && k.id !== editedKeyword.id) ||
                (k.keywordId && k.keywordId !== editedKeyword.keywordId) ||
                (k.state === 2 && k.name !== editedKeyword.name)),
        ),
    )

    if (similarKeyword) {
      responseHandlerService.errorHandler(t('settings.components.configuration.components.keywordsTable.duplicateKeyword'))
      return
    }

    const updatedKeywords = props.keywords.map(k => {
      if ((k.id && k.id === editedKeyword.id) ||
          (k.keywordId && k.keywordId === editedKeyword.keywordId) ||
          (k.state === 2 && k.name === editedKeyword.name)) {

        const isChanged = k.name !== editedKeyword.name || k.priority !== editedKeyword.priority

        return {
          ...k,
          name: editedKeyword.name.trim(),
          priority: editedKeyword.priority,
          state: k.state === 1 && isChanged ? 4 : k.state,
        }
      }
      return k
    })

    emit('update:keywords', updatedKeywords)
    responseHandlerService.successHandler(
        t('settings.components.configuration.components.keywordsTable.keywordUpdated', {
          keyword: editedKeyword.name,
        }),
    )
    showEditModal.value = false
  }

  const handleAddKeywords = (newKeywords) => {
    if (activeKeywordsCount.value + newKeywords.length > props.maxKeywords) {
      responseHandlerService.errorHandler(
          t('settings.components.configuration.components.keywordsTable.keywordLimitExceeded', {
            max: props.maxKeywords,
          }),
      )
      return
    }

    // Keyword validasyonu
    const invalidKeywords = newKeywords.filter(k => k.name.trim().length < 2)
    if (invalidKeywords.length > 0) {
      responseHandlerService.errorHandler(
          t('settings.components.configuration.validation.keyword.minLength'),
      )
      return
    }

    const updatedKeywords = [...props.keywords]
    const results = { new: 0, reactivated: 0, updated: 0 }

    newKeywords.forEach(newKeyword => {
      const existingKeyword = KeywordUtils.findSimilarInArray(
          newKeyword.name,
          updatedKeywords.filter(k => k.state !== 3),
      )

      if (existingKeyword) {
        results.unchanged++
      } else {
        updatedKeywords.push({
          name: newKeyword.name.trim(),
          priority: newKeyword.priority,
          state: 2,
        })
        results.new++
      }
    })

    if (results.new > 0) {
      emit('update:keywords', updatedKeywords)
      Object.entries(results).forEach(([type, count]) => {
        if (count > 0 && type !== 'unchanged') {
          responseHandlerService.successHandler(
              t(`settings.components.configuration.components.keywordsTable.keywords${type.charAt(0).toUpperCase() + type.slice(1)}`,
                  { count },
              ),
          )
        }
      })
    }
  }

  const removeKeyword = (keyword) => {
    try {
      let updatedKeywords = [...props.keywords]
      const keywordIndex = updatedKeywords.findIndex(k =>
          (k.id && k.id === keyword.id) ||
          (k.keywordId && k.keywordId === keyword.keywordId) ||
          (k.state === 2 && k.name === keyword.name),
      )

      if (keywordIndex === -1) {
        console.error('Keyword not found:', keyword)
        return
      }

      const keywordName = keyword.name

      if (updatedKeywords[keywordIndex].state === 2) {
        updatedKeywords = updatedKeywords.filter((_, index) => index !== keywordIndex)
      } else {
        updatedKeywords[keywordIndex] = {
          ...updatedKeywords[keywordIndex],
          state: 3,
          priority: null,
        }
      }

      emit('update:keywords', updatedKeywords)
      responseHandlerService.successHandler(
          t('settings.components.configuration.components.keywordsTable.keywordDeleted', {
            keyword: keywordName,
          }),
      )

    } catch (error) {
      console.error('Error removing keyword:', error)
      responseHandlerService.errorHandler(
          t('settings.components.configuration.components.keywordsTable.deleteError', {
            keyword: keyword.name,
          }),
      )
    }
  }

  const resetSelection = () => {
    selectedKeywords.value = []
    showEditModal.value = false
    selectedKeyword.value = null
  }

  return {
    selectedKeywords,
    selectedKeyword,
    showEditModal,
    keywordsTableData,
    activeKeywordsCount,
    handleSelectionChange,
    handleBulkDelete,
    handleEdit,
    handleEditKeyword,
    handleAddKeywords,
    removeKeyword,
    resetSelection,
  }
}