<template>
  <div class="relative flex-none w-full" v-click-outside="closeDatePicker">
    <div @click="toggleDatePicker" class="h-full">
      <slot name="trigger">
        <button class="w-full h-full inline-flex items-center justify-between px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 transition-colors duration-200">
          <span class="text-sm font-medium text-gray-700 truncate">{{ localizedCurrentMonthYear }}</span> <span class="material-symbols-outlined text-gray-400 flex-none ml-2">expand_more</span>
        </button>
      </slot>
    </div>

    <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
      <div v-if="isDatePickerVisible" class="absolute right-0 mt-2 z-40">
        <div class="bg-white rounded-lg shadow-lg border border-gray-200 w-64 p-4" @click.stop>
          <!-- Header -->
          <div class="flex justify-between items-center mb-4">
            <button @click="prevMonth" class="text-gray-600 hover:text-gray-800 focus:outline-none">
              <span class="material-symbols-outlined">chevron_left</span>
            </button>
            <div class="flex space-x-2">
              <button @click="toggleMonthSelector" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                {{ localizedMonth }}
              </button>
              <button @click="toggleYearSelector" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                {{ currentDate.year() }}
              </button>
            </div>
            <button @click="nextMonth" class="text-gray-600 hover:text-gray-800 focus:outline-none">
              <span class="material-symbols-outlined">chevron_right</span>
            </button>
          </div>

          <!-- Month Selector -->
          <div v-if="showMonthSelector" class="mb-4 grid grid-cols-3 gap-2">
            <button v-for="(month, index) in localizedMonthNames" :key="index" @click="selectMonth(index)" class="px-2 py-1 text-sm rounded-md hover:bg-primary hover:text-white transition-colors duration-150" :class="{ 'bg-primary text-white': index === currentDate.month() }">
              {{ month }}
            </button>
          </div>

          <!-- Calendar Grid -->
          <div v-if="!showMonthSelector && !showYearSelector" class="mt-2">
            <div class="grid grid-cols-7 gap-1 mb-2">
              <div v-for="day in localizedDaysOfWeek" :key="day" class="text-center text-xs font-medium text-gray-500">
                {{ day.slice(0, 1) }}
              </div>
            </div>

            <div class="grid grid-cols-7 gap-1">
              <div v-for="{ date, isCurrentMonth, isToday } in calendarDays" :key="date.toISOString()" @click="selectDate(date)" class="h-8 flex items-center justify-center text-sm rounded-full cursor-pointer transition-colors duration-200" :class="{
                  'text-gray-400': !isCurrentMonth,
                  'bg-primary text-white': isToday,
                  'hover:bg-gray-100': isCurrentMonth && !isToday,
                  'text-gray-800': isCurrentMonth && !isToday
                }">
                {{ date.date() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  markedDates: {
    type: Array,
    default: () => [],
  },
  monthYearOnly: {
    type: Boolean,
    default: false,
  },
  minYear: {
    type: Number,
    default: () => dayjs().year() - 3,
  },
  maxYear: {
    type: Number,
    default: () => dayjs().year(),
  },
})

const emit = defineEmits(['update:modelValue'])

const { t, locale, tm } = useI18n()

const currentDate = ref(dayjs(props.modelValue))
const isDatePickerVisible = ref(false)
const showMonthSelector = ref(false)
const showYearSelector = ref(false)

const localizedMonthNames = computed(() => tm('general.monthNames'))
const localizedDaysOfWeek = computed(() => t('general.daysShort').split('_'))

const localizedCurrentMonthYear = computed(() => {
  return currentDate.value.locale(locale.value).format('MMMM YYYY')
})

const localizedMonth = computed(() => {
  return currentDate.value.locale(locale.value).format('MMMM')
})

const yearRange = computed(() => {
  return Array.from({ length: props.maxYear - props.minYear + 1 }, (_, i) => props.minYear + i)
})

const calendarDays = computed(() => {
  const startOfMonth = currentDate.value.startOf('month')
  const endOfMonth = currentDate.value.endOf('month')
  const startDate = startOfMonth.startOf('week')
  const endDate = endOfMonth.endOf('week')

  const days = []
  let day = startDate

  while (day.isBefore(endDate) || day.isSame(endDate, 'day')) {
    const isCurrentMonth = day.month() === currentDate.value.month()
    const isToday = day.isSame(dayjs(), 'day')
    const hasEvent = props.markedDates.some(markedDate =>
        dayjs(markedDate).isSame(day, 'day'),
    )

    days.push({ date: day, isCurrentMonth, isToday, hasEvent })
    day = day.add(1, 'day')
  }

  return days
})

const closeDatePicker = () => {
  isDatePickerVisible.value = false
  showMonthSelector.value = false
  showYearSelector.value = false
}

const prevMonth = () => {
  currentDate.value = currentDate.value.subtract(1, 'month')
}

const nextMonth = () => {
  currentDate.value = currentDate.value.add(1, 'month')
}

const selectDate = (date) => {
  if (props.monthYearOnly) return
  emit('update:modelValue', date)
  closeDatePicker()
}

const toggleDatePicker = () => {
  isDatePickerVisible.value = !isDatePickerVisible.value
  if (!isDatePickerVisible.value) {
    showMonthSelector.value = false
    showYearSelector.value = false
  }
}

const toggleMonthSelector = () => {
  showMonthSelector.value = !showMonthSelector.value
  if (showMonthSelector.value) {
    showYearSelector.value = false
  }
}

const toggleYearSelector = () => {
  showYearSelector.value = !showYearSelector.value
  if (showYearSelector.value) {
    showMonthSelector.value = false
  }
}

const selectMonth = (monthIndex) => {
  currentDate.value = currentDate.value.month(monthIndex)
  showMonthSelector.value = false
  if (props.monthYearOnly) {
    emit('update:modelValue', currentDate.value)
  }
}

const selectYear = (year) => {
  currentDate.value = currentDate.value.year(year)
  showYearSelector.value = false
  if (props.monthYearOnly) {
    emit('update:modelValue', currentDate.value)
  }
}

watch(() => props.modelValue, (newValue) => {
  currentDate.value = dayjs(newValue)
})

watch(() => locale.value, () => {
  currentDate.value = currentDate.value.locale(locale.value)
})
</script>