<template>
  <div class="bg-white rounded-xl shadow-sm p-6">
    <h2 class="text-lg font-semibold mb-4 flex items-center gap-2">
      <span class="material-symbols-outlined text-primary">image</span> {{ t('entity.components.contentReview.mediaGallery') }} </h2>
    <ImageComponent :image-urls="imageUrls" :content-loading="isLoading" :is-image-uploadable="false" class="w-full "/>
  </div>
</template>

<script setup>
import ImageComponent from '@/components/common/VImage.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  imageUrls: Array,
  isLoading: Boolean,
})
</script>