<template>
  <div class="h-[280px] flex items-center justify-center">
    <!-- Error State -->
    <template v-if="hasError">
      <div class="flex flex-col items-center gap-4">
        <div class="w-16 h-16 bg-red-50 rounded-full flex items-center justify-center animate-pulse">
          <span class="material-symbols-outlined text-red-600 !text-5xl">error</span>
        </div>
        <div class="text-center">
          <p class="text-gray-800 font-medium mb-2">{{ t('assets.dashboard.aiReport.error.title') }}</p>
          <p class="text-gray-600 text-sm mb-4">{{ t('assets.dashboard.aiReport.error.message') }}</p>
          <custom-button @click="$emit('retry')" color="primary" size="md" :button-text="t('assets.dashboard.aiReport.error.retry')"/>
        </div>
      </div>
    </template>

    <!-- Loading State -->
    <template v-else-if="isLoading">
      <AIAnimation>
        <p class="text-gray-600 font-medium text-center">
          {{ t('assets.dashboard.aiReport.loading') }} </p>
        <LoadingDots/>
      </AIAnimation>
    </template>

    <!-- Ready State -->
    <template v-else-if="isReady">
      <div class="flex flex-col items-center justify-center h-full w-full">
        <div class="flex flex-col items-center justify-center gap-4">
          <div class="w-16 h-16 bg-emerald-50 rounded-full flex items-center justify-center animate-bounce-slow">
            <span class="material-symbols-outlined text-emerald-500 !text-5xl">task_alt</span>
          </div>
          <div class="text-center space-y-3">
            <div class="space-y-1">
              <p class="text-gray-800 font-medium text-xl">{{ t('assets.dashboard.aiReport.ready.title') }}</p>
              <p class="text-gray-500 text-sm">{{ t('assets.dashboard.aiReport.ready.message') }}</p>
            </div>
            <custom-button @click="$emit('view-report')" color="primary" size="md" variation="solid" class="w-full" :button-text="t('assets.dashboard.aiReport.ready.view')"/>
          </div>
        </div>
      </div>
    </template>

    <!-- Waiting State -->
    <template v-else>
      <AIAnimation>
        <p class="text-gray-600 font-medium text-center">
          {{ t('assets.dashboard.aiReport.waiting') }} </p>
        <LoadingDots/>
      </AIAnimation>
    </template>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import AIAnimation from '@/components/features/ai/AiAssistantAnimation.vue'
import LoadingDots from '@/components/loading/LoadingDots.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()

defineProps({
  isLoading: Boolean,
  hasError: Boolean,
  isReady: Boolean,
})

defineEmits(['retry', 'view-report'])
</script>