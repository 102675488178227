<template>
  <div class="relative" data-menu="language" v-click-outside="handleClickOutside">
    <!-- Icon Type -->
    <button v-if="props.type === 'icon'" @click="toggleMenu('language')" class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 focus:outline-none transition-colors" :class="menuStore.isOpen('language') ? 'text-primary bg-gray-100' : 'text-gray-600'">
      <span class="material-symbols-outlined !text-[18px]">language</span>
    </button>

    <!-- Text Type -->
    <button v-else @click="toggleMenu('language')" class="flex items-center space-x-1 px-2 py-1 rounded hover:bg-gray-100 focus:outline-none transition-colors" :class="menuStore.isOpen('language') ? 'text-primary bg-gray-100' : 'text-gray-600'">
      <span class="text-sm font-medium uppercase">{{ currentLanguage }}</span>
      <span class="material-symbols-outlined !text-[18px] transition-transform" :class="{ 'rotate-180': menuStore.isOpen('language') }">
        expand_more
      </span>
    </button>

    <!-- Dropdown Menu -->
    <div v-if="menuStore.isOpen('language')" class="absolute right-0 mt-1 z-[100] min-w-[160px] p-1 bg-white rounded-md shadow-[0_2px_16px_-2px_rgba(0,0,0,0.1)] border border-black/[0.06]">
      <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="language-menu">
        <a v-for="lang in languages" :key="lang.code" href="#" @click.prevent="changeLanguage(lang)" class="flex items-center justify-between px-3 py-1.5 text-xs hover:bg-gray-100 transition-colors" :class="currentLanguage === lang.code ? 'text-primary' : 'text-gray-700'" role="menuitem">
          <div class="flex items-center gap-3">
            <span class="uppercase text-xs text-gray-400">{{ lang.code }}</span> <span>{{ lang.name }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUIStore } from '@/stores/ui'
import { useMenuStore } from '@/stores/menuStore'
import { useDashboardStore } from '@/stores/dashboardOfAssets'

const props = defineProps({
  type: {
    type: String,
    default: 'icon',
    validator: (value) => ['icon', 'text'].includes(value),
  },
})

const uiStore = useUIStore()
const dashboardStore = useDashboardStore()
const i18n = useI18n()
const menuStore = useMenuStore()

const languages = [
  { code: 'en', name: 'English', langId: 1 },
  { code: 'tr', name: 'Türkçe', langId: 9 },
]

const currentLanguage = computed(() => i18n.locale.value)

const toggleMenu = (menuId) => {
  menuStore.toggleMenu(menuId)
}

const handleClickOutside = (event) => {
  const isClickInsideAnyMenu = event.target.closest('[data-menu]')
  if (!isClickInsideAnyMenu) {
    menuStore.closeAll()
  }
}

const changeLanguage = (lang) => {
  i18n.locale.value = lang.code
  uiStore.setLanguage(lang.code)
  dashboardStore.setLanguage(lang.langId)
  menuStore.closeAll()
}
</script>