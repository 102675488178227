import { defineStore } from 'pinia'
import { encryptedStorage } from '@/utils/encryption'

const defaultStepState = {
  currentStepIndex: 0,
  currentNestedStepIndex: 0,
  lastCompletedStepIndex: -1,
  lastCompletedNestedStepIndex: -1,
  stepValidations: {},
  stepData: {},
  navigation: {
    prevDisabled: false,
    nextDisabled: false,
  },
}

export const useStepsStore = defineStore('steps', {
  state: () => ({
    currentStepIndex: 0,
    currentNestedStepIndex: 0,
    lastCompletedStepIndex: -1,
    lastCompletedNestedStepIndex: -1,
    stepValidations: {},
    stepData: {},
    navigation: {
      prevDisabled: false,
      nextDisabled: false,
    },
  }),

  actions: {
    setCurrentStep({ stepIndex, nestedStepIndex }) {
      this.$patch((state) => {
        if (typeof stepIndex !== 'undefined') {
          state.currentStepIndex = stepIndex
        }
        if (typeof nestedStepIndex !== 'undefined') {
          state.currentNestedStepIndex = nestedStepIndex
        }
      })
    },

    setStepValidation({ stepKey, isValid, errors = {} }) {
      this.$patch((state) => {
        state.stepValidations[stepKey] = {
          isValid,
          errors: { ...errors },
        }
      })
    },

    updateStepCompletion({ stepIndex, nestedStepIndex }) {
      this.$patch((state) => {
        if (typeof stepIndex !== 'undefined') {
          state.lastCompletedStepIndex = stepIndex
        }
        if (typeof nestedStepIndex !== 'undefined') {
          state.lastCompletedNestedStepIndex = nestedStepIndex
        }
      })
    },

    setStepData({ stepKey, data }) {
      this.$patch((state) => {
        state.stepData[stepKey] = data
      })
    },

    updateNavigation({ prevDisabled, nextDisabled }) {
      this.$patch((state) => {
        state.navigation = {
          prevDisabled: typeof prevDisabled !== 'undefined' ? prevDisabled : state.navigation.prevDisabled,
          nextDisabled: typeof nextDisabled !== 'undefined' ? nextDisabled : state.navigation.nextDisabled,
        }
      })
    },

    resetStepManagement(initialState = {}) {
      this.$patch((state) => {
        state.currentStepIndex = initialState.currentStepIndex ?? 0
        state.currentNestedStepIndex = initialState.currentNestedStepIndex ?? 0
        state.lastCompletedStepIndex = initialState.lastCompletedStepIndex ?? -1
        state.lastCompletedNestedStepIndex = initialState.lastCompletedNestedStepIndex ?? -1
        state.stepValidations = initialState.stepValidations ?? {}
        state.stepData = initialState.stepData ?? {}
        state.navigation = {
          prevDisabled: initialState.navigation?.prevDisabled ?? false,
          nextDisabled: initialState.navigation?.nextDisabled ?? false,
        }
      })
    },

    clearStepData(stepKey) {
      this.$patch((state) => {
        const newStepData = { ...state.stepData }
        const newStepValidations = { ...state.stepValidations }
        delete newStepData[stepKey]
        delete newStepValidations[stepKey]

        state.stepData = newStepData
        state.stepValidations = newStepValidations
      })
    },
  },

  getters: {
    getCurrentStep: (state) => state.currentStepIndex,
    getCurrentNestedStep: (state) => state.currentNestedStepIndex,

    getStepValidation: (state) => (stepKey) =>
        state.stepValidations[stepKey] || { isValid: false, errors: {} },

    getStepData: (state) => (stepKey) =>
        state.stepData[stepKey],

    isStepCompleted: (state) => (stepIndex) =>
        state.lastCompletedStepIndex >= stepIndex,

    getNavigationState: (state) => state.navigation,

    isNestedStepCompleted: (state) => (stepIndex, nestedIndex) =>
        state.lastCompletedStepIndex > stepIndex ||
        (state.lastCompletedStepIndex === stepIndex &&
            state.lastCompletedNestedStepIndex >= nestedIndex),

    getTotalSteps: (state) =>
        Object.keys(state.stepData).length,

    getCompletedStepsCount: (state) =>
        Object.values(state.stepValidations)
            .filter(validation => validation.isValid).length,

    isAllStepsValid: (state) =>
        Object.values(state.stepValidations)
            .every(validation => validation.isValid),
  },

  persist: {
    key: 'steps-store',
    storage: encryptedStorage,
    paths: [
      'currentStepIndex',
      'currentNestedStepIndex',
      'lastCompletedStepIndex',
      'lastCompletedNestedStepIndex',
      'stepData',
    ],
    afterRestore: (context) => {
      const isValidIndexes =
          context.store.currentStepIndex >= 0 &&
          context.store.currentNestedStepIndex >= 0 &&
          context.store.lastCompletedStepIndex >= -1 &&
          context.store.lastCompletedNestedStepIndex >= -1

      if (!isValidIndexes) {
        // Geçersiz durumda varsayılan değerlere dön
        context.store.$patch((state) => {
          state.currentStepIndex = 0
          state.currentNestedStepIndex = 0
          state.lastCompletedStepIndex = -1
          state.lastCompletedNestedStepIndex = -1
          state.stepValidations = {}
          state.stepData = {}
          state.navigation = {
            prevDisabled: false,
            nextDisabled: false,
          }
        })
      }

      // Navigation state'ini güncelle
      context.store.updateNavigation({
        prevDisabled: context.store.currentStepIndex === 0,
        nextDisabled: false,
      })
    },
  },
})