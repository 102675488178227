<template>
  <VueFinalModal :click-to-close="!loading && clickToClose" :esc-to-close="!loading && escToClose" @opened="opened" :class="modalClass" :content-class="contentClass" contentTransition="vfm-fade">
    <div class="p-6">
      <h2 class="text-xl text-secondary font-semibold mb-2">{{ title }}</h2>
      <p class="text-gray-700 mb-4 text-sm">{{ message }}</p>
      <customInput type="textarea" v-if="showNote" v-model="noteValue" :placeholder="$t('customDialog.notePlaceholder')" additionalInputClass="w-full p-2 border rounded mb-4" :disabled="loading"/>
      <p v-if="showWarning" class="text-red-500 mb-1">{{ warningMessage }}</p>

      <div class="flex justify-between space-x-4">
        <custom-button v-if="showCancelButton" @click="handleDecline" :button-text="cancelButtonText" color="secondary" size="sm" :full-width="true" :is-loading="loading" :is-disabled="loading"/>
        <custom-button v-if="showCancelButton" @click="handleConfirm" :button-text="confirmButtonText" color="primary" size="sm" :full-width="true" :is-loading="loading" :is-disabled="loading"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import customInput from '../base/inputs/VInput.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'

const props = defineProps({
  clickToClose: {
    type: Boolean,
    default: true,
  },
  escToClose: {
    type: Boolean,
    default: true,
  },
  modalClass: {
    type: String,
    default: 'flex justify-center items-center z-50',
  },
  contentClass: {
    type: String,
    default: 'flex flex-col max-w-xl bg-white border rounded-lg space-y-2 shadow-xl relative w-[400px]',
  },
  title: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
  note: {
    type: String,
    default: '',
  },
  showNote: {
    type: Boolean,
    default: false,
  },
  confirmButtonText: {
    type: String,
    default: 'Evet',
  },
  cancelButtonText: {
    type: String,
    default: 'Hayır',
  },
  showCancelButton: {
    type: Boolean,
    default: false,
  },
  warningMessage: {
    type: String,
    default: 'Not bırakmadan devam etmek istediğinize emin misiniz?',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['opened', 'update:show', 'confirm', 'decline'])

const noteValue = ref(props.note)
const showWarning = ref(false)
const confirmButtonTextValue = ref(props.confirmButtonText)

const opened = () => emit('opened')

const closeModal = () => {
  if (!props.loading) {
    emit('update:show', false)
  }
}

const handleConfirm = () => {
  if (!props.loading) {
    if (props.showNote && !noteValue.value.trim()) {
      if (showWarning.value) {
        emit('confirm')
        closeModal()
      } else {
        showWarning.value = true
        confirmButtonTextValue.value = $t('customDialog.confirmButtonText')
      }
    } else {
      emit('confirm')
      closeModal()
    }
  }
}

const handleDecline = () => {
  if (!props.loading) {
    emit('decline')
    closeModal()
  }
}

watch(noteValue, () => {
  if (noteValue.value.trim()) showWarning.value = false
})
</script>