<template>
  <VueFinalModal :click-to-close="!loading" :esc-to-close="!loading" @opened="opened" @closed="emit('update:modelValue', false)" class="flex justify-center items-center" content-class="flex flex-col p-10 bg-white border rounded-lg shadow-xl w-[650px]">
    <div class="h-full flex flex-col space-y-4 w-full">
      <div class="w-full flex items-center justify-between">
        <h2 class="text-2xl font-bold text-secondary">
          {{
            isGeneralOnly
                ? t('entity.components.reviseContentModal.informModel')
                : t('entity.components.reviseContentModal.reviseContent')
          }} </h2>
      </div>
      <CustomContent v-if="selectedType !== contentCategory.image && !isGeneralOnly" :label="t('entity.components.reviseContentModal.selectedContent')" :message="selectedMessageFormatted"/>
      <CustomInput type="textarea" :label="isGeneralOnly ? null : t('entity.components.reviseContentModal.revise')" :description="isGeneralOnly ? null : t('entity.components.reviseContentModal.reviseDescription')" containerClass="w-full" v-model="assistiveDescription" :autoGrow="false"/>
      <CustomInput v-if="!isGeneralOnly" type="select" :label="t('entity.components.reviseContentModal.commentType')" :description="t('entity.components.reviseContentModal.commentTypeDescription')" containerClass="w-full" v-model="commentTypeId" :selectList="[
          {name: t('entity.components.reviseContentModal.general'), id: 1},
          {name: t('entity.components.reviseContentModal.specific'), id: 2}
        ]"/>
      <div class="flex items-center space-x-4">
        <CustomButton :button-text="t('entity.components.reviseContentModal.cancel')" :is-disabled="false" color="primary" @click="closeModal" :full-width="true"/>
        <CustomButton :button-text="t('entity.components.reviseContentModal.revise')" :is-loading="loading" :is-disabled="isSubmitDisabled" @click="reviseContent" :full-width="true" color="secondary"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomContent from './components/customContent.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

const assetStore = useAssetStore()
const { t } = useI18n()

const props = defineProps({
  selectedMessage: {
    type: [String, Object, Array],
    default: '',
  },
  selectedType: {
    type: [String, Number],
    default: '',
  },
  selectedData: {
    type: [Object, String],
    default: () => ({}),
  },
  entityId: {
    type: [String, Number],
    default: '',
  },
  isGeneralOnly: {
    type: Boolean,
    default: false,
  },
  moduleTypeId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['update:modelValue', 'confirm'])

const loading = ref(false)
const assistiveDescription = ref('')
const commentTypeId = ref(1)

const contentCategory = {
  title: 1,
  category: 2,
  content: 3,
  seoKeyword: 4,
  image: 5,
  metaDescription: 6,
}

const selectedMessageFormatted = computed(() => {
  if (props.selectedType === contentCategory.seoKeyword && Array.isArray(props.selectedMessage)) {
    return props.selectedMessage.map(item => item.name || item).join(', ')
  }
  return props.selectedMessage
})

const isSubmitDisabled = computed(() => {
  return loading.value || !assistiveDescription.value.trim()
})

const opened = () => {
  assistiveDescription.value = ''
}

const closeModal = () => {
  emit('update:modelValue', false)
}

const reviseContent = async () => {
  if (!assistiveDescription.value.trim()) {
    return
  }

  loading.value = true
  try {
    const requestData = {
      contentTypeId: props.isGeneralOnly ? 7 : props.selectedType,
      assistiveDescription: assistiveDescription.value.trim(),
      commentTypeId: commentTypeId.value,
      assetId: assetStore.assetId,
      entityId: props.entityId ? props.entityId : null,
      moduleTypeId: props.moduleTypeId,
      languageId: assetStore.assetLanguage,
    }

    if (props.selectedType === contentCategory.image) {
      requestData.detail = {
        imageIndex: props.selectedData.imageIndex,
        imageUrls: props.selectedData.imageUrls,
        seoKeywords: props.selectedData.keywords,
        content: props.selectedData.content,
      }
    } else {
      requestData.detail = props.isGeneralOnly ? '' : props.selectedMessage
    }

    const response = await axios.put(entity.revise, requestData)

    emit('confirm', {
      ...response.data.data,
      field: props.selectedType === contentCategory.image ? 'image' : props.selectedData.field,
    })
    closeModal()
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.reviseContentModal.error.responseError'))
  } finally {
    loading.value = false
  }
}
</script>