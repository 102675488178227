<template>
  <div class="bg-white rounded-xl shadow-sm p-6">
    <h2 class="text-lg font-semibold mb-4 flex items-center gap-2">
      <span class="material-symbols-outlined text-primary">tag</span> {{ t('entity.components.contentReview.keywords') }} </h2>
    <div class="flex flex-wrap gap-2 max-h-[150px] overflow-y-auto thin-scrollbar">
      <span v-for="keyword in keywords" :key="keyword.id" class="px-3 py-1.5 bg-primary/10 text-primary rounded-full text-sm font-medium hover:bg-primary/20 transition-colors">
        {{ keyword.name }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps({
  keywords: Array,
})
</script>