<template>
  <aside v-show="showSidebarToggle" :class="[
    'fixed left-0 top-0 h-screen bg-white w-64 shadow-lg transition-all duration-300 transform z-[1000]',
    modelValue ? 'translate-x-0' : '-translate-x-full'
  ]">
    <div class="h-full flex flex-col relative bg-gradient-to-br from-white to-gray-50">
      <!-- Sidebar Header -->
      <div class="h-14 flex items-center justify-between px-4 border-b border-gray-100 bg-white/80 backdrop-blur-sm">
        <router-link to="/assets"><img src="@/assets/logo/logoB.svg" alt="logo" class="h-6 w-auto"/></router-link>
        <div v-if="isMobile" class="px-4 py-3 border-b border-gray-100">
          <language-menu type="text"/>
        </div>
      </div>


      <!-- Project Selector - Mobile Only -->
      <div v-if="isMobile && showItemsForRoutes" class="px-4 py-3 border-b border-gray-100">
        <button @click="$emit('showProjectModal', true)" class="w-full flex items-center justify-between p-2.5 rounded-lg hover:bg-gray-50 transition-all duration-200 border border-gray-200 group">
          <div class="flex items-center gap-3 min-w-0">
            <span class="material-symbols-outlined !text-[18px] text-gray-500 group-hover:text-gray-700 shrink-0">
              folder_open
            </span>
            <div class="flex flex-col min-w-0">
              <span class="text-[13px] font-medium text-gray-700 group-hover:text-gray-900 truncate max-w-[180px]">
                {{ currentProject }}
              </span>
            </div>
          </div>
          <span class="material-symbols-outlined !text-[18px] text-gray-400 group-hover:text-gray-600 shrink-0">
            arrow_drop_down
          </span>
        </button>
      </div>

      <!-- Menu Items -->
      <div class="flex-1 overflow-y-auto">
        <div class="p-3 space-y-0.5">
          <div v-for="(item, index) in items" :key="index" class="relative group" @mouseenter="!isMobile && onMenuEnter(item, index, $event)" @mouseleave="!isMobile && onMenuLeave" :ref="el => { if (el) $emit('setMenuRef', { index, el }) }">
            <!-- Menu Item -->
            <div @click="onMenuClick(item, index)" class="flex items-center justify-between p-3 mb-4 rounded-lg cursor-pointer transition-all duration-200 group-hover:bg-gray-100" :class="{
                'bg-primary/10 text-primary shadow-sm': isActiveRoute(item.path),
                'text-gray-700 hover:text-gray-900': !isActiveRoute(item.path)
              }">
              <div class="flex items-center space-x-3 min-w-0">
                <span class="material-symbols-outlined !text-[18px] opacity-90 shrink-0">
                  {{ item.icon }}
                </span> <span class="text-[13px] font-medium truncate">
                  {{ item.label }}
                </span>
              </div>
              <span v-if="item.subItems" class="material-symbols-outlined !text-[20px] transition-transform duration-300 opacity-75 shrink-0" :class="{
                  'rotate-90 text-primary': openSubmenuIndex === index && !isMobile,
                  'rotate-180': openSubmenuIndex === index && isMobile
                }">
                {{ isMobile ? 'expand_more' : 'chevron_right' }}
              </span>
            </div>

            <!-- Mobile Submenu -->
            <div v-if="isMobile && item.subItems && openSubmenuIndex === index" class="mt-1 bg-gray-50 rounded-lg overflow-hidden transition-all duration-300" :class="{'max-h-0': !showSubmenu, 'max-h-[500px]': showSubmenu}">
              <div class="p-2 space-y-1">
                <router-link v-for="subItem in item.subItems" :key="subItem.key" :to="{ path: item.path, query: { tab: subItem.key }}" class="block px-3 py-2 rounded-md text-[13px] transition-all duration-200 hover:bg-gray-100" :class="{
                    'text-primary bg-primary/10 font-medium': isActiveSubRoute(item.path, subItem.key),
                    'text-gray-700 hover:text-gray-900': !isActiveSubRoute(item.path, subItem.key)
                  }" @click="onSubItemClick">
                  <span class="truncate block">{{ subItem.label }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="border-t border-gray-100 bg-white/80 backdrop-blur-sm">

      </div>
    </div>
  </aside>
</template>

<script setup>
import { useRoute } from 'vue-router'
import LanguageMenu from '../../../common/language.vue'

const props = defineProps({
  modelValue: Boolean,
  items: Array,
  showSidebarToggle: Boolean,
  isMobile: Boolean,
  openSubmenuIndex: Number,
  showSubmenu: Boolean,
  profileName: String,
  showItemsForRoutes: Boolean,
  currentProject: String,
})

const emit = defineEmits([
  'update:modelValue',
  'menuEnter',
  'menuLeave',
  'menuClick',
  'subItemClick',
  'setMenuRef',
  'showProjectModal',
])

const route = useRoute()

const isActiveRoute = (path) => {
  return route.path.startsWith(path)
}

const isActiveSubRoute = (path, tabKey) => {
  return route.path.startsWith(path) && route.query.tab === tabKey
}

const onMenuEnter = (item, index, event) => {
  emit('menuEnter', { item, index, event })
}

const onMenuLeave = () => {
  emit('menuLeave')
}

const onMenuClick = (item, index) => {
  emit('menuClick', { item, index })
}

const onSubItemClick = () => {
  emit('subItemClick')
}
</script>

<style scoped>
/* Smooth scrollbar for sidebar */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>