<template>
  <VueFinalModal class="flex justify-center items-center" content-class="relative bg-white rounded-xl w-[500px] max-w-full mx-4 p-6 shadow-lg border" :click-to-close="false" :esc-to-close="!isLoading">
    <div class="space-y-2">
      <!-- Modal Header -->
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium text-gray-900">
          {{ t('dashboard.components.competitorAnalysis.addNewDomain') }} </h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-500 p-1 hover:bg-gray-100 rounded-full transition-colors" :disabled="isLoading">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="space-y-4">
        <form @submit.prevent="handleSave" class="space-y-4">
          <div class="flex items-center gap-3">
            <div class="flex-grow">
              <CustomInput v-model="domain" :placeholder="t('dashboard.components.competitorAnalysis.enterDomain')" size="sm" class="w-full focus:ring-2 focus:ring-primary/20" :error="validationError" :show-error="true" @blur="validateDomain" :disabled="isLoading"/>
            </div>
          </div>
        </form>
      </div>

      <!-- Modal Footer -->
      <div class="flex justify-end gap-3 pt-4 border-t">
        <CustomButton type="button" :button-text="t('common.cancel')" @click="closeModal" color="secondary" variant="outline" size="sm"/>
        <CustomButton type="submit" :button-text="t('common.save')" @click="handleSave" :disabled="!isValid || validationError " color="primary" size="sm" :is-loading="isLoading"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import * as yup from 'yup'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const { t } = useI18n()
const assetStore = useAssetStore()

const emit = defineEmits(['update:modelValue', 'domain-added'])

const domain = ref('')
const validationError = ref('')
const isLoading = ref(false)
const cancelTokenSource = ref(null)

// Yup validation schema
const domainSchema = yup.object().shape({
  domain: yup.string()
      .required(t('form.errors.required'))
      .test('no-www', t('form.errors.noWww'), (value) => {
        return !value?.toLowerCase().startsWith('www.')
      })
      .test('https-required', t('form.errors.httpsRequired'), (value) => {
        return value?.toLowerCase().startsWith('https://')
      })
      .test('no-trailing-slash', t('form.errors.noTrailingSlash'), (value) => {
        return !value?.endsWith('/')
      })
      .test('domain-format', t('form.errors.invalidDomain'), (value) => {
        // HTTPS ile başlamalı ve en az bir nokta içermeli ve boşluk olmamalı
        return value &&
            value.includes('.') &&
            !value.includes(' ') &&
            /^https:\/\/[a-zA-Z0-9][a-zA-Z0-9-._]+[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(value)
      }),
})


const validateDomain = async () => {
  try {
    await domainSchema.validate({ domain: domain.value.trim() })
    validationError.value = ''
    return true
  } catch (error) {
    validationError.value = error.message
    return false
  }
}

const isValid = computed(() => {
  return domain.value.trim() !== '' && !validationError.value
})

const handleSave = async () => {
  const isValidDomain = await validateDomain()
  if (!isValidDomain) return
  isLoading.value = true

  cancelTokenSource.value = axios.CancelToken.source()

  try {
    await axios.post(dashboard.addCompetitor, {
      assetId: assetStore.assetId,
      domain: domain.value.trim(),
    }, {
      cancelToken: cancelTokenSource.value.token,
    })
    responseHandlerService.handleSuccess(t('dashboard.components.competitorAnalysis.messages.domainAdded'))
    emit('domain-added')
    domain.value = ''
    validationError.value = ''
    emit('update:modelValue', false)
  } catch (err) {
    if (err.response?.data?.data?.resultCount === 0) responseHandlerService.handleError(t('dashboard.components.competitorAnalysis.messages.noCompetitors'))
    else responseHandlerService.handleError((err.response?.data?.message || t('common.errors.saveError')))
    emit('update:modelValue', false)
  } finally {
    isLoading.value = false
    cancelTokenSource.value = null
  }
}

const closeModal = () => {
  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel('Operation canceled by the user.')
    cancelTokenSource.value = null
  }

  if (!isLoading.value) {
    domain.value = ''
    validationError.value = ''
  }

  emit('update:modelValue', false)
}
</script>