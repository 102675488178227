<template>
  <div class="bg-white rounded-md p-4 w-full">
    <div class="flex items-center justify-between">
      <div class="flex-grow">
        <h3 class="text-sm font-semibold mb-1">{{ title }}</h3>
        <p class="text-xs mb-2 text-gray-500">{{ description }}</p>
      </div>
      <CustomButton :button-text="t('general.view')" @click="$emit('view')" size="sm"/>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'

const { t } = useI18n()

defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
})

defineEmits(['view'])
</script>