import axios from 'axios'
import { useUIStore } from '@/stores/ui'
import { useAuthStore } from '@/stores/auth'
import router from '@/router'
import { pinia } from '@/main'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { baseUrl } from '@/networking/urlManager'
import { errorMessages } from './errorMessages'

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 400000,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

instance.interceptors.request.use(
    (config) => {
      const authStore = useAuthStore(pinia)
      const uiStore = useUIStore(pinia)
      const token = authStore.userData?.token

      // Update error messages language based on current UI language
      errorMessages.setLanguage(uiStore.getCurrentLanguage)

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers['Accept-Language'] = uiStore.getCurrentLanguage
      config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Istanbul'

      return config
    },
    (error) => Promise.reject(error),
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        return Promise.reject(error)
      }

      if (!error.response) {
        responseHandlerService.handleError(errorMessages.get('connectionError'))
        return Promise.reject(error)
      }

      switch (error.response.status) {
        case 401: {
          const authStore = useAuthStore(pinia)

          if (error.config._skipAuthRedirect) {
            await authStore.clearUserData()
            return Promise.reject(error)
          }

          await authStore.clearUserData()
          await router.push('/')

          if (!responseHandlerService.unauthorizedSnackbarDisplayed) {
            const message = error.response.data?.message || errorMessages.get('sessionExpired')
            responseHandlerService.handleError(message)
            responseHandlerService.unauthorizedSnackbarDisplayed = true
          }
          break
        }

        case 500: {
          const message = error.response.data?.message || errorMessages.get('serverError')
          responseHandlerService.handleError(message)
          break
        }
      }

      return Promise.reject(error)
    },
)

export default instance