<template>
  <div class="bg-slate-50 min-h-screen py-6 relative">
    <div class="mx-auto px-4">
      <!-- Header -->
      <div class="bg-white rounded-xl shadow-sm mb-6">
        <div class="p-6 border-b border-slate-100">
          <div class="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <HeaderInfo :title="entityData.title" :category="entityData.category"/>
            <ActionButtons v-bind="{socialMediaData,canOptimize,entityUrl,isAnalyzing,isRevisedEntityReceived,isHistoryContent}" @analyze="fetchAnalyzedContent" @toggleHistory="toggleHistoryModal" @navigate="goToContent" @saveContent="saveHistoryContent" @update:socialMediaData="updateSocialMediaData" @saveEditedContent="saveRevision" :saveEditedContentLoading="isSaving"/>
          </div>
        </div>
      </div>

      <!-- Main Content Grid -->
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <!-- Left Column -->
        <div class="lg:col-span-8 space-y-6">
          <ContentPreview :content="truncatedContent" @preview="showPreviewModal = true"/>
          <MediaGallery :image-urls="entityData.imageUrls" :is-loading="isLoading"/>
        </div>

        <!-- Right Column -->
        <div class="lg:col-span-4 space-y-6">
          <ScoreSection :score="entityData.score" :parsed-score-message="parsedScoreMessage" :carousel-items="carouselItems"/>
          <KeywordsSection :keywords="entityData.keywords"/>
          <RecommendationsSection v-if="hasRecommendations" :entity-data="entityData" :parsed-seo-shortcomings="parsedSeoShortcomings"/>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <PreviewContentModal v-model="showPreviewModal" :content="previewContent"/>
    <ReviseContentModal v-model="showReviseModal" :selected-message="selectedMessage" :selected-type="selectedType" :selected-data="selectedData" :module-type-id="1" :entity-id="entityData.optimizeId || entityData.id" @confirm="handleReviseConfirm"/>

    <!-- Sidebar -->
    <ContentHistories ref="contentHistory" :entity-id="entityData.id" :top-id="entityData.topId" @withdrawHistory="fetchEntityDetail"/>

    <!-- Loading -->
    <div v-if="isLoading || isAnalyzing" class="absolute inset-0 bg-white bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-40">
      <Loading/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import { entity } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { safeParse } from '@/utils/common'

// Import components
import HeaderInfo from './components/header/HeaderInfo.vue'
import ActionButtons from './components/header/ActionButtons.vue'
import ContentPreview from './components/content/ContentPreview.vue'
import MediaGallery from './components/content/MediaGallery.vue'
import ScoreSection from './components/score/ScoreSection.vue'
import KeywordsSection from './components/keyword/KeywordsSection.vue'
import RecommendationsSection from './components/recommendations/RecommendationsSection.vue'
import PreviewContentModal from '@/components/modals/PreviewContentModal.vue'
import ReviseContentModal from '../reviseContentModal/reviseContentModal.vue'
import ContentHistories from './components/contentHistory/contentHistories.vue'
import Loading from '@/components/loading/VLoading.vue'

const props = defineProps({
  selectedContent: { type: Number, required: true },
})

// Core state management
const { t } = useI18n()
const assetStore = useAssetStore()
const entityData = ref({})
const entityUrl = ref('')
const optimizeId = ref('')

// UI state management
const contentHistory = ref(null)
const showPreviewModal = ref(false)
const showReviseModal = ref(false)
const isLoading = ref(false)
const isAnalyzing = ref(false)
const isSaving = ref(false)
const isRevisedEntityReceived = ref(false)
const selectedMessage = ref('')
const selectedType = ref('')
const selectedData = ref('')
const isHistoryContent = ref(false)

// Computed Properties
const parsedScoreMessage = computed(() => {
  if (!entityData.value.scoreMessage) return null
  try {
    const parsed = JSON.parse(entityData.value.scoreMessage)
    return parsed.sections
        ? { type: 'detailed', data: parsed }
        : { type: 'simple', message: parsed.message || entityData.value.scoreMessage }
  } catch {
    return { type: 'simple', message: entityData.value.scoreMessage }
  }
})

const firstImage = computed(() => {
  const images = entityData.value?.imageUrls || []
  return images[0]?.url || images[0]?.image || null
})

const carouselItems = computed(() => {
  if (!parsedScoreMessage.value?.data?.sections) return []
  const sections = Object.entries(parsedScoreMessage.value.data.sections)
      .filter(([key]) => key !== 'improvements')
      .map(([key, data]) => ({ type: 'section', key, data }))

  if (parsedScoreMessage.value.data.sections.improvements?.length) {
    sections.push({
      type: 'improvements',
      data: parsedScoreMessage.value.data.sections.improvements,
    })
  }

  return sections
})

const socialMediaData = computed(() => ({
  entityId: entityData.value.id,
  assetId: assetStore.assetId,
  title: entityData.value.title,
  content: entityData.value?.socialMediaContent || '',
  hashtags: safeParse(entityData.value?.socialMediaHashtags, null, 'array') || [],
  url: entityUrl.value,
  image: firstImage.value,
  isSocialMediaContentReady: !!entityData.value?.socialMediaContent,
}))

const truncatedContent = computed(() => {
  if (!entityData.value.content) return ''
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = entityData.value.content
  const textContent = tempDiv.textContent || tempDiv.innerText
  return textContent.length > 500
      ? entityData.value.content.substring(0, 500) + '...'
      : entityData.value.content
})

const hasRecommendations = computed(() => {
  return entityData.value.semanticMessage ||
      entityData.value.spellingMessage ||
      entityData.value.seoShortcomings
})

const parsedSeoShortcomings = computed(() => {
  if (!entityData.value.seoShortcomings) return []
  try {
    return JSON.parse(entityData.value.seoShortcomings)
  } catch {
    return []
  }
})

const previewContent = computed(() => ({
  title: entityData.value.title,
  body: entityData.value.content,
  category: entityData.value.category,
  metaDescription: entityData.value.metaDescription,
  keywords: entityData.value.keywords?.map(k => k.name).join(', '),
  images: entityData.value.imageUrls,
}))

const canOptimize = computed(() => [2, 3].includes(entityData.value.entityTypeId))

// Helper Methods
const parseImageUrls = (imageUrlsData) => {
  if (Array.isArray(imageUrlsData) && imageUrlsData.every(item =>
      typeof item === 'object' && (item.url || item.image),
  )) {
    return imageUrlsData
  }

  const parsed = safeParse(imageUrlsData, [], 'array')

  if (parsed.length && parsed.every(item =>
      typeof item === 'object' && (item.url || item.image),
  )) {
    return parsed
  }

  console.warn('Invalid image URL data structure:', imageUrlsData)
  return []
}

const updateEntityData = (data) => {
  entityUrl.value = data.entityUrl || entityUrl.value
  entityData.value = {
    ...data,
    keywords: data.keywords || [],
    imageUrls: parseImageUrls(data.imageUrls || []).filter(img => img.url || img.image),
    recommendedKeywords: safeParse(data.recommendedKeywords, []),
    recomendationNotes: {
      scoreMessage: data.scoreMessage || '',
      semanticMessage: data.semanticMessage || '',
      metaDescription: data.metaDescription || '',
      seoShortcomings: data.seoShortcomings || [],
      spellingMessage: data.spellingMessage || '',
    },
  }
}

// API Methods
const fetchEntityDetail = async (entityId = null) => {
  isLoading.value = true
  if (entityId) {
    optimizeId.value = entityId
    isHistoryContent.value = true
  }

  try {
    const response = await axios.get(entity.getDetail, {
      params: {
        entityId: entityId || props.selectedContent,
        assetId: assetStore.assetId,
      },
    })
    updateEntityData(response.data.data)
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorFetch'))
    optimizeId.value = ''
  } finally {
    isLoading.value = false
  }
}

const fetchAnalyzedContent = async () => {
  isAnalyzing.value = true
  try {
    const response = await axios.put(entity.assetContentEdit, {
      entityId: entityData.value.id,
      assetId: assetStore.assetId,
    })
    const data = response.data.data
    updateEntityData({
      ...entityData.value,
      optimizeId: data.optimizeId || '',
      title: data.result.title || '',
      score: data.result.score || '',
      content: data.result.content || '',
      entityTypeId: 3,
      keywords: data.result.keywords || [],
      contentDifference: data.result.contentDifference || [],
      subTitle: data.result.spot || '',
      isActive: data.result.isActive || 1,
      imageUrls: entityData.value.imageUrls,
      recomendationNotes: {
        ...entityData.value.recomendationNotes,
        scoreMessage: data.result.scoreMessage || '',
      },
    })
    isRevisedEntityReceived.value = true
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorOptimize'))
  } finally {
    isAnalyzing.value = false
  }
}

const saveRevision = async () => {
  isSaving.value = true
  try {
    await axios.post(entity.saveEditedContent, {
      entityId: entityData.value.id,
      optimizeId: entityData.value.optimizeId,
      assetId: assetStore.assetId,
    })
    await fetchEntityDetail()
    responseHandlerService.handleSuccess(t('entity.components.contentReview.contentSaved'))
    isRevisedEntityReceived.value = false
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorSave'))
  } finally {
    isSaving.value = false
  }
}

const handleReviseConfirm = (response) => {
  if (response) {
    updateEntityData({
      ...entityData.value,
      title: response.data.title || entityData.value.title,
      category: response.data.category || entityData.value.category,
      content: response.data.content || entityData.value.content,
      keywords: response.data.keywords || entityData.value.keywords,
      imageUrls: Array.isArray(response.data.imageUrls)
          ? response.data.imageUrls
          : typeof response.data.imageUrls === 'string'
              ? JSON.parse(response.data.imageUrls)
              : entityData.value.imageUrls,
      optimizeId: response.optimizeId || '',
      entityTypeId: response.data.entityTypeId || entityData.value.entityTypeId,
      isActive: response.data.isActive || entityData.value.isActive,
      contentDifference: response.data.contentDifference || entityData.value.contentDifference,
      recomendationNotes: {
        ...entityData.value.recomendationNotes,
        metaDescription: response.data.metaDescription || entityData.value.recomendationNotes.metaDescription,
      },
    })
  }
}

const saveHistoryContent = async () => {
// Saves the current version to content history
  isLoading.value = true
  try {
    await axios.post(entity.saveEditedContent, {
      entityId: entityData.value.id,
      optimizeId: optimizeId.value,
      assetId: assetStore.assetId,
    })
    await fetchEntityDetail()
    isHistoryContent.value = false
    responseHandlerService.handleSuccess(t('entity.components.contentReview.contentSaved'))
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.contentReview.errorSave'))
  } finally {
    isLoading.value = false
  }
}

const goToContent = (url) => window.open(url, '_blank')
const toggleHistoryModal = () => contentHistory.value?.toggleSidebar()
const updateSocialMediaData = (newData) => {
  entityData.value = {
    ...entityData.value,
    socialMediaContent: {
      socialMediaContent: newData.content,
      socialMediaHashtags: newData.hashtags,
    },
  }
}

// Lifecycle and Watchers
onMounted(fetchEntityDetail)
watch(() => props.selectedContent, fetchEntityDetail)
</script>



