<template>
  <div class="bg-white rounded-xl shadow-sm p-6">
    <div class="grid grid-cols-1 gap-6">
      <div class="flex flex-col items-center">
        <h3 class="text-sm font-medium text-slate-600 mb-3 flex items-center gap-2 self-start">
          <span class="material-symbols-outlined text-primary">analytics</span> {{ t('entity.components.contentReview.contentScore') }} </h3>
        <div class="w-full h-[200px] flex items-center justify-center">
          <ScoreChart :score="score"/>
        </div>
      </div>
      <ScoreAnalysis :parsed-message="parsedScoreMessage" :carousel-items="carouselItems"/>
    </div>
  </div>
</template>

<script setup>
import ScoreAnalysis from './ScoreAnalysis.vue'
import ScoreChart from '@/components/features/charts/ScoreChart.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  score: Number,
  parsedScoreMessage: Object,
  carouselItems: Array,
})
</script>