import { defineStore } from 'pinia'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { encryptedStorage } from '@/utils/encryption'

const defaultUserData = {
  token: null,
  companyToken: null,
  email: null,
  fullName: null,
}

// Validation helpers
const generateValidationKey = () => {
  const random = Math.random().toString(36).substring(2)
  return btoa(`${random}:validated`)
}

const isValidationKeyValid = (key) => {
  try {
    const decoded = atob(key)
    return decoded.endsWith(':validated')
  } catch {
    return false
  }
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    userData: defaultUserData,
    rememberMe: false,
    userEmail: '',
    isValidating: false,
  }),

  actions: {
    async setUserData(userData, isNewLogin = false) {
      this.$patch((state) => {
        state.userData = {
          token: userData?.token ?? null,
          companyToken: userData?.companyToken ?? null,
          email: userData?.email ?? null,
          fullName: userData?.fullname ?? null,
        }
      })

      // Yeni login ise direkt validate olmuş sayılır
      if (isNewLogin && userData?.token) {
        sessionStorage.setItem('_vk', generateValidationKey())
        return true
      }

      // Yeni login değilse ve token varsa validate et
      if (!isNewLogin && userData?.token) {
        return this.validateToken()
      }

      return false
    },

    clearUserData() {
      this.$patch((state) => {
        state.userData = {
          token: null,
          companyToken: null,
          email: null,
          fullName: null,
        }

        if (!state.rememberMe) {
          state.userEmail = ''
        }
      })

      sessionStorage.removeItem('_vk')
    },

    setRememberMe(value) {
      this.$patch((state) => {
        state.rememberMe = value
        if (!value) {
          state.userEmail = ''
        }
      })
    },

    setUserEmail(email) {
      this.$patch((state) => {
        if (state.rememberMe) {
          state.userEmail = email
        }
      })
    },

    async validateToken() {
      if (!this.userData.token || this.isValidating) return false

      const currentKey = sessionStorage.getItem('_vk')
      if (currentKey && isValidationKeyValid(currentKey)) return true

      this.isValidating = true

      try {
        await axios.get(auth.validateToken, {
          _skipAuthRedirect: true,
        })

        sessionStorage.setItem('_vk', generateValidationKey())
        this.isValidating = false
        return true
      } catch (error) {
        this.isValidating = false
        sessionStorage.removeItem('_vk')
        await this.clearUserData()
        responseHandlerService.handleError('Oturumunuz geçersiz veya süresi dolmuş.')
        return false
      }
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.userData.token,
    getFullName: (state) => state.userData.fullName,
    getEmail: (state) => state.userData.email,
  },

  persist: {
    key: 'auth-store',
    storage: encryptedStorage,
    paths: ['userData', 'rememberMe', 'userEmail'],
    afterRestore: (context) => {
      if (!context.store.userData?.token) {
        context.store.$patch((state) => {
          state.userData = {
            token: null,
            companyToken: null,
            email: null,
            fullName: null,
          }

          if (!state.rememberMe) {
            state.userEmail = ''
          }
        })
      }
    },
  },
})