let currentLanguage = 'tr'

const messages = {
  tr: {
    connectionError: 'Bağlantı hatası! Lütfen internet bağlantınızı kontrol edin.',
    sessionExpired: 'Oturum süreniz dolmuştur.',
    serverError: 'Sunucu hatası!',
  },
  en: {
    connectionError: 'Connection error! Please check your internet connection.',
    sessionExpired: 'Your session has expired.',
    serverError: 'Server error!',
  },
}

export const errorMessages = {
  setLanguage(lang) {
    currentLanguage = lang
  },
  get(key) {
    return messages[currentLanguage]?.[key] || messages.tr[key]
  },
}