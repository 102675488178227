<template>
  <div class="max-w-7xl mx-auto bg-white p-4 rounded-md shadow-sm">
    <div ref="dummyFocusRef" tabindex="-1" class="outline-none"></div>
    <!-- Header -->
    <div class="flex justify-between items-center mb-4">
      <div>
        <h1 class="text-sm font-semibold text-secondary mb-1">
          {{ t('entity.components.newContent.components.contentForm.title') }} </h1>
        <p class="text-xs text-gray-500">
          {{ t('entity.components.newContent.components.contentForm.description') }} </p>
      </div>
      <div class="flex items-center space-x-2">
        <ShareMenu v-bind="socialMediaData" button-color="secondary" icon="share" :button-text="t('entity.components.newContent.components.contentForm.buttons.shareOnSocial')"/>
        <CustomButton @click="showPreviewModal = true" color="secondary" icon="preview" :button-text="t('entity.components.newContent.components.contentForm.previewContent')" size="sm" :show-icon="true"/>
        <CustomButton @click="$emit('requestAiSuggestion', 'all')" color="primary" icon="smart_toy" :button-text="t('entity.components.newContent.components.contentForm.getAiSuggestion')" size="sm" :show-icon="true"/>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row gap-4">
      <!-- Left Column -->
      <div class="w-full lg:w-1/2 space-y-4">
        <CustomInput v-model="content.title" :label="t('entity.components.newContent.components.contentForm.form.titleLabel')" :placeholder="t('entity.components.newContent.components.contentForm.form.titlePlaceholder')" :loading="contentLoading" :showAiIcon="content.title !== ''" :error="formErrors.title" @update:modelValue="updateParentContent" @iconClicked="openReviseModal('title')" labelIcon="title" labelIconClass="text-primary !text-[20px]" required disabled/>

        <CustomInput v-model="content.category" :label="t('entity.components.newContent.components.contentForm.form.categoryLabel')" :placeholder="t('entity.components.newContent.components.contentForm.form.categoryPlaceholder')" :loading="contentLoading" :showAiIcon="content.category !== ''" :error="formErrors.category" @update:modelValue="updateParentContent" @iconClicked="openReviseModal('category')" labelIcon="category" required disabled/>

        <div class="relative group" @click.stop="showPreviewModal = true">
          <CustomInput v-model="content.body" type="textarea" :label="t('entity.components.newContent.components.contentForm.form.bodyLabel')" :placeholder="t('entity.components.newContent.components.contentForm.form.bodyPlaceholder')" :loading="contentLoading" :show-ai-icon="content.body !== ''" :error="formErrors.body" @update:modelValue="updateParentContent" @iconClicked="openReviseModal('body')" text-area-height="h-56" labelIcon="description" additional-input-class="cursor-pointer hover:scale-95" :enable-blur="true" readonly required/>
          <div v-if="content.body" class="absolute inset-0 mt-7 flex items-center justify-center pointer-events-none opacity-100 group-hover:opacity-0 transition-all duration-300">
            <div class="bg-white/90 px-3 py-1.5 rounded-full shadow-sm flex items-center gap-1.5">
              <span class="material-symbols-outlined text-primary text-sm">visibility</span> <span class="text-xs font-medium text-slate-700">{{ t('common.clickToPreview') }}</span>
            </div>
          </div>
        </div>

        <CustomInput v-model="content.keywords" @detailClicked="showKeywordDetailModal=true" :showDetailButton="hasKeywordDetails" :label="t('entity.components.newContent.components.contentForm.form.keywordsLabel')" :placeholder="t('entity.components.newContent.components.contentForm.form.keywordsPlaceholder')" :loading="contentLoading" :showAiIcon="content.keywords !== ''" :error="formErrors.keywords" @update:modelValue="updateParentContent" @iconClicked="openReviseModal('keywords')" labelIcon="key" required disabled/>

        <CustomInput v-model="content.metaDescription" :label="t('entity.components.newContent.components.contentForm.form.metaDescriptionLabel')" :placeholder="t('entity.components.newContent.components.contentForm.form.metaDescriptionPlaceholder')" :loading="contentLoading" :showAiIcon="content.metaDescription !== ''" :error="formErrors.metaDescription" @update:modelValue="updateParentContent" @iconClicked="openReviseModal('metaDescription')" labelIcon="analytics" required disabled/>
      </div>

      <!-- Right Column -->
      <div class="w-full lg:w-1/2 space-y-4">
        <!-- Image Area -->
        <div class="bg-gray-50 rounded-md p-3">
          <h2 class="text-sm font-medium text-secondary flex items-center mb-2">
            <span class="material-symbols-outlined !text-[20px] mr-2 text-primary">image</span> {{ t('entity.components.newContent.components.contentForm.form.imagesLabel') }} </h2>
          <ImageComponent :image-urls="content.images" :content-loading="contentLoading" :is-image-refreshable="true" :is-image-uploadable="false" @refresh-image="(image, index) => openReviseModal('image', index)" @upload-image="handleImageUpload"/>
        </div>

        <!-- Tips Area -->
        <div class="bg-gray-50 rounded-md p-3">
          <h2 class="text-sm font-medium text-secondary flex items-center mb-2">
            <span class="material-symbols-outlined !text-[20px] mr-2 text-primary">lightbulb</span> {{ t('entity.components.newContent.components.contentForm.tips.title') }} </h2>
          <ul class="text-xs text-gray-600 list-disc list-inside space-y-1">
            <li>{{ t('entity.components.newContent.components.contentForm.tips.tip1') }}</li>
            <li>{{ t('entity.components.newContent.components.contentForm.tips.tip2') }}</li>
            <li>{{ t('entity.components.newContent.components.contentForm.tips.tip3') }}</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Bottom Buttons -->
    <div class="mt-4 flex justify-between items-center">
      <CustomButton @click="handleReset" type="button" color="accent" icon="restart_alt" :button-text="t('entity.components.newContent.components.contentForm.buttons.reset')" size="sm" :show-icon="true"/>
      <div class="flex items-center gap-2">
        <CustomButton @click="showCopyModal = true" color="secondary" variation="outline" icon="content_copy" :button-text="t('entity.components.newContent.components.contentForm.buttons.copyContent')" size="sm" :show-icon="true"/>
        <CustomButton @click="shareContent" color="primary" icon="upload" :button-text="t('entity.components.newContent.components.contentForm.buttons.share')" :loading-text="t('entity.components.newContent.components.contentForm.buttons.sharing')" :is-disabled="!isFormValid" :is-loading="sharing" size="sm" :show-icon="true"/>
      </div>
    </div>

    <!-- Modals -->
    <ReviseContentModal v-model="showReviseModal" :selected-message="selectedMessage" :selected-type="selectedType" :selected-data="selectedData" :entity-id="entityId" :module-type-id="2" @confirm="handleReviseConfirm"/>

    <PreviewContentModal v-model="showPreviewModal" :content="content" @modalClosed="handleModalClosed"/>

    <KeywordDetailModal v-model="showKeywordDetailModal" :keywordData="keywordData"/>

    <CopyContentModal v-model="showCopyModal" :content="content" @close="showCopyModal = false"/>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import ImageComponent from '@/components/common/VImage.vue'
import ReviseContentModal from '../../reviseContentModal/reviseContentModal.vue'
import KeywordDetailModal from './keywordDetailModal.vue'
import PreviewContentModal from '@/components/modals/PreviewContentModal.vue'
import CopyContentModal from './CopyContentModal.vue'
import ShareMenu from '../../shareOnSocialMedia/index.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'
import { safeParse } from '@/utils/common'

// Props
const props = defineProps({
  contentDetail: {
    type: Object,
    default: () => ({}),
  },
  contentLoading: {
    type: Boolean,
    default: false,
  },
})

// Emits
const emit = defineEmits([
  'content-saved',
  'update:modelValue',
  'content-loading',
  'tags',
  'response',
  'requestAiSuggestion',
  'reset',
  'update:contentDetail',
])

// Composables
const { t } = useI18n()
const assetStore = useAssetStore()
const dummyFocusRef = ref(null)

// Constants
const KEYWORD_TYPES = [
  'mainKeywords',
  'longTailKeywords',
  'lsiKeywords',
  'questionKeywords',
  'seasonalKeywords',
  'localKeywords',
  'trendingKeywords',
]

const contentCategory = {
  title: 1,
  category: 2,
  body: 3,
  keywords: 4,
  metaDescription: 6,
  image: 5,
}

// Refs
const showReviseModal = ref(false)
const showPreviewModal = ref(false)
const showKeywordDetailModal = ref(false)
const showCopyModal = ref(false)
const sharing = ref(false)
const selectedMessage = ref('')
const selectedType = ref('')
const selectedData = ref('')
const entityId = ref('')
const formErrors = ref({})

// Reactive State
const content = reactive({
  title: '',
  category: '',
  body: '',
  keywords: '',
  metaDescription: '',
  socialMediaContent: '',
  socialMediaHashtags: [],
  keywordDetail: {},
  images: [],
})

// Validation computed property
const isFormValid = computed(() => {
  const requiredFields = {
    title: content.title,
    category: content.category,
    body: content.body,
    keywords: content.keywords,
    metaDescription: content.metaDescription,
  }

  const errors = {}
  let isValid = true

  Object.entries(requiredFields).forEach(([field, value]) => {
    if (!value || !value.trim()) {
      errors[field] = t(`entity.validation.${field}Required`)
      isValid = false
    }
  })

  formErrors.value = errors
  return isValid
})

const keywordData = computed(() => {
  const keywordDetail = props.contentDetail?.keywordDetail

  if (!keywordDetail) return []

  if (typeof keywordDetail === 'object' && !Array.isArray(keywordDetail)) {
    if (keywordDetail.keywords?.keywords) return keywordDetail.keywords
    if (KEYWORD_TYPES.some(type => Array.isArray(keywordDetail[type]))) return keywordDetail
  }

  try {
    const parsed = safeParse(keywordDetail, null, 'object')
    if (parsed?.keywords?.keywords) return parsed.keywords
    if (KEYWORD_TYPES.some(type => Array.isArray(parsed?.[type]))) return parsed
    if (parsed?.keywords) return parsed.keywords
    return []
  } catch (error) {
    console.warn('Failed to parse keyword data:', error)
    return []
  }
})

const hasKeywordDetails = computed(() => {
  const keywordDetail = props.contentDetail?.keywordDetail

  if (!keywordDetail) return false

  if (typeof keywordDetail === 'string') {
    try {
      const parsed = JSON.parse(keywordDetail)
      if (parsed?.keywords) return true
      return KEYWORD_TYPES.some(type => Array.isArray(parsed[type]) && parsed[type].length > 0)
    } catch {
      return false
    }
  }

  if (typeof keywordDetail === 'object') {
    if (!keywordDetail) return false
    if (keywordDetail.keywords) return true
    if (keywordDetail?.keywords?.keywords) return true
    return KEYWORD_TYPES.some(type =>
        Array.isArray(keywordDetail[type]) && keywordDetail[type].length > 0,
    )
  }

  return false
})

const socialMediaData = computed(() => {
  const hashtags = Array.isArray(content.socialMediaHashtags)
      ? content.socialMediaHashtags
      : safeParse(content.socialMediaHashtags, [], 'array')

  const socialContent = typeof content.socialMediaContent === 'string'
      ? content.socialMediaContent
      : safeParse(content.socialMediaContent, '', 'any')

  return {
    entityId: props.contentDetail?.entityId || props.contentDetail?.optimizeId || 0,
    assetId: assetStore.assetId,
    title: content.title || '',
    content: socialContent,
    hashtags,
    url: '',
    image: content.images?.[0]?.image || '',
    isSocialMediaContentReady: !!socialContent,
  }
})

// Methods
const updateParentContent = () => {
  emit('update:contentDetail', {
    title: content.title,
    category: content.category,
    content: content.body,
    seoKeywords: content.keywords.split(',').map(k => k.trim()).filter(Boolean),
    metaDescription: content.metaDescription,
    pictureUrls: content.images,
    entityId: entityId.value,
    keywordDetail: content.keywordDetail,
    socialMediaContent: content.socialMediaContent,
    socialMediaHashtags: content.socialMediaHashtags,
  })
}

const shareContent = async () => {
  if (!isFormValid.value) return

  sharing.value = true
  try {
    const formattedKeywords = content.keywords
        .split(',')
        .map(k => k.trim())
        .filter(Boolean)

    const response = await axios.post(entity.add, {
      assetId: assetStore.assetId,
      entityId: entityId.value,
      title: content.title,
      category: content.category,
      content: content.body,
      imageUrls: content.images,
      metaDescription: content.metaDescription,
      seoKeywords: formattedKeywords,
    })
    entityId.value = response.data.entityId || response.data.optimizeId
    responseHandlerService.handleSuccess(t('entity.components.newContent.responseHandlers.saveSuccess'))
    emit('content-saved')
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.newContent.responseHandlers.saveError'))
  } finally {
    sharing.value = false
  }
}

const openReviseModal = (field, imageIndex = null) => {
  if (field === 'image') {
    selectedMessage.value = content.images
    selectedType.value = contentCategory.image
    selectedData.value = {
      imageIndex,
      imageUrls: content.images,
      keywords: content.keywords,
      content: content.body,
    }
  } else {
    selectedMessage.value = content[field]
    selectedType.value = contentCategory[field]
    selectedData.value = { field }
  }
  showReviseModal.value = true
}

const handleReviseConfirm = (responseData) => {
  if (!responseData?.data) return
  const { data, field } = responseData

  if (field === 'image') {
    if (data.imageUrls) content.images = data.imageUrls.filter(img => img && img.image)
    if (data.content) content.body = data.content
    if (data.keywords) content.keywords = formatSeoKeywords(data.keywords)
    if (data.keywordDetail) content.keywordDetail = safeParse(data.keywordDetail, {}, 'object')
  } else if (field && data[field]) {
    content[field] = data[field]
  }

  if (data.socialMediaContent) content.socialMediaContent = data.socialMediaContent
  if (data.socialMediaHashtags) content.socialMediaHashtags = safeParse(data.socialMediaHashtags, [], 'array')
  if (responseData.data?.keywordDetail) content.keywordDetail = safeParse(responseData.data.keywordDetail, {}, 'object')
  if (responseData.optimizeId) entityId.value = responseData.optimizeId

  updateParentContent()
  emit('response', responseData)
}

const handleImageUpload = (file) => {
  content.images.push(URL.createObjectURL(file))
  updateParentContent()
}

const handleReset = () => {
  Object.keys(content).forEach(key => {
    if (Array.isArray(content[key])) content[key] = []
    else content[key] = ''
  })
  entityId.value = ''
  formErrors.value = {}

  updateParentContent()
  emit('reset')
}

const handleModalClosed = () => {
  nextTick(() => {
    dummyFocusRef.value?.focus()
  })
}

const formatSeoKeywords = (keywords) => {
  if (!keywords) return ''

  if (Array.isArray(keywords)) {
    return keywords
        .map(k => {
          if (typeof k === 'string') return k
          return k && typeof k === 'object' && 'name' in k ? k.name : ''
        })
        .filter(Boolean)
        .join(', ')
  }

  const parsedKeywords = safeParse(keywords, '', 'any')
  return typeof parsedKeywords === 'string' ? parsedKeywords : ''
}

// Watchers
watch(
    () => props.contentDetail,
    (newVal) => {
      if (newVal) {
        content.title = newVal.title || ''
        content.category = newVal.category || ''
        content.body = newVal.content || ''
        content.keywords = formatSeoKeywords(newVal.seoKeywords)
        content.metaDescription = newVal.metaDescription || ''
        content.images = (newVal.pictureUrls || []).filter(img => img && img.image)
        content.keywordDetail = safeParse(newVal.keywordDetail, {}, 'object')
        entityId.value = newVal.entityId || newVal.optimizeId || ''
        content.socialMediaContent = newVal.socialMediaContent || ''
        content.socialMediaHashtags = safeParse(newVal.socialMediaHashtags, [], 'array')
      }
    },
    { immediate: true, deep: true },
)

// Expose methods to parent
defineExpose({
  handleReset,
  updateParentContent,
})
</script>