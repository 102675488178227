import { distance } from 'fastest-levenshtein'

// Keyword işlemleri için utility
export const KeywordUtils = {
  // Temel normalizasyon
  normalize: (keyword) => {
    if (!keyword) return ''
    return keyword
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ')         // çoklu boşlukları teke indir
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '') // noktalama işaretlerini kaldır
  },

  // İki kelime arasındaki benzerlik kontrolü
  areSimilar: (str1, str2, threshold = 0.15) => { // threshold değeri ayarlanabilir (0.15 = %85 benzerlik)
    const normalized1 = KeywordUtils.normalize(str1)
    const normalized2 = KeywordUtils.normalize(str2)

    // Tam eşleşme kontrolü
    if (normalized1 === normalized2) return true

    // Levenshtein mesafesi kontrolü
    const maxLength = Math.max(normalized1.length, normalized2.length)
    if (maxLength === 0) return false

    const dist = distance(normalized1, normalized2)
    const similarity = 1 - (dist / maxLength)

    return similarity > (1 - threshold)
  },

  // Array içinde benzer kelime kontrolü
  findSimilarInArray: (keyword, array) => {
    const normalizedKeyword = KeywordUtils.normalize(keyword)

    return array.find(item => {
      const normalizedItem = KeywordUtils.normalize(item.name)
      return KeywordUtils.areSimilar(normalizedKeyword, normalizedItem)
    })
  },

  // Toplu benzerlik kontrolü
  findAllSimilarInArray: (keyword, array) => {
    const normalizedKeyword = KeywordUtils.normalize(keyword)

    return array.filter(item => {
      const normalizedItem = KeywordUtils.normalize(item.name)
      return KeywordUtils.areSimilar(normalizedKeyword, normalizedItem)
    })
  },

  // Excel'den yüklenen keywordlerin kontrolü
  validateImportedKeywords: (keywords, existingKeywords, maxKeywords) => {
    const results = {
      valid: [],
      duplicates: [],
      invalid: [],
      limitExceeded: [],
    }

    const processedKeywords = new Map()
    let processedCount = 0

    keywords.forEach(keyword => {
      // Boş kontrol
      if (!keyword.name?.trim()) {
        results.invalid.push({ keyword, reason: 'empty' })
        return
      }

      const normalizedKeyword = KeywordUtils.normalize(keyword.name)

      // Excel içi duplicate kontrol
      if (processedKeywords.has(normalizedKeyword)) {
        results.duplicates.push({
          keyword,
          existingKeyword: processedKeywords.get(normalizedKeyword),
        })
        return
      }

      // Limit kontrol
      if (processedCount >= maxKeywords) {
        results.limitExceeded.push(keyword)
        return
      }

      // Mevcut keywordlerle benzerlik kontrolü
      const similarKeyword = KeywordUtils.findSimilarInArray(
          normalizedKeyword,
          existingKeywords.filter(k => k.state !== 3),
      )

      if (similarKeyword) {
        results.duplicates.push({
          keyword,
          existingKeyword: similarKeyword,
        })
        return
      }

      // Geçerli keyword
      results.valid.push(keyword)
      processedKeywords.set(normalizedKeyword, keyword)
      processedCount++
    })

    return results
  },
}