<template>
  <div class="relative" data-menu="notifications" v-click-outside="handleClickOutside">
    <!-- Trigger Button (aynı) -->
    <button @click="toggleMenu('notifications')" class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 focus:outline-none transition-colors" :class="menuStore.isOpen('notifications') ? 'text-primary bg-gray-100' : 'text-gray-600'">
      <span class="material-symbols-outlined !text-[18px]">notifications</span>
      <span v-if="unreadNotifications.length > 0" class="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full min-w-[16px] h-[16px]">
        {{ unreadNotifications.length }}
      </span>
    </button>

    <!-- Dropdown Menu -->
    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <div v-if="menuStore.isOpen('notifications')" class="fixed md:absolute inset-x-0 md:inset-x-auto top-14 md:top-auto md:right-0 bg-white shadow-lg z-50 border border-gray-200
          md:w-[320px] lg:w-[380px] xl:w-[420px] md:mt-1 md:rounded-lg
          max-h-[480px] md:max-h-[640px] flex flex-col">

        <!-- Header -->
        <div class="flex-none px-3 py-1.5 text-base font-medium text-gray-700 border-b border-gray-200">
          {{ t('navigation.layout.components.notifications.title') }}
        </div>

        <!-- Tabs -->
        <div class="flex-none flex border-b border-gray-200">
          <button @click="currentTab = 'new'" class="flex-1 py-1.5 text-xs font-medium" :class="currentTab === 'new' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'">
            {{ t('navigation.layout.components.notifications.new') }}
          </button>
          <button @click="currentTab = 'all'" class="flex-1 py-1.5 text-xs font-medium" :class="currentTab === 'all' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'">
            {{ t('navigation.layout.components.notifications.all') }}
          </button>
        </div>

        <!-- Content Area -->
        <div class="flex-1 overflow-y-auto">
          <template v-if="currentTab === 'new'">
            <div v-if="unreadNotifications.length === 0" class="px-3 py-2 text-xs text-gray-500">
              {{ t('navigation.layout.components.notifications.noNewNotifications') }}
            </div>
            <div v-else v-for="(notification, index) in unreadNotifications" :key="index" class="px-3 py-1.5 hover:bg-gray-50 flex justify-between items-center group border-b border-gray-50 last:border-0">
              <div>
                <p class="text-xs font-medium text-gray-900">{{ notification.message }}</p>
                <p class="text-xs text-gray-500">{{ notification.time }}</p>
              </div>
              <button @click="markAsRead(notification)" class="text-xs text-primary opacity-0 group-hover:opacity-100 transition-opacity">
                {{ t('navigation.layout.components.notifications.markAsRead') }}
              </button>
            </div>
          </template>

          <template v-else>
            <div v-if="allNotifications.length === 0" class="px-3 py-2 text-xs text-gray-500">
              {{ t('navigation.layout.components.notifications.noNotifications') }}
            </div>
            <div v-else v-for="(notification, index) in allNotifications" :key="index" class="px-3 py-1.5 hover:bg-gray-50 border-b border-gray-50 last:border-0">
              <p class="text-xs font-medium text-gray-900">{{ notification.message }}</p>
              <p class="text-xs text-gray-500">{{ notification.time }}</p>
            </div>
          </template>
        </div>

        <!-- Footer -->
        <div v-if="allNotifications.length > 0" class="flex-none px-3 py-1.5 border-t border-gray-100 flex justify-between items-center">
          <span class="text-xs text-gray-500">
            {{ allNotifications.length }} {{ t('navigation.layout.components.notifications.total') }}
          </span>
          <button @click="markAllAsRead" class="text-xs text-primary hover:text-primary-dark">
            {{ t('navigation.layout.components.notifications.markAllAsRead') }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useMenuStore } from '@/stores/menuStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const menuStore = useMenuStore()

const currentTab = ref('new')
const allNotifications = ref([])
const unreadNotifications = ref([])

const toggleMenu = (menuId) => {
  menuStore.toggleMenu(menuId)
}

const handleClickOutside = (event) => {
  const isClickInsideAnyMenu = event.target.closest('[data-menu]')
  if (!isClickInsideAnyMenu) {
    menuStore.closeAll()
  }
}

const addNotification = (notification) => {
  const newNotification = {
    ...notification,
    time: new Date().toLocaleTimeString(),
    read: false,
  }
  allNotifications.value.unshift(newNotification)
  unreadNotifications.value.unshift(newNotification)
}

const markAsRead = (notification) => {
  notification.read = true
  unreadNotifications.value = unreadNotifications.value.filter(n => !n.read)
}

const markAllAsRead = () => {
  allNotifications.value.forEach(n => n.read = true)
  unreadNotifications.value = []
}
</script>