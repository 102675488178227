<template>
  <div class="bg-white p-4 rounded-md w-full">
    <h2 class="text-xl font-semibold text-gray-800 mb-4">
      {{ t('settings.components.sources.websiteConfiguration') }} </h2>
    <div class="space-y-4">
      <custom-table :data="sourcesDataWithIds" :columns="tableColumns" :is-loading="false" :show-search="false" :show-sort="false" :show-filter="false">
        <template #table-actions>
          <custom-button icon="add" iconPosition="right" @click="addSource" :button-text="t('settings.components.sources.components.sourceTable.addSourceButton')" size="sm"/>
        </template>

        <template #category="{ item }">
          <CustomDropdown v-model="item.sourceCategoryId" :options="localizedCategoryOptions" :loading="false" size="sm" :show-search="true" :show-error="false"/>
        </template>

        <template #getUrl="{ item }" v-if="Number(assetServiceType) !== 2">
          <custom-input icon="link" :show-icon="true" v-model="item.getUrl" size="sm" :show-error="false"/>
        </template>

        <template #addUrl="{ item }">
          <custom-input icon="link" :show-icon="true" v-model="item.addUrl" size="sm" :show-error="false"/>
        </template>

        <template #editUrl="{ item }" v-if="Number(assetServiceType) !== 2">
          <custom-input icon="link" :show-icon="true" v-model="item.editUrl" size="sm" :show-error="false"/>
        </template>

        <template #actions="{ item }">
          <div class="flex items-center justify-center">
            <button v-if="item.id !== 1" @click.stop="removeSource(item.id)" class="text-lobster-500 hover:text-lobster-600">
              <span class="material-symbols-outlined text-sm">delete</span>
            </button>
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import CustomDropdown from '@/components/base/inputs/VDropdown.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomTable from '@/components/table/VTable.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'

// Static categories data with translations
const categories = [
  {
    id: 1,
    translations: {
      tr: 'Hizmetler',
      en: 'Services',
    },
  },
  {
    id: 2,
    translations: {
      tr: 'Blog',
      en: 'Blog',
    },
  },
  {
    id: 3,
    translations: {
      tr: 'Hakkımızda',
      en: 'About Us',
    },
  },
  {
    id: 4,
    translations: {
      tr: 'Sosyal Medya',
      en: 'Social Media',
    },
  },
  {
    id: 5,
    translations: {
      tr: 'Kategoriler',
      en: 'Categories',
    },
  },
  {
    id: 7,
    translations: {
      tr: 'İletişim',
      en: 'Contact',
    },
  },
  {
    id: 8,
    translations: {
      tr: 'Lokalizasyon',
      en: 'Localization',
    },
  },
  {
    id: 9,
    translations: {
      tr: 'Görsel',
      en: 'Image',
    },
  },
  {
    id: 10,
    translations: {
      tr: 'Tek İçerik',
      en: 'Single Content',
    },
  },
]

const props = defineProps({
  useStore: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  assetServiceTypeProp: {
    type: Number,
    default: 1,
  },
})

const emit = defineEmits(['update:modelValue'])

const assetStore = useAssetStore()
const { t, locale } = useI18n()

const localizedCategoryOptions = computed(() => {
  return categories.map(category => ({
    value: category.id,
    label: category.translations[locale.value] || category.translations.en,
  }))
})

let nextId = ref(1)
const sourcesData = ref(props.useStore ? assetStore.assetData.sources : props.modelValue)

const sourcesDataWithIds = computed(() => {
  return sourcesData.value.map((item, index) => ({
    ...item,
    id: item.id || index + 1,
  }))
})

const assetServiceType = computed(() => {
  if (props.useStore) {
    return assetStore.assetServiceType
  }
  return props.assetServiceTypeProp
})

const tableColumns = computed(() => [
  {
    key: 'category',
    label: t('settings.components.sources.components.sourceTable.categoryHeader'),
  },
  ...(Number(assetServiceType.value) !== 2 ? [
    {
      key: 'getUrl',
      label: t('settings.components.sources.components.sourceTable.getUrlHeader'),
    },
  ] : []),
  {
    key: 'addUrl',
    label: t('settings.components.sources.components.sourceTable.addUrlHeader'),
  },
  ...(Number(assetServiceType.value) !== 2 ? [
    {
      key: 'editUrl',
      label: t('settings.components.sources.components.sourceTable.editUrlHeader'),
    },
  ] : []),
  {
    key: 'actions',
    label: '#',
    width: 'w-1/12',
    align: 'center',
  },
])

const addSource = () => {
  nextId.value++
  const newSource = {
    id: nextId.value,
    sourceCategoryId: '',
    getUrl: '',
    addUrl: '',
    editUrl: '',
  }
  sourcesData.value = [...sourcesData.value, newSource]
  updateSources(sourcesData.value)
}

const removeSource = (id) => {
  sourcesData.value = sourcesData.value.filter(item => item.id !== id)
  updateSources(sourcesData.value)
}

const updateSources = (newSources) => {
  if (props.useStore) {
    assetStore.setSources(newSources)
  } else {
    emit('update:modelValue', newSources)
  }
}

onMounted(() => {
  nextId.value = Math.max(...sourcesData.value.map(item => item.id || 0), 0)
})

watch(() => props.modelValue, (newValue) => {
  if (!props.useStore) {
    sourcesData.value = newValue
  }
}, { deep: true })

watch(() => assetStore.assetData.sources, (newValue) => {
  if (props.useStore) {
    sourcesData.value = newValue
  }
}, { deep: true })
</script>