<template>
  <div class="relative w-full" ref="componentRef">
    <label v-if="label" :class="['block font-semibold text-sm mb-1', labelClass]"> {{ label }} <span v-if="required" class="text-red-500">*</span> </label>
    <p v-if="description" :class="['mb-2 text-xs text-gray-500', descriptionClass]">
      {{ description }} </p>

    <CustomDropdown :options="languageOptions" v-model="selectedLanguageId" :label="placeholder" :show-search="true" :loading="loading" :disabled="disabled" :error="error" size="sm" @update:modelValue="handleLanguageChange" @search="handleSearch"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import CustomDropdown from '@/components/base/inputs/VDropdown.vue'
import { useUIStore } from '@/stores/ui'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: [String, Number],
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  labelClass: { type: String, default: 'text-secondary' },
  descriptionClass: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  showSearch: { type: Boolean, default: true },
  error: { type: String, default: '' },
})

const uiStore = useUIStore()
const emit = defineEmits(['update:modelValue', 'langageSelected'])

const componentRef = ref(null)
const languages = ref([])
const loading = ref(true)
const selectedLanguageId = ref(props.modelValue)
const searchQuery = ref('')

const languageOptions = computed(() => {
  if (!languages.value?.length) return []

  return languages.value
      .filter(lang => {
        if (!searchQuery.value) return true
        return lang.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            lang.shortName.toLowerCase().includes(searchQuery.value.toLowerCase())
      })
      .map(lang => ({
        value: lang.id,
        label: lang.name,
      }))
})

const handleLanguageChange = (languageId) => {
  selectedLanguageId.value = languageId
  emit('langageSelected', languageId)
  emit('update:modelValue', languageId)
}

const handleSearch = (query) => {
  searchQuery.value = query || ''
}

const setLanguageByShortName = () => {
  const matchingLanguage = languages.value.find(lang => lang.shortName === uiStore.language)
  if (matchingLanguage) {
    selectedLanguageId.value = matchingLanguage.id
    emit('langageSelected', matchingLanguage.id)
    emit('update:modelValue', matchingLanguage.id)
  }
}

const fetchLanguages = async () => {
  try {
    loading.value = true
    const response = await axios.get(assets.languages)
    languages.value = response.data.data || []

    if (!props.modelValue) {
      setLanguageByShortName()
    } else {
      selectedLanguageId.value = props.modelValue
    }
  } catch (error) {
    console.error('Error fetching languages:', error)
    languages.value = []
    responseHandlerService.handleError('Diller yüklenirken bir hata oluştu')
  } finally {
    loading.value = false
  }
}

// Props değişimini izle
watch(() => props.modelValue, (newValue) => {
  if (newValue !== selectedLanguageId.value) {
    if (newValue) {
      selectedLanguageId.value = newValue
    } else {
      setLanguageByShortName()
    }
  }
}, { immediate: true })

// Component mount olduğunda dilleri getir
onMounted(() => {
  fetchLanguages()
})
</script>
