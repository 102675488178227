<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'contents'" @change-tab="handleTabChange">
    <template #right-content></template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" :key="currentTab" ref="tabRef" v-bind="$attrs"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TabNavigation from '@/components/base/navigation/TabNavigation.vue'
import contentList from './components/contentList/index.vue'
import newContent from './components/newContent/index.vue'
import videoContent from './components/newVideoContent/index.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'contents')

const tabs = computed(() => [
  { name: 'contents', label: t('entity.tabs.contents'), component: markRaw(contentList) },
  { name: 'new-content', label: t('entity.tabs.new_content'), component: markRaw(newContent) },
  // { name: 'new-video-content', label: t('entity.tabs.new_video_content'), component: markRaw(videoContent) },
])

const currentTabComponent = computed(() => {
  const tab = tabs.value.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

watch(() => route.query.tab, (newTab) => {
  currentTab.value = newTab || 'contents'
})
</script>
