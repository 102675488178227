<template>
  <customDropdown :options="categoryOptions" :model-value="modelValue" :loading="loading" @update:modelValue="$emit('update:modelValue', $event)" :placeholder="$t('assets.components.create.components.source.components.sourceCategory.select.notSelected')"/>
</template>

<script setup>
import { ref, computed } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import customDropdown from '@/components/base/inputs/VDropdown.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])

// States
const categories = ref([])
const loading = ref(true)

// Computed
const categoryOptions = computed(() => {
  return categories.value.map(category => ({
    value: category.id,
    label: category.name,
  }))
})

// Methods
const getCategories = async () => {
  loading.value = true
  try {
    const response = await axios.get(assets.categories)
    categories.value = response.data.data
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    loading.value = false
  }
}

// Lifecycle
getCategories()
</script>