<template>
  <div class="p-6 w-full">
    <stepController :steps="localizedSteps" persist-key="asset-creation" :loading="assetStore.assetData.loading" @step-change="handleStepChange" @complete="handleComplete"/>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useAssetStore } from '@/stores/asset'
import { useStepsStore } from '@/stores/steps'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { markRaw } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import stepController from '@/components/features/step-controller/index.vue'

import informationStep from './components/information/index.vue'
import analyseStep from './components/analyse/index.vue'
import introductionStep from './components/introduction/index.vue'
import sourcesStep from './components/source/index.vue'
import subscriptionStep from './components/subscriptions/index.vue'
import paymentStep from './components/payment/index.vue'
import BillingForm from './components/payment/components/BillingForm.vue'
import PaymentMethods from './components/payment/components/PaymentMethods.vue'
import PackageSummary from './components/payment/components/PackageSummary.vue'

const assetStore = useAssetStore()
const stepsStore = useStepsStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const servicesTypes = {
  service: 1,
  crawler: 2,
}

const currentServiceType = computed(() => assetStore.assetData.serviceType)

const getStepsByServiceType = (serviceType) => {
  let steps = [
    {
      component: markRaw(introductionStep),
      ref: 'introduction',
      label: 'assets.components.create.steps.introduction',
    },
    {
      component: markRaw(subscriptionStep),
      ref: 'subscription',
      label: 'assets.components.create.steps.subscription',
    },
  ]

  if (serviceType === servicesTypes.service) {
    steps.push(
        {
          component: markRaw(sourcesStep),
          ref: 'sources',
          label: 'assets.components.create.steps.sources',
          buttonText: 'assets.components.create.steps.addSources',
          buttonLoadingText: 'assets.components.create.steps.addingSources',
        },
        {
          component: markRaw(informationStep),
          ref: 'information',
          label: 'assets.components.create.steps.information',
        },
    )
  }

  steps.push(
      {
        component: markRaw(paymentStep),
        ref: 'payment',
        label: 'assets.components.create.steps.payment',
        nestedSteps: [
          {
            component: markRaw(BillingForm),
            ref: 'billing',
            label: 'assets.components.create.steps.nestedSteps.billing',
          },
          {
            component: markRaw(PaymentMethods),
            ref: 'method',
            label: 'assets.components.create.steps.nestedSteps.method',
          },
          {
            component: markRaw(PackageSummary),
            ref: 'confirmation',
            label: 'assets.components.create.steps.nestedSteps.confirmation',
          },
        ],
      },
      {
        component: markRaw(analyseStep),
        ref: 'analyse',
        label: 'assets.components.create.steps.analyse',
      },
  )

  return steps
}

const steps = computed(() => getStepsByServiceType(currentServiceType.value))

const localizedSteps = computed(() => {
  return steps.value.map((step) => {
    const localizedStep = {
      ...step,
      label: t(step.label),
      buttonText: step.buttonText ? t(step.buttonText) : undefined,
      buttonLoadingText: step.buttonLoadingText ? t(step.buttonLoadingText) : undefined,
    }

    if (step.nestedSteps) {
      localizedStep.nestedSteps = step.nestedSteps.map((nestedStep) => ({
        ...nestedStep,
        label: t(nestedStep.label),
      }))
    }

    return localizedStep
  })
})

const handleStepChange = async (stepIndex) => {
  await stepsStore.setCurrentStep({ stepIndex })
}

const handleComplete = async () => {
  await router.push('/assets')
}

async function getAsset(id) {
  await assetStore.setAssetInputLoading(true)
  try {
    const res = await axios.get(assets.get, { params: { assetId: id } })
    const { asset, locations } = res.data.data

    await assetStore.setIntroductionList({
      name: asset.name || '',
      website: asset.website || '',
      languageId: asset.languageId || 1,
      searchEngineLocations: {
        google: locations.google || [],
        bing: locations.bing || [],
        yandex: locations.yandex || [],
      },
      communicationId: asset.communicationId || 0,
    })
  } catch (error) {
    console.error(error)
  } finally {
    await assetStore.setAssetInputLoading(false)
  }
}

watch(
    () => currentServiceType.value,
    async () => {
      await stepsStore.resetStepManagement()
    },
)

const id = route.params.id
if (id) {
  getAsset(id)
}
</script>
