<template>
  <div class="p-4 bg-slate-50 rounded-lg">
    <h3 class="font-medium text-sm mb-2">
      {{ t(`entity.components.contentReview.${title}`) }} </h3>
    <p class="text-sm text-slate-600">{{ message }}</p>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  title: String,
  message: String,
})
</script>
