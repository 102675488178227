<template>
  <div class="mx-auto px-4 sm:px-6 pt-6 sm:pt-8 pb-6 min-h-fit sm:min-h-[70vh] bg-white rounded-lg shadow-md border border-gray-100">
    <div class="text-center max-w-4xl mx-auto">
      <h1 class="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
        <span class="block bg-gradient-to-r from-primary to-primary-dark bg-clip-text text-transparent leading-tight">
          {{ t('entity.components.newContent.title') }}
        </span> <span class="block text-primary mt-2 text-xl sm:text-2xl md:text-3xl">
          {{ t('entity.components.newContent.subTitle') }}
        </span>
      </h1>
      <p class="mt-4 sm:mt-6 mx-auto text-xs sm:text-sm md:text-base text-gray-600 max-w-2xl leading-relaxed px-2 sm:px-4">
        {{ t('entity.components.newContent.description') }} </p>
      <div class="mt-6 sm:mt-8 flex justify-center">
        <button @click="$emit('startCreating')" class="flex items-center px-6 sm:px-8 py-2.5 sm:py-3 text-xs sm:text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-lg transition-all duration-300 transform hover:scale-105 hover:shadow-lg">
          <span class="material-symbols-outlined !text-base sm:!text-[18px] mr-1.5 sm:mr-2">add_circle</span> {{ t('entity.components.newContent.createNewContent') }}
        </button>
      </div>
    </div>

    <div class="mt-8 sm:mt-12 md:mt-16 px-2 sm:px-4">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
        <FeatureCard icon="search" :title="t('entity.components.newContent.features.seo.title')" :description="t('entity.components.newContent.features.seo.description')"/>
        <FeatureCard icon="smart_toy" :title="t('entity.components.newContent.features.ai.title')" :description="t('entity.components.newContent.features.ai.description')"/>
        <FeatureCard icon="image" :title="t('entity.components.newContent.features.images.title')" :description="t('entity.components.newContent.features.images.description')"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import FeatureCard from './FeatureCard.vue'

const { t } = useI18n()

defineEmits(['startCreating'])
</script>