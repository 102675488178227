<template>
  <div class="component-layout">
    <div class="bg-white p-4 rounded-md shadow-md">
      <CustomTable :data="competitorsData" :columns="columns" :isLoading="isLoading" :min-height="'300px'" :max-height="'300px'" :min-width="'900px'" :total-count="totalCount" :page-count="pageCount" :current-page="currentPage" :items-per-page="itemsPerPage" @page-change="handlePageChange">
        <template v-slot:table-actions>
          <div class="flex justify-between items-center w-full">
            <h1 class="text-base font-semibold">
              {{ t('dashboard.components.competitorAnalysis.title') }} </h1>
            <CustomButton :button-text="t('common.add')" @click="showAddModal = true" size="sm" icon="add" icon-position="right" color="primary" :show-icon="true"/>
          </div>
        </template>

        <!-- Rank Column -->
        <template v-slot:rank="{ item, index }">
          {{ calculateRank(index) }}
        </template>

        <!-- Domain Column -->
        <template v-slot:domain="{ item }">
          <div class="flex items-center space-x-4 w-full text-xs">
            <a :href="item.domain" target="_blank" rel="noopener" class="text-blue-600">{{ item.domain }}</a>
          </div>
        </template>

        <!-- Average Position Column -->
        <template v-slot:avgPosition="{ item }">
          <Tooltip :title="t('dashboard.components.competitorAnalysis.tooltips.avgPosition')" :full-width="true" container-class="flex items-center justify-center">
            <template #content>
              <div class="flex items-center justify-center">
                <span>{{ formatDecimal(item.avgPosition, 2) }}</span>
              </div>
            </template>
          </Tooltip>
        </template>

        <!-- Median Position Column -->
        <template v-slot:medianPosition="{ item }">
          <Tooltip :title="t('dashboard.components.competitorAnalysis.tooltips.medianPosition')" :full-width="true" container-class="flex items-center justify-center">
            <template #content>
              <div class="flex items-center justify-center">
                <span>{{ formatDecimal(item.medianPosition, 0) }}</span>
              </div>
            </template>
          </Tooltip>
        </template>

        <!-- Keywords Count Column -->
        <template v-slot:keywordsCount="{ item }">
          <Tooltip :title="t('dashboard.components.competitorAnalysis.tooltips.keywordsCount')" :full-width="true" container-class="flex items-center justify-center">
            <template #content>
              <div class="flex items-center justify-center">
                <span>{{ formatLargeNumber(item.keywordsCount) }}</span>
              </div>
            </template>
          </Tooltip>
        </template>

        <!-- Visibility Column -->
        <template v-slot:visibility="{ item }">
          <Tooltip :title="t('dashboard.components.competitorAnalysis.tooltips.visibility')" :full-width="true" container-class="flex items-center justify-center">
            <template #content>
              <div class="flex items-center justify-center">
                <span>{{ formatMetric(item.visibility) }}</span>
              </div>
            </template>
          </Tooltip>
        </template>

        <!-- ETV Column -->
        <template v-slot:etv="{ item }">
          <Tooltip :title="t('dashboard.components.competitorAnalysis.tooltips.etv')" :full-width="true" container-class="flex items-center justify-center">
            <template #content>
              <div class="flex items-center justify-center">
                <span>{{ formatMetric(item.etv) }}</span>
              </div>
            </template>
          </Tooltip>
        </template>
      </CustomTable>
    </div>

    <!-- Add Domain Modal -->
    <AddDomainModal v-model="showAddModal" @domain-added="handleDomainAdded"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomTable from '@/components/table/VTable.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import CustomButton from '@/components/base/buttons/VButton.vue'
import AddDomainModal from './components/addCompetitor.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { formatDecimal, formatLargeNumber, formatMetric } from '@/utils/common'

const { t } = useI18n()
const assetStore = useAssetStore()

const competitors = ref([])
const isLoading = ref(true)
const totalCount = ref(0)
const pageCount = ref(1)
const currentPage = ref(1)
const itemsPerPage = ref(30)
const showAddModal = ref(false)

const columns = [
  { key: 'rank', label: '#', width: 'w-1/12' },
  { key: 'domain', label: t('dashboard.components.competitorAnalysis.columns.domain'), width: 'w-3/12' },
  { key: 'avgPosition', label: t('dashboard.components.competitorAnalysis.columns.avgPosition'), width: 'w-2/12', align: 'center' },
  { key: 'medianPosition', label: t('dashboard.components.competitorAnalysis.columns.medianPosition'), width: 'w-2/12', align: 'center' },
  { key: 'keywordsCount', label: t('dashboard.components.competitorAnalysis.columns.keywordsCount'), width: 'w-2/12', align: 'center' },
  { key: 'visibility', label: t('dashboard.components.competitorAnalysis.columns.visibility'), width: 'w-1/12', align: 'center' },
  { key: 'etv', label: t('dashboard.components.competitorAnalysis.columns.etv'), width: 'w-2/12', align: 'center' },
]

const competitorsData = computed(() => {
  return Array.isArray(competitors.value) ? competitors.value : []
})


const fetchCompetitors = async (page = 1) => {
  isLoading.value = true
  try {
    const response = await axios.get(dashboard.competitorAnalysis, {
      params: { assetId: assetStore.assetId, page: page - 1 },
    })
    const data = response.data.data
    competitors.value = data.detail
    totalCount.value = data.totalCount
    pageCount.value = data.pageCount
    currentPage.value = page
  } catch (error) {
    responseHandlerService.handleError(t('common.errors.fetchError'))
    competitors.value = []
    totalCount.value = 0
    pageCount.value = 1
  } finally {
    isLoading.value = false
  }
}

const handlePageChange = (newPage) => {
  fetchCompetitors(newPage)
}

const handleDomainAdded = () => {
  fetchCompetitors(1)
}

const calculateRank = (index) => {
  const baseIndex = (currentPage.value - 1) * itemsPerPage.value
  return baseIndex + index + 1
}

onMounted(() => {
  fetchCompetitors()
})
</script>