<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="bg-white p-4 space-y-4">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{ t('assets.components.create.components.introduction.information.titles.assetInformation') }} </h2>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center w-full">
        <customInput v-model="list.name" :label="t('assets.components.create.components.introduction.information.labels.assetName')" :description="t('assets.components.create.components.introduction.information.descriptions.enterAssetName')" :required="true" :loading="inputLoading" :error="errors.name" container-class="w-full" @update:model-value="handleChange"/>
        <customInput v-model="list.website" :label="t('assets.components.create.components.introduction.information.labels.website')" :description="t('assets.components.create.components.introduction.information.descriptions.enterWebsite')" :required="true" :loading="inputLoading" :error="errors.website" container-class="w-full" @update:model-value="handleChange"/>
      </div>
      <language-selector v-model="list.languageId" :label="t('assets.components.create.components.introduction.information.labels.languageSelection')" :description="t('assets.components.create.components.introduction.information.descriptions.selectLanguage')" :error="errors.languageId" @update:model-value="handleChange"/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import { useAssetStore } from '@/stores/asset'
import customInput from '@/components/base/inputs/VInput.vue'
import LanguageSelector from '@/components/base/inputs/VLanguageSelector.vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { createIntroductionSchema } from '@/validations/schemas/asset.create.introduction.schema'

const assetStore = useAssetStore()
const route = useRoute()
const { t } = useI18n()

const emit = defineEmits(['stepValidation', 'stepData'])
const errors = ref({})
const isValid = ref(false)

const inputLoading = computed(() => assetStore.assetData.inputLoading)
const list = computed(() => assetStore.assetData.introductionList)
const assets = computed(() => assetStore.assets)

const validationSchema = computed(() =>
    createIntroductionSchema(t, assets.value, !!route.params.id, route.params.id),
)

const validateForm = async () => {
  errors.value = {}

  try {
    await validationSchema.value.validate(list.value, { abortEarly: false })
    isValid.value = true
    emit('stepValidation', { isValid: true, errors: {} })
    return true
  } catch (error) {
    isValid.value = false
    if (error.inner) {
      error.inner.forEach(err => errors.value[err.path] = err.message)
    }
    emit('stepValidation', { isValid: false, errors: errors.value })
    return false
  }
}

const handleChange = () => {
  emit('stepData', list.value)
  validateForm()
}

watch(() => list.value, validateForm, { deep: true })

onMounted(validateForm)

defineExpose({
  validate: validateForm,
})
</script>