<template>
  <div class="relative bg-white p-4 rounded-lg">
    <div class="w-full overflow-hidden">
      <div ref="carouselContainer" class="flex transition-transform duration-300" @mousedown="startDrag" @mousemove="onDrag" @mouseup="stopDrag" @mouseleave="stopDrag" @touchstart="startDrag" @touchmove="onDrag" @touchend="stopDrag">
        <div v-for="(item, index) in items" :key="index" class="w-full flex-shrink-0 px-4">
          <slot name="item" :item="item" :index="index"></slot>
        </div>
      </div>
    </div>

    <button v-if="currentIndex > 0" @click="prev" class="absolute left-2 top-1/2 -translate-y-1/2 z-10 w-8 h-8 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-gray-50">
      <span class="material-symbols-outlined text-primary">chevron_left</span>
    </button>

    <button v-if="currentIndex < items.length - 1" @click="next" class="absolute right-2 top-1/2 -translate-y-1/2 z-10 w-8 h-8 rounded-full bg-white shadow-lg flex items-center justify-center hover:bg-gray-50">
      <span class="material-symbols-outlined text-primary">chevron_right</span>
    </button>

    <div class="absolute -bottom-4 left-1/2 -translate-x-1/2 flex gap-1.5">
      <button v-for="index in items.length" :key="index" @click="goToSlide(index - 1)" class="w-2 h-2 rounded-full transition-colors duration-200" :class="currentIndex === index - 1 ? 'bg-primary' : 'bg-slate-300'"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
})

const currentIndex = ref(0)
const carouselContainer = ref(null)
const isDragging = ref(false)
const startX = ref(0)
const scrollLeft = ref(0)

const translateX = computed(() => {
  return `translateX(-${currentIndex.value * 100}%)`
})

const updateCarouselPosition = () => {
  if (carouselContainer.value) {
    carouselContainer.value.style.transform = translateX.value
  }
}

const next = () => {
  if (currentIndex.value < props.items.length - 1) {
    currentIndex.value++
    updateCarouselPosition()
  }
}

const prev = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--
    updateCarouselPosition()
  }
}

const goToSlide = (index) => {
  currentIndex.value = index
  updateCarouselPosition()
}

const startDrag = (e) => {
  isDragging.value = true
  const pageX = e.type === 'mousedown' ? e.pageX : e.touches[0].pageX
  startX.value = pageX
  scrollLeft.value = currentIndex.value
}

const onDrag = (e) => {
  if (!isDragging.value) return
  e.preventDefault()

  const pageX = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX
  const diff = pageX - startX.value
  const containerWidth = carouselContainer.value?.offsetWidth || 0

  if (Math.abs(diff) > containerWidth / 4) {
    if (diff > 0 && currentIndex.value > 0) {
      prev()
      isDragging.value = false
    } else if (diff < 0 && currentIndex.value < props.items.length - 1) {
      next()
      isDragging.value = false
    }
  }
}

const stopDrag = () => {
  isDragging.value = false
}
</script>

<style scoped>
.transition-transform {
  transition: transform 0.3s ease-out;
}
</style>