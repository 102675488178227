import * as yup from 'yup'

// Base schema creator function
export const createIntroductionBaseSchema = (t) => ({
  name: yup
      .string()
      .required(t('form.errors.required'))
      .min(2, t('form.errors.minLength', { length: 2 }))
      .test('uniqueName', t('assets.components.create.components.introduction.information.errors.duplicateAssetName'),
          function (value) {
            return true
          }),

  website: yup
      .string()
      .required(t('form.errors.required'))
      .url(t('assets.components.create.components.introduction.information.errors.invalidWebsite')),

  languageId: yup
      .number()
      .required(t('form.errors.required')),
})

export const createIntroductionSchema = (t, assets = [], editMode = false, currentId = null) => {
  const schema = { ...createIntroductionBaseSchema(t) }

  // Name unique kontrolü için test fonksiyonunu override ediyoruz
  schema.name = schema.name.test('uniqueName',
      t('assets.components.create.components.introduction.information.errors.duplicateAssetName'),
      (value) => {
        if (editMode) {
          // Edit modunda, kendi id'si hariç diğer asset'lerle karşılaştır
          return !assets.some(asset =>
              asset.id !== currentId &&
              asset.name.trim().toLowerCase() === value?.trim().toLowerCase(),
          )
        }
        // Create modunda tüm asset'lerle karşılaştır
        return !assets.some(asset =>
            asset.name.trim().toLowerCase() === value?.trim().toLowerCase(),
        )
      },
  )

  return yup.object().shape(schema)
}

// Validation mesajları değiştiğinde şemayı yeniden oluşturmak için
export const refreshSchema = (t, assets, editMode, currentId) =>
    createIntroductionSchema(t, assets, editMode, currentId)