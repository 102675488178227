<template>
  <sideModal ref="historyModal" modalClass="w-full lg:w-10/12 xl:w-6/12" :isHeader="true" :headerTitle="$t('settings.components.configuration.components.memoryHistory.title')" @opened="getAll">
    <template v-slot:external>
      <customDialog v-model="showConfirmDialog" :title="$t('settings.components.configuration.components.memoryHistory.confirmDeleteTitle')" :message="$t('settings.components.configuration.components.memoryHistory.confirmDeleteMessage')" :showCancelButton="true" :cancelButtonText="$t('settings.components.configuration.components.memoryHistory.cancelButton')" :confirmButtonText="$t('settings.components.configuration.components.memoryHistory.confirmButton')" @confirm="confirmDelete" @decline="declineDelete" @update:show="showConfirmDialog = $event"/>
    </template>

    <template v-slot:content>
      <div class="p-4 w-full h-full flex flex-col">
        <!-- Search and filter section -->
        <div class="flex justify-between items-center px-4 mb-4">
          <search-sort-filter-box :filter-items="filterItems" :show-searchbar="true" :show-sort="true" :show-filter="true" @sort="handleSort" @filter="handleFilter" @search="handleSearch"/>
        </div>

        <!-- Main content area -->
        <div class="flex-1 relative">
          <!-- Loading state - centered in full height -->
          <div v-if="load" class="absolute inset-0 flex items-center justify-center">
            <loading :loading-text="$t('settings.components.configuration.components.memoryHistory.loadingText')" :showLoadingText="true" loadingTextClass="text-sm font-semibold"/>
          </div>

          <!-- Memory list with scroll -->
          <div v-else class="h-[550px] xl:h-[650px] 2xl:h-[650px] overflow-auto thin-scrollbar">
            <memory-list :memories="memoryList" @deleteMemory="deleteMemory"/>
          </div>
        </div>

        <!-- Pagination section -->
        <div class="mt-4 flex justify-end px-4" v-if="totalCount > 10">
          <pagination :totalCount="totalCount" :currentPage="current" :itemsPerPage="15" @page-changed="getAll"/>
        </div>
      </div>
    </template>
  </sideModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import loading from '@/components/loading/VLoading.vue'
import SideModal from '@/components/modals/VSideModal.vue'
import SearchSortFilterBox from '@/components/base/filters/index.vue'
import axios from '@/plugins/axiosInstance'
import { memories } from '@/networking/urlManager'
import MemoryList from '@/views/settings/components/configuration/components/memoryHistory/components/memoryList.vue'
import pagination from '@/components/base/pagination/index.vue'
import customDialog from '@/components/feedback/VDialog.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const historyModal = ref(null)
const load = ref(false)
const showConfirmDialog = ref(false)
const showReviseContentModal = ref(false)
const memoryToDelete = ref(null)
const searchQuery = ref('')
const current = ref(1)
const totalCount = ref(1)
const pageCount = ref(1)
const sort = ref('DESC')
const contentType = ref('')
const memoryList = ref([])

const filterItems = computed(() => [
  { id: 1, name: t('settings.components.configuration.components.memoryHistory.filterTitle') },
  { id: 2, name: t('settings.components.configuration.components.memoryHistory.filterCategory') },
  { id: 3, name: t('settings.components.configuration.components.memoryHistory.filterContent') },
  { id: 4, name: t('settings.components.configuration.components.memoryHistory.filterKeyword') },
  { id: 5, name: t('settings.components.configuration.components.memoryHistory.filterImage') },
  { id: 6, name: t('settings.components.configuration.components.memoryHistory.filterSeoDescription') },
])

const toggleSidebar = async () => {
  memoryList.value = []
  await historyModal.value.show()
  getAll()
}

const showModal = () => {
  showReviseContentModal.value = true
}

const getAll = (currentPage = current.value) => {
  load.value = true
  setTimeout(() => {
    axios.get(memories.getAll, {
      params: {
        search: searchQuery.value,
        page: currentPage,
        sort: sort.value,
        assetId: assetStore.assetId,
        contentType: contentType.value || '',
      },
    }).then((result) => {
      memoryList.value = (result.data.data?.detail || []).map(memory => ({ ...memory, deleting: false }))
      totalCount.value = result.data.data?.totalCount
      pageCount.value = result.data.data?.pageCount
    }).catch((err) => {
      responseHandlerService.handleError(err, t('settings.components.configuration.components.memoryHistory.fetchError'))
    }).finally(() => {
      load.value = false
    })
  }, 1000)
}

const deleteMemory = (id) => {
  memoryToDelete.value = id
  showConfirmDialog.value = true
}

const declineDelete = () => {
  const memory = memoryList.value.find(memory => memory.id === memoryToDelete.value)
  if (memory) memory.deleting = false
  else console.error(`Memory with id ${memoryToDelete.value} not found`)
}

const confirmDelete = async () => {
  showConfirmDialog.value = false
  const memory = memoryList.value.find(memory => memory.id === memoryToDelete.value)
  if (memory) {
    memory.deleting = true
  }
  try {
    await axios.delete(memories.delete, {
      params: {
        memoryId: memoryToDelete.value,
        assetId: assetStore.assetId,
      },
    })
    memoryList.value = memoryList.value.filter((memory) => memory.id !== memoryToDelete.value)
    totalCount.value--
    if (totalCount.value % 10 === 0) {
      pageCount.value--
    }
  } catch (err) {
    responseHandlerService.handleError(err, t('settings.components.configuration.components.memoryHistory.deleteError'))
  } finally {
    memoryToDelete.value = null
  }
}

const handleSort = (sortOption) => {
  sort.value = sortOption
  getAll()
}

const handleFilter = (filterOption) => {
  contentType.value = filterOption
  getAll()
}

const handleSearch = (query) => {
  searchQuery.value = query
  getAll()
}

defineExpose({
  toggleSidebar,
  showModal,
  getAll,
  deleteMemory,
  declineDelete,
  confirmDelete,
  handleSort,
  handleFilter,
  handleSearch,
})
</script>