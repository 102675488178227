<template>
  <div class="component-layout">
    <div class="bg-white shadow-md rounded-md overflow-hidden">
      <div class="border-b border-gray-200 px-4 py-3 flex items-center justify-between">
        <h2 class="text-lg font-semibold text-gray-900">{{ getPageTitle }}</h2>
        <CustomButton v-if="selectedContent" :button-text="t('entity.backToList')" icon="arrow_back" color="secondary" size="sm" @click="selectedContent = null"/>
      </div>

      <div v-if="!selectedContent" class="bg-white rounded-md w-full">
        <customTable :data="myData" :columns="tableColumns" v-bind="tableProps" @row-click="handleRowClick" @search="handleSearch" @sort="handleSort" @filter="handleFilter" @page-change="handlePageChange" :isLoading="isInitialLoading || isLoading">
          <template #table-actions>
            <CustomButton icon="add" iconPosition="right" @click="handleNewContentClick" :button-text="t('entity.newContentButton')" size="sm"/>
          </template>

          <template #title="{ item }">
            <Tooltip :title="item.title" :display-condition="titleTooltipVisible" justify-items="start">
              <template #content>
                <span @mouseover="checkIfTextOverflows" class="text-sm">{{ item.title }}</span>
              </template>
            </Tooltip>
          </template>

          <template #status="{ item }">
            <div class="flex items-center justify-center h-full text-gray-500">
              <span class="material-symbols-outlined !text-[12px]">radio_button_checked</span>
              <p class="ml-2 text-xs text-center ">{{ getEntityStatus(item.entityTypeId) }}</p>
            </div>
          </template>

          <template #publicationStatus="{ item }">
            <div class="flex items-center justify-center h-full" :class="getPublicationStatusColor(item.statusId)">
              <span class="material-symbols-outlined !text-[18px]">{{ getPublicationStatusIcon(item.statusId) }}</span>
              <p class="ml-2 text-xs text-center">{{ getPublicationStatus(item.statusId) }}</p>
            </div>
          </template>

          <template #date="{ item }">
            <div class="flex justify-center text-center">
              <span class="material-symbols-outlined !text-[16px]">calendar_month</span>
              <p class="w-36 text-xs">
                {{ formatDate(item) }} </p>
            </div>
          </template>

          <template #actions>
            <div class="flex items-center justify-center">
              <span class="material-symbols-outlined cursor-pointer !text-[18px] text-secondary">add_chart</span>
            </div>
          </template>
        </customTable>
      </div>

      <ContentReview v-else :selected-content="selectedContent" @content-updated="handleContentUpdated"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { dateHelper } from '@/utils/dateHelper'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useAssetStore } from '@/stores/asset'
import customTable from '@/components/table/VTable.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import ContentReview from '@/views/contents/components/reviewContent/index.vue'

// Enums
const EntityTypeEnum = {
  INTERNAL_SOURCE: 1,
  OUT_SOURCE: 2,
  OPTIMIZED: 3,
  AUTO_OPTIMIZING: 4,
  AUTO_OPTIMIZED: 5,
  REVISED: 6,
  TREND_ARTICLE: 7,
  PLANNED_CONTENT: 8,
}

const EntityStatusEnum = {
  PUBLISHED: 1,
  DRAFT: 2,
}

const { t } = useI18n()
const router = useRouter()
const assetStore = useAssetStore()

// State
const isInitialLoading = ref(true)
const isLoading = ref(false)
const myData = ref([])
const currentPage = ref(1)
const pageCount = ref(1)
const totalCount = ref(0)
const itemsPerPage = ref(15)
const sortKey = ref('')
const sortOrder = ref('DESC')
const titleTooltipVisible = ref(false)
const searchQuery = ref('')
const selectedFilter = ref(null)
const selectedContent = ref(null)

// Table Configuration
const tableColumns = computed(() => [
  { key: 'title', label: t('entity.tableHeaders.title'), sortable: false, width: 'w-5/12', align: 'left' },
  { key: 'score', label: t('entity.tableHeaders.score'), sortable: true, width: 'w-1/12', align: 'center' },
  { key: 'status', label: t('entity.tableHeaders.status'), sortable: false, width: 'w-1/12', align: 'center' },
  { key: 'publicationStatus', label: t('entity.tableHeaders.publicationStatus'), sortable: false, width: 'w-2/12', align: 'center' },
  { key: 'date', label: t('entity.tableHeaders.date'), sortable: true, width: 'w-2/12', align: 'center' },
  { key: 'actions', label: '#', sortable: false, width: 'w-1/12', align: 'center' },
])

const filterItems = [
  { id: 1, name: t('entity.filterItems.all') },
  { id: 2, name: t('entity.filterItems.optimized') },
  { id: 3, name: t('entity.filterItems.autoOptimized') },
  { id: 4, name: t('entity.filterItems.scheduled') },
  { id: 5, name: t('entity.filterItems.notOptimized') },
]

// Computed
const tableProps = computed(() => ({
  isLoading: isLoading.value,
  itemsPerPage: itemsPerPage.value,
  totalCount: totalCount.value,
  pageCount: pageCount.value,
  currentPage: currentPage.value,
  sortKey: sortKey.value,
  sortOrder: sortOrder.value,
  searchIcon: 'search',
  searchPlaceholder: t('entity.searchPlaceholder'),
  showSearch: true,
  showFilter: true,
  showSearchText: false,
  filterOptions: [{
    id: 'status',
    icon: 'filter_alt',
    iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
    showText: false,
    items: filterItems,
  }],
  minWidth: '80vh',
  minHeight: '53vh',
  maxHeight: '53vh',
}))

const getPageTitle = computed(() =>
    selectedContent.value > 0
        ? t('socialMedia.components.posts.pageTitle.contentInspection')
        : t('socialMedia.components.posts.pageTitle.contentManagement'),
)

const getPublicationStatus = (status) => {
  const statuses = {
    [EntityStatusEnum.PUBLISHED]: t('entity.publicationStatus.published'),
    [EntityStatusEnum.DRAFT]: t('entity.publicationStatus.draft'),
  }
  return statuses[status] || t('entity.publicationStatus.unknown')
}

const getPublicationStatusIcon = (status) => {
  switch (status) {
    case EntityStatusEnum.PUBLISHED:
      return 'task_alt'
    case EntityStatusEnum.DRAFT:
      return 'edit_note'
    default:
      return 'help'
  }
}

const getPublicationStatusColor = (status) => {
  switch (status) {
    case EntityStatusEnum.PUBLISHED:
      return 'text-green-500'
    case EntityStatusEnum.DRAFT:
      return 'text-blue-500'
    default:
      return 'text-gray-500'
  }
}

const formatDate = (item) => {
  return item.entityTypeId !== EntityTypeEnum.OUT_SOURCE
      ? dateHelper.formatLocalized(item.addDate, 'medium', { type: 'datetime' })
      : t('entity.unknown')
}

const getEntityStatus = (entityTypeId) => {
  const statuses = {
    [EntityTypeEnum.INTERNAL_SOURCE]: 'entity.statuses.newContent',
    [EntityTypeEnum.OUT_SOURCE]: 'entity.statuses.existingContent',
    [EntityTypeEnum.OPTIMIZED]: 'entity.statuses.optimized',
    [EntityTypeEnum.AUTO_OPTIMIZING]: 'entity.statuses.autoOptimizing',
    [EntityTypeEnum.AUTO_OPTIMIZED]: 'entity.statuses.autoOptimized',
    [EntityTypeEnum.REVISED]: 'entity.statuses.revised',
    [EntityTypeEnum.TREND_ARTICLE]: 'entity.statuses.trendContent',
    [EntityTypeEnum.PLANNED_CONTENT]: 'entity.statuses.plannedContent',
  }
  return t(statuses[entityTypeId] || 'entity.statuses.unknown')
}

const fetchData = async () => {
  try {
    isLoading.value = true
    const { data } = await axios.get(entity.getAll, {
      params: {
        assetId: assetStore.assetId,
        q: searchQuery.value,
        page: currentPage.value - 1,
        sort: sortKey.value,
        order: sortOrder.value,
        filter: selectedFilter.value,
      },
    })
    myData.value = data.data.entitiyData.detail
    totalCount.value = data.data.entitiyData.totalCount
    pageCount.value = data.data.entitiyData.pageCount
  } catch (err) {
    responseHandlerService.handleError(err, t('entity.fetchError'))
  } finally {
    isLoading.value = false
    isInitialLoading.value = false
  }
}

const handleSearch = (query) => {
  searchQuery.value = query
  currentPage.value = 1
  fetchData()
}

const handleFilter = ({ itemId }) => {
  selectedFilter.value = itemId
  currentPage.value = 1
  fetchData()
}

const handleSort = ({ key, order }) => {
  sortKey.value = key
  sortOrder.value = order
  fetchData()
}

const handlePageChange = (page) => {
  currentPage.value = page
  fetchData()
}

const handleRowClick = (item) => {
  selectedContent.value = item.id
}

const handleContentUpdated = () => {
  selectedContent.value = null
  fetchData()
}

const checkIfTextOverflows = (event) => {
  const element = event.target
  if (element.offsetWidth < element.scrollWidth)
    titleTooltipVisible.value = true
}

const handleNewContentClick = () => {
  router.push({ query: { tab: 'new-content' } })
}

onMounted(() => {
  fetchData()
})
</script>