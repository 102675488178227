<template>
  <div class="overflow-auto h-full w-full p-4">
    <ul class="list-none cursor-pointer" v-if="histories && histories.length > 0">
      <li v-for="(content, index) in histories" :key="index" @click="onclick(content)" class="border-b border-gray-200 py-2 flex justify-between items-center hover:bg-gray-300/10 transition-all duration-300 p-1">
        <div class="flex flex-col space-y-1">
          <p class="font-semibold text-ellipsis overflow-hidden">
            <span class="text-gray-950 text-xs">{{ t('entity.components.contentReview.component.contentHistory.content.title') }}:</span> {{ content.title }} </p>
          <p class="text-xs text-primary font-semibold">
            <span class="text-gray-950">{{ t('entity.components.contentReview.component.contentHistory.content.status') }}:</span> {{ getContentType(content.entityTypeId) }} </p>
          <p class="text-xs text-primary font-semibold" v-if="content.revisedContetId">
            <span class="text-gray-950">{{ t('entity.components.contentReview.component.contentHistory.content.changes') }}:</span> {{ getRevizedType(content.revisedContetId) }} </p>
        </div>
        <button @click.stop="onclick(content)" class="text-primary py-2 px-4">
          {{ t('entity.components.contentReview.component.contentHistory.content.select') }}
        </button>
      </li>
    </ul>
    <div v-else class="w-full flex items-center">
      <p class="font-semibold">
        {{ t('entity.components.contentReview.component.contentHistory.modal.emptyMessage') }} </p>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  histories: {
    type: Array,
    required: false,
  },
})

const emit = defineEmits(['withdrawHistory'])

const contentCategory = {
  title: 1,
  category: 2,
  content: 3,
  seoKeyword: 4,
  image: 5,
  metaDescription: 6,
}

const getContentType = (entityTypeId) => {
  switch (entityTypeId) {
    case 1:
      return t('entity.components.contentReview.component.contentHistory.content.types.new')
    case 2:
      return t('entity.components.contentReview.component.contentHistory.content.types.existing')
    case 3:
      return t('entity.components.contentReview.component.contentHistory.content.types.optimized')
    case 5:
      return t('entity.components.contentReview.component.contentHistory.content.types.autoOptimized')
    case 6:
      return t('entity.components.contentReview.component.contentHistory.content.types.revised')
    default:
      return ''
  }
}

const getRevizedType = (revisedContetId) => {
  switch (revisedContetId) {
    case contentCategory.title:
      return t('entity.components.contentReview.component.contentHistory.content.categories.title')
    case contentCategory.category:
      return t('entity.components.contentReview.component.contentHistory.content.categories.category')
    case contentCategory.content:
      return t('entity.components.contentReview.component.contentHistory.content.categories.content')
    case contentCategory.seoKeyword:
      return t('entity.components.contentReview.component.contentHistory.content.categories.seoKeyword')
    case contentCategory.image:
      return t('entity.components.contentReview.component.contentHistory.content.categories.image')
    case contentCategory.metaDescription:
      return t('entity.components.contentReview.component.contentHistory.content.categories.metaDescription')
  }
}

const onclick = (content) => {
  emit('withdrawHistory', content.id)
}
</script>