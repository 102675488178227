// RecommendationsSection.vue
<template>
  <div class="bg-white rounded-xl shadow-sm p-6">
    <h2 class="text-lg font-semibold mb-4 flex items-center gap-2">
      <span class="material-symbols-outlined text-primary">lightbulb</span> {{ t('entity.components.contentReview.recommendations') }} </h2>
    <div class="grid gap-4">
      <RecommendationItem v-if="entityData.semanticMessage" title="semanticStructure" :message="entityData.semanticMessage"/>
      <RecommendationItem v-if="entityData.spellingMessage" title="spelling" :message="entityData.spellingMessage"/>
      <SeoShortcomings v-if="entityData.seoShortcomings" :shortcomings="parsedSeoShortcomings"/>
    </div>
  </div>
</template>

<script setup>
import RecommendationItem from './RecommendationItem.vue'
import SeoShortcomings from './SeoShortcomings.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  entityData: Object,
  parsedSeoShortcomings: Array,
})
</script>