<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'seo'" @change-tab="handleTabChange"></tab-navigation>
  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef" @saving="(val) => buttonLoading = val"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import seoTab from './components/seo/index.vue'
import google from './components/analythics/index.vue'

import TabNavigation from '@/components/base/navigation/TabNavigation.vue'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()

const buttonLoading = ref(false)
const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'seo')

// Dil değiştiğinde güncellenecek tabs
const tabs = computed(() => [
  { name: 'seo', label: t('dashboard.tabs.seo'), component: markRaw(seoTab) },
  { name: 'analytics', label: t('dashboard.tabs.analytics'), component: markRaw(google) },
])

const currentTabComponent = computed(() => {
  const tab = tabs.value.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

watch(() => route.query.tab, (newTab) => {
  currentTab.value = newTab || 'seo'
})
</script>