<template>
  <div class="flex flex-col space-y-4 w-full">
    <div class="bg-white p-4 rounded-lg shadow-sm">
      <h2 class="text-2xl font-bold text-secondary mb-5">{{ t('informationList.titles.general') }}</h2>
      <customInput type="textarea" :label="t('assets.components.create.components.information.informationList.labels.aboutUs')" :description="t('assets.components.create.components.information.informationList.descriptions.aboutUs')" containerClass="w-full" additionalInputClass="h-40 bg-transparent resize-none" v-model="list.aboutUs" :loading="assetStore.assetData.inputLoading"/>
      <customFileInput :label="t('assets.components.create.components.information.informationList.labels.logo')" :description="t('assets.components.create.components.information.informationList.descriptions.logo')" @file-changed="onLogoChange"/>
    </div>

    <div class="bg-white p-4 rounded-lg shadow-sm">
      <h2 class="text-2xl font-bold text-secondary mb-5">{{ t('informationList.titles.socialMedia') }}</h2>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center mb-5">
        <customInput :label="t('assets.components.create.components.information.informationList.labels.facebookUrl')" :description="t('assets.components.create.components.information.informationList.descriptions.facebookUrl')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.facebookUrl" :loading="assetStore.assetData.inputLoading"/>
        <customInput :label="t('assets.components.create.components.information.informationList.labels.instagramUrl')" :description="t('assets.components.create.components.information.informationList.descriptions.instagramUrl')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.instagramUrl" :loading="assetStore.assetData.inputLoading"/>
      </div>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center mb-5">
        <customInput :label="t('assets.components.create.components.information.informationList.labels.twitterUrl')" :description="t('assets.components.create.components.information.informationList.descriptions.twitterUrl')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.twitterUrl" :loading="assetStore.assetData.inputLoading"/>
        <customInput :label="t('assets.components.create.components.information.informationList.labels.linkedinUrl')" :description="t('assets.components.create.components.information.informationList.descriptions.linkedinUrl')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.linkedinUrl" :loading="assetStore.assetData.inputLoading"/>
      </div>
    </div>

    <div class="bg-white p-4 rounded-lg shadow-sm">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{ t('assets.components.create.components.information.informationList.titles.recommendedInfo') }} </h2>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center mb-5">
        <tagInput :label="t('assets.components.create.components.information.informationList.labels.keywords')" :description="t('assets.components.create.components.information.informationList.descriptions.keywords')" v-model="keywords" containerClass="w-full" :secondary="true"/>
      </div>
    </div>

    <div class="bg-white p-4 rounded-lg shadow-sm">
      <h2 class="text-2xl font-bold text-secondary mb-5">
        {{ t('assets.components.create.components.information.informationList.titles.contact') }} </h2>
      <div class="flex flex-col space-x-0 md:flex-row md:space-x-4 items-center mb-5">
        <customInput :label="t('assets.components.create.components.information.informationList.labels.phone')" type="text" :description="t('assets.components.create.components.information.informationList.descriptions.phone')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.phone" :loading="assetStore.assetData.inputLoading"/>
        <customInput :label="t('assets.components.create.components.information.informationList.labels.wphone')" type="text" :description="t('assets.components.create.components.information.informationList.descriptions.wphone')" additionalInputClass="bg-transparent" containerClass="w-full" v-model="list.wphone" :loading="assetStore.assetData.inputLoading"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import customInput from '@/components/base/inputs/VInput.vue'
import tagInput from '@/components/base/inputs/VTagInput.vue'
import customFileInput from '@/components/base/inputs/VFileInput.vue'

const { t } = useI18n

const keywords = ref([])
const isValid = ref(true)
const assetStore = useAssetStore()

const list = computed(() => assetStore.assetData.informationList)

const finalize = async () => {
  list.value.keywords = keywords.value.map(keyword => keyword.name)
  return isValid.value
}

const onLogoChange = (file) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    // list.value.logo = e.target.result;
  }
  reader.readAsDataURL(file)
}

onMounted(() => {
  if (Array.isArray(list.value.keywords) && list.value.keywords.length) {
    keywords.value = list.value.keywords.map((keyword) => ({ name: keyword }))
  }
  setTimeout(() => {
    assetStore.setAssetInputLoading(false)
  }, 1000)
})

watch(keywords, (newKeywords) => {
  list.value.keywords = newKeywords.map(keyword => keyword.name)
})

defineExpose({
  finalize,
})
</script>
