import CryptoJS from 'crypto-js'

const SECRET_KEY = process.env.VUE_APP_ENCRYPTION_SECRET_KEY
if (!SECRET_KEY) {
  throw new Error('Encryption key not found in environment variables')
}

export const encryptData = (data) => {
  if (!data) return null

  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString()
  } catch (error) {
    console.error('Encryption error:', error)
    return null
  }
}

export const decryptData = (encryptedData) => {
  if (!encryptedData) return null

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY)
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8)

    if (!decryptedString) return null

    return JSON.parse(decryptedString)
  } catch (error) {
    console.error('Decryption error:', error)
    return null
  }
}

export const encryptedStorage = {
  getItem: (key) => {
    try {
      const encrypted = localStorage.getItem(key)
      if (!encrypted) return null

      return decryptData(encrypted)
    } catch (error) {
      console.error('Storage getItem error:', error)
      return null
    }
  },

  setItem: (key, value) => {
    try {
      const encrypted = encryptData(value)
      if (!encrypted) return

      try {
        localStorage.setItem(key, encrypted)
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          console.error('Storage quota exceeded')
          if (key === 'auth-store') {
            // Auth store için son bir deneme
            localStorage.clear()
            localStorage.setItem(key, encrypted)
          }
        }
      }
    } catch (error) {
      console.error('Storage setItem error:', error)
    }
  },

  removeItem: (key) => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error('Storage removeItem error:', error)
    }
  },
}

// Pinia store'lar için basit config oluşturucu
export const createStorageConfig = (storeName, options = {}) => ({
  key: storeName,
  storage: encryptedStorage,
  ...options,
})